import { Box, Grid, Paper, Typography } from '@mui/material';
import { style } from '../../Utils/themes/styles';
import { IMAGES } from '../../asset/Dis';
import ComButtom from '../formComponents/button/ComButtom';
import { useEffect } from 'react';

const Styles = {
    root: {
        borderRadius: '12px'
    },
    formSubHeading: {
        fontWeight: "500",
        color: style.palette.TitleColor,
        width: "400px",
        textAlign: "center"
    },
    subHeadParent: {
        display: "flex",
        justifyContent:'center',
        alignItems:'center',
        '& .MuiTypography-root': {
            width: '500px',
        }
    },
    popupBox: {
        width: "583px",
        height: "335px",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
    },
    popUpContainer: {
        height: '100%',
        borderRadius: "12px",
    },
    childComponent: {
        overflowY: "auto",
        padding: "0 20px",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        justifyContent: "center",
        gap: "20px"
    },
    buttonStyle: {
        width: "240px",
        height: "60px",
        borderRadius: "4px",
        margin: "10px 0",
        // border: `1px solid ${style.palette.GrayStroke}`,
    },
    footerBtn: {
        display: "flex",
        justifyContent: "flex-end",
        gap: "10px",
        '& .MuiButtonBase-root-MuiButton-root': {
            border: 'none',
            '&:hover': {
                border: 'none',
            }
        }
    }
};


interface IConfirmationText {
    confirmationMessage?: string;
    success?: boolean;
    infoText?: string;
    handleCloseModal: () => void;
    handleConfirm?: () => void;
    confirm?:boolean;
}

const ConfirmationText = ({ confirmationMessage, success, infoText, handleCloseModal, handleConfirm}: IConfirmationText) => {
    useEffect(() => {
        const submitTimeout = setTimeout(() => {
            if(success){
            handleCloseModal();
            }
        }, 3000);
        return () => {
            clearTimeout(submitTimeout)
        }
    }, [success])
    return (
        <Grid item xs={6} sx={Styles.popupBox}>
            <Paper sx={{ ...Styles.root, ...Styles.popUpContainer }}>
                <Box sx={Styles.childComponent}>
                    {success &&
                        <Box>
                            <img src={IMAGES.successIcon} alt='success' height='50px' />
                        </Box>
                    }
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '100%' }, display: "flex",
                            flexDirection: 'column',justifyContent:'center',width:'100%',alignItems:'center'
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid item xs={6} sx={Styles.subHeadParent}>
                            <Typography variant="h3" sx={Styles.formSubHeading}>{confirmationMessage}</Typography>
                        </Grid>
                        {infoText &&
                            <Grid item xs={6} sx={Styles.subHeadParent}>
                                <Typography variant="h3" sx={Styles.formSubHeading}>{infoText}</Typography>
                            </Grid>
                        }
                    </Box>
                    {!success &&
                        <Box sx={Styles.footerBtn}>
                            <ComButtom
                                label={"Cancel"}
                                variant={"outlined"}
                                textVariant="button"
                                inputStyle={Styles.buttonStyle}
                                onClick={handleCloseModal}
                            />
                            <ComButtom
                                label={"Continue"}
                                variant={"contained"}
                                textVariant="button"
                                inputStyle={Styles.buttonStyle}
                                onClick={handleConfirm}
                            />
                        </Box>
                    }
                </Box>
            </Paper>
        </Grid>
    )
}

export default ConfirmationText