import { MenuItem, Select, Stack, SxProps, Typography } from '@mui/material'
import React from 'react'
import { WarehouseData } from '../../../Pages/Dis/common/types';
import { styled } from '@mui/system';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { style } from '../../../Utils/themes/styles';
import { FormikTouched } from 'formik';

const Styles = {
    inputStyle : (error?:string,touched?:FormikTouched<unknown>)=>({
        width:'100%',
        '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${error && touched  ? style.palette.CellDataNotFoundColor : style.palette.black[50]}`,
        },
    }),
    errorMessage: {
        ...style.typography.caption,
        color: style.palette.CellDataNotFoundColor,
        marginTop: '4px',
    }
}

interface ISelectInput {
    id?: string;
    value?: unknown;
    options: WarehouseData[];
    label?: string;
    sx?:SxProps;
    required?:boolean;
    onChange?: SelectInputProps<unknown>['onChange'];
    error?:string;
    touched?:FormikTouched<unknown>;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const RequiredTypography = styled(Typography)({
    '&::after': {
        content: '" *"',
        color: 'red',
    },
});

const SelectInput = ({ id, value, onChange, options, label,sx,required,error ,touched,onBlur}: ISelectInput) => {
    console.log("touched",touched);
    
    return (
        <Stack>
             {label && required ? <RequiredTypography variant='h4'>{label}</RequiredTypography> : <Typography variant='h4'>{label}</Typography>}
            <Select
                labelId="select-label"
                id={id}
                value={value}
                // label={label}
                onChange={onChange}
                onBlur={onBlur}
                sx={{...Styles.inputStyle(error,touched),...sx}}
            >
                {options?.map((item) => {
                    return (
                        <MenuItem value={item.title} key={item.id}>{item.name}</MenuItem>
                    )
                })}
            </Select>
            {error && touched ? (
                <Typography sx={Styles.errorMessage}>
                    {error}
                </Typography>
            ) : null}
        </Stack>
    )
}

export default SelectInput