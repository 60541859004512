import { Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { IMAGES } from '../../../asset/Dis'
import { style } from '../../../Utils/themes/styles'
import { SxProps } from '@mui/system'
import { baseTheme } from '../../../Utils/themes/base'

const Styles = {
    root: {
        borderRadius: '12px',
        minHeight:'190px !important'
    },
    header: {
        padding: '28px 20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'nowrap',
        borderBottom: `1px solid ${style.palette.GrayStroke}`,
        '& .MuiTypography-h6': {
            color: style.palette.TitleColor,
            [baseTheme.breakpoints.down('lg')]: {
                fontSize: "14px"
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: "14px"
            }
        },
        'img': {
            marginRight: '12px',
            height: '24px',
            [baseTheme.breakpoints.down('lg')]: {
                height: '20px',
            },
            [baseTheme.breakpoints.up('lg')]: {
                height: '20px',
            }
        },
        [baseTheme.breakpoints.down('lg')]: {
            padding: '20px 20px',
        },
        [baseTheme.breakpoints.up('lg')]: {
            padding: '20px 20px',
        }
    },
    leftContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    rightContainer: {
        height: '24px',
        display: "flex",
        alignItems: "center"
    },
    children: {
        padding: '20px',
        height: '100%'
    }
}

interface IListContainer {
    children?: React.ReactNode;
    leftContainer?: string;
    rightContainer?: React.ReactNode;
    sx?: SxProps;
    headerStyle?: SxProps;
    childContainerStyle?: SxProps;
}

const ListContainer = ({
    children,
    leftContainer,
    rightContainer,
    sx,
    headerStyle,
    childContainerStyle
}: IListContainer) => {

    const ContainerStyle: SxProps = Styles.header;
    const ConatinerStyleProps = {
        ...ContainerStyle as SxProps,
        ...headerStyle
    }
    return (
        <Paper sx={{ ...Styles.root, ...sx }} className='customeScroll'>
            <Grid container sx={ConatinerStyleProps} >
                <Grid sx={Styles.leftContainer}>
                    <img src={IMAGES.LineImage} alt='line' />
                    <Typography variant='h6'> {leftContainer}</Typography>
                </Grid>
                <Grid sx={Styles.rightContainer}>
                    {rightContainer}
                </Grid>
            </Grid>
            <Box sx={{ ...Styles.children, ...childContainerStyle }} className='customeScroll'>
                {children}
            </Box>
        </Paper>
    )
}

export default ListContainer