import React, { MouseEventHandler } from 'react';
import { Box, Button, SxProps, Theme, Typography, TypographyProps } from '@mui/material'
import { style } from '../../../Utils/themes/styles';
import { baseTheme } from '../../../Utils/themes/base';

const Styles = {
    root:{
        '& .MuiTypography-subTitle1': {
            fontSize: "13px",
            [baseTheme.breakpoints.down('lg')]: {
                fontSize: "11px !important"
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: "11px !important"
            }
        },
    },
    button: (varinat: string): SxProps => ({
        borderRadius: '8px',
        border: 'none',
        background: varinat === 'contained' ? style.palette.green[500] : style.palette.BtnBgColor,
        height: '80px',
        width: '100%',
        color: varinat === 'contained' ? style.palette.Neutral : style.palette.black[300],
        fontFamily: 'Poppins',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        '&:hover': {
            background: varinat === 'contained' ? style.palette.green[500] : style.palette.BtnBgColor,
            border: varinat === 'outlined' ? `1px solid ${style.palette.black[300]}` : 'none',

        },
        '& .MuiTypography-button': {
            color: varinat === 'contained' ? style.palette.Neutral : style.palette.black[300]
        }
    })
}

type ButtonVariant = 'text' | 'outlined' | 'contained';
type TypographyVariant = TypographyProps['variant'];
type ColorVariants = "inherit" | "success" | "primary" | "secondary" | "error" | "info" | "warning" | undefined;
type ButtonProps = "button" | "reset" | "submit" | undefined;

interface IComButtom {
    variant: ButtonVariant;
    disabled?: boolean;
    href?: string;
    onClick?:MouseEventHandler<HTMLButtonElement>;
    color?: ColorVariants;
    boxStyle?: SxProps<Theme>;
    inputStyle: SxProps<Theme>;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    label?: string;
    textVariant?: TypographyVariant;
    type?: ButtonProps;
}



const ComButtom = ({
    variant,
    disabled,
    href,
    onClick,
    color,
    boxStyle,
    inputStyle,
    startIcon,
    endIcon,
    label,
    textVariant,
    type
}: IComButtom) => {
    const buttonStyle: SxProps = Styles.button(variant);
    const styledProps = {
        ...(buttonStyle as SxProps),
        ...inputStyle,
    };
    return (
        <Box sx={{...Styles.root,...boxStyle}}>
            <Button
                type={type}
                variant={`${variant}`}
                disabled={disabled}
                href={href}
                onClick={onClick}
                color={color}
                startIcon={startIcon}
                endIcon={endIcon}
                sx={styledProps}
            ><Typography variant={textVariant}>{label}</Typography></Button>
        </Box>
    )
}

export default ComButtom