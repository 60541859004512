import { Box, Stack } from '@mui/material';
import ListViewWarehouse from '../../../common/components/ListViewWarehouse';
import { useTranslation } from 'react-i18next';
import { style } from '../../../../Utils/themes/styles';
import ActiveButton from '../../../../components/formComponents/button/ActiveButton';
import { AllWarehouseDataTypes } from '../../../common/interfaces';
import { Dispatch, SetStateAction } from 'react';
import { baseTheme } from '../../../../Utils/themes/base';

const Styles = {
    accordian: {
        height: '100%',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {
            background: style.palette.ActiveBgColor,
            '& .MuiTypography-root': {
                color: style.palette.green[500]
            },
            '& .MuiSvgIcon-root': {
                color: style.palette.green[500]
            }
        },
        '& .MuiAccordionSummary-content': {
            margin: '11px 0px'
        },
        '& .MuiAccordion-root': {
            marginBottom: '10px',
            background: style.palette.BgColor,
            border: 'none',
            boxShadow: "none",
            position: 'unset',
        },
        '& .MuiCollapse-wrapper': {
            background: style.palette.Neutral
        },
        '& .MuiAccordionDetails-root': {
            padding: '8px 0px 0px'
        },


    },
    AccordianHeading: {
        ...style.typography.subtitle1,
        fontSize: '14px',
        '& .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {
            color: style.palette.green[500]
        }
    },
    buttonStyle: {
    }
}

interface IAllWarehouse {
    AllWarehouseData: AllWarehouseDataTypes;
    warehouseSelect?: string;
    handleWarehouseSelect?: (id: string | undefined) => void;
    setNewWarehouseStatus?: Dispatch<SetStateAction<boolean>>;
    setDeleteWarehouseStatus?: Dispatch<SetStateAction<boolean>>;
}

const AllWarehouse = ({ AllWarehouseData, warehouseSelect, handleWarehouseSelect, setNewWarehouseStatus, setDeleteWarehouseStatus }: IAllWarehouse) => {
    const { t } = useTranslation();
    return (
        <Box sx={{
            ...Styles.accordian, [baseTheme.breakpoints.down('md')]: {
                height: 'initial',
            }
        }}>
            <Stack>

                {
                    AllWarehouseData.map((item: AllWarehouseDataTypes) => {
                        return (
                            <ListViewWarehouse
                                key={item?.id}
                                id={item?.id}
                                title={item?.name}
                                image={item?.image1}
                                image2={item?.image2}
                                warehouseSelect={warehouseSelect}
                                handleWarehouseSelect={handleWarehouseSelect}
                                setDeleteWarehouseStatus={setDeleteWarehouseStatus}
                            />
                        )
                    })
                }

            </Stack>
            <ActiveButton
                label={t(`${"CreateNewWarehouse"}`)}
                variant="contained"
                textVariant="subtitle1"
                boxStyle={Styles.buttonStyle}
                inputStyle={{ height: '40px' }}
                onClick={() => setNewWarehouseStatus?.(true)}

            />
        </Box>
    )
}

export default AllWarehouse;