import { Box, Grid } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import FormContainer from '../../../common/components/FormContainer';
import { IMAGES } from '../../../../asset/Dis';
import { Dispatch, SetStateAction } from 'react';
import { style } from '../../../../Utils/themes/styles';
import { baseTheme } from '../../../../Utils/themes/base';
import { AllWarehouseDataTypes } from '../../../common/interfaces';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

const Styles = {
    popupBox: {
        width: "583px",
        height: "720px",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        [baseTheme.breakpoints.down('sm')]: {
            height: "580px",
            width: "520px"
        },
    },
    popUpContainer: {
        height: '100%',
        borderRadius: "12px"
    },
    childComponent: {
        height: '81%',
        padding: "0 20px",
        overflowX: "hidden"
    }
}

interface IEditWarehouse {
    setEditWarehouseStatus: Dispatch<SetStateAction<boolean>>
    selectedWarehouse?: string;
}

const EditWarehouse = ({ setEditWarehouseStatus, selectedWarehouse }: IEditWarehouse) => {
    const { t } = useTranslation();
    const warehouseList: AllWarehouseDataTypes = useSelector((state: RootState) => state.warehouseReducer.warehouses);
    const WarehouseDetails = warehouseList.find((item: AllWarehouseDataTypes) => item.id == selectedWarehouse);

    const handleClose = () => {
        setEditWarehouseStatus(false)
    }
    return (
        <Box>
            <Grid item xs={6} sx={Styles.popupBox}>
                <FormContainer
                    leftContainer={t("Edit Warehouse")}
                    rightContainer={
                        <img src={IMAGES.crossIcon} alt='search' height='24px' onClick={handleClose} />
                    }
                    sx={Styles.popUpContainer}
                    childContainerStyle={Styles.childComponent}
                    submitText={t("Edit")}
                    handleStatus={()=>setEditWarehouseStatus(false)}
                    WarehouseDetails={WarehouseDetails}
                />
            </Grid>
        </Box>
    )
}

export default EditWarehouse;