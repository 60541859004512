// LoadingDetailsBox.tsx
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Box, Typography, TextField, Divider, Stack, Grid } from '@mui/material';
import { style } from '../../../Utils/themes/styles';
import { IMAGES } from '../../../asset/Dis';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { baseTheme } from '../../../Utils/themes/base';
import { useLocation } from 'react-router-dom';
import ComButtom from '../../../components/formComponents/button/ComButtom';

const Styles = {
    popupBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "stretch",
        border: `1px solid ${style.palette.black[50]}`,
        borderRadius: '8px',
        padding: '24px 32px',
        backgroundColor: "#E6E6E61A",
        width: '642px',
        [baseTheme.breakpoints.down('lg')]: {
            width: '475px',
        },
    },
    divider: {
        backgroundColor: style.palette.GrayStroke,
        marginBottom: '20px',
        width: '642px',
        position: 'relative',
        left: '-33px',
        [baseTheme.breakpoints.down('lg')]: {
            width: '475px',
        },
    },
    editButton: {
        fontSize: '14px',
        color: style.palette.green[500],
        cursor: 'pointer'
    },
    editIcon: {
        height: '14px',
        width: '14px',
        marginLeft: '5px',
    },
    detailsLoading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& .css-m7i2ec-MuiTypography-root': {
            color: style.palette.black[300]
        },
        '& .css-2bot5r-MuiInputBase-root-MuiOutlinedInput-root': {
            height: '98px',
            alignItems: 'flex-start',
            fontWeight: 400,
            fontSize: '12px',
        },
        '& .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input': {
            color: style.palette.black[100]
        },
    },
    alignFieldName: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
    },
    comments: {
        '& .css-q0igbi-MuiTypography-root': {
            display: 'flex',
            justifyContent: 'space-between',
        },
        '& .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input': {
            color: style.palette.black[100]
        },
        '& .css-2bot5r-MuiInputBase-root-MuiOutlinedInput-root': {
            fontWeight: 400,
            fontSize: '14px',
        },
        '& .MuiTypography-h5': {
            marginBottom:'12px'
        }
    },
    commentButton: {
        fontSize: '14px',   
        color: style.palette.green[500],
        cursor: 'pointer'
    },
    loadingSlipBox: {
        fontSize: '14px',
        color: style.palette.black[400],
        textAlign: 'left',
        borderRadius: '4px',
    },
    receiptSlipAlign: {
        border: `1px solid ${style.palette.black[50]}`,
        borderRadius: '4px',
        padding: '8px'
    },
    pdfIconStyle: {
        height: '21px'
    },
    buttonStyle: {
        height: "40px",
        border: `1px solid ${style.palette.green[500]}`,
        color:style.palette.green[500]
    },
};

interface loadingStates {
    setLoadingSteps: Dispatch<SetStateAction<string>>
}
const LoadingDetailsBox: React.FC<loadingStates> = ({ setLoadingSteps }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [isCommentsEditable, setIsCommentsEditable] = useState<boolean>(false);

    const handleEditDetailsClick = () => {
        console.log("edit");
        setLoadingSteps("modifyLoading")
    };

    const handleAddCommentsClick = () => {
        setIsCommentsEditable(!isCommentsEditable);
    };

    const handleScanEAN = () =>{
        console.log("handleScanEAN");
        
    }

    return (
        <Box sx={Styles.popupBox}>
            <Formik
                initialValues={{
                    loadingDetails: "GRAPHIPAN CEE 103\n90 m2–25 PACKAGE",
                    loadingLocation: "Warehouse Name\nAddress 1\nAddress 2",
                    comments: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"
                }}

                onSubmit={(values) => {
                    console.log(values);
                }}
            >
                {({ values, handleChange, handleBlur }) => (
                    <Form>
                        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
                            <Typography variant="h4">{t("ConfirmLoadingDetails")}</Typography>
                            {location.pathname !== '/receipt' &&
                                <Typography variant="h5" sx={Styles.editButton} onClick={handleEditDetailsClick}>
                                    {t("EditDetails")}
                                    <img src={IMAGES.editIcon2} alt="edit" style={Styles.editIcon} />
                                </Typography>
                            }
                        </Box>
                        <Divider sx={Styles.divider} orientation="horizontal" flexItem />
                        <Box display="flex" gap={4} marginBottom={2}>
                            <Box flex={1} sx={Styles.detailsLoading}>
                                {location.pathname !== '/receipt' ?
                                    <Typography variant="h5">{t("LoadingDetails")}</Typography>
                                    :
                                    <Stack sx={Styles.alignFieldName}>
                                        <Typography variant="h5">{t("LoadingDetails")}</Typography>
                                        <Typography variant="h5" sx={Styles.editButton} onClick={handleEditDetailsClick}>
                                            {t("EditDetails")}
                                            <img src={IMAGES.editIcon2} alt="edit" style={Styles.editIcon} />
                                        </Typography>
                                    </Stack>
                                }
                                <Field
                                    as={TextField}
                                    name="loadingDetails"
                                    multiline
                                    variant="outlined"
                                    fullWidth
                                    value={values.loadingDetails}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{ readOnly: true }}
                                />
                            </Box>
                            <Box flex={1} sx={Styles.detailsLoading}>
                                {location.pathname !== '/receipt' ?
                                    <Typography variant="h5">{t("LoadingLocation")}</Typography>
                                    :
                                    <Stack sx={Styles.alignFieldName}>
                                        <Typography variant="h5">{t("LoadingLocation")}</Typography>
                                        <Typography variant="h5">
                                            <Typography variant="h5" sx={Styles.commentButton} onClick={handleAddCommentsClick}>+{t("AddComments")}</Typography>
                                        </Typography>
                                    </Stack>
                                }
                                {location.pathname !== '/receipt' ?
                                    <Field
                                        as={TextField}
                                        name="loadingLocation"
                                        multiline
                                        variant="outlined"
                                        fullWidth
                                        value={values.loadingLocation}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{ readOnly: true }}
                                    />
                                    :
                                    <Field
                                        as={TextField}
                                        name="comments"
                                        multiline
                                        variant="outlined"
                                        fullWidth
                                        value={values.comments}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{ readOnly: !isCommentsEditable }}
                                    />
                                }
                            </Box>
                        </Box>
                        <Box sx={Styles.comments}>
                            {location.pathname !== '/receipt' &&
                                <>
                                    <Typography variant="h5">
                                        {t("Comments")} <Typography variant="h5" sx={Styles.commentButton} onClick={handleAddCommentsClick}>+{t("AddComments")}</Typography>
                                    </Typography>
                                    <Field
                                        as={TextField}
                                        name="comments"
                                        multiline
                                        variant="outlined"
                                        fullWidth
                                        value={values.comments}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{ readOnly: !isCommentsEditable }}
                                    />
                                </>


                            }
                            {location.pathname === '/receipt' &&
                                <>
                                    <Typography variant="h5">{t("CompleteReceiptForm")}</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Box sx={Styles.receiptSlipAlign}>
                                                <Typography variant="subtitle1" sx={Styles.loadingSlipBox}>
                                                    <img src={IMAGES.pdfIcon} style={Styles.pdfIconStyle} alt="pdf" /> Receipt slip
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ComButtom
                                                label={"SCAN EAN"}
                                                variant={"outlined"}
                                                textVariant="subtitle1"
                                                inputStyle={Styles.buttonStyle}
                                                onClick={handleScanEAN}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default LoadingDetailsBox;
