import axios from 'axios'

const BaseUrl = process.env.REACT_APP_APP_URL;
const APIKey = process.env.REACT_APP_API_KEY;
const APISecret = process.env.REACT_APP_API_SECRET;

const instance = axios.create({
  baseURL: BaseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use((config) => {
  config.headers['api_key'] = APIKey;
  config.headers['api_secrete'] = APISecret;
  const state = localStorage.getItem('state');
  const token = state ? JSON.parse(state).userReducer.authToken : null;
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;