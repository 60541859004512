import React from 'react'
import ModalView from '../../../common/components/ModalView'
import { Box, SelectChangeEvent, Typography } from '@mui/material'
import SelectCategories from '../../../../components/formComponents/select/SelectCategories'
import ActiveButton from '../../../../components/formComponents/button/ActiveButton'
import { style } from '../../../../Utils/themes/styles'

const Styles = {
  ModalBody: {
    padding: '16px 16px 0px',
    '& .MuiTypography-body1': {
      fontSize: '12px',
      fontWeight: 400,
      color: style.palette.black[300]
    },
    '& .MuiTypography-h5': {
      fontSize: '12px',
      display: 'flex',
      justifyContent: 'center',
      color: style.palette.black[300]
    }
  },
  ModalFooter: {
    borderTop: `1px solid ${style.palette.black[50]}`,
    padding: '0px 16px 0px',
    marginTop: '16px',
  },
  selectTypeStyle: {
    width: '551px',
    background: 'transparent',
    backgroundColor: 'transparent',
  },
  continueButtonInputStyle: {
    width: '122px',
    height: '40px'
  },
  continueButtonStyle: {
    marginTop: '9.5px',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px',

  },
}

type CategoryOptionType = {
  title: string;
  value: string;
}[];

interface ISelectAppointment {
  open: boolean;
  handleClose?: () => void;
  options?: CategoryOptionType;
  category?: string;
  handleChangeAppointmentType?: (data: SelectChangeEvent) => void;
  handleAddEvent?: () => void;
}

const SelectAppointment = ({ open, handleClose, options, category, handleChangeAppointmentType, handleAddEvent }: ISelectAppointment) => {
  return (
    <ModalView
      open={open}
      handleClose={handleClose}
      title={"Create a new appointment"}
    >
      <Box sx={Styles.ModalBody}>
        <Typography variant='body1'>Select appointment type</Typography>
        <SelectCategories
          options={options}
          category={category}
          handleChange={handleChangeAppointmentType}
          placeholder={"Appointment Type"}
          sx={Styles.selectTypeStyle}
        />
        <Box sx={Styles.ModalFooter}>
          <ActiveButton
            label="Continue"
            variant="contained"
            textVariant="h6"
            boxStyle={Styles.continueButtonStyle}
            inputStyle={Styles.continueButtonInputStyle}

            onClick={handleAddEvent} />
        </Box>
      </Box>


    </ModalView>
  )
}

export default SelectAppointment