import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { Typography } from '@mui/material'
import { style } from "../../../Utils/themes/styles";
import { rows } from "../../Dis/common/constant";
const Styles = {
    subHeading: {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "24px",
        textAlign: "center",
        color: style.palette.black[ 300 ],
    },
    grayBorderRight: {
        borderRight: `1px solid ${style.palette.GrayStroke}`,
    },
    grayBorderAll: {
        border: `1px solid ${style.palette.GrayStroke}`,
    },
    subValue: {
        color: style.palette.black[ 200 ],
    },
    width80: {
        width: "80px"
    },
    width100: {
        width: "100px"
    }
}
export default function StockTableList() {
    const { t } = useTranslation();
    return (
        <TableContainer component={ Paper } >
            <Table sx={ { minWidth: 650 } } aria-label="simple table">
                <TableHead>
                    <TableRow sx={ Styles.grayBorderAll }>
                        { " " }
                        {/* Header Row */ }
                        <TableCell
                            align="center"
                            sx={ Styles.grayBorderRight }
                            rowSpan={ 2 }
                        >
                            <Typography sx={ Styles.subHeading }>  { t("Site") }</Typography>
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={ Styles.grayBorderRight }
                            rowSpan={ 2 }
                        >
                            <Typography sx={ { ...Styles.subHeading, width: "220px" } }>  { t("Material") }</Typography>
                        </TableCell>
                        <TableCell
                            align="center"
                            colSpan={ 4 }
                            sx={ Styles.grayBorderRight }
                        >
                            <Typography sx={ Styles.subHeading }>  { t("PackagingAndVolume") }</Typography>
                        </TableCell>{ " " }
                        {/* Spanning for Fat */ }
                        <TableCell colSpan={ 3 } align="center" sx={ Styles.grayBorderRight }>
                            <Typography sx={ Styles.subHeading }>  { t("Forecast") }</Typography>
                        </TableCell>
                        <TableCell align="center"> <Typography sx={ Styles.subHeading }>  { t("Action") }</Typography></TableCell>
                    </TableRow>
                    <TableRow sx={ Styles.grayBorderAll }>
                        { " " }
                        {/* Subheader Row */ }
                        <TableCell align="center" sx={ Styles.grayBorderRight }>
                            <Typography sx={ { ...Styles.subHeading, ...Styles.width100 } }>  { t("Quantity") }</Typography>
                        </TableCell>
                        <TableCell align="center" sx={ Styles.grayBorderRight }>
                            <Typography sx={ { ...Styles.subHeading, ...Styles.width100 } }>  { t("Type") }</Typography>
                        </TableCell>
                        <TableCell align="center" sx={ Styles.grayBorderRight }>
                            <Typography sx={ { ...Styles.subHeading, width: "110px" } }>  { t("VolumeTotal") }</Typography>
                        </TableCell>
                        <TableCell variant="subtitle1" align="center" sx={ Styles.grayBorderRight }>
                            <Typography sx={ { ...Styles.subHeading, ...Styles.width80 } }>  { t("Unit") }</Typography>
                        </TableCell>
                        <TableCell align="center" sx={ Styles.grayBorderRight }>
                            <Typography sx={ { ...Styles.subHeading, ...Styles.width80 } }>  { t("week1") }</Typography>
                        </TableCell>
                        <TableCell align="center" sx={ Styles.grayBorderRight }>
                            <Typography sx={ { ...Styles.subHeading, ...Styles.width80 } }>  { t("week2") }</Typography>
                        </TableCell>
                        <TableCell variant="subtitle1" align="center" sx={ Styles.grayBorderRight }>
                            <Typography sx={ { ...Styles.subHeading, ...Styles.width80 } }>  { t("month1") }</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { rows.map((row) => (
                        <TableRow
                            key={ row.name }
                            sx={ Styles.grayBorderAll }
                        >
                            <TableCell
                                component="th"
                                scope="row"
                                sx={ Styles.grayBorderRight }
                            >
                                <Typography color="gray" sx={ { ...Styles.subHeading, ...Styles.subValue, width: "135px" } }>  { row.site }</Typography>
                            </TableCell>
                            <TableCell align="right" sx={ Styles.grayBorderRight }>
                                <Typography color="gray" sx={ { ...Styles.subHeading, ...Styles.subValue, textAlign: "left" } }>  { row.material }</Typography>
                            </TableCell>
                            <TableCell align="right" sx={ Styles.grayBorderRight }>
                                <Typography color="gray" sx={ { ...Styles.subHeading, ...Styles.subValue } }>  { row.PackagingAndVolume.quantity }</Typography>
                            </TableCell>
                            <TableCell align="right" sx={ Styles.grayBorderRight }>
                                <Typography color="gray" sx={ { ...Styles.subHeading, ...Styles.subValue } }>{ row.PackagingAndVolume.type }</Typography>
                            </TableCell>
                            <TableCell align="right" sx={ Styles.grayBorderRight }>
                                <Typography color="gray" sx={ { ...Styles.subHeading, ...Styles.subValue } }>{ row.PackagingAndVolume.volumeTotal }</Typography>
                            </TableCell>
                            <TableCell align="right" sx={ Styles.grayBorderRight }>
                                <Typography color="gray" sx={ { ...Styles.subHeading, ...Styles.subValue } }>{ row.PackagingAndVolume.unit }</Typography>
                            </TableCell>
                            <TableCell align="right" sx={ Styles.grayBorderRight }>
                                <Typography color="gray" sx={ { ...Styles.subHeading, ...Styles.subValue } }>{ row.forecast.week1 }</Typography>
                            </TableCell>
                            <TableCell align="right" sx={ Styles.grayBorderRight }>
                                <Typography color="gray" sx={ { ...Styles.subHeading, ...Styles.subValue } }>{ row.forecast.week2 }</Typography>
                            </TableCell>
                            <TableCell align="right" sx={ Styles.grayBorderRight }>
                                <Typography color="gray" sx={ { ...Styles.subHeading, ...Styles.subValue } }>{ row.forecast.month1 }</Typography>
                            </TableCell>
                            <TableCell align="right"><Typography color="gray" sx={ { ...Styles.subHeading, ...Styles.subValue } }>{ row.action }</Typography></TableCell>
                        </TableRow>
                    )) }
                </TableBody>
            </Table>
        </TableContainer>
    );
}
