import { Box, Stack } from '@mui/material';
import { style } from '../../../../Utils/themes/styles';
import DetailsView from '../../../common/components/DetailsView';
import { AllWarehouseDataTypes } from '../../../common/interfaces';


const Styles = {
    accordian: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {
            background: style.palette.ActiveBgColor,
            '& .MuiTypography-root': {
                color: style.palette.green[500]
            },
            '& .MuiSvgIcon-root': {
                color: style.palette.green[500]
            }
        },
        '& .MuiAccordionSummary-content': {
            margin: '11px 0px'
        },
        '& .MuiAccordion-root': {
            marginBottom: '10px',
            background: style.palette.BgColor,
            border: 'none',
            boxShadow: "none",
            position: 'unset',
        },
        '& .MuiCollapse-wrapper': {
            background: style.palette.Neutral
        },
        '& .MuiAccordionDetails-root': {
            padding: '8px 0px 0px'
        }

    },
}

interface IWarehouseDetails {
    AllWarehouseData: AllWarehouseDataTypes;
}

const WarehouseDetails = ({ AllWarehouseData }: IWarehouseDetails) => {
    console.log(AllWarehouseData)
    return (
        <Box sx={Styles.accordian}>
            <Stack>

                {
                    // AllWarehouseData.map((item) => {
                    // return (
                    <DetailsView
                        id={AllWarehouseData?.id}
                        title={AllWarehouseData?.name}
                        image={AllWarehouseData?.image1}
                        company={AllWarehouseData?.company_name}
                        contact={AllWarehouseData?.contact_no}
                        mail={AllWarehouseData?.mail}
                        address={AllWarehouseData?.address}
                        gap="24px"
                    />
                    // )
                    // })
                }

            </Stack>
        </Box>
    )
}

export default WarehouseDetails;