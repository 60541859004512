import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, Typography, Divider, Modal } from '@mui/material';
import { style } from '../../../../Utils/themes/styles';
import ComButtom from '../../../../components/formComponents/button/ComButtom';
import LoadingDetailsBox from '../../../common/components/LoadingDetailsBox';
import FileUploadBox from '../../../common/components/FileUploadBox';
import LoadingSummery from '../../../common/components/LoadingSummery';
import ModifyLoadingDetail from './ModifyLoadingDetail';
import PopupModal from '../../../common/components/PopupModal';
import { useTranslation } from 'react-i18next';
import { baseTheme } from '../../../../Utils/themes/base';
import { useLocation } from 'react-router-dom';

const Styles = {
    buttonStyle: {
        padding: '19px 35px',
        height: "40px",
        border: `1px solid ${style.palette.black[50]}`,
        borderRadius: "4px",
    },
    heading: {
        fontSize: '20px'
    },
    subHeading: {
        fontSize: '24px'
    },
    divider: {
        backgroundColor: style.palette.GrayStroke,
        margin: '20px 0'
    },
    manualNumber: {
        fontSize: '12px',
        color: style.palette.black[100],
        margin: '10px 0'
    },
    popupBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        border: `1px solid ${style.palette.black[50]}`,
        borderRadius: '8px',
        padding: 4,
        backgroundColor: "#E6E6E61A",
        width: '642px',
        [baseTheme.breakpoints.down('lg')]: {
            width: '475px',
        },
    }
}
interface preparationStates {
    setLoadingProcess: Dispatch<SetStateAction<boolean>>;
    setConfirmLoadingStatus: (value: boolean) => void;
}
const LoadingPreparation: React.FC<preparationStates> = ({ setLoadingProcess, setConfirmLoadingStatus }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [loadingSteps, setLoadingSteps] = useState<string>('step1');
    const [loadingEditStatus, setLoadingEditStatus] = useState<boolean>(false);

    useEffect(() => {
        if (location.pathname === '/receipt') {
            setLoadingSteps('step2')
        }
    }, [location.pathname])

    const handleSubmit = () => {
        console.log('Yes')
    }
    const handleCancel = () => {
        console.log('No');
        setLoadingProcess(false)
    }
    const handleNextStep = () => {
        console.log(loadingSteps)
        switch (loadingSteps) {
            case 'step1':
                setLoadingSteps('step2');
                break;
            case 'step2':
                setLoadingSteps('step3');
                break;
            case 'step3':
                setLoadingSteps('step4');
                break;
            case 'step4':
                setConfirmLoadingStatus(true);
                break;
            default:
                console.log('Unknown step');
        }
    };
    const handlePreviousStep = () => {
        switch (loadingSteps) {
            case 'step1':
                setLoadingSteps('step1');
                break;
            case 'step2':
                if (location.pathname === '/receipt') {
                    setLoadingProcess(false)
                } else {
                    setLoadingSteps('step1');
                }
                break;
            case 'step3':
                setLoadingSteps('step2');
                break;
            case 'step4':
                setLoadingSteps('step3');
                break;
            default:
                console.log('Unknown step');
        }
    }
    const handleCancelEdit = () => {
        setLoadingSteps('step2');
    }
    const handleConfirmEdit = () => {
        setLoadingEditStatus(true)
    }
    useEffect(() => {
        const deleteTimeout = setTimeout(() => {
            if (loadingEditStatus) {
                setLoadingEditStatus(false);
                setLoadingSteps('step2');
            }
        }, 2000);
        return () => {
            clearTimeout(deleteTimeout)
        }
    }, [loadingEditStatus]);
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            height="90vh"
            bgcolor="white"
            padding={2}
        >
            <Box />
            {loadingSteps == "step1" &&
                <Box
                    sx={Styles.popupBox}
                >
                    <Typography variant="subtitle1" sx={Styles.heading} align="center" gutterBottom>
                        {t("ConfirmRegistrationOfTheTruckPresentedForRemoval")}
                    </Typography>
                    <Typography variant="h6" sx={Styles.subHeading} align="center" gutterBottom>
                        CC-123-CC
                    </Typography>
                    <Box display="flex" justifyContent="center" alignItems="center" gap={2} marginY={2}>
                        <ComButtom
                            label={t("Yes")}
                            variant={"contained"}
                            textVariant="subtitle1"
                            inputStyle={Styles.buttonStyle}
                            onClick={handleSubmit}
                        />
                        <ComButtom
                            label={t("No")}
                            variant={"outlined"}
                            textVariant="subtitle1"
                            inputStyle={Styles.buttonStyle}
                            onClick={handleCancel}
                        />
                    </Box>
                    <Divider sx={Styles.divider} orientation="horizontal" flexItem />
                    <Typography variant="h5" align="center" gutterBottom>
                        {t('EnterTruckRegistrationManually')}
                    </Typography>
                    <Typography variant="h3" sx={Styles.manualNumber}>AA123AA</Typography>
                </Box>
            }
            {loadingSteps == "step2" &&
                <LoadingDetailsBox setLoadingSteps={setLoadingSteps} />
            }
            {
                !loadingEditStatus && loadingSteps == "modifyLoading" &&
                <ModifyLoadingDetail handleConfirmEdit={handleConfirmEdit} handleCancelEdit={handleCancelEdit} />
            }
            {loadingSteps == "step3" &&
                <FileUploadBox />
            }
            {loadingSteps == "step4" &&
                <LoadingSummery />
            }
            {
                loadingEditStatus &&

                <Modal
                    open={loadingEditStatus}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <PopupModal showText={t("LoadingHasBeenUpdated")} bottomText='' />
                </Modal>

            }
            <Box
                width="100%"
                display="flex"
                justifyContent="flex-end"
                padding={2}
                gap={1}
            >
                {loadingSteps !== "modifyLoading" && (
                    <>
                        {loadingSteps !== 'step1' && (
                            <ComButtom
                                label={t("Previous")}
                                variant="outlined"
                                textVariant="subtitle1"
                                inputStyle={Styles.buttonStyle}
                                onClick={handlePreviousStep}
                            />
                        )}
                        <ComButtom
                            label={loadingSteps === "step4" ? t('ConfirmLoading') : t('Next')}
                            variant="contained"
                            textVariant="subtitle1"
                            inputStyle={Styles.buttonStyle}
                            onClick={handleNextStep}
                        />
                    </>
                )}
                {loadingSteps === "modifyLoading" &&
                    <>

                    </>
                }

            </Box>
        </Box>
    );
};

export default LoadingPreparation;
