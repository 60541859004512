import { Box, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Heading from '../common/components/Heading';
import { useTranslation } from 'react-i18next';
import { AllWarehouseData } from './common/constant';
import EditWarehouse from './common/components/EditWarehouse';
import AddWarehouse from './common/components/AddWarehouse';
import { AllWarehouseDataTypes } from '../common/interfaces';
import AddInventory from './common/components/AddInventory';
import AddLogistician from './common/components/AddLogistician';
import EditLogistician from './common/components/EditLogistician';
import ConfirmationModal from '../common/components/ConfirmationModal';
import PopupModal from '../common/components/PopupModal';
import WarehouseDis from './WarehouseDis';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import WarehouseBO from './warehouse/WarehouseBO';
import { getWarehouses, receiveWarehouseList } from '../../redux/warehouse/actions/getWarehouse';
import WarehouseServices from '../../services/WarehouseServices';
import { getWarehousesInventory } from '../../redux/warehouse/actions/getInventory';
import { getWarehousesLogistician } from '../../redux/warehouse/actions/getLogistician';
import { deleteWarehouseLogistician } from '../../redux/warehouse/actions/deleteLogistician';

const Warehouse: React.FC = () => {
    const [warehouseSelect, setWarehouseSelect] = useState<string | undefined>('');
    const [editWarehouseStatus, setEditWarehouseStatus] = useState<boolean>(false);
    const [newWarehouseStatus, setNewWarehouseStatus] = useState<boolean>(false);
    const [newInventoryStatus, setNewInventoryStatus] = useState<boolean>(false);
    const [selection, setSelection] = useState<string>("");
    const [newLogisticianStatus, setNewLogisticianStatus] = useState<boolean>(false);
    const [editLogisticianStatus, setEditLogisticianStatus] = useState<boolean>(false);
    const [fullStockShowStatus, setFullStockShowStatus] = useState<boolean>(false);
    const [logisticsHistoryStatus, setLogisticsHistoryStatus] = useState<boolean>(false);
    const [deleteWarehouseStatus, setDeleteWarehouseStatus] = useState<boolean>(false);
    const [deleteLogisticianStatus, setDeleteLogisticianStatus] = useState<boolean>(false);
    const [searched, setSearched] = useState<string>("");
    const [selectedLogistician, setSelectedLogistician] = useState<string | undefined>('');
    const role = useSelector((state: RootState) => state.userReducer.user.role);
    const warehouseList: AllWarehouseDataTypes = useSelector((state: RootState) => state.warehouseReducer.warehouses);
    const warehouseLogistician: any = useSelector((state: RootState) => state.warehouseReducer.warehouseLogistician);
    const selectedWarehouse = warehouseList.find((item: AllWarehouseDataTypes) => item.id == warehouseSelect);
    console.log("editWarehouseStatus", editWarehouseStatus);

    const dispatch = useDispatch<any>()

    const handleEditWarehouseDetails = () => {
        setEditWarehouseStatus(true);
    };

    const handleAddNewInventory = () => {
        setNewInventoryStatus(true);
        setSelection("inventory")
        console.log("add inventory button");
    };
    const handleAddNewLogistician = () => {
        setNewLogisticianStatus(true);
        setSelection("logistician")
        console.log("add logistician button");
    };
    const handleWarehouseSelect = (id?: string) => {
        setWarehouseSelect(id);
    };

    // const selectedWarehouse = AllWarehouseData.find((item: AllWarehouseDataTypes) => item.id === warehouseSelect);

    const handleStockTableShow = () => {
        setFullStockShowStatus(true);
    };

    const handleLogisticsHistory = () => {
        setLogisticsHistoryStatus(true);
    };
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearched(e.target.value);
    }

    const handleNewMaterialAddInStock = () => {
        console.log("new material add");
    };

    const getWarehouseList = async () => {
        await dispatch(getWarehouses());

    }

    const handleEditLogistician = (value: boolean, id?: string) => {
        console.log("handleEditLogistician");
        setEditLogisticianStatus(value);
        setSelectedLogistician(id);

    }

    const handleDeleteLogistician = async (value: boolean, id?: string) => {
        const response = await dispatch(deleteWarehouseLogistician(id))
        if (response.status === 200) {
            await dispatch(getWarehousesLogistician(selectedWarehouse.id));
            setDeleteLogisticianStatus(value);

        }
    }

    const getWarehouseInventory = async () => {
        try {
            await dispatch(getWarehousesInventory(warehouseSelect));
            await dispatch(getWarehousesLogistician(warehouseSelect))
        } catch (err) {
            console.log("error in get warehouse Inventory", err);
        }
    }

    const getWarehouseLogistician = async () => {
        try {
            await dispatch(getWarehousesLogistician(warehouseSelect))
        } catch (err) {
            console.log("error in get warehouse Logistician", err);
        }
    }

    useEffect(() => {
        getWarehouseList();
    }, []);

    useEffect(() => {
        if (warehouseSelect) {
            getWarehouseInventory();
            getWarehouseLogistician();
        }
    }, [warehouseSelect])

    let ProfileView;
    switch (role) {
        case "DI-BO":
            ProfileView = WarehouseBO;
            break;
        case "DI-WH":
            ProfileView = WarehouseDis;
            break;
        case "TC-BO":
            ProfileView = WarehouseBO;
            break;
        default:
            return null;
    }

    useEffect(() => {
        const deleteTimeout = setTimeout(() => {
            if (deleteLogisticianStatus) {
                setDeleteLogisticianStatus(false);
            }
        }, 1500);
        return () => {
            clearTimeout(deleteTimeout)
        }
    }, [deleteLogisticianStatus]);
    const { t } = useTranslation();




    return (
        <>
            <Box sx={{ padding: '20px' }} >
                <Heading title={t("Warehouse")} />
                {ProfileView && (
                    <ProfileView
                        warehouseSelect={warehouseSelect}
                        setWarehouseSelect={setWarehouseSelect}
                        editWarehouseStatus={editWarehouseStatus}
                        setEditWarehouseStatus={setEditWarehouseStatus}
                        newWarehouseStatus={newWarehouseStatus}
                        setNewWarehouseStatus={setNewWarehouseStatus}
                        newInventoryStatus={newInventoryStatus}
                        setNewInventoryStatus={setNewInventoryStatus}
                        selection={selection}
                        setSelection={setSelection}
                        newLogisticianStatus={newLogisticianStatus}
                        setNewLogisticianStatus={setNewLogisticianStatus}
                        editLogisticianStatus={editLogisticianStatus}
                        handleEditLogistician={handleEditLogistician}
                        setEditLogisticianStatus={setEditLogisticianStatus}
                        fullStockShowStatus={fullStockShowStatus}
                        setFullStockShowStatus={setFullStockShowStatus}
                        logisticsHistoryStatus={logisticsHistoryStatus}
                        setLogisticsHistoryStatus={setLogisticsHistoryStatus}
                        deleteWarehouseStatus={deleteWarehouseStatus}
                        setDeleteWarehouseStatus={setDeleteWarehouseStatus}
                        searched={searched}
                        setSearched={setSearched}
                        handleWarehouseSelect={handleWarehouseSelect}
                        handleEditWarehouseDetails={handleEditWarehouseDetails}
                        handleStockTableShow={handleStockTableShow}
                        handleAddNewInventory={handleAddNewInventory}
                        handleAddNewLogistician={handleAddNewLogistician}
                        handleLogisticsHistory={handleLogisticsHistory}
                        setDeleteLogisticianStatus={setDeleteLogisticianStatus}
                        handleSearchChange={handleSearchChange}
                        handleNewMaterialAddInStock={handleNewMaterialAddInStock}
                        handleDeleteLogistician={handleDeleteLogistician}
                    />
                )}
            </Box>
            {editWarehouseStatus &&
                <Modal
                    open={editWarehouseStatus}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <EditWarehouse setEditWarehouseStatus={setEditWarehouseStatus} selectedWarehouse={warehouseSelect} />
                </Modal>
            }
            {newWarehouseStatus &&
                <Modal
                    open={newWarehouseStatus}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <AddWarehouse setNewWarehouseStatus={setNewWarehouseStatus} />
                </Modal>
            }
            {newInventoryStatus &&
                <Modal
                    open={newInventoryStatus}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <AddInventory setNewInventoryStatus={setNewInventoryStatus} selection={selection} selectedWarehouse={warehouseSelect} />
                </Modal>
            }
            {newLogisticianStatus &&
                <Modal
                    open={newLogisticianStatus}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <AddLogistician setNewLogisticianStatus={setNewLogisticianStatus} selection={selection} selectedWarehouse={warehouseSelect} />
                </Modal>
            }
            {editLogisticianStatus &&
                <Modal
                    open={editLogisticianStatus}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <EditLogistician setEditLogisticianStatus={setEditLogisticianStatus} selection={selection} selectedLogistician={selectedLogistician} selectedWarehouse={selectedWarehouse.id} />
                </Modal>
            }
            {deleteWarehouseStatus &&
                <Modal
                    open={deleteWarehouseStatus}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ConfirmationModal setDeleteWarehouseStatus={setDeleteWarehouseStatus} selectedWarehouse={warehouseSelect} />
                </Modal>
            }
            {deleteLogisticianStatus &&
                <Modal
                    open={deleteLogisticianStatus}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <PopupModal showText={t("Martin Bernard information has been deleted successfully!!")} bottomText='' />
                </Modal>
            }
        </>
    );
};

export default Warehouse;
