import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Warehouse from '../Dis/Warehouse';

const RoleBasedWarehouse = () => {
  const role = useSelector((state: RootState) => state.userReducer.user.role);
  switch (role) {
    case 'DI-BO':
      return <Warehouse />;
    case 'DI-WH':
      return <Warehouse />;
    case 'TC-BO':
      return <Warehouse />;
    default:
      return <Warehouse />;
  }

}

export default RoleBasedWarehouse