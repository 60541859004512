import { IMAGES } from "../../../asset/Dis";
import { AllAppointmentsEntry, AllWarehouseDataTypes } from "../../common/interfaces";
import { WarehouseData } from "./types";

export const AllAppointmentsData: AllAppointmentsEntry[] = [
    {
        id: 1,
        title: "Loading",
        appointments: [
            {
                id: 1,
                title: "CHT-013",
                image: IMAGES.CourseIcon,
                subTitle: "BB-123-BB-11/01/2022",
                wearhouse: "Warehouse 1"
            },
            {
                id: 2,
                "title": "DHT-011",
                "image": IMAGES.CourseIcon,
                "subTitle": "BB-123-BB-11/01/2022",
                "wearhouse": "Warehouse 1"
            },
            {
                id: 3,
                "title": "DHT-011",
                "image": IMAGES.CourseIcon,
                "subTitle": "BB-123-BB-11/01/2022",
                "wearhouse": "Warehouse 1"
            },
            {
                id: 4,
                "title": "DHT-011",
                "image": IMAGES.CourseIcon,
                "subTitle": "BB-123-BB-11/01/2022",
                "wearhouse": "Warehouse 1"
            },
            {
                id: 5,
                "title": "DHT-011",
                "image": IMAGES.CourseIcon,
                "subTitle": "BB-123-BB-11/01/2022",
                "wearhouse": "Warehouse 1"
            },
            {
                id: 6,
                "title": "DHT-011",
                "image": IMAGES.CourseIcon,
                "subTitle": "BB-123-BB-11/01/2022",
                "wearhouse": "Warehouse 1"
            },
            {
                id: 7,
                "title": "DHT-011",
                "image": IMAGES.CourseIcon,
                "subTitle": "BB-123-BB-11/01/2022",
                "wearhouse": "Warehouse 1"
            },
            {
                id: 8,
                "title": "DHT-011",
                "image": IMAGES.CourseIcon,
                "subTitle": "BB-123-BB-11/01/2022",
                "wearhouse": "Warehouse 1"
            },
        ]
    },
    {
        id: 2,
        title: "Unloading",
        appointments: [
            {
                "id": 1,
                "title": "CHT-013",
                "image": IMAGES.CourseIcon,
                "subTitle": "BB-123-BB-11/01/2022",
                "wearhouse": "Warehouse 1"
            },
            {
                "id": 2,
                "title": "DHT-011",
                "image": IMAGES.CourseIcon,
                "subTitle": "BB-123-BB-11/01/2022",
                "wearhouse": "Warehouse 1"
            },
        ]
    },
    {
        id: 3,
        title: "Delivery",
        appointments: [
            {
                "id": 1,
                "title": "CHT-013",
                "image": IMAGES.CourseIcon,
                "subTitle": "BB-123-BB-11/01/2022",
                "wearhouse": "Warehouse 1"
            },
            {
                "id": 2,
                "title": "DHT-011",
                "image": IMAGES.CourseIcon,
                "subTitle": "BB-123-BB-11/01/2022",
                "wearhouse": "Warehouse 1"
            },
        ]
    },
    {
        id: 4,
        title: "Receipt",
        appointments: [
            {
                "id": 1,
                "title": "CHT-013",
                "image": IMAGES.CourseIcon,
                "subTitle": "BB-123-BB-11/01/2022",
                "wearhouse": "Warehouse 1"
            },
            {
                "id": 2,
                "title": "DHT-011",
                "image": IMAGES.CourseIcon,
                "subTitle": "BB-123-BB-11/01/2022",
                "wearhouse": "Warehouse 1"
            },
        ]
    }

]

export const ScheduleAppointmentsData = [
    {
        "id": 1,
        "title": "CHT-013",
        "image": IMAGES.CourseIcon,
        "subTitle": "BB-123-BB-11/01/2022",
        "label": "Receipt"
    },
    {
        "id": 2,
        "title": "DHT-011",
        "image": IMAGES.CourseIcon,
        "subTitle": "BB-123-BB-11/01/2022",
        "label": "Unloading"
    },
    {
        "id": 3,
        "title": "Warehouse 1",
        "image": IMAGES.CourseIcon,
        "subTitle": "11/01/2022  15h50m",
        "label": "Loading"
    },
]

export const mapOptions = [
    {
        id: 1,
        name: 'Calendar',
    },
    {
        id: 2,
        name: 'List',
    },
    {
        id: 3,
        name: 'Map',
    }
]

export const Appointments = {
    Loading: "Loading",
    Unloading: "Unloading",
    Delivery: "Delivery",
    Receipt: "Receipt"
}

export const CatgeoryOptions = [
    {
        title: "All",
        value: "All"
    },
    {
        title: Appointments.Loading,
        value: Appointments.Loading
    },
    {
        title: Appointments.Unloading,
        value: Appointments.Unloading
    },
    {
        title: Appointments.Delivery,
        value: Appointments.Delivery
    },
    {
        title: Appointments.Receipt,
        value: Appointments.Receipt
    }
];

export const AppointmentsOptions = [
    {
        title: Appointments.Loading,
        value: Appointments.Loading
    },
    {
        title: Appointments.Unloading,
        value: Appointments.Unloading
    },
    {
        title: Appointments.Delivery,
        value: Appointments.Delivery
    },
    {
        title: Appointments.Receipt,
        value: Appointments.Receipt
    }
]



export const CalenderViewOption = [
    {
        title: "Month",
        value: "Month"
    },
    {
        title: "Week",
        value: "Week"
    },
    {
        title: "Day",
        value: "Day"
    },
];
export const AllWarehouseData: AllWarehouseDataTypes = [
    {
        id: 1,
        title: "Warehouse",
        image1: IMAGES.WarehouseIcon,
        image2: IMAGES.DeleteIcon,
        company: "Martin Leclerc",
        contact: "1452897563",
        mail: "warehouse1@gmail.com",
        address: "Park City",
        managerDetails: [{
            id: 1,
            name: "Martin Leclerc", contact: "1452897563",
            mail: "warehouse1@gmail.com",
        },],
        assistant: [{ name: "John Watson" }],
        stocks: {
            stocksWarehouse: [
                {
                    id: 1,
                    name: "pellet stoves",
                    value: 12,
                },
                {
                    id: 2,
                    name: "smoking kits",
                    value: 12,
                },
                {
                    id: 3,
                    name: "tiled plates",
                    value: 12,
                },
                {
                    id: 4,
                    name: "tiled plates",
                    value: 12,
                },
                {
                    id: 5,
                    name: "tiled plates",
                    value: 12,
                },
                {
                    id: 6,
                    name: "87 Heat pump",
                    value: "LG 11kg reverse",
                },
                {
                    id: 7,
                    name: "102 Boiler",
                    value: "LG 1524",
                },
                {
                    id: 8,
                    name: "96 Thermo dynamic tank",
                    value: "GREE",
                },
                {
                    id: 9,
                    name: "96 Thermo dynamic tank",
                    value: "GREE",
                },
                {
                    id: 10,
                    name: "96 Thermo dynamic tank",
                    value: "GREE",
                },
            ]
        },
        inventories: [
            {
                title: "Warehouse 1",
                subTitle: "INV/WH1-004- 15/01/2022",
                image: IMAGES.GraphQueIcon,
            },
            {
                title: "Warehouse 2",
                subTitle: "INV/WH2-004- 15/01/2022",
                image: IMAGES.CourseIcon,
            },
            {
                title: "Warehouse 3",
                subTitle: "INV/WH2-004- 15/01/2022",
                image: IMAGES.CourseIcon,
            }
        ],
        logistician: [
            {
                id: 1,
                name: "Martin Bernard",
                image1: IMAGES.logisticianIcon,
                image2: IMAGES.DeleteIcon,
                image3: IMAGES.EditIcon,
            },
            {
                id: 2,
                name: "Lucie Aubrac",
                image1: IMAGES.logisticianIcon,
                image2: IMAGES.DeleteIcon,
                image3: IMAGES.EditIcon,
            }
        ],
        logistique: [
            {
                id: 1,
                title: "Delivery",
                appointments: [
                    {
                        id: 1,
                        title: "Warehouse 1",
                        image: IMAGES.GraphQueIcon,
                        subTitle: "11/01/2022 15h50m",
                        wearhouse: "Distributor"
                    },
                    {
                        id: 2,
                        title: "Warehouse 1",
                        image: IMAGES.CourseIcon,
                        subTitle: "11/01/2022 15h50m",
                        wearhouse: "Distributor"
                    },
                ]
            },
            {
                id: 2,
                title: "Loading",
                appointments: [
                    {
                        "id": 1,
                        "title": "Warehouse 1",
                        "image": IMAGES.GraphQueIcon,
                        "subTitle": "11/01/2022 15h50m",
                        "wearhouse": "Distributor"
                    },
                    {
                        "id": 2,
                        "title": "Warehouse 1",
                        "image": IMAGES.CourseIcon,
                        "subTitle": "11/01/2022 15h50m",
                        "wearhouse": "Distributor"
                    },
                ]
            },
            {
                id: 3,
                title: "Unloading",
                appointments: [
                    {
                        "id": 1,
                        "title": "Warehouse 1",
                        "image": IMAGES.GraphQueIcon,
                        "subTitle": "11/01/2022 15h50m",
                        "wearhouse": "Distributor"
                    },
                    {
                        "id": 2,
                        "title": "Warehouse 1",
                        "image": IMAGES.CourseIcon,
                        "subTitle": "11/01/2022 15h50m",
                        "wearhouse": "Distributor"
                    },
                ]
            },
        ]
    },
    {
        id: 2,
        title: "Warehouse",
        image1: IMAGES.WarehouseIcon,
        image2: IMAGES.DeleteIcon,
        company: "Martin Leclerc",
        contact: "1452897563",
        mail: "warehouse1@gmail.com",
        address: "Park City"
    },
    {
        id: 3,
        title: "Warehouse",
        image1: IMAGES.WarehouseIcon,
        image2: IMAGES.DeleteIcon,
        company: "Martin Leclerc",
        contact: "1452897563",
        mail: "warehouse1@gmail.com",
        address: "Park City"
    },
    {
        id: 4,
        title: "Warehouse",
        image1: IMAGES.WarehouseIcon,
        image2: IMAGES.DeleteIcon,
        company: "Martin Leclerc",
        contact: "1452897563",
        mail: "warehouse1@gmail.com",
        address: "Park City"
    }

]
export const logisticsData = [
    {
        id: 'LIV-568955',
        type: 'Deliveries',
        status: 'Validation Pending',
        mainStatus: "pending",
        distributor: 'Distributor',
        enrepot: 'Enrepot',
        destination: '2L Distribution',
        enrepotDetails: "Ware/2L01",
        date: '15/01/22 15:30',
        loadingStatus: "Loading",
        loadingPdf: "path/to/pdf",

    },
    {
        id: 'CHT-568955',
        type: 'Loadings',
        status: 'Validation Pending',
        mainStatus: "pending",
        distributor: 'Distributor',
        enrepot: 'Enrepot',
        destination: '2L Distribution',
        enrepotDetails: "Ware/2L01",
        date: '15/01/22 15:30',
        loadingStatus: "Loading",
        loadingPdf: "path/to/pdf",

    },
    {
        id: 'DHT-568955',
        type: 'Unloadings',
        status: 'Validation Pending',
        mainStatus: "pending",
        distributor: 'Distributor',
        enrepot: 'Enrepot',
        destination: '2L Distribution',
        enrepotDetails: "Ware/2L01",
        date: '15/01/22 15:30',
        loadingStatus: "Loading",
        loadingPdf: "path/to/pdf",

    },
    {
        id: 'LIV-568955',
        type: 'Deliveries',
        status: 'Validated',
        mainStatus: "validated",
        distributor: 'Distributor',
        enrepot: 'Enrepot',
        destination: '2L Distribution',
        enrepotDetails: "Ware/2L01",
        date: '15/01/22 15:30',
        loadingStatus: "Loading",
        loadingPdf: "path/to/pdf",
        validationStatus: "Validations",
        validationPdf: "path/to/pdf"
    },
    {
        id: 'CHT-568955',
        type: 'Loadings',
        status: 'Validated',
        mainStatus: "validated",
        distributor: 'Distributor',
        enrepot: 'Enrepot',
        destination: '2L Distribution',
        enrepotDetails: "Ware/2L01",
        date: '15/01/22 15:30',
        loadingStatus: "Loading",
        loadingPdf: "path/to/pdf",
        validationStatus: "Validations",
        validationPdf: "path/to/pdf"
    },
    {
        id: 'DHT-568955',
        type: 'Unloadings',
        status: 'Validation Pending',
        mainStatus: "validated",
        distributor: 'Distributor',
        enrepot: 'Enrepot',
        destination: '2L Distribution',
        enrepotDetails: "Ware/2L01",
        date: '15/01/22 15:30',
        loadingStatus: "Loading",
        loadingPdf: "path/to/pdf",
        validationStatus: "Validations",
        validationPdf: "path/to/pdf"
    },
    {
        id: 'LIV-568955',
        type: 'Deliveries',
        status: 'Validated',
        mainStatus: "validated",
        distributor: 'Distributor',
        enrepot: 'Enrepot',
        destination: '2L Distribution',
        enrepotDetails: "Ware/2L01",
        date: '15/01/22 15:30',
        loadingStatus: "Loading",
        loadingPdf: "path/to/pdf",
        validationStatus: "Validations",
        validationPdf: "path/to/pdf"
    },
    {
        id: 'CHT-568955',
        type: 'Loadings',
        status: 'Validated',
        mainStatus: "validated",
        distributor: 'Distributor',
        enrepot: 'Enrepot',
        destination: '2L Distribution',
        enrepotDetails: "Ware/2L01",
        date: '15/01/22 15:30',
        loadingStatus: "Loading",
        loadingPdf: "path/to/pdf",
        validationStatus: "Validations",
        validationPdf: "path/to/pdf"
    },
    {
        id: 'DHT-568955',
        type: 'Unloadings',
        status: 'Validated',
        mainStatus: "validated",
        distributor: 'Distributor',
        enrepot: 'Enrepot',
        destination: '2L Distribution',
        enrepotDetails: "Ware/2L01",
        date: '15/01/22 15:30',
        loadingStatus: "Loading",
        loadingPdf: "path/to/pdf",
        validationStatus: "Validations",
        validationPdf: "path/to/pdf"
    },
    // Add more objects as needed
];
function createData(
    site: string,
    material: string,
    PackagingAndVolume: { quantity: number; type: string; volumeTotal: number; unit: string },
    forecast: {
        week1: number,
        week2: number,
        month1: number,
    },
    action: number,
) {
    return { site, material, PackagingAndVolume, forecast, action };
}

export const rows = [
    createData(
        "Warehouse 1",
        "GRAPHIPAN CEE 103 - 100mm Pack of 5 panels",
        { quantity: 54, type: "Package", volumeTotal: 3.0, unit: "m2" },
        { week1: 10, week2: 20, month1: 30 },
        4.0
    ),
    createData(
        "Warehouse 2",
        "GRAPHIPAN CEE 103 - 100mm Pack of 5 panels",
        { quantity: 127, type: "Package", volumeTotal: 5.0, unit: "m2" },
        { week1: 10, week2: 20, month1: 30 },
        4.3
    ),
    createData(
        "Warehouse 3",
        "GRAPHIPAN CEE 103 - 100mm Pack of 5 panels",
        { quantity: 256, type: "Package", volumeTotal: 12.0, unit: "m2" },
        { week1: 10, week2: 20, month1: 30 },
        6.0
    ),
    createData(
        "Warehouse 4",
        "GRAPHIPAN CEE 103 - 100mm Pack of 5 panels",
        { quantity: 264, type: "Package", volumeTotal: 1.7, unit: "m2" },
        { week1: 10, week2: 20, month1: 30 },
        4.3
    ),
    createData(
        "Warehouse 4",
        "GRAPHIPAN CEE 103 - 100mm Pack of 5 panels",
        { quantity: 7, type: "Package", volumeTotal: 12.0, unit: "m2" },
        { week1: 10, week2: 20, month1: 30 },
        3.9
    ),
    createData(
        "Warehouse 1",
        "GRAPHIPAN CEE 103 - 100mm Pack of 5 panels",
        { quantity: 54, type: "Package", volumeTotal: 3.0, unit: "m2" },
        { week1: 10, week2: 20, month1: 30 },
        4.0
    ),
    createData(
        "Warehouse 2",
        "GRAPHIPAN CEE 103 - 100mm Pack of 5 panels",
        { quantity: 127, type: "Package", volumeTotal: 5.0, unit: "m2" },
        { week1: 10, week2: 20, month1: 30 },
        4.3
    ),
    createData(
        "Warehouse 3",
        "GRAPHIPAN CEE 103 - 100mm Pack of 5 panels",
        { quantity: 256, type: "Package", volumeTotal: 12.0, unit: "m2" },
        { week1: 10, week2: 20, month1: 30 },
        6.0
    ),
    createData(
        "Warehouse 4",
        "GRAPHIPAN CEE 103 - 100mm Pack of 5 panels",
        { quantity: 264, type: "Package", volumeTotal: 1.7, unit: "m2" },
        { week1: 10, week2: 20, month1: 30 },
        4.3
    ),
];



export const wearhouses: WarehouseData[] = [
    {
        id: 1,
        name: "ENTR 1,2,3",
        title: "ENTR 1,2,3"
    },
    {
        id: 2,
        name: "ENTR 2,5,6",
        title: "ENTR 2,5,6"
    },
    {
        id: 3,
        name: "ENTR 7,8,9",
        title: "ENTR 7,8,9"
    },

]