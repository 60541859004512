import React, { useState } from 'react';
import { COMMON_IMAGES } from '../asset';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { Box, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ActiveButton from './formComponents/button/ActiveButton';
import Input from './formComponents/input/Input';
import { style } from '../Utils/themes/styles';
import { IMAGES } from '../asset/Dis';
import { baseTheme } from '../Utils/themes/base';

const Styles = {
    loginForm: {
        '& .MuiTypography-h2': {
            color: style.palette.TitleColor
        },

        '& .MuiTypography-h4': {
            color: style.palette.black[200]
        },
        '& .MuiTypography-body1': {
            color: style.palette.black[500]
        },
        '& .MuiTypography-subtitle2': {
            color: style.palette.black[500],
            wordWrap: "break-word"
        }
    },
    forgotPassDescription: {
        padding: '0px 80px',
    },
    loginBtn: {
        marginTop: '8.688rem',
        [baseTheme.breakpoints.down('lg')]: {
            marginTop: '4.688rem !important',
        },
        [baseTheme.breakpoints.down('xl')]: {
            marginTop: '2.688rem !important',
        },
        [baseTheme.breakpoints.up('xl')]: {
            marginTop: '3rem !important',
        }
    },
    inputMargin: {
        marginTop: '2.5rem',
        [baseTheme.breakpoints.down('lg')]: {
            marginTop: '1rem !important',
        },
        [baseTheme.breakpoints.down('xl')]: {
            marginTop: '1rem !important',
        },
        [baseTheme.breakpoints.up('xl')]: {
            marginTop: '2rem !important',
        }
    },
    endAdornmentIcon: {
        'img': {
            height: '18px !important',
            marginBottom: '0px',
            width: 'auto',
            [baseTheme.breakpoints.up('xl')]: {
                height: '18px !important',
            }
        }
    }

}

const ResetPassword = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [signUpState, setSignUpState] = useState({
        showPassword: false,
    })

    const handleShowHidePassword = () => {
        setSignUpState((prev) => {
            return {
                ...prev,
                showPassword: !signUpState.showPassword
            }
        })
    }

    const handleLogin = () => {
        navigate("/");
    }
    return (
        <div className='login-root'>
            <Grid container spacing={2}>
                <Grid xs={6} md={7} sx={{ display: { xs: 'none', md: 'block' }, padding: '0px' }} className="login-sideImage">
                    <img src={COMMON_IMAGES?.LoginImage} alt='' height={"100vh"} width={'100%'} />
                </Grid>
                <Grid xs={6} md={5} className="login-form" sx={Styles.loginForm}>
                    <Box>
                        <img src={COMMON_IMAGES?.hiveLogo} alt='' />
                    </Box>
                    <div>
                        <Stack>
                            <Typography variant='h2'>{t("ResetPassword")}</Typography>
                        </Stack>
                        <Formik
                            initialValues={{
                                newPassword: '',
                                confirmPassword: '',
                            }}
                            onSubmit={async (values) => {
                                await new Promise((r) => setTimeout(r, 500));
                                alert(JSON.stringify(values, null, 2));
                            }}
                        >
                            <Form className='login-fields'>
                                <Input
                                    label="New Password"
                                    id="newPassword"
                                    placeholder="*******"
                                    type="password"
                                    width={'100%'}
                                    inputstyle={Styles.inputMargin}
                                />

                                <Input
                                    label="Confirm Password"
                                    id="confirmPassword"
                                    placeholder={signUpState.showPassword ? "Password" : "*******"}
                                    type={signUpState.showPassword ? "text" : "password"}
                                    width={'100%'}
                                    InputProps={{
                                        endAdornment: <Box sx={Styles.endAdornmentIcon} onClick={handleShowHidePassword}>
                                            {signUpState.showPassword ?
                                                <img src={IMAGES.showPasswordIcon} alt="hide" /> :
                                                <img src={IMAGES.hidePasswordIcon} alt="hide" />
                                            }
                                        </Box>
                                    }}
                                    inputstyle={Styles.inputMargin}
                                />
                                <ActiveButton label="Submit" variant="contained" textVariant="button" boxStyle={Styles.loginBtn} onClick={handleLogin} />
                            </Form>
                        </Formik>
                    </div>
                </Grid>

            </Grid>
        </div>
    )
}

export default ResetPassword
