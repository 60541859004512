import Request from "../../../Axios/Instance";
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../../store';
import { AxiosResponse } from "axios";
import { getWarehouses } from "./getWarehouse";
import { getWarehousesLogistician } from "./getLogistician";

export const ADD_WAREHOUSE_LOGISTICIAN = "ADD_WAREHOUSE_LOGISTICIAN";
export const ADD_LOGISTICIAN_ERROR = "RECEIVE_LOGISTICIAN_ERROR";

export const addLogistician = (data: any) => ({
    type: ADD_WAREHOUSE_LOGISTICIAN,
    payload: data
})

export const addLogisticianError = (err: unknown) => ({
    type: ADD_LOGISTICIAN_ERROR,
    payload: err
})

export const postWarehouseLogistician = (payload: any): ThunkAction<Promise<AxiosResponse<any>>, RootState, unknown, AnyAction> =>
    (dispatch, getState) =>
        new Promise((resolve, reject) => {
            console.log("payload", payload);
            const fetchPost = async () => {
                try {
                    const response = await Request.post(`/logistician`, payload);
                    await dispatch(getWarehousesLogistician(payload.warehouse_id));
                    dispatch(addLogistician(response?.data?.data));
                    resolve(response.data);
                } catch (err) {
                    dispatch(addLogisticianError(err));
                    reject(err);
                }
            }
            fetchPost();
        });

