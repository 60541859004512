import './App.css';
import "../src/asset/css/style.css";
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import Routing from './routing';
import { theme } from "./Utils/themes/styles";

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Routing />
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
