import React, { useState } from 'react';
import { Box, Typography, TextField, Divider, MenuItem, Select, IconButton, Grid } from '@mui/material';
import { Formik, Form, Field, FormikValues } from 'formik';
import { style } from '../../../../Utils/themes/styles';
import { IMAGES } from '../../../../asset/Dis';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup'; // Import Yup for validation
import ComButtom from '../../../../components/formComponents/button/ComButtom';
import { baseTheme } from '../../../../Utils/themes/base';

const Styles = {
    summaryBox: {
        padding: '24px 32px',
        borderRadius: '8px',
        border: `1px solid ${style.palette.black[50]}`,
        backgroundColor: '#fff',
        width: '700px',
        position: 'relative',
        [baseTheme.breakpoints.down('lg')]: {
            width: '475px',
        },
    },
    title: {
        marginBottom: '20px',
        textAlign: 'left'
    },
    section: {
        marginBottom: '20px',
    },
    sectionMainHeader: {
        marginBottom: '10px',
        '& .css-1qh8f7g-MuiTypography-root': {
            fontSize: '12px',
            color: style.palette.black[200],
            textAlign: 'left'
        }
    },
    sectionHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
        color: style.palette.black[300]
    },
    editButton: {
        fontSize: '14px',
        color: style.palette.green[500],
        cursor: 'pointer'
    },
    addButton: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        color: style.palette.green[500],
        cursor: 'pointer'
    },
    detailsLoading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& .css-m7i2ec-MuiTypography-root': {
            color: style.palette.black[300]
        },
        '& .css-2bot5r-MuiInputBase-root-MuiOutlinedInput-root': {
            height: '90px',
            alignItems: 'flex-start',
        },
        '& .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input': {
            color: style.palette.black[100]
        },
    },
    comments: {
        '& .css-q0igbi-MuiTypography-root': {
            display: 'flex',
            justifyContent: 'space-between',
        },
        '& .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input': {
            color: style.palette.black[100]
        },
    },
    divider: {
        backgroundColor: style.palette.GrayStroke,
        position: 'relative',
        left: '-33px',
        marginBottom: '10px'
    },
    inputFields: {
        display: "flex",
        alignItems: "center",
        marginBottom: '10px',
        position: 'relative',
        '& .css-1qbuzrr-MuiInputBase-root-MuiOutlinedInput-root': {
            height: '45px',
            backgroundColor: style.palette.BgColor
        },
        '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
            fontSize: '14px',
            color: style.palette.black[100]
        },
        '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    },
    editIconStyle: {
        position: 'absolute',
        right: 0,

        '& img': {
            height: '15px',
        }
    },
    selectInput: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        '& .css-1vnfelh-MuiTypography-root': {
            fontSize: '12px',
            color: style.palette.black[300]
        },
        '& css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
            color: style.palette.black[300],
            fontSize: '12px',
        }
    },
    commentSection: {
        '& .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input': {
            fontSize: '12px',
            color: style.palette.black[100]
        }
    },
    refInput: {
        '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
            fontSize: '12px',
            color: style.palette.black[100]
        }
    },
    buttonStyle: {
        padding: '19px 35px',
        height: "40px",
        border: `1px solid ${style.palette.black[50]}`,
        borderRadius: "4px",
    },
    editLoadingBtn: {
        position: 'absolute',
        bottom: '-75px',
        display: 'flex',
        gap: '10px',
        right: '0px'
    }
};
interface ModifyLoadingDetailProps {
    handleConfirmEdit: (values: FormikValues) => void;
    handleCancelEdit: () => void;
}
const ModifyLoadingDetail: React.FC<ModifyLoadingDetailProps> = ({ handleConfirmEdit, handleCancelEdit }) => {
    const { t } = useTranslation();
    const [isEditable, setIsEditable] = useState<boolean>(false);

    const handleEditDetailsClick = () => {
        setIsEditable(!isEditable);
    };

    // Define validation schema using Yup
    const validationSchema = Yup.object().shape({
        materialName1: Yup.string().required('Material name is required'),
        materialName2: Yup.string().required('Material name is required'),
        referenceMaterial: Yup.string().required('Reference material is required'),
        quantities: Yup.number().required('Quantities is required'),
        comments: Yup.string().required('Comments are required'),
    });

    const initialValues = {
        materialName1: 'Graphipan CEE 103',
        materialName2: 'Graphipan CEE 103',
        referenceMaterial: 'GRAPHIPAN CEE 103',
        quantities: 20,
        comments: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable.',
    }
    return (
        <Box sx={Styles.summaryBox}>
            <Typography variant="h4" sx={Styles.title}>{t("ModifyLoadingDetail")}</Typography>
            <Divider sx={Styles.divider} />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => handleConfirmEdit(values)} // Make sure this is a function
            >
                {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
                    <Form onSubmit={handleSubmit}>
                        <Box sx={Styles.sectionMainHeader}>
                            <Typography variant="h3">{t("SelectTheMaterialToBeModifiedOrAddANewMaterialToTheLoad")}</Typography>
                        </Box>
                        <Box sx={Styles.section}>
                            <Box sx={Styles.sectionHeader}>
                                <Typography variant="h5">{t("Material")}</Typography>
                                <Box sx={Styles.addButton} onClick={() => alert('Add new material')}>
                                    <Typography variant="h6">+{t("Add")}</Typography>
                                </Box>
                            </Box>
                            <Box sx={Styles.inputFields}>
                                <Field
                                    as={TextField}
                                    name="materialName1"
                                    variant="outlined"
                                    fullWidth
                                    value={values.materialName1}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.materialName1 && !!errors.materialName1}
                                    helperText={touched.materialName1 && errors.materialName1}
                                />
                                <IconButton onClick={handleEditDetailsClick} sx={Styles.editIconStyle}>
                                    <img src={IMAGES.editIcon2} alt="edit" />
                                </IconButton>
                            </Box>
                            <Box sx={Styles.inputFields}>
                                <Field
                                    as={TextField}
                                    name="materialName2"
                                    variant="outlined"
                                    fullWidth
                                    value={values.materialName2}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.materialName2 && !!errors.materialName2}
                                    helperText={touched.materialName2 && errors.materialName2}
                                />
                                <IconButton onClick={handleEditDetailsClick} sx={Styles.editIconStyle}>
                                    <img src={IMAGES.editIcon2} alt="edit" />
                                </IconButton>
                            </Box>
                        </Box>

                        <Box sx={Styles.section} display="flex" justifyContent="space-between" alignItems="center">
                            <Box flex={1} marginRight={2}>
                                <Box sx={Styles.sectionHeader}>
                                    <Typography variant="h5">{t("ReferenceMaterial")}</Typography>
                                </Box>
                                <Field
                                    as={TextField}
                                    name="referenceMaterial"
                                    variant="outlined"
                                    fullWidth
                                    value={values.referenceMaterial}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.referenceMaterial && !!errors.referenceMaterial}
                                    helperText={touched.referenceMaterial && errors.referenceMaterial}
                                    sx={Styles.refInput}
                                />
                            </Box>
                            <Box flex={1} marginLeft={2}>
                                <Box sx={Styles.sectionHeader}>
                                    <Typography variant="h5">{t("Quantities")}</Typography>
                                </Box>
                                <Grid sx={Styles.selectInput}>
                                    <Field
                                        as={Select}
                                        name="quantities"
                                        variant="outlined"
                                        fullWidth
                                        value={values.quantities}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.quantities && !!errors.quantities}
                                        helperText={touched.quantities && errors.quantities}
                                    >
                                        {[...Array(100).keys()].map(i => (
                                            <MenuItem key={i} value={i + 1}>{i + 1}</MenuItem>
                                        ))}
                                    </Field>
                                    <Typography variant="body1">{t("Packages")}</Typography>
                                </Grid>
                            </Box>
                        </Box>

                        <Box sx={Styles.section}>
                            <Box sx={Styles.sectionHeader}>
                                <Typography variant="h5">{t("Comments")}</Typography>
                            </Box>
                            <Field
                                as={TextField}
                                name="comments"
                                multiline
                                variant="outlined"
                                fullWidth
                                value={values.comments}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.comments && !!errors.comments}
                                helperText={touched.comments && errors.comments}
                                InputProps={{ readOnly: false }}
                                sx={Styles.commentSection}
                            />
                        </Box>
                        <Box sx={Styles.editLoadingBtn}>
                            <ComButtom
                                label={t("Cancel")}
                                variant="outlined"
                                textVariant="subtitle1"
                                inputStyle={Styles.buttonStyle}
                                onClick={handleCancelEdit}
                            />
                            <ComButtom
                                label={t("Confirm")}
                                variant="contained"
                                textVariant="subtitle1"
                                inputStyle={Styles.buttonStyle}
                                type="submit"
                            />
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default ModifyLoadingDetail;
