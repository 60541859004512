import { Box, Grid } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import React from 'react'
import { useTranslation } from 'react-i18next';
import FormContainer from '../../../common/components/FormContainer';
import { IMAGES } from '../../../../asset/Dis';
import { baseTheme } from '../../../../Utils/themes/base';

const Styles = {
    popupBox: {
        width: "583px",
        height: "720px",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        [baseTheme.breakpoints.down('sm')]: {
            height: "580px",
            width: "520px"
        },
    },
    popUpContainer: {
        height: '100%',
        borderRadius: "12px",
    },
    childComponent: {
        height: '81%',
        padding: "0 20px",
        overflowX: "hidden"
    }
}


interface MyComponentProps {
    setNewWarehouseStatus: Dispatch<SetStateAction<boolean>>;
}

const AddWarehouse = ({ setNewWarehouseStatus }: MyComponentProps) => {

    const handleClose = () => {
        setNewWarehouseStatus(false);
    }
    const { t } = useTranslation();
    return (
        <Box>
            <Grid item xs={6} sx={Styles.popupBox}>
                <FormContainer
                    leftContainer={t("CreateANewWarehouse")}
                    rightContainer={
                        <img src={IMAGES.crossIcon} alt='search' height='24px' onClick={handleClose} />
                    }
                    sx={Styles.popUpContainer}
                    childContainerStyle={Styles.childComponent}
                    submitText={t("Submit")}
                    handleStatus={setNewWarehouseStatus}
                />
            </Grid>
        </Box>
    )
}
export default AddWarehouse;