import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react';
import { IMAGES } from '../../../../asset/Dis';
import { style } from '../../../../Utils/themes/styles';
import { useTranslation } from 'react-i18next';

const Styles = {
    root:{
        '& .MuiGrid-item':{
            paddingLeft:'20px',
        }
    },
    QAContainer:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        '& .MuiTypography-subtitle1': {
            color: style.palette.black[100],
            marginTop:'8px'
        }
    },
    quickAccessAlign: {
        height:'60px',
        width:'70px',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        borderRadius:'8px',
        
    },
}

const QuickAccess = () => {
    const { t } = useTranslation();
    return (
        <Box sx={Styles.root}>
            <Grid container spacing={4}>
                <Grid item xs={2} sx={Styles.QAContainer}>
                    <Stack sx={{...Styles.quickAccessAlign,background:"#E0DEFD"}} >
                        <img src={IMAGES.WearhouseQuickAccessIcon} alt='qaIcon' height={'32px'} />
                    </Stack>
                    <Typography variant='subtitle1'>{t("Warehouse")}</Typography>
                </Grid>
                <Grid item xs={2} sx={Styles.QAContainer}>
                    <Stack sx={{...Styles.quickAccessAlign,background:"#C8E6CB"}} >
                        <img src={IMAGES.LogistitianQuickAccessIcon} alt='qaIcon' height={'32px'} />
                    </Stack>
                    <Typography variant='subtitle1'>{t("Logisticians")}</Typography>
                </Grid>
                <Grid item xs={2} sx={Styles.QAContainer}>
                    <Stack sx={{...Styles.quickAccessAlign,background:"#FBD8A1"}} >
                        <img src={IMAGES.PartnerQuickAccessIcon} alt='qaIcon' height={'32px'} />
                    </Stack>
                    <Typography variant='subtitle1'>{t("Partner")}</Typography>
                </Grid>
                <Grid item xs={2} sx={Styles.QAContainer}>
                    <Stack sx={{...Styles.quickAccessAlign,background:"#FFE57F"}} >
                        <img src={IMAGES.AppointmentQuickAccessIcon} alt='qaIcon' height={'32px'} />
                    </Stack>
                    <Typography variant='subtitle1'>{t("Appointment")}</Typography>
                </Grid>
                <Grid item xs={2} sx={Styles.QAContainer}>
                    <Stack sx={{...Styles.quickAccessAlign,background:"#C5E5FB"}} >
                        <img src={IMAGES.ExportQuickAccessIcon} alt='qaIcon' height={'32px'} />
                    </Stack>
                    <Typography variant='subtitle1'>{t("Export")}</Typography>
                </Grid>
                <Grid item xs={2} sx={Styles.QAContainer}>
                    <Stack sx={{...Styles.quickAccessAlign,background:"#FFC1D8"}} >
                        <img src={IMAGES.LogQuickAccessIcon} alt='qaIcon' height={'32px'} />
                    </Stack>
                    <Typography variant='subtitle1'>{t("Log")}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default QuickAccess