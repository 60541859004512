import { Box, Stack, Typography } from '@mui/material';
import { style } from '../../../../Utils/themes/styles';
import ListViewLogistician from '../../../common/components/ListViewLogistician';
import { AllWarehouseDataTypes, logisticianType } from '../../../common/interfaces';
import { Dispatch, SetStateAction } from 'react';
import { baseTheme } from '../../../../Utils/themes/base';
import { COMMON_IMAGES } from '../../../../asset';

const Styles = {
    accordian: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {
            background: style.palette.ActiveBgColor,
            '& .MuiTypography-root': {
                color: style.palette.green[500]
            },
            '& .MuiSvgIcon-root': {
                color: style.palette.green[500]
            }
        },
        '& .MuiAccordionSummary-content': {
            margin: '11px 0px'
        },
        '& .MuiAccordion-root': {
            marginBottom: '10px',
            background: style.palette.BgColor,
            border: 'none',
            boxShadow: "none",
            position: 'unset',
        },
        '& .MuiCollapse-wrapper': {
            background: style.palette.Neutral
        },
        '& .MuiAccordionDetails-root': {
            padding: '8px 0px 0px'
        }

    },
    DataNotFoundSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // padding:'12px',
        '& .MuiTypography-body2': {
            fontSize: "16px",
            marginTop: '10px',
            [baseTheme.breakpoints.down('lg')]: {
                fontSize: "12px !important"
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: "12px !important"
            }
        },
    }
}

interface IAllLogistician {
    AllWarehouseData: AllWarehouseDataTypes;
    handleEditLogistician?: (value:boolean,id?:string)=>void;
    setSelection?: Dispatch<SetStateAction<string>>
    setDeleteLogisticianStatus?: Dispatch<SetStateAction<boolean>>;
    handleDeleteLogistician?: (value:boolean,id?:string) => void;

}

const AllLogistician = ({ AllWarehouseData, handleEditLogistician, setSelection, setDeleteLogisticianStatus,handleDeleteLogistician }: IAllLogistician) => {
    console.log("AllWarehouseData", AllWarehouseData);

    return (
        <Box sx={Styles.accordian}>
            <Stack>
                {
                    AllWarehouseData?.data.length >= 1 ?
                        AllWarehouseData?.data.map((item: any) => {
                            return (
                                <ListViewLogistician
                                    key={item?.id}
                                    id={item?.id}
                                    name={`${item?.f_name} ${item.l_name}`}
                                    image={item?.image1}
                                    image2={item?.image2}
                                    image3={item?.image3}
                                    handleEditLogistician={handleEditLogistician}
                                    setSelection={setSelection}
                                    handleDeleteLogistician={handleDeleteLogistician}
                                />
                            )
                        }) :
                        (
                            <Box sx={Styles.DataNotFoundSection}>
                                <img src={COMMON_IMAGES.DataNotFoundImage} alt="data_not_found" height='80px' width='80px' />
                                <Typography variant="body2">{"No Logistician Found"}</Typography>
                            </Box>)
                }
            </Stack>
        </Box>
    )
}

export default AllLogistician;