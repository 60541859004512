import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { style } from '../../../../Utils/themes/styles';
import { Box, Divider, Grid, Modal, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadingPreparation from './LoadingPreparation';
import ModalView from '../../../common/components/ModalView';
import LoadingCommentForm from './LoadingCommentForm';
import ConfirmationText from '../../../../components/ConfirmModals/ConfirmationText';
import LoadingConfirmationBox from './LoadingConfirmationBox';
import { useLocation } from 'react-router-dom';
import { IMAGES } from '../../../../asset/Dis';
import { baseTheme } from '../../../../Utils/themes/base';
import FileUploadBox from '../../../common/components/FileUploadBox';
import QuotationDetailModal from '../../../common/components/QuotationDetailModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import LogisticsDetailPageFooter from './LogisticsDetailWHFooter';
import LogisticsDetailDIFooter from './LogisticsDetailDIFooter';
import ValidateLoadingPhoto from './ValidateLoadingPhoto';
import { COMMON_IMAGES } from '../../../../asset';

const Styles = {
    LoadingDetails: {
        height: '100%',
        backgroundColor: style.palette.Neutral,
        borderRadius: '12px',
        padding: '20px'
    },
    LoadingDetailHeader: {
        padding: '20px',
        backgroundColor: style.palette.ActiveBgColor,
        '& .MuiGrid-root': {
            marginTop: '0px',
            paddingTop: '0px'
        },
        [baseTheme.breakpoints.down('xl')]: {
            padding: '0px 20px 20px',
        }
    },
    detailHeader: {
        backgroundColor: style.palette.ActiveBgColor,
        borderRight: `1px solid ${style.palette.green[100]}`,
        display: 'flex',
        justifyContent: 'center',
        '& .MuiGrid-item': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '67px',
            marginTop: '16px !important'
        },
        '& .MuiStack-root': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& .MuiTypography-h5': {
                fontSize: '18px',
                color: style.palette.black[200]
            },
            '& .MuiTypography-h3': {
                fontSize: '24px',
                fontWeight: 500,
                color: style.palette.black[300],
                [baseTheme.breakpoints.down('xl')]: {
                    fontSize: '18px',
                },
                [baseTheme.breakpoints.up('lg')]: {
                    fontSize: '18px',
                },
            }

        },
    },
    planningSection: {
        marginTop: '16px',
        borderBottom: `1px solid ${style.palette.black[50]}`,
        paddingBottom: '16px',
        '& .MuiTypography-h5': {
            fontSize: '16px',
            fontWeight: 400,
            color: style.palette.TitleColor
        },
        '& .MuiGrid-item': {
            display: 'flex'
        }
    },
    editIcon: {
        height: '12.39px',
        width: '12.39px',
        marginLeft: '5px',
    },
    alignSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',

    },
    alignHeading: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loadingPhotos: {
        display: 'flex',
        flexDirection: 'column',
        // gap: '10px',
        width: '100%',
        // marginTop: '8px'
    },
    photoIconStyle: {
        height: '21px',

    },
    photoBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        // padding: '10px',
        background: style.palette.BgColor,
        borderRadius: '4px',
        cursor: 'pointer',
        // height: '50px',
        width: '100%'
    },
    confirmValidationStyle: (confirmation?: string) => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        'img': {
            height: '12px',
            marginRight: '2px',
        },
        '& .MuiTypography-body2': {
            color: confirmation === 'Approved'? style.palette.Green :  confirmation === 'Rejected' ? style.palette.Red : style.palette.InProgressTextColor,
        },
    }),
    divider: {
        color: style.palette.GrayStroke,
        // width:'24px',
    },
    PlanningSectionBox: {
        border: `1px solid ${style.palette.black[50]}`,
        borderRadius: '4px',
        height: '103px',
        width: '100%',
        padding: '4px 16px 12px',
        marginTop: '8px',
        '& .MuiStack-root': {
            display: 'felx',
            flexDirection: 'row',
            // marginTop: '8px',
            '& .MuiTypography-subtitle1': {
                fontSize: '14px',
                fontWeight: 400,
                color: style.palette.black[300],
            },
            '& .MuiTypography-body1': {
                fontSize: '14px',
                fontWeight: 500,
                marginLeft: '8px',
                color: style.palette.black[100],
            }
        }
    },
    LoadingDetailSection: {
        marginTop: '16px',
        borderBottom: `1px solid ${style.palette.black[50]}`,
        paddingBottom: '16px',
        '& .MuiStack-root': {
            display: 'flex',
            alignItems: 'flex-start',
            backgroundColor: style.palette.BtnBgColor,
            padding: '12px 16px',
            marginTop: '8px',
            width: '100%',
            borderRadius: '4px',
            '& .MuiTypography-subtitle1': {
                fontSize: '14px',
                fontWeight: 500,
                color: style.palette.black[100],
            },
        }
    },
    commentSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        '& .MuiTypography-body1': {
            fontSize: '14px',
            fontWeight: 500,
            color: style.palette.green[500],
        },
    },
    LoadingSectionFooter: {
        '& .MuiTypography-subtitle1': {
            fontSize: '14px',
            fontWeight: 500,
            // color: style.palette.black[200],
        },
        '& .MuiStack-root': {
            display: "flex",
            flexDirection: 'row',
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "40px"
        }
    },
    buttonStyle: {
        // width: "115px",
        height: "40px",
        // border: `1px solid ${style.palette.black[50]}`,
        borderRadius: "4px",
    },
    accordian: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    loadingSlips: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        // gap: '10px',
        '& .MuiTypography-body1': {
            fontSize: '14px',
            fontWeight: 500,
            color: style.palette.black[400],
        },
        '& .MuiTypography-subtitle2': {
            fontSize: '14px',
            color: `${style.palette.green[500]} !important`,
            textDecoration: 'underline',
            whiteSpace: 'noWrap',
            'img': {
                marginLeft: '20px'
            }
        }
    },
    pdfIconStyle: {
        height: '21px',

    },
    profarmaDetail: {
        padding: '12px 12px 16px',
        backgroundColor: style.palette.BgColor,
        'tr': {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            padding: '12px'
        },
        '& .MuiDivider-root': {
            color: style.palette.black[50]
        }

    },
    loadingSlipBox: {
        display: 'flex',
        flexDirection: 'row',
        background: style.palette.BgColor,
        fontSize: '14px',
        color: style.palette.black[400],
        textAlign: 'left',
        borderRadius: '4px',

    },
    viewFileSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end !important',
        justifyContent: 'flex-end',
        'img': {
            marginLeft: '8px'
        }
    },
    waitingValidationTextAlign: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: style.palette.waitingTextBgColor,
        width: '153px',
        height: '36px',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '16px 0px',
        '& .MuiTypography-body1': {
            fontSize: '12px',
            color: style.palette.InProgressTextColor,
            marginLeft: '4px'
        },
    },
    infoText: {},
    planningSectionText: {
        margin: '5px 0'
    },
    alignSectionText: {
        color: style.palette.TitleColor,
        fontWeight: 400
    },
    commentText: {
        color: style.palette.black[300]
    },
    alignLeft: {
        textAlign: 'left'
    }
};

interface ILogisticsDetailSection {
    selectedAppointment: string;
    loadingProcess?: boolean;
    handleAddCommentModal?: () => void;
    handleCancel?: () => void;
    handleSubmit?: () => void;
    setConfirmLoadingStatus: (value: boolean) => void;
    setLoadingProcess: Dispatch<SetStateAction<boolean>>;
    isOpenCommentModal?: boolean;
    handleCloseCommentModal?: () => void;
    handleValidateComment?: () => void;
    isOpenConfirmValidationModal?: boolean;
    handleCloseConfirmModal: () => void;
    confirmLoadingStatus?: boolean;
    confirmLoading?: boolean;
    handleConfirmLoading?: () => void;
    isConfirmLoading?: boolean;
    setIsConfirmLoading: Dispatch<SetStateAction<boolean>>;
    handleUploadInvoice?: () => void;
    handleUploadProforma?: () => void;
    isAccountFileUpload?: boolean;
    handleCloseFileUploadModal?: () => void;
    handleViewInvoice?: () => void;
    isInvoiceQuotationModalOpen?: boolean;
    handleCloseQuotationModal?: () => void;
    handleConfirmFinalValidation?: () => void;
    confirmFinalValidation?: string;
    handleIsConfirmLoading?: () => void;
    handleFinalValidationProcess?: (value: boolean) => void;
    handleViewPhoto?: () => void;
    viewLoadingPhotoModal?: boolean;
    handleValidateLoadingPhoto: (value: boolean) => void;
    isApprovedLoadingPhoto?: string;
}

const LogisticsDetailSection = ({
    selectedAppointment,
    loadingProcess,
    handleAddCommentModal,
    handleCancel,
    handleSubmit,
    setConfirmLoadingStatus,
    setLoadingProcess,
    isOpenCommentModal,
    handleCloseCommentModal,
    handleValidateComment,
    isOpenConfirmValidationModal,
    handleCloseConfirmModal,
    confirmLoadingStatus,
    confirmLoading,
    handleConfirmLoading,
    isConfirmLoading,
    setIsConfirmLoading,
    handleUploadInvoice,
    handleUploadProforma,
    isAccountFileUpload,
    handleCloseFileUploadModal,
    handleViewInvoice,
    isInvoiceQuotationModalOpen,
    handleCloseQuotationModal,
    handleConfirmFinalValidation,
    confirmFinalValidation,
    handleIsConfirmLoading,
    handleFinalValidationProcess,
    handleViewPhoto,
    viewLoadingPhotoModal,
    handleValidateLoadingPhoto,
    isApprovedLoadingPhoto
}: ILogisticsDetailSection) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [logisticDetailState, setLogisticDetailState] = useState({
        isLoadingNumber: '',
        isDistributor: '',
        isClient: '',
        isTruck: '',
        planningSections: 4,
        invoice: '',
        proForma: '',
    })
    const role = useSelector((state: RootState) => state.userReducer.user.role);
    useEffect(() => {
        setLogisticDetailState((prev) => {
            return {
                ...prev,
                isLoadingNumber: location.pathname === '/unloading' || location.pathname === '/loading' ? 'LoadingNumber' :
                    location.pathname === '/delivery' ? 'DeliveryNumber' : 'ReceptionNumber',
                isDistributor: location.pathname === '/receipt' ? 'Manufacturer' : 'Distributor',
                isClient: location.pathname === '/receipt' ? 'ARCNumber' : 'Client',
                isTruck: location.pathname === '/receipt' ? 'Packaging' : location.pathname === '/delivery' ? 'DeliveryPerson' : 'Truck',
                planningSections: location.pathname === '/delivery' ? 3 : 4
            }
        })
    }, [location.pathname])

    const handleUpload = (files: File[]) => {
        console.log("handleUpload", files);
        setLogisticDetailState((prev) => {
            return {
                ...prev,
                invoice: files[0].name,
                proForma: files[0].name
            }
        })
        handleCloseFileUploadModal && handleCloseFileUploadModal();
    }

    const handleConfirm = () => {
        handleConfirmFinalValidation && handleConfirmFinalValidation();
    }



    let LogisticianFooterView;
    switch (role) {
        case "DI-BO":
            LogisticianFooterView = LogisticsDetailDIFooter;
            break;
        case "DI-WH":
            LogisticianFooterView = LogisticsDetailPageFooter;
            break;
        case "TC-BO":
            LogisticianFooterView = LogisticsDetailDIFooter;
            break;
        default:
            return null;
    }

    return (
        <Box sx={Styles.LoadingDetails}>
            <Box sx={Styles.LoadingDetailHeader}>
                <Grid container alignItems="stretch" >
                    <Grid item xs={3} sx={Styles.detailHeader}>
                        <Stack>
                            <Typography variant='h5'>{t(`${logisticDetailState.isLoadingNumber}`)}</Typography>
                            <Typography variant='h3'>{selectedAppointment}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={3} sx={Styles.detailHeader}>
                        <Stack>
                            <Typography variant='h5'>{t(`${logisticDetailState.isDistributor}`)}</Typography>
                            <Typography variant='h3'>{t("2LDistribution")}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={3} sx={Styles.detailHeader}>
                        <Stack>
                            <Typography variant='h5'>{t(`${logisticDetailState.isClient}`)}</Typography>
                            <Typography variant='h3'>{t("ParisEnergy")}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={3} sx={{ ...Styles.detailHeader, border: 'none' }}>
                        <Stack>
                            <Typography variant='h5'>{(`${logisticDetailState.isTruck}`)}</Typography>
                            <Typography variant='h3' sx={Styles.infoText}>Name/ AA123AA</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            {!loadingProcess &&
                <>
                    {confirmLoading && confirmFinalValidation === 'pending' &&
                        <Stack sx={Styles.waitingValidationTextAlign}>
                            <img src={IMAGES.CourseIcon} alt='CourseIcon' height='18.34px' />
                            <Typography variant='body1'>{t("WaitingValidation")}</Typography>
                        </Stack>
                    }
                    <Box sx={Styles.planningSection}>
                        <Grid container spacing={2} alignItems="stretch" >
                            <Grid item xs={logisticDetailState.planningSections} sx={Styles.alignSection}>
                                <Stack sx={Styles.alignHeading}>
                                    <Typography variant='h5'>{t("Planning")}</Typography>
                                    <img src={IMAGES.editIcon2} alt="edit" style={Styles.editIcon} />
                                </Stack>
                                <Box sx={Styles.PlanningSectionBox}>
                                    <Stack sx={Styles.planningSectionText}>
                                        <Typography variant='subtitle1'>{t("Date")}</Typography>
                                        <Typography variant='body1'>15/01/2024</Typography>
                                    </Stack>
                                    <Stack sx={Styles.planningSectionText}>
                                        <Typography variant='subtitle1'>{t("Hours")}</Typography>
                                        <Typography variant='body1'>2</Typography>
                                    </Stack>
                                    <Stack sx={Styles.planningSectionText}>
                                        <Typography variant='subtitle1'>{t("Duration")}</Typography>
                                        <Typography variant='body1'>09:00 AM - 11:00 AM</Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item xs={logisticDetailState.planningSections} sx={Styles.alignSection}>
                                <Stack sx={Styles.alignHeading}>
                                    <Typography variant='h5'>{t("LoadingLocation")}</Typography>
                                    <img src={IMAGES.editIcon2} alt="edit" style={Styles.editIcon} />
                                </Stack>
                                <Box sx={Styles.PlanningSectionBox}>
                                    <Stack sx={Styles.planningSectionText}>
                                        <Typography variant='subtitle1'>{t("Warehouse")}</Typography>
                                        <Typography variant='body1'>2LD - 95</Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                            {location.pathname === '/delivery' &&
                                <Grid item xs={logisticDetailState.planningSections} sx={Styles.alignSection}>
                                    <Stack sx={Styles.alignHeading}>
                                        <Typography variant='h5'>{t("DeliveryPlace")}</Typography>
                                        <img src={IMAGES.editIcon2} alt="edit" style={Styles.editIcon} />
                                    </Stack>
                                    <Box sx={Styles.PlanningSectionBox}>
                                        <Stack sx={Styles.planningSectionText}>
                                            <Typography variant='subtitle1'>{t("Warehouse")}</Typography>
                                            <Typography variant='body1'>2LD - 95</Typography>
                                        </Stack>
                                    </Box>
                                </Grid>
                            }
                            <Grid item xs={logisticDetailState.planningSections} sx={Styles.alignSection}>
                                <Stack sx={Styles.alignHeading}>
                                    <Typography variant='h5'>{t("LeadTechnician")}</Typography>
                                    <img src={IMAGES.editIcon2} alt="edit" style={Styles.editIcon} />
                                </Stack>
                                <Box sx={Styles.PlanningSectionBox}>
                                    <Stack sx={Styles.planningSectionText}>
                                        <Typography variant='subtitle1'>{t("TechnicianName")}</Typography>
                                        <Typography variant='body1'>Martin</Typography>
                                    </Stack>
                                    <Stack sx={Styles.planningSectionText}>
                                        <Typography variant='subtitle1'>{t("MobileNo.")}</Typography>
                                        <Typography variant='body1'>0625252525</Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={Styles.LoadingDetailSection}>
                        <Grid container spacing={2} alignItems="stretch" >
                            <Grid item xs={6} sx={Styles.alignSection} gap={'10px'}>
                                <Typography variant='h5' sx={Styles.alignSectionText}>{t("LoadingDetails")}</Typography>
                                <Stack>
                                    <Typography variant='subtitle1'>{t("TechnicianName")}</Typography>
                                </Stack>
                                <Stack>
                                    <Typography variant='subtitle1'>{t("TechnicianName")}</Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={6} sx={Styles.alignSection} gap={'10px'}>
                                <Typography variant='h5' sx={Styles.alignSectionText}>{t("LogisticalProofs")}</Typography>
                                {!confirmLoading ? <>
                                    <Stack>
                                        <Typography variant='subtitle1'>{t("LoadingSlip")}</Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography variant='subtitle1'>{t("CertificateValidations")}</Typography>
                                    </Stack></>
                                    : confirmLoading && location.pathname === '/receipt' ? <>
                                        <Stack sx={Styles.loadingSlips} >
                                            <Typography variant="body1" sx={Styles.loadingSlipBox}>
                                                <img src={IMAGES.pdfIcon} style={Styles.pdfIconStyle} alt="pdf" /> {t("SignedLoadingForm")}
                                            </Typography>
                                            <img src={IMAGES.cancelfileIcon} alt="cancel" height='20px' />
                                        </Stack>
                                        <Stack sx={Styles.loadingSlips}>
                                            <Typography variant="body1" sx={Styles.loadingSlipBox}>
                                                <img src={IMAGES.pdfIcon} style={Styles.pdfIconStyle} alt="pdf" />{t("CertificateValidations")}
                                            </Typography>
                                            <img src={IMAGES.cancelfileIcon} alt="cancel" height='20px' />
                                        </Stack>
                                    </> : <>
                                        <Stack sx={Styles.loadingSlips} >
                                            <Box sx={Styles.photoBox}>
                                                <img src={IMAGES.pdfIcon} style={Styles.pdfIconStyle} alt="pdf" />
                                                <Typography variant="body1" sx={Styles.loadingSlipBox}>
                                                    {t("SignedLoadingForm")}
                                                </Typography>
                                                <img src={IMAGES.dividerIcon} alt="cancel" />
                                                <Box sx={Styles.confirmValidationStyle(confirmFinalValidation)}>
                                                    <img src={confirmFinalValidation === 'Approved' ? IMAGES.ApprovedIcon : confirmFinalValidation === 'Rejected' ? IMAGES.RejectIcon :COMMON_IMAGES.WarningIcon} alt="cancel" />

                                                    <Typography variant='body2'>
                                                        {confirmFinalValidation}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Typography variant='subtitle2'>
                                                {("ViewFile")}
                                                {/* <img src={confirmFinalValidation ? IMAGES.successIcon : IMAGES.cancelfileIcon} alt="cancel" height='20px' /> */}
                                            </Typography>

                                        </Stack>
                                        <Stack sx={Styles.loadingSlips}>
                                            <Box sx={Styles.photoBox}>
                                                <img src={IMAGES.pdfIcon} style={Styles.pdfIconStyle} alt="pdf" />
                                                <Typography variant="body1" sx={Styles.loadingSlipBox}>
                                                    {t("CertificateValidations")}

                                                </Typography>
                                                <img src={IMAGES.dividerIcon} alt="cancel" />
                                                <Box sx={Styles.confirmValidationStyle(confirmFinalValidation)}>
                                                    <img src={confirmFinalValidation === 'Approved' ? IMAGES.ApprovedIcon : confirmFinalValidation === 'Rejected' ? IMAGES.RejectIcon : COMMON_IMAGES.WarningIcon} alt="cancel" />

                                                    <Typography variant='body2'>
                                                        {confirmFinalValidation}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Typography variant='subtitle2'>
                                                {("ViewFile")}
                                                {/* <img src={confirmFinalValidation ? IMAGES.successIcon : IMAGES.cancelfileIcon} alt="cancel" height='20px' /> */}
                                            </Typography>
                                        </Stack></>}
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={Styles.LoadingDetailSection}>
                        <Grid container spacing={2} alignItems="stretch" >
                            <Grid item xs={6} sx={Styles.alignSection} gap={'10px'}>

                                <Typography variant='h5' sx={Styles.alignSectionText}>{t("LoadingPhotos")}</Typography>
                                {!confirmLoading ? <>
                                    <Stack>
                                        <Typography variant='subtitle1'>{t("LoadingSlip")}</Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography variant='subtitle1'>{t("CertificateValidations")}</Typography>
                                    </Stack>
                                </> :
                                    <Box sx={Styles.loadingPhotos}>
                                        <Stack sx={Styles.loadingSlips}>
                                            <Box sx={Styles.photoBox}>
                                                <img src={IMAGES.photoIcon} style={Styles.photoIconStyle} alt="Photo" />
                                                <Typography variant="body1">{t("Photo")}</Typography>
                                                <img src={IMAGES.dividerIcon} alt="cancel" />
                                                <Box sx={Styles.confirmValidationStyle(isApprovedLoadingPhoto)}>
                                                    <img src={isApprovedLoadingPhoto === 'Approved' ? IMAGES.ApprovedIcon : isApprovedLoadingPhoto  === 'Rejected'? IMAGES.RejectIcon : COMMON_IMAGES.WarningIcon} alt="cancel" />

                                                    <Typography variant='body2'>
                                                        {isApprovedLoadingPhoto}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Typography variant='subtitle2' onClick={handleViewPhoto}>
                                                {("ViewFile")}
                                                {/* <img src={isApprovedLoadingPhoto ? IMAGES.successIcon : IMAGES.cancelfileIcon} alt="cancel" height='20px' /> */}
                                            </Typography>

                                        </Stack>
                                        <Stack sx={Styles.loadingSlips}>
                                            <Box sx={Styles.photoBox}>
                                                <img src={IMAGES.photoIcon} style={Styles.photoIconStyle} alt="Photo" />
                                                <Typography variant="body1">{t("Photo")}</Typography>
                                                <img src={IMAGES.dividerIcon} alt="cancel" />
                                                <Box sx={Styles.confirmValidationStyle(isApprovedLoadingPhoto)}>
                                                    <img src={isApprovedLoadingPhoto  === 'Approved' ? IMAGES.ApprovedIcon :isApprovedLoadingPhoto  === 'Rejected'? IMAGES.RejectIcon : COMMON_IMAGES.WarningIcon} alt="cancel" />

                                                    <Typography variant='body2'>
                                                        {isApprovedLoadingPhoto}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Typography variant='subtitle2'>
                                                {("ViewFile")}
                                                {/* <img src={confirmFinalValidation ? IMAGES.successIcon : IMAGES.cancelfileIcon} alt="cancel" height='20px' /> */}
                                            </Typography>

                                        </Stack>
                                    </Box>}

                            </Grid>

                            <Grid item xs={6} sx={Styles.alignSection} gap={'10px'}>
                                <Typography variant='h5' sx={Styles.alignSectionText}>{t("Accounting")}</Typography>
                                {!confirmLoading ? <>
                                    <Stack>
                                        <Typography variant='subtitle1'>{t("Proforma")}</Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography variant='subtitle1'>{t("Invoice")}</Typography>
                                    </Stack>
                                </> : <>
                                    <Grid container display='flex' flexDirection={'row'}>
                                        <Grid item xs={6} gap={'10px'}>
                                            <>
                                                <Stack sx={Styles.loadingSlips} >
                                                    {logisticDetailState.proForma ? <>
                                                        <Typography variant="body1" sx={Styles.loadingSlipBox}>
                                                            <img src={IMAGES.pdfIcon} style={Styles.pdfIconStyle} alt="pdf" /> {t("Proforma")}
                                                        </Typography>
                                                        <Box sx={Styles.viewFileSection}>
                                                            <Typography variant='subtitle2' onClick={handleViewInvoice}>{("ViewFile")}</Typography>
                                                            <img src={confirmFinalValidation ? IMAGES.successIcon : IMAGES.cancelfileIcon} alt="cancel" height='20px' />
                                                        </Box> </> :
                                                        <>
                                                            <Typography variant='subtitle1'>{t("Proforma")}</Typography>
                                                            <Typography variant='subtitle2' onClick={handleUploadProforma}>{t("Upload")}</Typography>
                                                        </>}
                                                </Stack>
                                                <Stack sx={Styles.loadingSlips} >
                                                    {logisticDetailState.invoice ? <>
                                                        <Typography variant="body1" sx={Styles.loadingSlipBox}>
                                                            <img src={IMAGES.pdfIcon} style={Styles.pdfIconStyle} alt="pdf" />{t("Invoice")}
                                                        </Typography>
                                                        <Box sx={Styles.viewFileSection}>
                                                            <Typography variant='subtitle2' onClick={handleViewInvoice}>{("ViewFile")}</Typography>
                                                            <img src={confirmFinalValidation ? IMAGES.successIcon : IMAGES.cancelfileIcon} alt="cancel" height='20px' />
                                                        </Box></> : <>
                                                        <Typography variant='subtitle1'>{t("Invoice")}</Typography>
                                                        <Typography variant='subtitle2' onClick={handleUploadInvoice}>{t("Upload")}</Typography>
                                                    </>}
                                                </Stack>
                                            </>
                                        </Grid>
                                        <Grid item xs={6} gap={'10px'}>
                                            <Box sx={Styles.profarmaDetail}>
                                                <tr>
                                                    <td>
                                                        <Typography>HT</Typography>
                                                    </td>
                                                    <td>
                                                        <Typography>TVA</Typography>
                                                    </td>
                                                    <td>
                                                        <Typography>TTC</Typography>
                                                    </td>
                                                </tr>
                                                <Divider />
                                                <tr>
                                                    <td>
                                                        <Typography>15.000€</Typography>
                                                    </td>
                                                    <td>
                                                        <Typography>3.000€</Typography>
                                                    </td>
                                                    <td>
                                                        <Typography>18.000€</Typography>
                                                    </td>
                                                </tr>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </>}
                                {/* // :
                                //     <>
                                //         <Stack sx={Styles.loadingSlips} >
                                //             {logisticDetailState.proForma ? <>
                                //                 <Typography variant="body1" sx={Styles.loadingSlipBox}>
                                //                     <img src={IMAGES.pdfIcon} style={Styles.pdfIconStyle} alt="pdf" /> {t("Proforma")}
                                //                 </Typography>
                                //                 <img src={IMAGES.cancelfileIcon} alt="cancel" height='20px' /> </> :
                                //                 <>
                                //                     <Typography variant='subtitle1'>{t("Proforma")}</Typography>
                                //                     <Typography variant='subtitle2' onClick={handleUploadProforma}>{t("Upload")}</Typography>
                                //                 </>}
                                //         </Stack>
                                //         <Stack sx={Styles.loadingSlips} >
                                //             {logisticDetailState.invoice ? <>
                                //                 <Typography variant="body1" sx={Styles.loadingSlipBox}>
                                //                     <img src={IMAGES.pdfIcon} style={Styles.pdfIconStyle} alt="pdf" />{t("Invoice")}
                                //                 </Typography>
                                //                 <Box sx={Styles.viewFileSection}>
                                //                     <Typography variant='subtitle2' onClick={handleViewInvoice}>{("ViewFile")}</Typography>
                                //                     <img src={confirmFinalValidation ? IMAGES.successIcon : IMAGES.cancelfileIcon} alt="cancel" height='20px' />
                                //                 </Box></> : <>
                                //                 <Typography variant='subtitle1'>{t("Invoice")}</Typography>
                                //                 <Typography variant='subtitle2' onClick={handleUploadInvoice}>{t("Upload")}</Typography>
                                //             </>}
                                //         </Stack>
                                //     </>} */}
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={Styles.LoadingDetailSection}>
                        <Grid container spacing={2} alignItems="stretch" >
                            <Grid item xs={12} sx={Styles.alignSection}>
                                <Box sx={Styles.commentSection}>
                                    <Typography variant='h5' sx={Styles.commentText}>{t("Comments")}</Typography>
                                    <Typography variant='body1' onClick={handleAddCommentModal}>+ {t("AddComments")}</Typography>
                                </Box>
                                <Stack>
                                    <Typography variant='subtitle1' display="block" sx={Styles.alignLeft}>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form</Typography>
                                </Stack>
                                <Stack>
                                    <Typography variant='subtitle1' display="block" sx={Styles.alignLeft}>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={Styles.LoadingSectionFooter}>
                        <Stack>
                            {LogisticianFooterView &&
                                <LogisticianFooterView
                                    confirmLoading={confirmLoading}
                                    handleConfirmLoading={handleConfirmLoading}
                                    handleCancel={handleCancel}
                                    handleSubmit={handleSubmit}
                                    confirmFinalValidation={confirmFinalValidation}
                                />
                            }
                            {/* {confirmLoading ?
                                <ComButtom
                                    label={t("ConfirmLoading")}
                                    variant={"contained"}
                                    textVariant="subtitle1"
                                    inputStyle={Styles.buttonStyle}
                                    onClick={handleConfirmLoading}
                                />
                                : <>
                                    <ComButtom
                                        label={t("Cancel")}
                                        variant={"outlined"}
                                        textVariant="subtitle1"
                                        inputStyle={Styles.buttonStyle}
                                        onClick={handleCancel}
                                    />
                                    {location.pathname === '/loading' || location.pathname === '/unloading' ?
                                        <>
                                            <ComButtom
                                                label={t("EditingAnOrderPreparationForm")}
                                                variant={"contained"}
                                                textVariant="subtitle1"
                                                inputStyle={Styles.buttonStyle}
                                                onClick={handleSubmit}
                                            />
                                            <ComButtom
                                                label={t("StartLoading")}
                                                variant={"contained"}
                                                textVariant="subtitle1"
                                                inputStyle={Styles.buttonStyle}
                                                onClick={handleCancel}
                                                disabled={true}
                                            />
                                        </>
                                        : location.pathname === '/delivery' ?
                                            <ComButtom
                                                label={t("ConfirmDelivery")}
                                                variant={"contained"}
                                                textVariant="subtitle1"
                                                inputStyle={Styles.buttonStyle}
                                                onClick={handleSubmit}
                                            />
                                            : location.pathname === '/receipt' ?
                                                <ComButtom
                                                    label={t("ConfirmReceipt")}
                                                    variant={"contained"}
                                                    textVariant="subtitle1"
                                                    inputStyle={Styles.buttonStyle}
                                                    onClick={handleSubmit}
                                                // disabled={true}
                                                />
                                                :
                                                <ComButtom
                                                    label={t("StartLoading")}
                                                    variant={"contained"}
                                                    textVariant="subtitle1"
                                                    inputStyle={Styles.buttonStyle}
                                                    onClick={handleCancel}
                                                />
                                    }
                                </>} */}
                        </Stack>
                    </Box>
                </>
            }
            {loadingProcess && <LoadingPreparation setConfirmLoadingStatus={setConfirmLoadingStatus} setLoadingProcess={setLoadingProcess} />}
            {isOpenCommentModal &&
                <ModalView
                    open={isOpenCommentModal}
                    handleClose={handleCloseCommentModal}
                    title={t("LeaveaComment")}
                >
                    <LoadingCommentForm handleValidateComment={handleValidateComment} />
                </ModalView>
            }
            {viewLoadingPhotoModal &&
                <ModalView
                    open={viewLoadingPhotoModal}
                    handleClose={handleCloseCommentModal}
                >
                    <ValidateLoadingPhoto handleValidateLoadingPhoto={handleValidateLoadingPhoto} />
                </ModalView>
            }
            {isOpenConfirmValidationModal &&

                <ModalView
                    open={isOpenConfirmValidationModal}
                    handleClose={handleCloseConfirmModal}
                >
                    <ConfirmationText success confirmationMessage={t("YourCommenthasbeenRegistered")} handleCloseModal={handleCloseConfirmModal} />
                </ModalView>
            }
            {
                confirmLoadingStatus &&
                <Modal
                    open={confirmLoadingStatus}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <LoadingConfirmationBox setConfirmLoadingStatus={setConfirmLoadingStatus} setLoadingProcess={setLoadingProcess} />
                </Modal>
            }
            {isConfirmLoading &&
                <Modal
                    open={isConfirmLoading}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <LoadingConfirmationBox finalValidation setConfirmLoadingStatus={setIsConfirmLoading} setLoadingProcess={handleFinalValidationProcess} handleConfirmLoading={handleIsConfirmLoading} />
                </Modal>
            }

            {isAccountFileUpload &&
                <ModalView
                    open={isAccountFileUpload}
                    handleClose={handleCloseFileUploadModal}
                    title=" "
                >
                    <FileUploadBox heading={t("DragYourDocumentsheretoStartUploading")} browseMessage={t("BrowseFiles")} handleUpload={handleUpload} />
                </ModalView>
            }

            {isInvoiceQuotationModalOpen &&
                <ModalView
                    open={isInvoiceQuotationModalOpen}
                    handleClose={handleCloseQuotationModal}
                    title={t("CheckQuotationDetails")}
                >
                    <QuotationDetailModal handleConfirm={handleConfirm} />
                </ModalView>
            }

        </Box>
    )
}

export default LogisticsDetailSection