
import Request from "../../../Axios/Instance";
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../../store';
import { AxiosResponse } from "axios";
import { getWarehouses } from "./getWarehouse";
export const FETCH_WAREHOUSE = "FETCH_WAREHOUSE";
export const RECEIVE_WAREHOUSE_UPDATE = "RECEIVE_WAREHOUSE_UPDATE";
export const RECEIVE_WAREHOUSE_ERROR = "RECEIVE_WAREHOUSE_ERROR";

const fetchWarehouse = () => ({
    type: FETCH_WAREHOUSE
})

export const receiveWarehouseUpdate = (data: any) => ({
    type: RECEIVE_WAREHOUSE_UPDATE,
    payload: data
})

export const receiveWarehouseError = (err: unknown) => ({
    type: RECEIVE_WAREHOUSE_ERROR,
    payload: err
})

export const deleteWarehouse = (payload: any): ThunkAction<Promise<AxiosResponse<any>>, RootState, unknown, AnyAction> =>
    (dispatch, getState) =>
        new Promise((resolve, reject) => {
            dispatch(fetchWarehouse);
            const fetchPost = async () => {
                try {
                    const response = await Request.delete(`/warehouse?warehouse_id=${payload}`);
                    await dispatch(getWarehouses());
                    console.log("response",response);
                    
                    resolve(response.data)
                } catch (err) {
                    dispatch(receiveWarehouseError(err));
                }
            }
            fetchPost();
        })