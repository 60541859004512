import { Box, Stack, Typography } from '@mui/material'
import React from 'react';
import {
    GridActionsCellItem, GridColDef, GridColumnGroupingModel, GridRenderCellParams,
} from '@mui/x-data-grid';
import DataGridTable from '../../../common/components/DataGridTable';
import { style } from '../../../../Utils/themes/styles';
import { IMAGES } from '../../../../asset/Dis';
import { useTranslation } from 'react-i18next';
import { rowsDataTypes } from '../../../common/interfaces';

const Styles = {
    agendaListRoot: {
        '& .MuiTableCell-root ': {
            width: '135px',
            border: '1px solid #E1E1E1',
        },
        '& .MuiDataGrid-root': {
            ...style.typography.subtitle1,
            fontSize: '10px'
        },
        '& .MuiDataGrid-cell': {
            display: 'flex'
        },
        '& .my-super-theme--naming-group': {
            '& .MuiDataGrid-columnHeaderTitle': {
                ...style.typography.h6,
                fontWeight: 600,
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {
                display: "flex",
                justifyContent: 'space-between'
            },
        },
        '& .MuiButtonBase-root-MuiIconButton-root': {
            padding: '0px'
        },
        '& .MuiDataGrid-actionsCell ': {
            gridGap: '0px !important'
        },
       
    },
    viewListStyle: (data: string) => ({
        background: data === 'view' ? style.palette.ViewIconBGColor : style.palette.EditIconBGColor,
        height: '25px',
        width: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px'
    }),
    truckNameWithImage: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
        '& .MuiTypography-subtitle1': {
            fontSize: '10px'
        },
        '& .MuiTypography-body1': {
            fontSize: '10px',
            color: style.palette.CellDataNotFoundColor
        }
    }
}

interface IAgendaList {
    rowsData: rowsDataTypes
}

interface CustomImageCellProps {
    data: {
        data: string; // Adjust according to the actual type
    }
}

const AgendaList = ({ rowsData }: IAgendaList) => {
    const { t } = useTranslation();
    const CustomImageCell = ({ data }: CustomImageCellProps) => {
        console.log("data", data);

        return (
            <Stack sx={Styles.truckNameWithImage}>
                <img src={`${IMAGES.TruckIcon}`} alt='Icon' height="28px" />
                <Typography variant='subtitle1'>{t(`${data}`)}</Typography>
            </Stack>
        )
    }

    const CustomDataCell = (data: CustomImageCellProps) => {
        return (
            <Stack sx={Styles.truckNameWithImage}>
                {data?.data ?
                    <Typography variant='subtitle1'>{t(`${data?.data}`)}</Typography>
                    :
                    <Typography variant='body1'>M/D</Typography>
                }
            </Stack>
        )
    }


    const columns: readonly GridColDef[] = [
        {
            field: 'Appointment_Type',
            headerName: t("AppointmentType"),
            editable: true,
            align: 'center',
            width: 160,
            headerAlign: 'center',
            flex: 1,
            renderCell: (cellValues: GridRenderCellParams) => (
                <CustomDataCell data={cellValues.value} />
            ),
        },
        {
            field: 'Truck_Name',
            headerName: t("TruckName"),
            width: 150,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues: GridRenderCellParams) => (
                <CustomImageCell data={cellValues.value} />
            ),
        },
        {
            field: 'File_Number',
            headerName: t("FileNumber"),
            editable: true,
            width: 120,
            align: 'center',
            headerAlign: 'center',
            flex: 1,
        },
        {
            field: 'Warehouse',
            headerName: t("Warehouse"),
            editable: true,
            align: 'center',
            width: 120,
            headerAlign: 'center',
            flex: 1,
            renderCell: (cellValues: GridRenderCellParams) => (
                <CustomDataCell data={cellValues.value} />
            ),
        },
        {
            field: 'Date_Of_Work',
            headerName: t("DateOfWork"),
            editable: true,
            width: 120,
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'actions',
            type: 'actions',
            width: 75,
            headerName: t("Actions"),
            cellClassName: 'actions',
            getActions: () => {

                return [
                    <GridActionsCellItem
                        icon={<Stack sx={Styles.viewListStyle("view")}>
                            <img src={`${IMAGES.ViewIcon}`} alt='view' height='16px' />
                        </Stack>}
                        label="Edit"
                        className="textPrimary"
                        // onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Stack sx={Styles.viewListStyle("edit")}>
                            <img src={`${IMAGES.EditIcon}`} alt='view' height='16px' />
                        </Stack>}
                        label="Delete"
                        // onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const columnGroupingModel: GridColumnGroupingModel = [
        {
            groupId: 'internal_data',
            headerName: t("Appointment"),
            headerClassName: 'my-super-theme--naming-group',
            children: [{ field: 'Appointment_Type' }],
        },
        {
            groupId: 'naming',
            // align: 'right',
            headerName: t("Teams"),
            headerClassName: 'my-super-theme--naming-group',
            children: [{ field: 'Truck_Name' }, { field: 'File_Number' }, { field: 'Warehouse' }, { field: 'Date_Of_Work' }, { field: 'actions' }],
        },
    ];

    return (
        <Box sx={Styles.agendaListRoot}>
            <DataGridTable
                rowsData={rowsData}
                columns={columns}
                columnGroupingModel={columnGroupingModel} handleOnCellClick={undefined} />
        </Box>
    )
}

export default AgendaList