import React from 'react'
import { useLocation } from 'react-router-dom';
import ComButtom from '../../../../components/formComponents/button/ComButtom';
import { style } from '../../../../Utils/themes/styles';
import { useTranslation } from 'react-i18next';
import { baseTheme } from '../../../../Utils/themes/base';

const Styles = {
    LoadingDetails: {
        height: '100%',
        backgroundColor: style.palette.Neutral,
        borderRadius: '12px',
        padding: '20px'
    },
    LoadingDetailHeader: {
        padding: '20px',
        backgroundColor: style.palette.ActiveBgColor,
        '& .MuiGrid-root': {
            marginTop: '0px',
            paddingTop: '0px'
        },
        [baseTheme.breakpoints.down('xl')]: {
            padding: '0px 20px 20px',
        }
    },
    detailHeader: {
        backgroundColor: style.palette.ActiveBgColor,
        borderRight: `1px solid ${style.palette.green[100]}`,
        display: 'flex',
        justifyContent: 'center',
        '& .MuiGrid-item': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '67px',
            marginTop: '16px !important'
        },
        '& .MuiStack-root': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& .MuiTypography-h5': {
                fontSize: '18px',
                color: style.palette.black[200]
            },
            '& .MuiTypography-h3': {
                fontSize: '24px',
                fontWeight: 500,
                color: style.palette.black[300]
            }

        },
    },
    planningSection: {
        marginTop: '16px',
        borderBottom: `1px solid ${style.palette.black[50]}`,
        paddingBottom: '16px',
        '& .MuiTypography-h5': {
            fontSize: '16px',
            fontWeight: 400,
            color: style.palette.TitleColor
        },
        '& .MuiGrid-item': {
            display: 'flex'
        }
    },
    editIcon: {
        height: '12.39px',
        width: '12.39px',
        marginLeft: '5px',
    },
    alignSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',

    },
    alignHeading: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loadingPhotos: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        width: '100%',
        marginTop: '8px'
    },
    photoIconStyle: {
        height: '21px',

    },
    photoBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        // padding: '10px',
        background: style.palette.BgColor,
        borderRadius: '4px',
        cursor: 'pointer',
        // height: '50px',
        width: '100%'
    },
    PlanningSectionBox: {
        border: `1px solid ${style.palette.black[50]}`,
        borderRadius: '4px',
        height: '103px',
        width: '100%',
        padding: '4px 16px 12px',
        marginTop: '8px',
        '& .MuiStack-root': {
            display: 'felx',
            flexDirection: 'row',
            // marginTop: '8px',
            '& .MuiTypography-subtitle1': {
                fontSize: '14px',
                fontWeight: 400,
                color: style.palette.black[300],
            },
            '& .MuiTypography-body1': {
                fontSize: '14px',
                fontWeight: 500,
                marginLeft: '8px',
                color: style.palette.black[100],
            }
        }
    },
    LoadingDetailSection: {
        marginTop: '16px',
        borderBottom: `1px solid ${style.palette.black[50]}`,
        paddingBottom: '16px',
        '& .MuiStack-root': {
            display: 'flex',
            alignItems: 'flex-start',
            backgroundColor: style.palette.BtnBgColor,
            padding: '12px 16px',
            marginTop: '8px',
            width: '100%',
            borderRadius: '4px',
            '& .MuiTypography-subtitle1': {
                fontSize: '14px',
                fontWeight: 500,
                color: style.palette.black[100],
            },
        }
    },
    commentSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        '& .MuiTypography-body1': {
            fontSize: '14px',
            fontWeight: 500,
            color: style.palette.green[500],
        },
    },
    LoadingSectionFooter: {
        '& .MuiTypography-subtitle1': {
            fontSize: '14px',
            fontWeight: 500,
            // color: style.palette.black[200],
        },
        '& .MuiStack-root': {
            display: "flex",
            flexDirection: 'row',
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "40px"
        }
    },
    buttonStyle: {
        // width: "115px",
        height: "40px",
        // border: `1px solid ${style.palette.black[50]}`,
        borderRadius: "4px",
    },
    accordian: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    loadingSlips: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '10px',
        '& .MuiTypography-body1': {
            fontSize: '14px',
            fontWeight: 500,
            color: style.palette.black[400],
        },
        '& .MuiTypography-subtitle2': {
            fontSize: '14px',
            color: `${style.palette.green[500]} !important`,
            textDecoration: 'underline',
            whiteSpace: 'noWrap',
            'img': {
                marginLeft: '20px'
            }
        }
    },
    pdfIconStyle: {
        height: '21px',

    },
    profarmaDetail: {
        padding: '12px 12px 16px',
        backgroundColor: style.palette.BgColor,
        'tr': {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            padding: '12px'
        },
        '& .MuiDivider-root': {
            color: style.palette.black[50]
        }

    },
    loadingSlipBox: {
        display: 'flex',
        flexDirection: 'row',
        background: style.palette.BgColor,
        fontSize: '14px',
        color: style.palette.black[400],
        textAlign: 'left',
        borderRadius: '4px',

    },
    viewFileSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end !important',
        justifyContent: 'flex-end',
        'img': {
            marginLeft: '8px'
        }
    },
    waitingValidationTextAlign: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: style.palette.waitingTextBgColor,
        width: '153px',
        height: '36px',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '16px 0px',
        '& .MuiTypography-body1': {
            fontSize: '12px',
            color: style.palette.InProgressTextColor,
            marginLeft: '4px'
        },
    },
    infoText: {},
    planningSectionText: {
        margin: '5px 0'
    },
    alignSectionText: {
        color: style.palette.TitleColor,
        fontWeight: 400
    },
    commentText: {
        color: style.palette.black[300]
    },
    alignLeft: {
        textAlign: 'left'
    }
};

interface ILogisticsDetailPageFooter {
    handleCancel?: () => void;
    handleSubmit?: () => void;
    confirmLoading?: boolean;
}

const LogisticsDetailWHFooter = ({ handleCancel, handleSubmit, confirmLoading }: ILogisticsDetailPageFooter) => {
    const location = useLocation();
    const { t } = useTranslation();
    return (
        <>
            {!confirmLoading &&
                <>
                    <ComButtom
                        label={t("Cancel")}
                        variant={"outlined"}
                        textVariant="subtitle1"
                        inputStyle={Styles.buttonStyle}
                        onClick={handleCancel}
                    />
                    {location.pathname === '/loading' || location.pathname === '/unloading' ?
                        <>
                            <ComButtom
                                label={t("EditingAnOrderPreparationForm")}
                                variant={"contained"}
                                textVariant="subtitle1"
                                inputStyle={Styles.buttonStyle}
                                onClick={handleSubmit}
                            />
                            <ComButtom
                                label={t("StartLoading")}
                                variant={"contained"}
                                textVariant="subtitle1"
                                inputStyle={Styles.buttonStyle}
                                onClick={handleCancel}
                                disabled={true}
                            />
                        </>
                        : location.pathname === '/delivery' ?
                            <ComButtom
                                label={t("ConfirmDelivery")}
                                variant={"contained"}
                                textVariant="subtitle1"
                                inputStyle={Styles.buttonStyle}
                                onClick={handleSubmit}
                            />
                            : location.pathname === '/receipt' ?
                                <ComButtom
                                    label={t("ConfirmReceipt")}
                                    variant={"contained"}
                                    textVariant="subtitle1"
                                    inputStyle={Styles.buttonStyle}
                                    onClick={handleSubmit}
                                // disabled={true}
                                />
                                :
                                <ComButtom
                                    label={t("StartLoading")}
                                    variant={"contained"}
                                    textVariant="subtitle1"
                                    inputStyle={Styles.buttonStyle}
                                    onClick={handleCancel}
                                />
                    }
                </>
            }
        </>
    )
}

export default LogisticsDetailWHFooter