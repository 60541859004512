import { Box, Stack, Typography, Grid } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { style } from '../../../../Utils/themes/styles';
import StockListDetails from '../../../common/components/StockListDetails';
import { AllWarehouseDataTypes } from '../../../common/interfaces';

const Styles = {
    accordian: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {
            background: style.palette.ActiveBgColor,
            '& .MuiTypography-root': {
                color: style.palette.green[500]
            },
            '& .MuiSvgIcon-root': {
                color: style.palette.green[500]
            }
        },
        '& .MuiAccordionSummary-content': {
            margin: '11px 0px'
        },
        '& .MuiAccordion-root': {
            marginBottom: '10px',
            background: style.palette.BgColor,
            border: 'none',
            boxShadow: "none",
            position: 'unset',
        },
        '& .MuiCollapse-wrapper': {
            background: style.palette.Neutral
        },
        '& .MuiAccordionDetails-root': {
            padding: '8px 0px 0px'
        }

    },
    header: {
        padding: '8px 0px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${style.palette.GrayStroke}`,
        '& .MuiTypography-h6': {
            color: style.palette.TitleColor,
        },
        'img': {
            marginRight: '12px',
        }
    },
    fontWeight: {
        fontWeight: 500,
    },
    linkStyle: {
        lineHeight: "19.5px",
        textAlign: "left",
        color: "#007A6A",
        textDecoration: "underline",
        cursor: "pointer"
    },
    positionRight: {
        positions: "absolute",
        right: 0,
    }
}
interface IWarehouseDetails {

    AllWarehouseData: AllWarehouseDataTypes;
    handleStockTableShow: () => void;
}
const StocksDetails = ({ AllWarehouseData, handleStockTableShow }: IWarehouseDetails) => {
    const { t } = useTranslation();
    return (
        <Box sx={Styles.accordian}>
            <Stack>
                <Grid container sx={{ ...Styles.header, position: "relative" }} >
                    <Grid >
                        <Typography variant='h6' sx={Styles.fontWeight}> {t("stocksWarehouse")}</Typography>
                    </Grid>
                    <Grid sx={Styles.positionRight}>
                        <Typography variant='subtitle1' sx={Styles.linkStyle} onClick={handleStockTableShow}>  {t("viewMore")}</Typography>
                    </Grid>
                </Grid>
                <StockListDetails
                    stockDetails={AllWarehouseData[0]?.stocks?.stocksWarehouse}
                />
            </Stack>
        </Box>
    )
}

export default StocksDetails;