import { Box, Divider, Grid, TextField, Typography, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListContainer from '../common/components/ListContainer';
import { AllWarehouseData } from './common/constant';
import ListView from '../common/components/ListView';
import ComButtom from '../../components/formComponents/button/ComButtom';
import { style } from '../../Utils/themes/styles';
import WarehouseDetails from './common/components/WarehouseDetails';
import ManagerDetails from './common/components/ManagerDetails';
import AssistantDetails from './common/components/AssistantDetails';
import DetailsContainer from '../common/components/DetailsContainer';
import StocksDetails from './common/components/StocksDetails';
import AllLogistique from './common/components/AllLogistique';
import AllLogistician from './common/components/AllLogistician';
import EditWarehouse from './common/components/EditWarehouse';
import AddWarehouse from './common/components/AddWarehouse';
import WarehouseBox from '../common/components/WarehouseBox';
import { AllWarehouseDataTypes } from '../common/interfaces';
import LogisticsHistory from '../common/components/LogisticsHistory';
import ComponentDetails from './common/components/ComponentDetails';
import StockTableList from '../common/components/StockTableList';
import AddInventory from './common/components/AddInventory';
import AddLogistician from './common/components/AddLogistician';
import EditLogistician from './common/components/EditLogistician';
import ConfirmationModal from '../common/components/ConfirmationModal';
import PopupModal from '../common/components/PopupModal';
import { baseTheme } from '../../Utils/themes/base';

const Styles = {
    mapViewbtns: {
        display: 'flex',
        flexDirection: 'row',
        [baseTheme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            alignItems: 'flex-end',
            '& .MuiTypography-root': {
                fontSize: '11px'
            }
        },

    },
    editBtnStyle: {
        height: '36px',
        borderRadius: '4px',
        [baseTheme.breakpoints.down('lg')]: {
            height: '32px',
        },
        [baseTheme.breakpoints.up('lg')]: {
            height: '32px',
        }
    },
    btnBoxStyle: {
        marginRight: '4px',
        '& .MuiTypography-subtitle1': {
            fontSize: "13px",
            [baseTheme.breakpoints.down('lg')]: {
                fontSize: "11px !important"
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: "11px !important"
            }
        },
    },
    fullHeight: {
        height: "100%"
    },
    alignTextStyle: {
        '& .MuiTypography-root MuiTypography-subtitle1': {
            fontSize: "13px",
            [baseTheme.breakpoints.down('lg')]: {
                fontSize: "11px !important"
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: "11px !important"
            }
        },
    },
    headerSpacing: {
        padding: "20px 20px 15px 20px"
    },
    linkStyle: {
        lineHeight: "19.5px",
        textAlign: "left",
        color: style.palette.green[500],
        textDecoration: "underline",
        cursor: "pointer"
    },
    divider: {
        borderRadius: 0,
        backgroundColor: style.palette.GrayStroke,
    },
    paddingBottomHigh: {
        padding: '15px 20px 35px 20px'
    },
    paddingTopLow: {
        padding: '15px 20px 20px 20px'
    },
    borderRadiusTop: {
        borderRadius: "12px 12px 0 0"
    },
    borderRadiusBottom: {
        borderRadius: "0 0 12px 12px"
    },
    searchBarStyle: {
        width: "368px !important",
        height: "40px",
        border: "none",
        boxShadow: "none",
        background: style.palette.BgColor,
        marginRight: "10px",
        '& .css-1vy85e9-MuiInputBase-root-MuiOutlinedInput-root': {
            height: "100%"
        },
        '& fieldset': {
            border: "none"
        },
        [baseTheme.breakpoints.down('lg')]: {
            width: "158px !important",
        }
    },
    buttonBoxStyle: {
        marginRight: "4px",
        height: "40px",
        borderRadius: "4px",
    },
    inventoryHeading: {
        [baseTheme.breakpoints.down('xl')]: {
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            alignItems: 'flex-start'
        },
    },
    detailsHeading: {
        [baseTheme.breakpoints.down('xl')]: {
            display: 'flex',
            gap: '7px',
            flexDirection: 'row',
            height: '34%'
        },
        height: '30%'
    },
    warehouseChildStyle: {
        padding: '20px',
        height: '90%',
        [baseTheme.breakpoints.down('xl')]: {
            padding: '10px',
            '& .css-sx40us': {
                padding: '5px'
            },
            '& .css-70qvj9 img': {
                height: '25px'
            }
        }
    },
    buttonStyle: {
        marginRight: '4px',
        [baseTheme.breakpoints.down('xl')]: {
            width: '105px'
        }
    },
    stockHeading: {
        [baseTheme.breakpoints.down('lg')]: {
            height: '10%'
        },
    }
};

interface IWarehouseDis {
    warehouseSelect:string | undefined;
    setWarehouseSelect:React.Dispatch<React.SetStateAction<string | undefined>>
    editWarehouseStatus:boolean;
    setEditWarehouseStatus: React.Dispatch<React.SetStateAction<boolean>>;
    newWarehouseStatus:boolean;
    setNewWarehouseStatus:React.Dispatch<React.SetStateAction<boolean>>;
    newInventoryStatus:boolean;
    setNewInventoryStatus:React.Dispatch<React.SetStateAction<boolean>>;
    selection:string;
    setSelection: React.Dispatch<React.SetStateAction<string>>;
    newLogisticianStatus:boolean;
    setNewLogisticianStatus:React.Dispatch<React.SetStateAction<boolean>>;
    editLogisticianStatus:boolean;
    handleEditLogistician:(value:boolean)=>void;
    setEditLogisticianStatus:React.Dispatch<React.SetStateAction<boolean>>;
    fullStockShowStatus:boolean;
    setFullStockShowStatus:React.Dispatch<React.SetStateAction<boolean>>;
    logisticsHistoryStatus:boolean;
    setLogisticsHistoryStatus:React.Dispatch<React.SetStateAction<boolean>>;
    deleteWarehouseStatus:boolean;
    setDeleteWarehouseStatus:React.Dispatch<React.SetStateAction<boolean>>;
    searched:string;
    setSearched:React.Dispatch<React.SetStateAction<string>>;
}

const WarehouseDis = ({
    warehouseSelect,
    setWarehouseSelect,
    editWarehouseStatus,
    setEditWarehouseStatus,
    newWarehouseStatus,
    setNewWarehouseStatus,
    newInventoryStatus,
    setNewInventoryStatus,
    selection,
    setSelection,
    newLogisticianStatus,
    setNewLogisticianStatus,
    editLogisticianStatus,
    handleEditLogistician,
    setEditLogisticianStatus,
    fullStockShowStatus,
    setFullStockShowStatus,
    logisticsHistoryStatus,
    setLogisticsHistoryStatus,
    deleteWarehouseStatus,
    setDeleteWarehouseStatus,
    searched,
    setSearched,
}:IWarehouseDis) => {
    const [deleteLogisticianStatus, setDeleteLogisticianStatus] = useState<boolean>(false);

    const handleEditWarehouseDetails = () => {
        console.log("edit button");
        setEditWarehouseStatus(true);
    };

    const handleAddNewInventory = () => {
        setNewInventoryStatus(true);
        setSelection("inventory")
        console.log("add inventory button");
    };
    const handleAddNewLogistician = () => {
        setNewLogisticianStatus(true);
        setSelection("logistician")
        console.log("add logistician button");
    };

    const selectedWarehouse = AllWarehouseData.find((item: AllWarehouseDataTypes) => item.id === warehouseSelect);
    console.log(selectedWarehouse);

    const handleStockTableShow = () => {
        setFullStockShowStatus(true);
    };

    const handleLogisticsHistory = () => {
        setLogisticsHistoryStatus(true);
    };
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearched(e.target.value);
    }
    interface InventoryItem {
        id: number;
        title: string;
        subTitle: string;
        image: string;
    }

    const handleNewMaterialAddInStock = () => {
        console.log("new material add");
    };
    useEffect(() => {
        const deleteTimeout = setTimeout(() => {
            if (deleteLogisticianStatus) {
                setDeleteLogisticianStatus(false);
            }
        }, 1500);
        return () => {
            clearTimeout(deleteTimeout)
        }
    }, [deleteLogisticianStatus]);
    const { t } = useTranslation();
    return (
        <>
            <Grid container spacing={2} alignItems="stretch" sx={Styles.alignTextStyle}>

                {!fullStockShowStatus && !logisticsHistoryStatus && (
                    <>
                        <Grid item lg={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <WarehouseBox
                                        leftContainer={t(`${selectedWarehouse?.title} #${selectedWarehouse?.id}`)}
                                        rightContainer={
                                            <Box sx={Styles.mapViewbtns}>
                                                <ComButtom
                                                    label={t(`Edit`)}
                                                    variant={"contained"}
                                                    textVariant="subtitle1"
                                                    inputStyle={Styles.editBtnStyle}
                                                    boxStyle={Styles.btnBoxStyle}
                                                    onClick={() => handleEditWarehouseDetails()}
                                                />
                                            </Box>
                                        }
                                        sx={Styles.fullHeight}
                                        headerStyle={Styles.detailsHeading}
                                        childContainerStyle={{ height: '81%' }}
                                    >
                                        <WarehouseDetails AllWarehouseData={AllWarehouseData} />
                                    </WarehouseBox>
                                </Grid>
                                <Grid item xs={4}>
                                    <DetailsContainer
                                        leftContainer={t("ManagerDetails")}
                                        sx={{ ...Styles.borderRadiusTop, boxShadow: "none" }}
                                        headerStyle={Styles.headerSpacing}
                                        childContainerStyle={{ height: '75%' }}
                                    >
                                        <ManagerDetails ManagerData={AllWarehouseData} />
                                    </DetailsContainer>
                                    <Divider sx={Styles.divider} orientation="horizontal" flexItem />
                                    <DetailsContainer
                                        leftContainer={t("Assistant")}
                                        sx={Styles.borderRadiusBottom}
                                        headerStyle={{ ...Styles.paddingTopLow, height: '12%' }}
                                        childContainerStyle={{ height: '81%' }}
                                    >
                                        <AssistantDetails AsistantData={AllWarehouseData} />
                                    </DetailsContainer>
                                </Grid>
                                <Grid item xs={4}>
                                    <ListContainer
                                        leftContainer={t("Logistician")}
                                        rightContainer={
                                            <Box sx={Styles.mapViewbtns}>
                                                <ComButtom
                                                    label={t(`AddNewLogistician`)}
                                                    variant={"contained"}
                                                    textVariant="subtitle1"
                                                    inputStyle={Styles.editBtnStyle}
                                                    boxStyle={Styles.btnBoxStyle}
                                                    onClick={() => handleAddNewLogistician()}
                                                />
                                            </Box>
                                        }
                                        sx={Styles.fullHeight}
                                        childContainerStyle={{ height: '81%' }}
                                        headerStyle={{ ...Styles.headerSpacing, ...Styles.inventoryHeading }}
                                    >
                                        <AllLogistician AllWarehouseData={AllWarehouseData} handleEditLogistician={handleEditLogistician} setSelection={setSelection} setDeleteLogisticianStatus={setDeleteLogisticianStatus} />
                                    </ListContainer>
                                </Grid>
                                <Grid item xs={8}>
                                    <DetailsContainer
                                        leftContainer={t("Stocks")}
                                        rightContainer={""}
                                        sx={{ height: '100%' }}
                                        headerStyle={{
                                            ...Styles.paddingBottomHigh,
                                            height: '12%',
                                        }}
                                        childContainerStyle={{ height: '81%' }}
                                    >
                                        <StocksDetails
                                            AllWarehouseData={AllWarehouseData}
                                            handleStockTableShow={handleStockTableShow}
                                        />
                                    </DetailsContainer>
                                </Grid>
                                <Grid item xs={4}>
                                    <ListContainer
                                        leftContainer={t("Inventories")}
                                        sx={{ height: '100%' }}
                                        rightContainer={
                                            <Box sx={Styles.mapViewbtns}>
                                                <ComButtom
                                                    label={t(`AddNewInventory`)}
                                                    variant={"contained"}
                                                    textVariant="subtitle1"
                                                    inputStyle={Styles.editBtnStyle}
                                                    boxStyle={Styles.btnBoxStyle}
                                                    onClick={() => handleAddNewInventory()}
                                                />
                                            </Box>
                                        }
                                        headerStyle={{ ...Styles.headerSpacing, ...Styles.inventoryHeading }}
                                    >
                                        {Array.isArray(AllWarehouseData[0]?.inventories) ? (
                                            AllWarehouseData[0].inventories.map((item: InventoryItem) => (
                                                <ListView
                                                    key={item.id}
                                                    title={item.title}
                                                    subTitle={item.subTitle}
                                                    image={item.image}
                                                />
                                            ))
                                        ) : (
                                            <Typography variant="body2">{t("NoInventoriesFound")}</Typography>
                                        )}
                                    </ListContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={3}>
                            <ListContainer
                                leftContainer={t("Logistique")}
                                rightContainer={
                                    <Grid>
                                        <Typography variant='subtitle1' sx={Styles.linkStyle} onClick={() => handleLogisticsHistory()}>{t("viewHistory")}</Typography>
                                    </Grid>
                                }
                                sx={Styles.fullHeight}
                                headerStyle={Styles.headerSpacing}
                                childContainerStyle={{ height: '81%' }}
                            >
                                <AllLogistique AllWarehouseData={AllWarehouseData} />
                            </ListContainer>
                        </Grid>
                    </>
                )}
                {fullStockShowStatus && (
                    <Grid item md={8} lg={12}>
                        <ComponentDetails
                            leftContainer={t("WarehouseStock")}
                            rightContainer={
                                <Box sx={Styles.mapViewbtns}>
                                    <TextField
                                        id="outlined-password-input"
                                        placeholder='Search here'
                                        type="text"
                                        autoComplete="current-password"
                                        sx={Styles.searchBarStyle}
                                        value={searched}
                                        onChange={handleSearchChange}
                                    />
                                    <ComButtom
                                        label={t(`AddNewMaterialToStock`)}
                                        variant={"contained"}
                                        textVariant="subtitle1"
                                        inputStyle={Styles.editBtnStyle}
                                        boxStyle={Styles.buttonBoxStyle}
                                        onClick={() => handleNewMaterialAddInStock()}
                                    />
                                </Box>
                            }
                            sx={Styles.fullHeight}
                            headerStyle={{ ...Styles.headerSpacing, ...Styles.stockHeading }}
                            childContainerStyle={{ height: '92%' }}
                            handleStatus={setFullStockShowStatus}
                            nextPreviousStatus={true}
                        >
                            <StockTableList />
                        </ComponentDetails>
                    </Grid>
                )}
                {logisticsHistoryStatus && (
                    <Grid item lg={12} md={12}>
                        <ComponentDetails
                            leftContainer={t("LogisticsHistory")}
                            rightContainer={null}
                            sx={Styles.fullHeight}
                            headerStyle={Styles.headerSpacing}
                            childContainerStyle={{ padding: "none" }}
                            handleStatus={setLogisticsHistoryStatus}
                            nextPreviousStatus={false}
                        >
                            <LogisticsHistory />
                        </ComponentDetails>
                    </Grid>
                )}
            </Grid>
            <Modal
                open={editWarehouseStatus}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <EditWarehouse setEditWarehouseStatus={setEditWarehouseStatus} />
            </Modal>
            <Modal
                open={newWarehouseStatus}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddWarehouse setNewWarehouseStatus={setNewWarehouseStatus} />
            </Modal>
            <Modal
                open={newInventoryStatus}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddInventory setNewInventoryStatus={setNewInventoryStatus} selection={selection} />
            </Modal>
            <Modal
                open={newLogisticianStatus}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddLogistician setNewLogisticianStatus={setNewLogisticianStatus} selection={selection} />
            </Modal>
            <Modal
                open={editLogisticianStatus}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <EditLogistician setEditLogisticianStatus={setEditLogisticianStatus} selection={selection} />
            </Modal>
            <Modal
                open={deleteWarehouseStatus}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ConfirmationModal setDeleteWarehouseStatus={setDeleteWarehouseStatus} />
            </Modal>
            <Modal
                open={deleteLogisticianStatus}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <PopupModal showText={t("Martin Bernard information has been deleted successfully!!")} bottomText='' />
            </Modal>
        </>
    );
};

export default WarehouseDis;
