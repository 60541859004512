import { Box, Grid, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'
import { IMAGES } from '../../../../asset/Dis'
import { style } from '../../../../Utils/themes/styles'
import { useTranslation } from 'react-i18next'
import ComButtom from '../../../../components/formComponents/button/ComButtom'
import { baseTheme } from '../../../../Utils/themes/base'
const Styles = {
    root: {
        borderRadius: '12px'
    },
    mapViewbtns: {
        display: 'flex',
        flexDirection: 'row'
    },
    header: {
        padding: '28px 20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'nowrap',
        borderBottom: `1px solid ${style.palette.GrayStroke}`,
        '& .MuiTypography-h6': {
            color: style.palette.TitleColor,
        },
        'img': {
            marginRight: '12px',
        }
    },
    leftContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    rightContainer: {
        height: '24px',
        display: "flex",
        alignItems: "center"
    },
    children: {
        padding: '20px',
        height: '100%'
    },
    fontWeight: {
        fontWeight: "500",
        color: style.palette.black[ 300 ],

    },
    nextPrevComp: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 15px 15px 15px",
        position: "relative",
        top: "-30px",
        [ baseTheme.breakpoints.down('xl') ]: {
            top: '-55px'
        }

    },
    marginLR: {
        margin: "10px"
    },
    singleTxt: {
        color: style.palette.black[ 300 ]
    }
}
interface IDetailsContainer {
    children?: React.ReactNode;
    leftContainer?: string;
    rightContainer?: React.ReactNode;
    sx?: object;
    headerStyle?: object;
    childContainerStyle?: object;
    handleStatus: (status: boolean) => void;
    nextPreviousStatus: boolean;
}
const ComponentDetails = ({
    leftContainer,
    rightContainer,
    sx,
    headerStyle,
    children,
    childContainerStyle,
    handleStatus,
    nextPreviousStatus
}: IDetailsContainer) => {
    const [ page, setPage ] = useState(1);
    const handlePreviousPage = () => {

        if (page > 1) {
            setPage(prev => prev - 1)
        }

    }
    const handleNextPage = () => {
        setPage(prev => prev + 1)
    }
    const handlePageSelect = (pageNum) => {
        setPage(pageNum)
    }
    const { t } = useTranslation();
    return (
        <Paper sx={ { ...Styles.root, ...sx } }>
            <Grid container sx={ { ...Styles.header, ...headerStyle } } >
                <Grid sx={ Styles.leftContainer }>
                    <img src={ IMAGES.backIcon } alt='line' height='24px' onClick={ () => handleStatus(false) } />
                    <Typography variant='h6'> { leftContainer }</Typography>
                </Grid>
                <Grid sx={ Styles.rightContainer }>
                    { rightContainer }
                </Grid>
            </Grid>
            <Box sx={ { ...Styles.children, ...childContainerStyle } }>
                { children }
            </Box>
            { nextPreviousStatus && <Box sx={ Styles.nextPrevComp }>
                <Typography sx={ { ...Styles.singleTxt, ...Styles.fontWeight } } onClick={ handlePreviousPage } disabled={ page === 1 } variant='h6'>{ t("ShowingFiveEntries") } </Typography>
                <Box sx={ {
                    display: 'flex', alignItems: "center"
                } }>
                    <Typography sx={ Styles.marginLR } onClick={ handlePreviousPage } disabled={ page === 1 } variant='subtitle1'>  { t("Prev") } </Typography>
                    <Box sx={ Styles.mapViewbtns }>
                        <ComButtom
                            label={ `1` }
                            variant={ page === 1 ? "contained" : "outlined" }
                            textVariant="subtitle1"
                            inputStyle={ { height: '36px' } }
                            boxStyle={ { marginRight: '4px' } }
                            onClick={ () => handlePageSelect(1) }
                        />
                    </Box>
                    <Box sx={ Styles.mapViewbtns }>
                        <ComButtom
                            label={ `2` }
                            variant={ page === 2 ? "contained" : "outlined" }
                            sx={ { color: "green" } }
                            textVariant="subtitle1"
                            inputStyle={ { height: '36px' } }
                            boxStyle={ { marginRight: '4px' } }
                            onClick={ () => handlePageSelect(2) }
                        />
                    </Box>
                    <Typography sx={ Styles.marginLR } onClick={ handleNextPage } variant='subtitle1'> { t("Next") }</Typography>
                </Box>
            </Box > }

        </Paper >
    )
}

export default ComponentDetails;