import { Box } from '@mui/material'
import { DataGrid, GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid'
import React from 'react'
import { rowsDataTypes } from '../interfaces';

interface IDataGridTable {
    rowsData?:rowsDataTypes;
    columns:readonly GridColDef[];
    handleOnCellClick?:()=>void;
    columnGroupingModel?:GridColumnGroupingModel;
}

const DataGridTable = ({
    rowsData,
    columns,
    handleOnCellClick,
    columnGroupingModel,
}:IDataGridTable) => {

    const other = {
        autoHeight: true,
        showCellVerticalBorder: true,
        showColumnVerticalBorder: true,
      };
  return (
    <Box>
          <DataGrid
                rows={rowsData}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                // checkboxSelection
                disableRowSelectionOnClick
                onCellClick={handleOnCellClick}
                columnGroupingModel={columnGroupingModel}
                // slots={{
                //     toolbar: addRecord || approve || addStock ? EditToolbar : null,

                // }}
                // slotProps={{
                //     toolbar: { setRows },
                // }}
                // autoWidth={true}
                {...other}
            />
    </Box>
  )
}

export default DataGridTable