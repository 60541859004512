import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

const PrivateRoute = (props: any) => {
    const location = useLocation();
    const { children } = props
    const auth = useSelector((state:RootState) => state.userReducer.loginFlag);
    console.log("auth",auth);
    
    return (

        auth ?
            <>{children}</> : (
                <Navigate to={`/`} replace state={{ from: location.pathname }} />
            )
    )
}

export default PrivateRoute