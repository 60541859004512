import { Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import Login from "./components/Login";
import Dashboard from './Pages/Dis/Dashboard';
import Agenda from './Pages/Dis/Agenda';
import ResetPassword from './components/ResetPassword';
import Loading from './Pages/Dis/Loading';
import RoleBasedWarehouse from './Pages/RoleBasedRoute/RoleBasedWarehouse'
import PrivateRoute from './Utils/ProtectedRoute';

const Routing = () => {

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path='/' element={<Layout />}>
        <Route path='/home' element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        } />
        <Route path='/agenda' element={
          <PrivateRoute>
            <Agenda />
          </PrivateRoute>} />
        <Route path='/warehouse' element={
          <PrivateRoute>
            <RoleBasedWarehouse />
          </PrivateRoute>} />
        <Route path='/loading' element={
          <PrivateRoute>
            <Loading />
          </PrivateRoute>
        } />
        <Route path='/unloading' element={
          <PrivateRoute>
            <Loading />
          </PrivateRoute>
        } />
        <Route path='/delivery' element={
          <PrivateRoute>
            <Loading />
          </PrivateRoute>
        } />
        <Route path='/receipt' element={
          <PrivateRoute>
            <Loading />
          </PrivateRoute>} />
      </Route>
    </Routes>
  );
}

export default Routing;
