import { Box, Grid, Paper, SxProps, Typography } from '@mui/material'
import React from 'react'
import { IMAGES } from '../../../asset/Dis'
import { style } from '../../../Utils/themes/styles'
import { baseTheme } from '../../../Utils/themes/base'

const Styles = {
    root: {
        borderRadius: '12px'
    },
    header: {
        padding: '10px 20px 28px 20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${style.palette.GrayStroke}`,
        '& .MuiTypography-h6': {
            color: style.palette.TitleColor,
            [baseTheme.breakpoints.down('lg')]: {
                fontSize: "14px"
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: "14px"
            }
        },
        'img': {
            marginRight: '12px',
            [baseTheme.breakpoints.down('lg')]: {
                height: '20px',
            },
            [baseTheme.breakpoints.up('lg')]: {
                height: '20px',
            }
        }
    },
    leftContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    rightContainer: {
        height: '24px'
    },
    children: {
        padding: '10px 20px',
        height: '100%'
    }
}

interface IDetailsContainer {
    children?:React.ReactNode;
    leftContainer?:string;
    rightContainer?:React.ReactNode;
    sx?:SxProps;
    headerStyle?:SxProps;
    childContainerStyle?:SxProps;
}

const DetailsContainer = ({
    children,
    leftContainer,
    rightContainer,
    sx,
    headerStyle,
    childContainerStyle
}:IDetailsContainer) => {

    const ContainerStyle: SxProps = Styles.header;
    const styledProps = {
        ...(ContainerStyle as SxProps),
        ...headerStyle,
    };
    
    return (
        <Paper sx={ { ...Styles.root, ...sx } }>
            <Grid container sx={styledProps} >
                <Grid sx={ Styles.leftContainer }>
                    <img src={ IMAGES.LineImage } alt='line' height='24px' />
                    <Typography variant='h6'> { leftContainer }</Typography>
                </Grid>
                <Grid sx={ Styles.rightContainer }>
                    { rightContainer }
                </Grid>
            </Grid>
            <Box sx={ { ...Styles.children, ...childContainerStyle } }>
                { children }
            </Box>
        </Paper>
    )
}

export default DetailsContainer;