import {
    Box, Grid, Paper, Typography, Divider, TextField, MenuItem, Select, FormControl, SxProps
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { style } from '../../../Utils/themes/styles';
import { useTranslation } from 'react-i18next';
import ComButtom from '../../../components/formComponents/button/ComButtom';
import PopupModal from './PopupModal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { postWarehouseInventory } from '../../../redux/warehouse/actions/addInventory';
import { IWarehouseInventory } from '../interfaces';
import { postWarehouseLogistician } from '../../../redux/warehouse/actions/addLogistician';
import { updateWarehouseLogistician } from '../../../redux/warehouse/actions/updateLogistician';

const Styles = {
    root: {
        borderRadius: '12px'
    },
    header: {
        padding: '28px 20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${style.palette.GrayStroke}`,
        '& .MuiTypography-h6': {
            color: style.palette.TitleColor,
        },
        'img': {
            marginRight: '12px',
        }
    },
    component: {},
    leftContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    rightContainer: {
        height: '24px',
        textAlign: "right"
    },
    children: {
        padding: '20px',
        height: '100%',
        overflowY: "auto"
    },
    formSubHeading: {
        margin: "7px 0",
        color: style.palette.black[300],
        fontSize: "12px",
        lineHeight: "18px",
    },
    formHeading: {
        margin: "10px 0 0 0",
        textAlign: "left",
        fontSize: "14px",
        color: style.palette.black[300],
    },
    buttonBoxStyle: {
        position: "relative",
        top: "20px",
        marginRight: '4px'
    },
    buttonStyle: {
        width: "122px",
        height: "40px",
        borderRadius: "4px",
        margin: "10px 0",
        border: `1px solid ${style.palette.GrayStroke}`,
    },
    divider: {
        borderRadius: 0,
        backgroundColor: style.palette.GrayStroke,
        marginTop: "15px",
        width: "570px",
        position: "relative",
        left: "-20px"
    },
    inputText: {
        '& .MuiInputBase-input': {
            padding: "8px 12px"
        },
        '& div': {
            height: "36px"
        }
    },
    subHeading2: {
        fontSize: "12px",
        color: style.palette.black[300]
    },
    editBtn: {
        display: "flex",
        justifyContent: "flex-end",
        gap: "10px"
    }
};

interface IFormContainerProps {
    leftContainer?: React.ReactNode;
    rightContainer?: React.ReactNode;
    sx?: SxProps;
    headerStyle?: SxProps;
    childContainerStyle?: SxProps;
    submitText: string;
    selection?: string;
    handleStatus: Dispatch<SetStateAction<boolean>>;
    selectedWarehouse?: string
    selectedLogistician?: string
}

const MiniFormContainer: React.FC<IFormContainerProps> = ({
    leftContainer,
    rightContainer,
    sx,
    headerStyle,
    childContainerStyle,
    submitText,
    selection,
    handleStatus,
    selectedWarehouse,
    selectedLogistician
}) => {
    const [editStatus, setEditStatus] = useState<boolean>(false);
    const [editSubmit, setEditSubmit] = useState<boolean>(false);
    const [submitTextShow, setSubmitTextShow] = useState<string>('');
    const warehouses = ["Warehouse 1", "Warehouse 2", "Warehouse 3", "Warehouse 4"];
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const warehouseLogistician: any = useSelector((state: RootState) => state.warehouseReducer.warehouseLogistician);
    const selectedLg = warehouseLogistician.data.find((item: any) => item.id == selectedLogistician);

    const inventoryValidationSchema = Yup.object().shape({
        material: Yup.string().required(t('Material is required')),
        quantity: Yup.number().required(t('Quantity is required')),
    });

    const logisticianValidationSchema = Yup.object().shape({
        f_name: Yup.string().required(t('First name is required')),
        l_name: Yup.string().required(t('Last name is required')),
        mail: Yup.string().email(t('Invalid email address')).required(t('Email is required')),
    });

    const formik = useFormik({
        initialValues: selection === "inventory" ? {
            material: '',
            quantity: 0,
        } : {
            f_name: selectedLg ? selectedLg.f_name : '',
            l_name: selectedLg ? selectedLg.l_name : '',
            mail: selectedLg ? selectedLg.mail : '',
        },
        validationSchema: selection === "inventory" ? inventoryValidationSchema : logisticianValidationSchema,
        onSubmit: (values: IWarehouseInventory) => {
            console.log("values===>",values);
            if (editStatus) {
                handleUpdate();
            } else {
                handleEdit(values);
            }
        },
    });

    const handleEditCancel = () => {
        console.log("cancel edit");
        setEditStatus(false);
    };

    const handleEdit = async (values: IWarehouseInventory) => {
        console.log("values==>", values);

        if (submitText === "Edit") {
            if (selection === "logistician") {
                const LogisticianData = { ...values, warehouse_id: selectedWarehouse ,logistician_id:selectedLogistician}
                const response = await dispatch(updateWarehouseLogistician(LogisticianData));
                if (response.status === 200) {
                    setEditStatus(true);
                    setSubmitTextShow(t("Logistician information has been updated successfully!!"));
                }
            }
            // setEditStatus(true);
            // console.log("handleUpdate==>");
        } else {
            if (selection === "inventory") {
                const InventoryData = { ...values, warehouse_id: selectedWarehouse }
                const response = await dispatch(postWarehouseInventory(InventoryData));
                if (response.status === 201) {
                    setEditSubmit(true);
                    setSubmitTextShow(t("A new material has been added successfully!!"));
                }
            }
            if (selection === "logistician") {
                const LogisticianData = { ...values, warehouse_id: selectedWarehouse }
                const response = await dispatch(postWarehouseLogistician(LogisticianData));
                if (response.status === 201) {
                    setEditSubmit(true);
                    setSubmitTextShow(t("A new logistician has been added successfully!!"));
                }
            }
        }
    };

    const handleUpdate = () => {
        console.log("handleUpdate==>");
        
        setEditSubmit(true);
        setSubmitTextShow(t("Logistician information has been updated successfully!!"));
    };

    useEffect(() => {
        const submitTimeout = setTimeout(() => {
            if (editSubmit) {
                setEditSubmit(false);
                setEditStatus(false);
                handleStatus(false);
            }
        }, 1500);
        return () => {
            clearTimeout(submitTimeout);
        };
    }, [editSubmit]);

    const ContainerStyle: SxProps = Styles.header;
    const ChildrenStyle: SxProps = Styles.children;
    const styledProps = {
        ...(ContainerStyle as SxProps),
        ...headerStyle,
    };

    const ChildrenStyleProps = {
        ...(ChildrenStyle as SxProps),
        ...childContainerStyle,
    };

    return (
        <>
            {!editSubmit &&
                <Paper sx={{ ...Styles.root, ...sx }}>
                    <Grid container sx={styledProps}>
                        <Grid sx={Styles.leftContainer}>
                            <Typography variant='h6'>{leftContainer}</Typography>
                        </Grid>
                        <Grid sx={Styles.rightContainer}>
                            {rightContainer}
                        </Grid>
                    </Grid>
                    <Box sx={ChildrenStyleProps}>
                        <form onSubmit={formik.handleSubmit}>
                            {selection === "inventory" &&
                                <Box sx={Styles.component}>
                                    <Box
                                        sx={{
                                            '& > :not(style)': { m: 1, width: '100%' }, display: "flex",
                                        }}
                                    >
                                        <Grid item>
                                            <Typography variant="h3" sx={Styles.formSubHeading}>{t("Material")}</Typography>
                                            <TextField
                                                id="material"
                                                name="material"
                                                variant="outlined"
                                                sx={Styles.inputText}
                                                value={formik.values.material}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.material && Boolean(formik.errors.material)}
                                                helperText={formik.touched.material && formik.errors.material}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box
                                        sx={{
                                            '& > :not(style)': { m: 1, width: '100%' }, display: "flex"
                                        }}
                                    >
                                        <Grid item>
                                            <Typography variant="h3" sx={Styles.formSubHeading}>{t("Quantity")}</Typography>
                                            <Grid sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '10px'
                                            }}>
                                                <FormControl variant="outlined" sx={{ ...Styles.inputText, width: '100%' }}>
                                                    <Select
                                                        id="quantity"
                                                        name="quantity"
                                                        value={formik.values.quantity}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                                                    >
                                                        {[1, 2, 3, 4, 5].map((value) => (
                                                            <MenuItem key={value} value={value}>
                                                                {value}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <Typography variant="body1" sx={Styles.subHeading2}>{t("Packages")}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            }
                            {selection === "logistician" &&
                                <Box sx={Styles.component}>
                                    <Box
                                        sx={{
                                            '& > :not(style)': { m: 1, width: '50%' }, display: "flex",
                                        }}
                                    >
                                        <Grid item xs={6}>
                                            <Typography variant="h3" sx={Styles.formSubHeading}>{t("FirstName")}</Typography>
                                            <TextField
                                                id="f_name"
                                                name="f_name"
                                                variant="outlined"
                                                sx={Styles.inputText}
                                                value={formik.values.f_name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.f_name && Boolean(formik.errors.f_name)}
                                                helperText={formik.touched.f_name && formik.errors.f_name}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h3" sx={Styles.formSubHeading}>{t("LastName")}</Typography>
                                            <TextField
                                                id="l_name"
                                                name="l_name"
                                                variant="outlined"
                                                sx={Styles.inputText}
                                                value={formik.values.l_name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.l_name && Boolean(formik.errors.l_name)}
                                                helperText={formik.touched.l_name && formik.errors.l_name}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box
                                        sx={{
                                            '& > :not(style)': { m: 1, width: '100%' }, display: "flex",
                                        }}
                                    >
                                        <Grid item xs={12}>
                                            <Typography variant="h3" sx={Styles.formSubHeading}>{t("Email")}</Typography>
                                            <TextField
                                                id="mail"
                                                name="mail"
                                                variant="outlined"
                                                sx={Styles.inputText}
                                                value={formik.values.mail}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.mail && Boolean(formik.errors.mail)}
                                                helperText={formik.touched.mail && formik.errors.mail}
                                            />
                                        </Grid>
                                    </Box>
                                </Box>
                            }
                            <Divider sx={Styles.divider} />
                            <Grid sx={Styles.rightContainer}>
                                {!editStatus &&
                                    <ComButtom
                                        label={submitText}
                                        variant="contained"
                                        textVariant="subtitle1"
                                        inputStyle={Styles.buttonStyle}
                                        type="submit"
                                    />
                                }

                                {editStatus &&
                                    <Grid sx={Styles.editBtn}>
                                        <ComButtom
                                            label={"Cancel"}
                                            variant={"outlined"}
                                            textVariant="subtitle1"
                                            inputStyle={Styles.buttonStyle}
                                            onClick={handleEditCancel}
                                        />
                                        <ComButtom
                                            label={"Update"}
                                            variant={"contained"}
                                            textVariant="subtitle1"
                                            inputStyle={Styles.buttonStyle}
                                            type="submit"
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </form>
                    </Box>
                </Paper>
            }
            {editSubmit && (
                <PopupModal
                    showText={submitTextShow}
                    bottomText=''
                />
            )}
        </>
    );
}

export default MiniFormContainer;
