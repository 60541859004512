import { MouseEvent, MouseEventHandler, useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'
import { Box, Divider, List, ListItem, ListItemText, MenuItem, Popover, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { style } from '../../../../Utils/themes/styles';
import { IMAGES } from '../../../../asset/Dis';
import { DateSelectArg, EventApi, EventClickArg, EventContentArg } from '@fullcalendar/core';
import SelectAppointment from './SelectAppointment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { baseTheme } from '../../../../Utils/themes/base';

const Styles = {
    root: {
        height: '100%',
        '& .fc-direction-ltr': {
            height: '100%',
        },
        '& .MuiPopover-paper': {
            width: '229px'
        },
        '& .fc .fc-button-primary': {
            backgroundColor: style.palette.BgColor,
            color: style.palette.black[300],
            border: 'none'
        },
        '& .fc .fc-button-primary:focus': {
            boxShadow: 'none'
        },
        '& .fc .fc-button-primary:not(:disabled).fc-button-active:focus': {
            boxShadow: 'none'
        },
        '& .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events': {
            minHeight: '6em',
        },
        'a': {
            ...style.typography.h6,
            fontSize: "12px",
            fontWeight: 500,
            color: style.palette.black[200],
            textDecoration: 'none'
        },
        '& .fc .fc-daygrid-day-top': {
            justifyContent: 'center'
        },
        '& .fc-h-event': {
            backgroundColor: style.palette.eventBgColor,
            border: 'none',
        },
        '& .fc-v-event': {
            backgroundColor: style.palette.eventBgColor,
            border: 'none',
        },
        '& .fc-h-event .fc-event-main': {
            color: style.palette.black[500]
        },
        '& .fc .fc-timegrid-col.fc-day-today': {
            backgroundColor: 'transparent'
        },
        '& .fc .fc-daygrid-day.fc-day-today': {
            backgroundColor: 'transparent'
        },
        // '& .fc .fc-scrollgrid, .css-wr40zs .fc .fc-scrollgrid table':{
        //     width:'-webkit-fill-available !important'
        // },
        // '& .fc .fc-scrollgrid table':{
        //     width:'-webkit-fill-available !important',
        // },
        '& .fc-dayGridMonth-view  .fc-theme-standard td, .fc-theme-standard th': {
            width: '140px !important',
            [baseTheme.breakpoints.down('xl')]: {
                width: '140px !important',
            },
            // [baseTheme.breakpoints.up('xxl')]: {
            //     width: '216px !important',
            // },
        },
        '& .fc-dayGridDay-view  .fc-theme-standard td, .fc-theme-standard th': {
            width: '100%',
            [baseTheme.breakpoints.down('xl')]: {
                width: '100%',
            },
            // [baseTheme.breakpoints.up('xxl')]: {
            //     width: '216px !important',
            // },
        },
        '& .fc .fc-scroller-liquid-absolute': {
            overflow: 'visible !important'
        },
        '& .fc .fc-scroller-harness': {
            overflow: 'inherit'
        },
        '& .fc .fc-view-harness-active > .fc-view': {
            overflow: 'scroll'
        },
        '& .fc .fc-scroller': {
            overflow: 'inherit scroll',
        },
        '& .fc .fc-scrollgrid-liquid': {
            height: 'fit-content'
        },
        '& .fc .fc-scrollgrid, .fc .fc-scrollgrid table': {
            width: 'auto'
        },
        '& .fc .fc-toolbar-title': {
            fontSize: "20px",
        },
        '& .fc .fc-button': {
            fontSize: "13px",
            fontWeight: 500,
        },
      
    },
    categoryList: {
        width: '229px'
    },
    mapViewList: {
        width: 'auto'
    },
    emptyEvent: {
        display: 'flex',
        backgroundColor: style.palette.eventBgColor,
        border: 'none',
        '& .MuiTypography-subTitle1': {
            fontSize: '12px',
        }
    },
    eventStyle: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: style.palette.eventBgColor,
        border: 'none',
        padding: '8px',
        '& .MuiTypography-h6': {
            fontSize: '12px'
        },
        '& .MuiTypography-subTitle1': {
            fontSize: '11px',
            marginLeft: '8px'
        }
    },
    headerTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '.trucImg': {
            margin: '0px 8px'
        }
    },
    headerTitleDayView: {
        display: 'flex',
        flexDirection: 'row',

    },
    dayViewAppointment: (appointmentType: string) => ({
        '& .MuiTypography-caption': {
            color: appointmentType === "Delivery" ? style.palette.green[500] : appointmentType === "Loading" ? style.palette.Blue : appointmentType === "Receipt" ? style.palette.Purple : style.palette.Orange
        },
        paddingLeft: '8px',
        borderLeft: `1px dotted ${style.palette.black[50]}`
    }),
    TypeAndTime: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& .MuiTypography-caption': {
            fontWeight: 400,
            color: style.palette.black[200],
            marginLeft: '6px'
        }
    },
    headerTitleWeekView: {
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .MuiTypography-subTitle1': {
            fontSize: '12px',
            color: style.palette.black[500]
        }
    },
    dayViewTitle: {
        marginRight: '29px',
        '& .MuiTypography-caption': {
            marginLeft: '8px',
            color: style.palette.black[500]
        }
    },
    weekViewTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

    },
    weekViewAppointmentCount: {
        backgroundColor: style.palette.RecieptEventBgColor,
        padding: '5px 13px',
        borderRadius: '2px',
        '& .MuiTypography-h6': {
            fontWeight: 600,
            color: style.palette.Purple
        }

    },
    commonEventStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '7.5px 8px',
        marginBottom: '8px',
        borderRadius: '2px'
    },
    AppointmentStyleType: (appointmentType?: string) => ({
        color: appointmentType === "Delivery" ? style.palette.Primary : appointmentType === "Loading" ? style.palette.Blue : appointmentType === "Receipt" ? style.palette.Purple : style.palette.Orange,
        backgroundColor: appointmentType === "Delivery" ? style.palette.EditIconBGColor : appointmentType === "Loading" ? style.palette.ViewIconBGColor : appointmentType === "Receipt" ? style.palette.RecieptEventBgColor : style.palette.unloadingEventBgColor,
    }),
    deliveryAlign: {
        backgroundColor: style.palette.EditIconBGColor,
        color: style.palette.Primary
    },
    unloadingAlign: {
        backgroundColor: style.palette.unloadingEventBgColor,
        color: style.palette.Orange
    },
    loadingAlign: {
        backgroundColor: style.palette.ViewIconBGColor,
        color: style.palette.Blue
    },
    receiptAlign: {
        backgroundColor: style.palette.RecieptEventBgColor,
        color: style.palette.Purple
    },
    dayEventPopup: {
        width: '221px'
    },
    ModalBody: {
        padding: '16px 16px 0px',
        '& .MuiTypography-body1': {
            fontSize: '12px',
            fontWeight: 400,
            color: style.palette.black[300]
        },
        '& .MuiTypography-h5': {
            fontSize: '12px',
            display: 'flex',
            justifyContent: 'center',
            color: style.palette.black[300]
        }
    },
    ModalFooter: {
        borderTop: `1px solid ${style.palette.black[50]}`,
        padding: '0px 16px 0px',
        marginTop: '16px',
    },
    dayModalFooter: {
        borderTop: `1px solid ${style.palette.black[50]}`,
        padding: '0px 16px 0px',
        marginTop: '16px',
        display: 'flex',
        justifyContent: 'flex-end',
        '& .MuiTypography-body2': {
            color: style.palette.Primary,
            margin: '8px 0px',
        },
    },
    weekModalFooter: {
        '& .MuiStack-root': {
            background: 'transparent',
            padding: '4px',
            marginBottom: '0px',
            width: '155px'
        },
        padding: '0px 12px 12px'

    },
    continueButtonStyle: {
        marginTop: '9.5px',
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '20px',

    },
    selectTypeStyle: {
        width: '551px',
        background: 'transparent',
        backgroundColor: 'transparent',
    },
    continueButtonInputStyle: {
        width: '122px',
        height: '40px'
    },
    closeButtonInputStyle: {
        border: 'none'
    },
    weekModalheader: {
        display: 'felx',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        '& .MuiTypography-body2': {
            fontSize: '10px',
            fontWeight: 500,
            color: style.palette.black[200]
        },
        '& .MuiStack-root': {
            marginLeft: '62px'
        }
    },
    dayEventHeaderRoot: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    dayEventHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& .MuiTypography-body2': {
            color: style.palette.Primary
        },
        '& .MuiStack-root': {
            height: '5px',
            width: '5px',
            borderRadius: '50%',
            backgroundColor: style.palette.Primary,
            marginRight: '4px'
        }
    },
    dayEventContent: {

    },
    fileTitle: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '12px',
        '& .MuiTypography-caption': {
            color: style.palette.black[500],
            marginRight: '12px'
        },
    },
    fileDetail: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '12px',
        marginBottom: '2px',
        '& .MuiTypography-caption': {
            color: style.palette.black[200],
            marginRight: '12px',
            marginLeft: '6px'
        },
    },
    viewMoreText: {
        marginTop: '20px',
        '& .MuiTypography-body2': {
            color: style.palette.Primary
        },
    }
}

type CategoryOptionType = {
    title: string;
    value: string;
}[];

type CategoryOptionItem = {
    title: string;
    value: string;
}

type anchorE1Type = HTMLElement | null;
interface IAgendaCalender {
    handleEventData: (arg: EventClickArg) => void;
    CatgeoryOptions: CategoryOptionType;
    handleUnloadingCreateModal: () => void;
    handleChangeAppointmentType: (data: SelectChangeEvent) => void;
    appointmentType?: string;
}

interface PopoverPosition {
    top: number;
    left: number;
}

interface IAppointment {
    id?: number,
    Appointment_Type?: string,
    time?: string,
    date?: string,
    location?: string
}

interface MyState {
    weekendsVisible: boolean;
    currentEvents: EventApi[];
    isScheduleAppModalOpen: boolean;
    viewText: string;
    selectedCategory: string;
    openAddEventModal: boolean;
    openWeekEventModal?: boolean;
    monthEventDetailPopup?: boolean;
    viewMore?: boolean;

}

const AgendaCalender = ({ handleEventData, CatgeoryOptions, handleUnloadingCreateModal, handleChangeAppointmentType, appointmentType }: IAgendaCalender) => {
    const { t } = useTranslation();
    const [state, setState] = useState<MyState>({
        weekendsVisible: true,
        currentEvents: [],
        isScheduleAppModalOpen: true,
        viewText: 'Month',
        selectedCategory: '',
        openAddEventModal: false,
        openWeekEventModal: false,
        monthEventDetailPopup: false,
        viewMore: false,
    })
    const calendarRef = useRef<FullCalendar>(null);
    const [anchorEl, setAnchorEl] = useState<anchorE1Type | null>(null);
    const [anchorE2, setAnchorE2] = useState<anchorE1Type | null>(null);
    const [anchorPosition, setAnchorPosition] = useState<PopoverPosition | undefined>(undefined);
    const [anchorPositionDay, setAnchorPositionDay] = useState<PopoverPosition | undefined>(undefined);
    const [anchorPositionModifyPopup, setAnchorPositionModifyPopup] = useState<PopoverPosition | undefined>(undefined);
    const [monthEventDetailPopup, setMonthEventDetailPopup] = useState<PopoverPosition | undefined>(undefined)
    const role = useSelector((state: RootState) => state.userReducer.user.role);
    console.log("openAddEventModal", state.openAddEventModal);

    const handleClick = (ev: globalThis.MouseEvent, element: HTMLElement) => {
        setAnchorEl(element);

    };

    const handleChangeView = (ev: globalThis.MouseEvent, element: HTMLElement) => {
        setAnchorE2(element);

    }

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorE2(null);
        setAnchorPosition(undefined);
        setAnchorPositionDay(undefined);
        setAnchorPositionModifyPopup(undefined);
        setMonthEventDetailPopup(undefined);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const openCalenderView = Boolean(anchorE2)
    const openWeekEventDetails = anchorPosition
    const openDayEventDetails = anchorPositionDay
    const openDayEditEvent = anchorPositionModifyPopup
    const openMonthEventDetail = monthEventDetailPopup
    const handleDateSelect = (selectInfo: DateSelectArg) => {
        if (role === "DI-BO") {

            if (selectInfo.view.type === "timeGridWeek") {
                setAnchorPosition({ top: selectInfo?.jsEvent?.clientY ?? 0, left: selectInfo?.jsEvent?.clientX ?? 0, });

            } else if (selectInfo.view.type === "dayGridMonth") {
                setState((prev) => {
                    return {
                        ...prev,
                        openAddEventModal: true,
                    }
                })
            } else {
                setAnchorPositionDay({
                    top: selectInfo?.jsEvent?.clientY ?? 0,
                    left: selectInfo?.jsEvent?.clientX ?? 0,
                })
            }
        }

    }

    const handleUnloading = () => {
        setState((prev) => {
            return {
                ...prev,
                openAddEventModal: false,
            }
        });
        handleUnloadingCreateModal();

    }

    const handleModifyEvent = (event: MouseEvent) => {
        console.log("event", event);

        setAnchorPositionModifyPopup({
            top: event.clientY,
            left: event.clientX,
        })
    }

    const handleCloseEventModal = () => {
        setState((prev) => {
            return {
                ...prev,
                openAddEventModal: false,
                openWeekEventModal: false,
            }
        })
    }

    const handleViewMore = () => {
        setState((prev) => {
            return {
                ...prev,
                viewMore: !state.viewMore
            }
        })
    }
    function renderEventContent(eventInfo: EventContentArg) {
        console.log("eventInfo", eventInfo);
        const recordsToShow = eventInfo?.event?.extendedProps?.Appointments?.length > 3 ? eventInfo?.event?.extendedProps?.Appointments.slice(0, 2) : eventInfo?.event?.extendedProps?.Appointments

        return (
            <>
                {eventInfo ?
                    <Box sx={Styles.eventStyle}>
                        {eventInfo?.view?.type === "dayGridMonth" &&
                            <Box sx={{ width: 'auto' }}>
                                <Stack sx={Styles.headerTitle}>
                                    <img src={`${IMAGES.eventTitleLineImg}`} alt='Icon' height="33px" />
                                    <img src={`${IMAGES.TruckIcon}`} alt='Icon' height="24px" className='trucImg' />
                                    <Typography variant='subtitle1'>{eventInfo?.event?.title}</Typography>
                                </Stack>
                                {recordsToShow?.map((appointment: IAppointment) => {
                                    return (
                                        <>
                                            <Stack sx={{ ...Styles.AppointmentStyleType(appointment?.Appointment_Type), ...Styles.commonEventStyle }}>
                                                <Typography variant='caption'>{appointment?.Appointment_Type}</Typography>
                                                <Typography variant='h6'>04</Typography>
                                            </Stack>

                                        </>
                                    )
                                })
                                }
                                {eventInfo?.event?.extendedProps?.Appointments?.length > 3 &&
                                    <Stack onClick={handleViewMore} sx={Styles.viewMoreText}>
                                        <Typography variant='body2'>2 more</Typography>
                                    </Stack>
                                }
                            </Box>
                        }
                        {eventInfo.view.type === "timeGridWeek" && <>
                            <Stack sx={{ ...Styles.headerTitle, ...Styles.headerTitleWeekView }}>
                                <Box sx={Styles.weekViewTitle}>
                                    <img src={`${IMAGES.eventTitleLineImg}`} alt='Icon' height="33px" />
                                    <img src={`${IMAGES.TruckIcon}`} alt='Icon' height="28px" className='trucImg' />
                                    <Typography variant='subtitle1'>{eventInfo.event.title}</Typography>
                                </Box>
                                <Box sx={Styles.weekViewAppointmentCount}>
                                    <Typography variant='h6'>04</Typography>
                                </Box>
                            </Stack>
                        </>}
                        {eventInfo.view.type === "timeGridDay" && <>
                            <Stack sx={{ ...Styles.headerTitle, ...Styles.headerTitleDayView }}>
                                <Box sx={{ ...Styles.weekViewTitle, ...Styles.dayViewTitle }}>
                                    <img src={`${IMAGES.eventTitleLineImg}`} alt='Icon' height="24px" />
                                    <img src={`${IMAGES.TruckIcon}`} alt='Icon' height="16px" className='trucImg' />
                                    <Typography variant='caption'>{eventInfo.event.title}</Typography>
                                </Box>
                                <Box sx={Styles.dayViewAppointment(eventInfo.event.extendedProps.Appointment_Type)}>
                                    <Typography variant='caption'>{eventInfo.event.extendedProps.Appointment_Type}</Typography>
                                    <Stack sx={Styles.TypeAndTime}>
                                        <img src={`${IMAGES.watchIcon}`} alt='time' height='8px' width='8px' />
                                        <Typography variant='caption'>{eventInfo.timeText}</Typography>
                                        {/* <Typography>{moment(eventInfo.event.startStr).format('LT')} - {moment(eventInfo.event.endStr).format('LT')}</Typography> */}
                                    </Stack>
                                </Box>
                            </Stack>
                        </>}
                    </Box>
                    :
                    <Box sx={Styles.emptyEvent}>
                        <Typography variant='subtitle1'>No Appointments</Typography>
                    </Box>
                }
            </>
        )
    }

    const handleEventClick = (arg: EventClickArg) => {
        console.log("Click Info", arg);

        setMonthEventDetailPopup({
            top: arg.jsEvent.clientY,
            left: arg.jsEvent.clientX,
        })

        handleEventData(arg)

    }

    // const handleAddEvent = () => {
    //     console.log("handleAddEvent click");
    //     handleUnloadingCreateModal();
    // }

    const handleEvents = (events: EventApi[]) => {
        setState((prev) => {
            return {
                ...prev,
                currentEvents: events
            }
        })
    }
    let eventGuid = 0
    // let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

    const INITIAL_EVENTS = [
        {
            id: createEventId(),
            title: `Team#1`,
            extendedProps: {
                Appointment_Type: "Delivery",
                File_Number: "2022-68479",
                Warehouse: "Warehouse 1",
                Appointments: [
                    {
                        id: 1,
                        Appointment_Type: "Delivery",
                        time: "01:00 AM - 02:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 2,
                        Appointment_Type: "Loading",
                        time: "03:00 AM - 04:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 3,
                        Appointment_Type: "Unloading",
                        time: "05:00 AM - 06:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    }
                ]
            },
            start: '2024-07-11', // a property!
            end: '2024-07-12'
        },
        { title: 'Event 1', date: '2024-06-15' },
        {
            id: createEventId(),
            title: "AA-123-AA",
            extendedProps: {
                Appointment_Type: "Loading",
                File_Number: "2022-89741",
                Warehouse: "Warehouse 2",
                Appointments: [
                    {
                        id: 1,
                        Appointment_Type: "Delivery",
                        time: "01:00 AM - 02:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 2,
                        Appointment_Type: "Loading",
                        time: "03:00 AM - 04:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 3,
                        Appointment_Type: "Unloading",
                        time: "05:00 AM - 06:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 4,
                        Appointment_Type: "Receipt",
                        time: "07:00 AM - 08:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    }
                ]
            },
            start: '2024-07-17',
            end: '2024-07-17'
        },
        {
            id: createEventId(),
            title: "BB-123-BB",
            extendedProps: {
                Appointment_Type: "Loading",
                File_Number: "2022-89741",
                Warehouse: "Warehouse 2",
                Appointments: [
                    {
                        id: 1,
                        Appointment_Type: "Delivery",
                        time: "01:00 AM - 02:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 2,
                        Appointment_Type: "Loading",
                        time: "03:00 AM - 04:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 3,
                        Appointment_Type: "Unloading",
                        time: "05:00 AM - 06:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 4,
                        Appointment_Type: "Receipt",
                        time: "07:00 AM - 08:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    }
                ]
            },
            start: '2024-07-10',
            end: "2024-07-10"
        },
        {
            id: createEventId(),
            title: "BB-123-BB",
            extendedProps: {
                Appointment_Type: "Loading",
                File_Number: "2022-89741",
                Warehouse: "Warehouse 2",
                Appointments: [
                    {
                        id: 1,
                        Appointment_Type: "Delivery",
                        time: "01:00 AM - 02:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 2,
                        Appointment_Type: "Loading",
                        time: "03:00 AM - 04:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 3,
                        Appointment_Type: "Unloading",
                        time: "05:00 AM - 06:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 4,
                        Appointment_Type: "Receipt",
                        time: "07:00 AM - 08:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    }
                ]
            },
            start: '2024-07-09',
            end: "2024-07-09"
        },
        {
            id: createEventId(),
            title: "BB-123-BB",
            extendedProps: {
                Appointment_Type: "Loading",
                File_Number: "2022-89741",
                Warehouse: "Warehouse 2",
                Appointments: [
                    {
                        id: 1,
                        Appointment_Type: "Delivery",
                        time: "01:00 AM - 02:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 2,
                        Appointment_Type: "Loading",
                        time: "03:00 AM - 04:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 3,
                        Appointment_Type: "Unloading",
                        time: "05:00 AM - 06:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 4,
                        Appointment_Type: "Receipt",
                        time: "07:00 AM - 08:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    }
                ]
            },
            start: '2024-07-12',
            end: "2024-07-12"
        },
        {
            id: createEventId(),
            title: "BB-123-BB",
            extendedProps: {
                Appointment_Type: "Loading",
                File_Number: "2022-89741",
                Warehouse: "Warehouse 2",
                Appointments: [
                    {
                        id: 1,
                        Appointment_Type: "Delivery",
                        time: "01:00 AM - 02:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 2,
                        Appointment_Type: "Loading",
                        time: "03:00 AM - 04:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 3,
                        Appointment_Type: "Unloading",
                        time: "05:00 AM - 06:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 4,
                        Appointment_Type: "Receipt",
                        time: "07:00 AM - 08:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    }
                ]
            },
            start: '2024-07-13',
            end: "2024-07-13"
        },
        {
            id: createEventId(),
            title: "BB-123-BB",
            extendedProps: {
                Appointment_Type: "Loading",
                File_Number: "2022-89741",
                Warehouse: "Warehouse 2",
                Appointments: [
                    {
                        id: 1,
                        Appointment_Type: "Delivery",
                        time: "01:00 AM - 02:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 2,
                        Appointment_Type: "Loading",
                        time: "03:00 AM - 04:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 3,
                        Appointment_Type: "Unloading",
                        time: "05:00 AM - 06:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 4,
                        Appointment_Type: "Receipt",
                        time: "07:00 AM - 08:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    }
                ]
            },
            start: '2024-07-14',
            end: "2024-07-14"
        },
        {
            id: createEventId(),
            title: "CC-123-CC",
            extendedProps: {
                Appointment_Type: "Unloading",
                File_Number: "2022-96587",
                Warehouse: "Warehouse 3",
                Appointments: [
                    {
                        id: 1,
                        Appointment_Type: "Delivery",
                        time: "01:00 AM - 02:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 2,
                        Appointment_Type: "Loading",
                        time: "03:00 AM - 04:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    },
                    {
                        id: 3,
                        Appointment_Type: "Unloading",
                        time: "05:00 AM - 06:00 AM",
                        date: "Sunday, 26 December 2023",
                        location: "Warehouse Name"
                    }
                ]
            },
            start: "2024-07-04",
            end: "2024-07-04"
        }
    ]

    function createEventId() {
        return String(eventGuid++)
    }

    const handleCategorySelect: MouseEventHandler<HTMLLIElement> = (event) => {
        const target = event.target;
        if (target instanceof HTMLElement) {
            setState((prev) => {
                return {
                    ...prev,
                    selectedCategory: target.innerText
                }
            })
        }
        setAnchorEl(null);
    }

    // const handleChangeAppointmentType = (data: SelectChangeEvent) => {
    //     console.log("data", data);
    //     setState((prev) => {
    //         return {
    //             ...prev,
    //             appointmentType: data.target.value as string
    //         }
    //     })

    // }

    const handleSelectView: MouseEventHandler<HTMLLIElement> = (event) => {
        const target = event.target;
        if (target instanceof HTMLElement) {
            if (target?.classList.contains('fc-dayview-button')) {
                calendarRef?.current?.getApi().changeView('timeGridDay');
                setState((prev) => {
                    return {
                        ...prev,
                        viewText: "Day"
                    }
                })
                setAnchorE2(null);
            } else if (target?.classList.contains('fc-weekview-button')) {
                calendarRef?.current?.getApi().changeView('timeGridWeek');
                setState((prev) => {
                    return {
                        ...prev,
                        viewText: "Week"
                    }
                })
                setAnchorE2(null);
            } else if (target?.classList.contains('fc-monthview-button')) {
                calendarRef?.current?.getApi().changeView('dayGridMonth');
                setState((prev) => {
                    return {
                        ...prev,
                        viewText: "Month"
                    }
                })
                setAnchorE2(null);
            }
        }
    }

    useEffect(() => {

        // Update custom button HTML
        const addCustomBtn = document.querySelector('.fc-addcustombtn-button');
        if (addCustomBtn) {
            addCustomBtn.innerHTML = `${state.selectedCategory !== '' ? t(`${state.selectedCategory}`) : t("AppointmentCategories")} <img class="dropdown-icon" src="${anchorEl == null ? IMAGES.dropdownArrow : IMAGES.downDropdownArrow}" alt="arrow" />`;
        }
        const custombtnView = document.querySelector('.fc-custombtnView-button');
        if (custombtnView) {
            custombtnView.innerHTML = ` <img class="calendar-icon" src="${IMAGES.CalendarIcon}" alt="calendar" /> <div class="vertical-divider"></div> ${t(`${state.viewText}`)} <img class="dropdown-icon" src="${anchorE2 == null ? IMAGES.dropdownArrow : IMAGES.downDropdownArrow}" alt="arrow" />`;
        }
    }, [anchorEl, anchorE2, state.selectedCategory, state.viewText]);

    return (
        <div className='calender-root'>
            <Box sx={Styles.root}>
                <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}

                    headerToolbar={{
                        left: 'title',
                        // center: 'title',
                        right: 'addcustombtn custombtnView',

                    }}
                    customButtons={{
                        addcustombtn: {
                            click: handleClick,

                        },
                        custombtnView: {

                            click: handleChangeView,
                        }
                    }}
                    initialEvents={INITIAL_EVENTS}
                    // schedulerLicenseKey="GPL-My-Project-Is-Open-Source" 
                    editable={true}
                    eventStartEditable={true}
                    selectable={true}
                    // defaultDate={new Date()}
                    initialView={"dayGridMonth"}
                    // scrollTime='09:00'
                    // slotDuration='01:00'
                    nowIndicator={true}
                    // slotLabelFormat={[
                    //     //{ year: 'numeric', month: 'long', day: 'numeric' }, // top level of text
                    //     { hour: 'numeric', minute: '2-digit', } // lower level of text
                    // ]}
                    // events={events}

                    select={handleDateSelect}
                    eventContent={renderEventContent} // custom render function
                    eventClick={handleEventClick}
                    eventsSet={handleEvents} // 
                />
                {open &&
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <Box sx={Styles.categoryList}>
                            {CatgeoryOptions?.map((item: CategoryOptionItem) => (
                                <MenuItem
                                    key={item.title}
                                    value={t(`${item.value}`)}
                                    onClick={handleCategorySelect}
                                >
                                    <Typography variant='subtitle1'>{t(`${item.title}`)}</Typography>
                                </MenuItem>
                            ))}
                        </Box>
                    </Popover>
                }
                {openCalenderView &&
                    <Popover
                        id={id}
                        open={openCalenderView}
                        anchorEl={anchorE2}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <Box sx={Styles.mapViewList}>
                            <MenuItem
                                key="Month"
                                value="dayGridMonth"
                                className='fc-monthview-button'
                                onClick={handleSelectView}
                            >
                                <Typography variant='subtitle1' className='fc-monthview-button'>{"Month"}</Typography>
                            </MenuItem>
                            <MenuItem
                                key="Week"
                                value="timeGridWeek"
                                className='fc-weekview-button'
                                onClick={handleSelectView}
                            >
                                <Typography variant='subtitle1' className='fc-weekview-button'>{"Week"}</Typography>
                            </MenuItem>
                            <MenuItem
                                key="Day"
                                value="timeGridDay"
                                className='fc-dayview-button'
                                onClick={handleSelectView}
                            >
                                <Typography variant='subtitle1' className='fc-dayview-button'>{"Day"}</Typography>
                            </MenuItem>
                        </Box>
                    </Popover>
                }
                {
                    state.openAddEventModal &&
                    <SelectAppointment
                        open={state.openAddEventModal}
                        handleClose={handleCloseEventModal}
                        category={appointmentType}
                        options={CatgeoryOptions}
                        handleChangeAppointmentType={handleChangeAppointmentType}
                        handleAddEvent={handleUnloading}
                    />
                }

                {openWeekEventDetails &&

                    <Popover
                        id={id}
                        open={openWeekEventDetails ? true : false}
                        anchorEl={anchorE2}
                        onClose={handleClose}
                        anchorReference="anchorPosition"
                        anchorPosition={anchorPosition || { top: 0, left: 0 }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Box sx={Styles.ModalBody}>
                            <Stack sx={Styles.weekModalheader}>
                                <Typography variant='body2'>SUN</Typography>
                                <Stack onClick={handleClose}>
                                    <img src={IMAGES.crossIcon} alt='close' height={'16px'} />
                                </Stack>
                            </Stack>
                            <Typography variant='h5'>26</Typography>

                            <Box sx={Styles.weekModalFooter}>
                                <Stack sx={{ ...Styles.deliveryAlign, ...Styles.commonEventStyle }}>
                                    <Typography variant='caption'>Delivery</Typography>
                                    <Typography variant='h6'>04</Typography>
                                </Stack>
                                <Stack sx={{ ...Styles.unloadingAlign, ...Styles.commonEventStyle }}>
                                    <Typography variant='caption'>Unloading</Typography>
                                    <Typography variant='h6'>05</Typography>
                                </Stack>
                            </Box>
                        </Box>
                    </Popover>
                }

                {openDayEventDetails &&
                    <Popover
                        id={id}
                        open={openDayEventDetails ? true : false}
                        anchorEl={anchorE2}
                        onClose={handleClose}
                        anchorReference="anchorPosition"
                        anchorPosition={anchorPositionDay || { top: 0, left: 0 }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Box sx={{ ...Styles.ModalBody, ...Styles.dayEventPopup }}>
                            <Box sx={Styles.dayEventHeaderRoot}>
                                <Box sx={Styles.dayEventHeader}>
                                    <Stack></Stack>
                                    <Typography variant='body2'>Delivery</Typography>
                                </Box>
                                <Box onClick={handleModifyEvent}>
                                    <img src={IMAGES.MoreIconHorizontal} alt='moreIcon' height={'2.33px'} />
                                </Box>
                            </Box>
                            <Box sx={Styles.dayEventContent}>
                                <Stack sx={Styles.fileTitle}>
                                    <Typography variant='caption'>File No : XX123</Typography>
                                    <Typography variant='caption'>Worker#1</Typography>
                                </Stack>
                                <Stack sx={Styles.fileDetail}>
                                    <img src={IMAGES.grayColoredCalenderIcon} alt='calendericon' height='8px' />
                                    <Typography variant='caption'>Sunday, 26 December 2023 </Typography>
                                </Stack>
                                <Stack sx={Styles.fileDetail}>
                                    <img src={IMAGES.watchIcon} alt='calendericon' height='8px' />
                                    <Typography variant='caption'>01:00 AM - 02:00 AM </Typography>
                                </Stack>
                                <Stack sx={Styles.fileDetail}>
                                    <img src={IMAGES.locationIcon} alt='calendericon' height='8px' />
                                    <Typography variant='caption'>Warehouse Name</Typography>
                                </Stack>
                            </Box>
                            <Box sx={Styles.dayModalFooter}>
                                <Stack>
                                    <Typography variant='body2'>Close</Typography>
                                </Stack>
                            </Box>
                        </Box>
                    </Popover>
                }
                {anchorPositionModifyPopup &&
                    <Popover
                        id={id}
                        open={anchorPositionModifyPopup ? true : false}
                        anchorEl={anchorE2}
                        onClose={handleClose}
                        anchorReference="anchorPosition"
                        anchorPosition={openDayEditEvent}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <List aria-label="mailbox folders">
                            <ListItem>
                                <ListItemText primary="Modify an appointment" />
                            </ListItem>
                            <Divider component="li" />
                            <ListItem>
                                <ListItemText primary="Assign a team" />
                            </ListItem>
                        </List>
                    </Popover>
                }

                {openMonthEventDetail &&
                    <Popover
                        id={id}
                        open={openMonthEventDetail ? true : false}
                        anchorEl={anchorE2}
                        onClose={handleClose}
                        anchorReference="anchorPosition"
                        anchorPosition={monthEventDetailPopup || { top: 0, left: 0 }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Box sx={Styles.ModalBody}>
                            <Stack sx={Styles.weekModalheader}>
                                <Typography variant='body2'>SUN</Typography>
                                <Stack onClick={handleClose}>
                                    <img src={IMAGES.crossIcon} alt='close' height={'16px'} />
                                </Stack>
                            </Stack>
                            <Typography variant='h5'>26</Typography>

                            <Box sx={Styles.weekModalFooter}>
                                <Stack sx={{ ...Styles.deliveryAlign, ...Styles.commonEventStyle }}>
                                    <Typography variant='caption'>Delivery</Typography>
                                    <Typography variant='caption'>04</Typography>
                                </Stack>
                                <Stack sx={{ ...Styles.unloadingAlign, ...Styles.commonEventStyle }}>
                                    <Typography variant='caption'>Unloading</Typography>
                                    <Typography variant='caption'>05</Typography>
                                </Stack>
                                <Stack sx={{ ...Styles.loadingAlign, ...Styles.commonEventStyle }}>
                                    <Typography variant='caption'>Loading</Typography>
                                    <Typography variant='caption'>04</Typography>
                                </Stack>
                                <Stack sx={{ ...Styles.receiptAlign, ...Styles.commonEventStyle }}>
                                    <Typography variant='caption'>Receipt</Typography>
                                    <Typography variant='caption'>05</Typography>
                                </Stack>
                            </Box>
                        </Box>
                    </Popover>
                }

            </Box>

        </div>
    )
}

export default AgendaCalender
