import { Box, Typography } from '@mui/material';
import { style } from '../../../../Utils/themes/styles';
import CanvasJSReact from '@canvasjs/react-charts';
import { CSSProperties, useEffect, useRef } from 'react';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Styles = {
    root: {
        border: `1px solid ${style.palette.black[50]}`,
        borderRadius: '8px',
        height: '200px',
        width: '100%',
        position: 'relative',
        'canvas': {
            width: '100% !important',
            height: '198px !important'
        },
        '& .canvasjs-chart-credit': {
            display: 'none !important'
        },
        '& .canvasjs-chart-canvas': {
            width: '100% !important',
            height: '100%'
        }
    },
    graphHeight: {
        height: "120px !important"
    },
    graphTitle: {
        position: 'absolute',
        top: '10px',
        left: '10px',
        zIndex: 100,
        color: 'black',
        backgroundColor: 'white', // Optional: add background to make text more visible
        padding: '8px 0px 0px 8px',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        '& .MuiTypography-subtitle1': {
            color: style.palette.black[200],
        }
    } as CSSProperties,
    title:{
        color: style.palette.black[200],
    },
    subtitle: {
        ...style.typography.subtitle1,
        fontWeight: 600,
        color: style.palette.TitleColor,
    }
}

interface IAreaGraph {
    // series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
    title?: string;
    subTitle?: string;
    color?: string;
    gradiantColor:string[];
}

const xAxisData = [new Date(2023, 0, 1), new Date(2023, 0, 2), new Date(2023, 0, 3), new Date(2023, 0, 4), new Date(2023, 0, 5), new Date(2023, 0, 6), new Date(2023, 0, 7), new Date(2023, 0, 8), new Date(2023, 0, 9), new Date(2023, 0, 10)];
const yAxisData = [10, 50, 45, 70, 30, 80, 10, 90, 20, 50];
// var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const AreaGraph = ({title, subTitle ,gradiantColor}: IAreaGraph) => {
    const chartRef = useRef<any | null>(null);
    const dataPoints = xAxisData.map((x, index) => ({ x, y: yAxisData[index] }));

    const options = {
        animationEnabled: true,
        title: {
            text: '',
        },
        axisX: {
            title: '',
            labelFormatter: () => '', // Hide X-axis labels
            gridThickness: 0,
            tickThickness: 0,
            lineThickness: 0
        },
        axisY: {
            title: '',
            labelFormatter: () => '', // Hide X-axis labels
            gridThickness: 0,
            tickThickness: 0,
            lineThickness: 0
        },
        data: [
            {
                type: 'splineArea',
                dataPoints,
                // color: [color], // Solid color as fallback
                fillOpacity: 1,
                fill: {
                    type: 'gradient',
                },
                lineThickness: 3,  // Adjust the thickness of the line
                lineTension: 0.5,  // Control the smoothness of the curve (0 to 1)
                markerType: 'none',  // Hide the data points
            },
        ],
        creditText: '',
        creditHref: '',
    };

    useEffect(() => {
        const chart = chartRef.current?.chart;
        if (chart) {
            const ctx = chart.ctx;
            if (ctx) {
                const gradient = ctx.createLinearGradient(0, 0, 0, 350);
                gradient.addColorStop(0, gradiantColor[0]);  // Dark color at the top
                gradient.addColorStop(1, gradiantColor[1]);  // Transparent color at the bottom
                chart.options.data[0].color = gradient;
                chart.render();
            }
        }
    }, []);

    return (
        <Box sx={Styles.root}>
            <span style={Styles.graphTitle}>
                <Typography variant='subtitle1' sx={Styles.title}>{title}</Typography>
                <Typography sx={Styles.subtitle}>{subTitle}</Typography>
            </span>
            <CanvasJSChart options={options} containerProps={{ width: '100%', height: '100%' }} ref={chartRef} />
        </Box>
    )
};

export default AreaGraph;
