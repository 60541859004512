import { Box, Modal, Stack, Typography } from '@mui/material';
import React from 'react'
import { style } from '../../../Utils/themes/styles';
import { IMAGES } from '../../../asset/Dis';

const Styles = {
    ModalRoot: {
        backgroundColor: style.palette.Neutral,
        borderRadius: '12px',
        overflow: 'scroll',
        maxHeight: '90%',
    },
    root: {

        marginBottom: '8px',


    },
    header: (title:string) => ( {
        display: 'felx',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '16px',
        borderBottom: title !== "" ?  `1px solid ${style.palette.black[50]}` : 'none'
    }),
    subHeaderAlign: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& .MuiTypography-body1': {
            fontSize: '12px',
            fontWeight: 500,
            color: style.palette.black[200],
        },
    },
    footer: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-end',
        borderTop: `1px solid ${style.palette.black[50]}`,
        padding:'16px'
    }
}

interface IModalView {
    children: React.ReactElement;
    open: boolean;
    handleClose?: () => void;
    title?: string;
    subTitle?: string;
    footer?: React.ReactNode;
}

const ModalView = ({
    children,
    open,
    handleClose,
    title,
    subTitle,
    footer
}: IModalView) => {
    console.log("title",title);
    
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={Styles.ModalRoot} className="customeScroll">
                <Box sx={Styles.root} >
                    {title &&
                        <Stack sx={Styles.header(title)}>
                            {subTitle ?
                                <Stack sx={Styles.subHeaderAlign}>
                                    <Typography variant='subtitle2'>{title}</Typography>
                                    <Typography variant='body1'>{subTitle}</Typography>
                                </Stack> :
                                <Typography variant='subtitle2'>{title}</Typography>
                            }
                            <Stack onClick={handleClose}>
                                <img src={IMAGES.crossIcon} alt='close' height={'36px'} />
                            </Stack>
                        </Stack>
                    }
                    <Box>
                        {children}
                    </Box>
                </Box>
                {footer &&
                    <Box sx={Styles.footer}>
                        {footer}
                    </Box>
                }
            </Box>
        </Modal>
    )
}

export default ModalView