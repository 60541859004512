import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { style } from '../../../Utils/themes/styles';
import { useTranslation } from 'react-i18next';
import ComButtom from '../../../components/formComponents/button/ComButtom';
import { IMAGES } from '../../../asset/Dis';
import PopupModal from './PopupModal';
import { AppDispatch } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import { deleteWarehouse } from '../../../redux/warehouse/actions/deleteWarehouse';

const Styles = {
    root: {
        borderRadius: '12px'
    },
    header: {
        padding: '28px 20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        'img': {
            marginRight: '12px',
        }
    },
    component: {},
    leftContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    rightContainer: {
        height: '24px',
        textAlign: "right"
    },
    children: {
        padding: '20px',
        height: '100%',
        overflowY: "auto"
    },
    formSubHeading: {
        fontWeight: "500",
        color: style.palette.TitleColor,
        width: "400px",
        textAlign: "center"
    },
    subHeadParent: {
        display: "flex",
        justifyContent: "center"
    },
    formHeading: {
        margin: "10px 0 0 0",
        textAlign: "left",
        fontSize: "14px",
        color: style.palette.black[300],
    },
    buttonBoxStyle: {
        position: "relative",
        top: "20px",
        marginRight: '4px'
    },
    buttonStyle: {
        width: "220px",
        height: "60px",
        '& .css-m7i2ec-MuiTypography-root': {
            fontSize: "24px",
            fontFamily: 'Poppins',
            fontWeight: "600"
        },
        border: `1px solid ${style.palette.black[300]}`,
        borderRadius: "8px",
    },
    divider: {
        borderRadius: 0,
        backgroundColor: style.palette.GrayStroke,
        marginTop: "15px",
        width: "570px",
        position: "relative",
        left: "-20px"
    },
    inputText: {
        '& .MuiInputBase-input': {
            padding: "8px 12px"
        },
        '& div': {
            height: "36px"
        }
    },
    subHeading2: {
        width: "auto !important",
        margin: "0",
        position: "relative",
        top: "42px",
        fontSize: "12px",
        color: style.palette.black[300]
    },
    editBtn: {
        display: "flex",
        justifyContent: "center",
        gap: "10px",
        marginTop: "40px"
    },
    popupBox: {
        width: "583px",
        height: "335px",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
    },
    popUpContainer: {
        height: '100%',
        borderRadius: "12px",
    },
    childComponent: {
        height: '81%',
        padding: "0 20px",
        overflowX: "hidden"
    }
};

interface IFormContainerProps {
    setDeleteWarehouseStatus: Dispatch<SetStateAction<boolean>>;
    selectedWarehouse?:string;
}

const MiniFormContainer: React.FC<IFormContainerProps> = ({
    setDeleteWarehouseStatus,
    selectedWarehouse
}) => {
    const [submit, setSubmit] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const handleCancel = () => {
        setDeleteWarehouseStatus(false);
    }
    const handleSubmit = async() => {
        const response = await dispatch(deleteWarehouse(selectedWarehouse));
        if(response.status === 200){
            setSubmit(true)
        }
    }
    useEffect(() => {
        const submitTimeout = setTimeout(() => {
            if (submit) {
                setSubmit(false);
                setDeleteWarehouseStatus(false);
            }
        }, 1500);
        return () => {
            clearTimeout(submitTimeout)
        }
    }, [submit])
    const { t } = useTranslation();

    return (
        <>
            {!submit && <Box>
                <Grid item xs={6} sx={Styles.popupBox}>
                    <Paper sx={{ ...Styles.root, ...Styles.popUpContainer }}>
                        <Grid container sx={{ ...Styles.header }}>
                            <Grid sx={Styles.rightContainer}>
                                <img src={IMAGES.crossIcon} alt='search' height='36px' onClick={handleCancel} />
                            </Grid>
                        </Grid>
                        <Box sx={{ ...Styles.children, ...Styles.childComponent }}>
                            <Box sx={Styles.component}>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { m: 1, width: '100%' }, display: "flex",
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Grid item xs={6} sx={Styles.subHeadParent}>
                                        <Typography variant="h3" sx={Styles.formSubHeading}>{t("AreYouSureDoYouWantToDeleteThisWarehouse")}</Typography>
                                    </Grid>
                                </Box>
                            </Box>
                            <Grid sx={Styles.rightContainer}>
                                <Grid sx={Styles.editBtn}>
                                    <ComButtom
                                        label={"No"}
                                        variant={"outlined"}
                                        textVariant="subtitle1"
                                        inputStyle={Styles.buttonStyle}
                                        onClick={handleCancel}
                                    />
                                    <ComButtom
                                        label={"Yes"}
                                        variant={"contained"}
                                        textVariant="subtitle1"
                                        inputStyle={Styles.buttonStyle}
                                        onClick={handleSubmit}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            </Box>
            }
            {submit && <PopupModal showText={t("Warehouse #1 deleted successfully!!")} bottomText='' />}
        </>
    );
};

export default MiniFormContainer;
