// FileUploadBox.tsx
import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { style } from '../../../Utils/themes/styles';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

const Styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: `1px solid ${style.palette.black[50]}`,
        borderRadius: '8px',
        padding: '24px 32px',
        backgroundColor: "#E6E6E61A",
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto',
    },
    fileInput: {
        display: 'none',
    },
    fileInputLabel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: '20px',
        border: `2px dashed ${style.palette.GreenBgColor}`,
        borderRadius: '4px',
        cursor: 'pointer',
        backgroundColor: style.palette.green[50],
        color: style.palette.green[500],
        fontSize: '20px',
        height: '120px',
        marginTop: '18px',
        fontFamily: 'Poppins',
        fontWeight: 500
    },
    errorMessage: {
        color: style.palette.DarkRed,
    },
    heading: {
        color: style.palette.TitleColor,
        marginBottom: 'auto'
    }
};


interface IFileUploadBox {
    heading?: string;
    browseMessage?: string;
    handleUpload?:(files:File[])=>void;
}
const FileUploadBox = ({ heading, browseMessage,handleUpload }: IFileUploadBox) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState<File[]>([]);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>('Vehicle license plate must be visible');
    console.log("files", files);

    const { getRootProps, getInputProps } = useDropzone({
        // accept: {
        //     "files/*": [".pdf"],
        // },
        multiple: heading ? true : false,
        // maxSize: 500000,
        maxFiles: 1,
        onDrop: (acceptedFiles:File[]) => {
            console.log("acceptedFiles",acceptedFiles);
            
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
            handleUpload &&  handleUpload(acceptedFiles)

        },
        
    });
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            // Check if the file is valid (you can add more validation if needed)
            if (file.type.startsWith('image/') || file.type === 'application/pdf') {
                setSelectedFile(file);
                setErrorMessage('');
            } else {
                setErrorMessage(t('InvalidFileTypePleaseUploadAnImageOrPDF'));
            }
        }
    };

    return (
        <Box sx={Styles.container}>
            {heading ?
                <Typography variant="h4" gutterBottom sx={Styles.heading}>
                    {heading}
                </Typography> :
                <Typography variant="h4" gutterBottom sx={Styles.heading}>
                    {t("UploadTheSignedLoadingForm")}
                </Typography>}

            {heading ?
                <Typography variant="h4" gutterBottom sx={Styles.heading}>
                    or
                </Typography> :

                errorMessage && <Typography sx={Styles.errorMessage}>{errorMessage}</Typography>}
            {heading ?  <div {...getRootProps()} style={{width:'100%'}}>  
            <input {...getInputProps()} /> 
            <label style={Styles.fileInputLabel}>
                Browse files
            </label>
            </div>:
                <input
                    type="file"
                    id="fileInput"
                    style={Styles.fileInput}
                    onChange={handleFileChange}

                />
            }
            {!browseMessage &&
                <label htmlFor="fileInput" style={Styles.fileInputLabel}>
                    {selectedFile ? selectedFile.name : t('ChooseYourFile')}
                </label>
            }

        </Box>
    );
};

export default FileUploadBox;
