import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './components/Sidebar'
import Header from './components/Header.js'

const Layout = () => {
  const [ isSidebarOpen, setIsSidebarOpen ] = useState(true);
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };
  return (
    <div>
      <Sidebar
        userRole={ 'DIS' }
        isSidebarOpen={ isSidebarOpen }
      />
      <div className='layout-render' style={ { paddingLeft: isSidebarOpen ? '220px' : '0px', transition: 'all 0.3s ease' } }>
        <Header toggleSidebar={ toggleSidebar } />
        <div className='outlet'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Layout
