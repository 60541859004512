import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import ListContainer from '../common/components/ListContainer';
import { AllWarehouseData } from '../common/constant';
import ListView from '../../common/components/ListView';
import ComButtom from '../../../components/formComponents/button/ComButtom';
import { style } from '../../../Utils/themes/styles';
import AllWarehouse from '../common/components/AllWarehouse';
import WarehouseDetails from '../common/components/WarehouseDetails';
import ManagerDetails from '../common/components/ManagerDetails';
import AssistantDetails from '../common/components/AssistantDetails';
import DetailsContainer from '../../common/components/DetailsContainer';
import StocksDetails from '../common/components/StocksDetails';
import AllLogistique from '../common/components/AllLogistique';
import AllLogistician from '../common/components/AllLogistician';
import WarehouseBox from '../../common/components/WarehouseBox';
import { AllWarehouseDataTypes } from '../../common/interfaces';
import LogisticsHistory from '../../common/components/LogisticsHistory';
import ComponentDetails from '../common/components/ComponentDetails';
import StockTableList from '../../common/components/StockTableList';
import ListContainer from '../../common/components/ListContainer';
import { baseTheme } from '../../../Utils/themes/base';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { COMMON_IMAGES } from '../../../asset';

const Styles = {
    mapViewbtns: {
        display: 'flex',
        flexDirection: 'row',
        [baseTheme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            alignItems: 'flex-end',
            '& .MuiTypography-root': {
                fontSize: '11px'
            }
        },

    },
    editBtnStyle: {
        height: '36px',
        borderRadius: '4px',
        [baseTheme.breakpoints.down('lg')]: {
            height: '32px',
        },
        [baseTheme.breakpoints.up('lg')]: {
            height: '32px',
        }
    },
    btnBoxStyle: {
        marginRight: '4px',
        '& .MuiTypography-subtitle1': {
            fontSize: "13px",
            [baseTheme.breakpoints.down('lg')]: {
                fontSize: "11px !important"
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: "11px !important"
            }
        },
    },
    fullHeight: {
        height: "100%"
    },
    alignTextStyle: {
        '& .MuiTypography-root MuiTypography-subtitle1': {
            fontSize: "13px",
            [baseTheme.breakpoints.down('lg')]: {
                fontSize: "11px !important"
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: "11px !important"
            }
        },
    },
    headerSpacing: {
        padding: "20px 20px 15px 20px"
    },
    linkStyle: {
        lineHeight: "19.5px",
        textAlign: "left",
        color: style.palette.green[500],
        textDecoration: "underline",
        cursor: "pointer"
    },
    divider: {
        borderRadius: 0,
        backgroundColor: style.palette.GrayStroke,
    },
    paddingBottomHigh: {
        padding: '15px 20px 35px 20px'
    },
    paddingTopLow: {
        padding: '15px 20px 20px 20px'
    },
    borderRadiusTop: {
        borderRadius: "12px 12px 0 0"
    },
    borderRadiusBottom: {
        borderRadius: "0 0 12px 12px"
    },
    searchBarStyle: {
        width: "368px !important",
        height: "40px",
        border: "none",
        boxShadow: "none",
        background: style.palette.BgColor,
        marginRight: "10px",
        '& .css-1vy85e9-MuiInputBase-root-MuiOutlinedInput-root': {
            height: "100%"
        },
        '& fieldset': {
            border: "none"
        },
        [baseTheme.breakpoints.down('lg')]: {
            width: "158px !important",
        }
    },
    buttonBoxStyle: {
        marginRight: "4px",
        height: "40px",
        borderRadius: "4px",
    },
    inventoryHeading: {
        [baseTheme.breakpoints.down('xl')]: {
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            alignItems: 'flex-start'
        },
    },
    detailsHeading: {
        [baseTheme.breakpoints.down('xl')]: {
            display: 'flex',
            gap: '7px',
            flexDirection: 'row',
            height: '34%'
        },
        height: '30%'
    },
    warehouseChildStyle: {
        padding: '20px',
        height: '90%',
        [baseTheme.breakpoints.down('xl')]: {
            padding: '10px',
            '& .css-sx40us': {
                padding: '5px'
            },
            '& .css-70qvj9 img': {
                height: '25px'
            }
        }
    },
    buttonStyle: {
        marginRight: '4px',
        [baseTheme.breakpoints.down('xl')]: {
            width: '105px'
        }
    },
    stockHeading: {
        [baseTheme.breakpoints.down('lg')]: {
            height: '10%'
        },
    },
    DataNotFoundSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // padding:'12px',
        '& .MuiTypography-body2': {
            fontSize: "16px",
            marginTop: '10px',
            [baseTheme.breakpoints.down('lg')]: {
                fontSize: "12px !important"
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: "12px !important"
            }
        },
    }
};

interface InventoryItem {
    id: number;
    title: string;
    subTitle: string;
    image: string;
}

interface Warehouse {
    warehouseSelect: string | undefined;
    setWarehouseSelect: React.Dispatch<React.SetStateAction<string | undefined>>
    editWarehouseStatus: boolean;
    setEditWarehouseStatus: React.Dispatch<React.SetStateAction<boolean>>;
    newWarehouseStatus: boolean;
    setNewWarehouseStatus: React.Dispatch<React.SetStateAction<boolean>>;
    newInventoryStatus: boolean;
    setNewInventoryStatus: React.Dispatch<React.SetStateAction<boolean>>;
    selection: string;
    setSelection: React.Dispatch<React.SetStateAction<string>>;
    newLogisticianStatus: boolean;
    setNewLogisticianStatus: React.Dispatch<React.SetStateAction<boolean>>;
    editLogisticianStatus: boolean;
    handleEditLogistician?: (value: boolean, id?: string) => void;
    fullStockShowStatus: boolean;
    setFullStockShowStatus: React.Dispatch<React.SetStateAction<boolean>>;
    logisticsHistoryStatus: boolean;
    setLogisticsHistoryStatus: React.Dispatch<React.SetStateAction<boolean>>;
    deleteWarehouseStatus: boolean;
    setDeleteWarehouseStatus: React.Dispatch<React.SetStateAction<boolean>>;
    searched: string;
    setSearched: React.Dispatch<React.SetStateAction<string>>;
    handleWarehouseSelect?: (id: string | undefined) => void;
    handleEditWarehouseDetails: () => void;
    handleStockTableShow: () => void;
    handleAddNewInventory: () => void;
    handleAddNewLogistician: () => void;
    handleLogisticsHistory: () => void;
    setDeleteLogisticianStatus: React.Dispatch<React.SetStateAction<boolean>>;
    handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleNewMaterialAddInStock: () => void;
    handleDeleteLogistician?: (value: boolean, id?: string) => void;

}
const WarehouseBO = (
    {
        warehouseSelect,
        setWarehouseSelect,
        editWarehouseStatus,
        setEditWarehouseStatus,
        newWarehouseStatus,
        setNewWarehouseStatus,
        newInventoryStatus,
        setNewInventoryStatus,
        selection,
        setSelection,
        newLogisticianStatus,
        setNewLogisticianStatus,
        editLogisticianStatus,
        handleEditLogistician,
        fullStockShowStatus,
        setFullStockShowStatus,
        logisticsHistoryStatus,
        setLogisticsHistoryStatus,
        deleteWarehouseStatus,
        setDeleteWarehouseStatus,
        searched,
        setSearched,
        handleWarehouseSelect,
        handleEditWarehouseDetails,
        handleStockTableShow,
        handleAddNewInventory,
        handleAddNewLogistician,
        handleLogisticsHistory,
        setDeleteLogisticianStatus,
        handleSearchChange,
        handleNewMaterialAddInStock,
        handleDeleteLogistician
    }: Warehouse
) => {
    const { t } = useTranslation();
    const warehouseList: AllWarehouseDataTypes = useSelector((state: RootState) => state.warehouseReducer.warehouses);
    const warehouseInventory: any = useSelector((state: RootState) => state.warehouseReducer.warehouseInventory);
    const warehouseLogistician: any = useSelector((state: RootState) => state.warehouseReducer.warehouseLogistician);
    const selectedWarehouse = warehouseList.find((item: AllWarehouseDataTypes) => item.id == warehouseSelect);

    console.log("warehouseLogistician", warehouseLogistician);

    useEffect(() => {
        if (warehouseList && warehouseList[0]?.id) {
            return setWarehouseSelect(warehouseList[0]?.id);
        }
    }, [warehouseList])

    return (
        <Grid container spacing={2} alignItems="stretch" sx={Styles.alignTextStyle}>
            <Grid item xs={12} md={4} lg={2.5}>
                <ListContainer
                    leftContainer={t("MyWarehouses")}
                    sx={Styles.fullHeight}
                    headerStyle={Styles.headerSpacing}
                    childContainerStyle={Styles.warehouseChildStyle}
                >
                    <AllWarehouse
                        AllWarehouseData={warehouseList}
                        warehouseSelect={warehouseSelect}
                        handleWarehouseSelect={handleWarehouseSelect}
                        setNewWarehouseStatus={setNewWarehouseStatus}
                        setDeleteWarehouseStatus={setDeleteWarehouseStatus}
                    />
                </ListContainer>
            </Grid>
            {!fullStockShowStatus && !logisticsHistoryStatus && (
                <>
                    <Grid item md={8} lg={7}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <WarehouseBox
                                    leftContainer={t(`${selectedWarehouse?.name}`)}
                                    rightContainer={
                                        <Box sx={Styles.mapViewbtns}>
                                            <ComButtom
                                                label={t(`Edit`)}
                                                variant={"contained"}
                                                textVariant="subtitle1"
                                                inputStyle={Styles.editBtnStyle}
                                                boxStyle={Styles.btnBoxStyle}
                                                onClick={() => handleEditWarehouseDetails()}
                                            />
                                        </Box>
                                    }
                                    sx={Styles.fullHeight}
                                    headerStyle={Styles.detailsHeading}
                                    childContainerStyle={{ height: '81%' }}
                                >
                                    <WarehouseDetails AllWarehouseData={selectedWarehouse} />
                                </WarehouseBox>
                            </Grid>
                            <Grid item xs={6}>
                                <DetailsContainer
                                    leftContainer={t("ManagerDetails")}
                                    sx={{ ...Styles.borderRadiusTop, boxShadow: "none" }}
                                    headerStyle={Styles.headerSpacing}
                                    childContainerStyle={{ height: '75%' }}
                                >
                                    <ManagerDetails ManagerData={selectedWarehouse?.manager_details} />
                                </DetailsContainer>
                                <Divider sx={Styles.divider} orientation="horizontal" flexItem />
                                <DetailsContainer
                                    leftContainer={t("Assistant")}
                                    sx={Styles.borderRadiusBottom}
                                    headerStyle={{ ...Styles.paddingTopLow, height: '12%' }}
                                    childContainerStyle={{ height: '81%' }}
                                >
                                    <AssistantDetails AsistantData={selectedWarehouse?.assistant_details} />
                                </DetailsContainer>
                            </Grid>
                            <Grid item xs={12}>
                                <DetailsContainer
                                    leftContainer={t("Stocks")}
                                    rightContainer={""}
                                    sx={{ height: '94%' }}
                                    headerStyle={{
                                        ...Styles.paddingBottomHigh,
                                        height: '12%',
                                    }}
                                    childContainerStyle={{ height: '81%' }}
                                >
                                    <StocksDetails
                                        AllWarehouseData={AllWarehouseData}
                                        handleStockTableShow={handleStockTableShow}
                                    />
                                </DetailsContainer>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <ListContainer
                                    leftContainer={t("Inventories")}
                                    rightContainer={
                                        <Box sx={Styles.mapViewbtns}>
                                            <ComButtom
                                                label={t(`AddNewInventory`)}
                                                variant={"contained"}
                                                textVariant="subtitle1"
                                                inputStyle={Styles.editBtnStyle}
                                                boxStyle={Styles.btnBoxStyle}
                                                onClick={() => handleAddNewInventory()}
                                            />
                                        </Box>
                                    }
                                    headerStyle={{ ...Styles.headerSpacing, ...Styles.inventoryHeading }}
                                >
                                    {warehouseInventory.data.length >= 1 ? (
                                        warehouseInventory.data.map((item: any) => (
                                            <ListView
                                                key={item.id}
                                                title={item.material}
                                                subTitle={item.quantity}
                                                image={item.image}
                                            />
                                        ))
                                    ) : (
                                        <Box sx={Styles.DataNotFoundSection}>
                                            <img src={COMMON_IMAGES.DataNotFoundImage} alt="data_not_found" height='80px' width='80px' />
                                            <Typography variant="body2">{t("NoInventoriesFound")}</Typography>
                                        </Box>
                                    )}
                                </ListContainer>
                            </Grid>
                            <Grid item xs={6}>
                                <ListContainer
                                    leftContainer={t("Logistician")}
                                    rightContainer={
                                        <Box sx={Styles.mapViewbtns}>
                                            <ComButtom
                                                label={t(`AddNewLogistician`)}
                                                variant={"contained"}
                                                textVariant="subtitle1"
                                                inputStyle={Styles.editBtnStyle}
                                                boxStyle={Styles.btnBoxStyle}
                                                onClick={() => handleAddNewLogistician()}
                                            />
                                        </Box>
                                    }
                                    sx={Styles.fullHeight}
                                    childContainerStyle={{ height: '81%' }}
                                    headerStyle={{ ...Styles.headerSpacing, ...Styles.inventoryHeading }}
                                >
                                    <AllLogistician AllWarehouseData={warehouseLogistician} handleEditLogistician={handleEditLogistician} setSelection={setSelection} setDeleteLogisticianStatus={setDeleteLogisticianStatus} handleDeleteLogistician={handleDeleteLogistician} />
                                </ListContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={2.5}>
                        <ListContainer
                            leftContainer={t("Logistique")}
                            rightContainer={
                                <Grid>
                                    <Typography variant='subtitle1' sx={Styles.linkStyle} onClick={() => handleLogisticsHistory()}>{t("viewHistory")}</Typography>
                                </Grid>
                            }
                            sx={Styles.fullHeight}
                            headerStyle={Styles.headerSpacing}
                            childContainerStyle={{ height: '81%' }}
                        >
                            <AllLogistique AllWarehouseData={AllWarehouseData} />
                        </ListContainer>
                    </Grid>
                </>
            )}
            {fullStockShowStatus && (
                <Grid item md={8} lg={9.5}>
                    <ComponentDetails
                        leftContainer={t("WarehouseStock")}
                        rightContainer={
                            <Box sx={Styles.mapViewbtns}>
                                <TextField
                                    id="outlined-password-input"
                                    placeholder='Search here'
                                    type="text"
                                    autoComplete="current-password"
                                    sx={Styles.searchBarStyle}
                                    value={searched}
                                    onChange={handleSearchChange}
                                />
                                <ComButtom
                                    label={t(`AddNewMaterialToStock`)}
                                    variant={"contained"}
                                    textVariant="subtitle1"
                                    inputStyle={Styles.editBtnStyle}
                                    boxStyle={Styles.buttonBoxStyle}
                                    onClick={() => handleNewMaterialAddInStock()}
                                />
                            </Box>
                        }
                        sx={Styles.fullHeight}
                        headerStyle={{ ...Styles.headerSpacing, ...Styles.stockHeading }}
                        childContainerStyle={{ height: '92%' }}
                        handleStatus={setFullStockShowStatus}
                        nextPreviousStatus={true}
                    >
                        <StockTableList />
                    </ComponentDetails>
                </Grid>
            )}
            {logisticsHistoryStatus && (
                <Grid item lg={9.5} md={8}>
                    <ComponentDetails
                        leftContainer={t("LogisticsHistory")}
                        rightContainer={null}
                        sx={Styles.fullHeight}
                        headerStyle={Styles.headerSpacing}
                        childContainerStyle={{ padding: "none" }}
                        handleStatus={setLogisticsHistoryStatus}
                        nextPreviousStatus={false}
                    >
                        <LogisticsHistory />
                    </ComponentDetails>
                </Grid>
            )}
        </Grid>
    )
}

export default WarehouseBO