import { Box, Stack, Typography } from '@mui/material'
import React, { MouseEventHandler } from 'react'
import { IMAGES } from '../../../asset/Dis';
import { style } from '../../../Utils/themes/styles';
import { baseTheme } from '../../../Utils/themes/base';

const Styles = {
    root: {
        padding: '11px 12px',
        backgroundColor: style.palette.BtnBgColor,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '8px',

    },
    active: {
        border: ` 1px solid ${style.palette.green[500]}`,
        backgroundColor:`${style.palette.ActiveBgColor } !important`
    },
    listTitle: {
        ...style.typography.subtitle1,
        fontSize: '14px',
        color: style.palette.black[300],
        [baseTheme.breakpoints.down('lg')]: {
            fontSize: '11px',
        },
        [baseTheme.breakpoints.up('lg')]: {
            fontSize: '11px',
        }
    },
    leftConatiner: {
        display: 'flex',
        flexDirection: 'row' as const,
        [baseTheme.breakpoints.down('xl')]: {
            flexDirection: 'column' as const,
            alignItems: 'flex-start' as const,
        },
        'img': {
            height: '36px',
            marginRight: '12px',
            [baseTheme.breakpoints.down('lg')]: {
                height: '32px',
            },
            [baseTheme.breakpoints.up('lg')]: {
                height: '32px',
            }
        },
        '& .MuiTypography-subtitle1': {
            color: style.palette.black[200],
            [baseTheme.breakpoints.down('lg')]: {
                fontSize: '11px',
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: '11px',
            }
        },
        '& .MuiStack-root': {
            display: 'flex',
            alignItems: 'flex-start'
        },

    },
    rightConatiner: {
        '& .MuiTypography-body2': {
            color: style.palette.green[500]
        }
    },
    appointmentLabel: (label: string) => ({
        background: label === "Receipt" ? '#531BB515' : label === "Unloading" ? "#F4960015" : label === "Delivery" ? "#007A6A15" : "#007A6A15",
        padding: '6px',
        marginTop: '6px',
        '& .MuiTypography-root': {
            fontSize: '8px',
            fontWeight: 500,
            color: label === "Receipt" ? '#531BB5' : label === "Unloading" ? "#F8C837" : label === "Delivery" ? "#007A6A" : "#007A6A",
        }
    }),

    appintmentViewAllSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        height: '100%',
        '& .MuiTypography-body1': {
            color: style.palette.green[500],
            fontSize: '12px',
            textDecoration: 'underline'
        }
    },
    loadingStatus: {
        '& .MuiTypography-body1': {
            color: style.palette.black[700],
            fontSize: '10px',
            textDecoration: 'none'
        },
        '& .MuiStack-root': {
            backgroundColor: style.palette.InProgressBgColor,
            height: '18px',
            width: '75px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .MuiTypography-caption': {
                color: style.palette.InProgressTextColor
            }
        }
    },
    moreIconContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
    }
}

interface IListView {
    title: string;
    subTitle?: string;
    image?: string;
    wearhouse?: string;
    label?: string;
    handleModifyAppointModal?: MouseEventHandler<HTMLDivElement>;
    totalItems?: number;
    index?: number;
    handleViewAllClick?: () => void;
    showAll?: boolean;
    truck?: string;
    distributor?: string;
    status?: string;
    handleSelectAppointment?: (value: string) => void;
    selectedAppointment?: string;
}

const ListView = ({
    title,
    subTitle,
    image,
    wearhouse,
    label,
    handleModifyAppointModal,
    totalItems,
    index,
    handleViewAllClick,
    showAll,
    truck,
    distributor,
    status,
    handleSelectAppointment,
    selectedAppointment
}: IListView) => {

    const handleListClick = (value: string) => {
        handleSelectAppointment && handleSelectAppointment(value)
    }

    return (
        <Box sx={selectedAppointment && selectedAppointment === title ? { ...Styles.root, ...Styles.active } : Styles.root} onClick={() => handleListClick(title)}>
            <Box sx={Styles.leftConatiner}>
                {image && image?.length > 1000 ? <img src={image} alt='graphicon' /> :
                    <img src={`${IMAGES.GraphQueIcon}`} alt='graphicon' />}
                <Stack>
                    <Typography sx={Styles.listTitle} >{title}</Typography>
                    <Typography variant='subtitle1'>{subTitle}</Typography>
                    {truck &&
                        <Typography variant='subtitle1'>{truck}</Typography>
                    }
                </Stack>
            </Box>
            <Box sx={Styles.rightConatiner}>
                {(wearhouse && !showAll &&
                    ((totalItems && totalItems > 2) && (index && index == 1))) ?
                    <Stack sx={Styles.appintmentViewAllSection}>
                        <Typography variant='body2'>{wearhouse}</Typography>
                        <Box onClick={handleViewAllClick}>
                            <Typography variant='body1'>View All</Typography>
                        </Box>
                    </Stack> : wearhouse && showAll && ((totalItems === (index && index + 1))) ?
                        <Stack sx={Styles.appintmentViewAllSection}>
                            <Typography variant='body2'>{wearhouse}</Typography>
                            <Box onClick={handleViewAllClick}>
                                <Typography variant='body1'>View less</Typography>
                            </Box>
                        </Stack>
                        :
                        <Typography variant='body2'>{wearhouse}</Typography>

                }
                {truck &&
                    <Stack sx={{ ...Styles.appintmentViewAllSection, ...Styles.loadingStatus }}>
                        <Typography variant='body1' >{distributor}</Typography>
                        <Stack>
                            <Typography variant='caption'>{status}</Typography>
                        </Stack>
                    </Stack>
                }
                {
                    label &&
                    <Stack sx={Styles.moreIconContainer}>
                        <Box onClick={handleModifyAppointModal}>
                            <img src={`${IMAGES.MoreIconHorizontal}`} alt='moreIcon' height='3px' />
                        </Box>
                        <Stack sx={Styles.appointmentLabel(label)}>
                            <Typography>{label}</Typography>
                        </Stack>
                    </Stack>
                }
            </Box>
        </Box>
    )
}

export default ListView