import { Box, Stack, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import { IMAGES } from '../../../asset/Dis';
import { style } from '../../../Utils/themes/styles';
import { baseTheme } from '../../../Utils/themes/base';

const Styles = {
    root: {
        padding: '11px 12px',
        backgroundColor: style.palette.BtnBgColor,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '8px',
        alignItems: 'center',
    },
    listTitle: {
        ...style.typography.subtitle1,
        fontSize: '14px',
        marginLeft: '10px',
        color: style.palette.black[300],
        [baseTheme.breakpoints.down('lg')]: {
           fontSize: '11px',
        },
        [baseTheme.breakpoints.up('lg')]: {
           fontSize: '11px',
        }
    },
    leftConatiner: {
        display: 'flex',
        flexDirection: 'row',

        'img': {
            marginRight: '12px',
        },
        '& .MuiTypography-subtitle1': {
            color: style.palette.black[200]
        }
    },
    rightConatiner: {
        'img': {
            height:'16px',
            // marginRight: '12px',
            [baseTheme.breakpoints.down('lg')]: {
                height: '12px',
            },
            [baseTheme.breakpoints.up('lg')]: {
                height: '14px',
            }
        },
        '& .MuiTypography-body2': {
            color: style.palette.green[500]
        }
    },
    appointmentLabel: (label: string) => ({
        background: label === "Receipt" ? '#531BB515' : label === "Unloading" ? "#F4960015" : label === "Delivery" ? "#007A6A15" : "#007A6A15",
        padding: '6px',
        marginTop: '6px',
        '& .MuiTypography-root': {
            fontSize: '8px',
            fontWeight: 500,
            color: label === "Receipt" ? '#531BB5' : label === "Unloading" ? "#F8C837" : label === "Delivery" ? "#007A6A" : "#007A6A",
        }
    }),
    moreIconContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
    },
    active: {
        border: "1px solid #007A6A"
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
        'img': {
            height:'36px',
            // marginRight: '12px',
            [baseTheme.breakpoints.down('lg')]: {
                height: '12px',
            },
            [baseTheme.breakpoints.up('lg')]: {
                height: '28px',
            }
        },
    }
}

interface IListViewWarehouse {
    id?: string;
    title?: string;
    image?: string;
    image2?: string;
    warehouseSelect?: string;
    handleWarehouseSelect?: (id?: string) => void;
    setDeleteWarehouseStatus: Dispatch<SetStateAction<boolean>> | undefined;
}

const ListViewWarehouse = ({
    id,
    title,
    image,
    image2, warehouseSelect,
    handleWarehouseSelect,
    setDeleteWarehouseStatus
}: IListViewWarehouse) => {

    const handleDelete = () => {
        console.log(setDeleteWarehouseStatus)
        if (setDeleteWarehouseStatus) {
            setDeleteWarehouseStatus(true);
        }
    }
    return (
        <Box sx={warehouseSelect === id ? { ...Styles.root, ...Styles.active } : Styles.root} onClick={() => warehouseSelect !== undefined && handleWarehouseSelect && handleWarehouseSelect(id)}>
            <Box sx={Styles.alignCenter}>
                {image && image?.length > 1000 ? <img src={image} alt='warehouseIcon' /> :
                    <img src={IMAGES.WarehouseIcon} alt='warehouseIcon' />}
                <Stack>
                    <Typography sx={Styles.listTitle}>{`#${title} `}</Typography>

                </Stack>

            </Box>
            <Box sx={Styles.rightConatiner}>
                {image2 && image2?.length > 1000 ? <img src={image2} alt='deleteicon' height='16px' onClick={handleDelete} /> :
                    <img src={IMAGES.DeleteIcon} alt='deleteicon' height='16px' onClick={handleDelete} />}
            </Box>
        </Box >
    )
}

export default ListViewWarehouse