import React from 'react'
import ListContainer from '../common/components/ListContainer'
import { Box, Grid, Stack, Typography } from '@mui/material'
import Heading from '../common/components/Heading';
import { IMAGES } from '../../asset/Dis';
import { style, theme } from '../../Utils/themes/styles';
import ListView from '../common/components/ListView';
import data from './StaticData/recentActivity.json'
import DoughnutChart from './common/components/DoughnutChart ';
import AreaGraph from './common/components/AreaGraph';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { chartData } from '../common/interfaces';
import { baseTheme } from '../../Utils/themes/base';

const Styles = {
  listConatiners: {
    '& .MuiPaper-root': {
      height: '100vh',
      [baseTheme.breakpoints.down('lg')]: {
        overflow: "scroll"
      },
      [baseTheme.breakpoints.up('lg')]: {
        overflow: "scroll"
      }
    },
    [baseTheme.breakpoints.down('lg')]: {
      '& .MuiPaper-root': {
        overflowY: 'auto',
      },
    },
  },
  rightContainerStyle: {
    '& .MuiTypography-h6': {
      fontWeight: 500,
      color: theme.palette.Primary,
      textDecoration: 'underline'
    }
  }

}

const Dashboard = () => {
  const { t } = useTranslation();
  const chartData: chartData = {
    labels: [`${style.palette.PurpleStroke}`, `${style.palette.Orange}`, `${style.palette.Primary}`, `${style.palette.Yellow}`],
    values: [50, 20, 3, 15],
    colors: undefined
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Heading title={t("Home")} />
      <Grid container spacing={2} sx={Styles.listConatiners}>
        <Grid item xs={12} md={6} lg={4}>
          <ListContainer
            leftContainer={t("RecentActivity")}
          >
            {
              data.map((item) => {
                return (
                  <ListView
                    title={item.title}
                    subTitle={item.subTitle}
                    wearhouse={t(`${item.wearhouse}`)}
                    image={item.image}
                  />
                )
              })
            }
          </ListContainer>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ListContainer
            leftContainer={t("FilesInProgress")}
            rightContainer={
              <img src={IMAGES.SearchFilesInProgressIcon} alt='search' height='24px' />
            }
          >
            {
              data.map((item) => {
                return (
                  <ListView
                    title={item.title}
                    subTitle={item.subTitle}
                    wearhouse={t(`${item.wearhouse}`)}
                    image={item.image}
                  />
                )
              })
            }
          </ListContainer>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <ListContainer
            leftContainer={t("FilesInProgress")}
            rightContainer={
              <Stack sx={Styles.rightContainerStyle}>
                <Link to="/agenda">
                  <Typography variant='h6'>{t("ShowAgenda")}</Typography>
                </Link>
              </Stack>
            }
          >
            <>
              <Box sx={{ marginBottom: '40px' }}>
                <DoughnutChart 
                data={chartData} 
                subTitle="400 Validated lots"
                colors={[`${style.palette.PurpleStroke}`, `${style.palette.Orange}`, `${style.palette.Primary}`, `${style.palette.Yellow}`]}
                />
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <AreaGraph title={t("Loading")} subTitle={"150"} color={style.palette.Purple} gradiantColor={['rgba(83, 27, 181, 1)', 'rgba(255, 255, 255, 0)']} />
                  </Grid>
                  <Grid item xs={6}>
                    <AreaGraph title={t("Unloading")} subTitle={"100"} color={style.palette.Orange} gradiantColor={['rgba(246, 164, 23, 1)', 'rgba(255, 255, 255, 0)']} />
                  </Grid>
                  <Grid item xs={6}>
                    <AreaGraph title={t("Delivery")} subTitle={"100"} color={style.palette.GreenBgColor} gradiantColor={['rgba(0, 122, 106, 1)', 'rgba(255, 255, 255, 0)']} />
                  </Grid>
                  <Grid item xs={6}>
                    <AreaGraph title={t("Receipt")} subTitle={"50"} color={style.palette.Yellow} gradiantColor={['rgba(254, 207, 22, 1)', 'rgba(255, 255, 255, 0)']} />
                  </Grid>
                </Grid>

              </Box>
            </>
          </ListContainer>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Dashboard
