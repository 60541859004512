import Request from "../../../Axios/Instance";
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../../store';
import { AxiosResponse } from "axios";
import { getWarehouses } from "./getWarehouse";

export const ADD_WAREHOUSE = "ADD_WAREHOUSE";
export const ADD_WAREHOUSE_ERROR = "RECEIVE_WAREHOUSE_ERROR";

export const addWarehouse = (data: any) => ({
    type: ADD_WAREHOUSE,
    payload: data
})

export const addWarehouseError = (err: unknown) => ({
    type: ADD_WAREHOUSE_ERROR,
    payload: err
})

export const postWarehouse = (payload: any): ThunkAction<Promise<AxiosResponse<any>>, RootState, unknown, AnyAction> =>
    (dispatch, getState) =>
        new Promise((resolve, reject) => {
            console.log("payload", payload);
            const fetchPost = async () => {
                try {
                    const response = await Request.post(`/warehouse`, payload);
                    await dispatch(getWarehouses());
                    dispatch(addWarehouse(response?.data?.data));
                    resolve(response.data);
                } catch (err) {
                    dispatch(addWarehouseError(err));
                    reject(err);
                }
            }
            fetchPost();
        });

