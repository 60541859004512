import React, { useState } from 'react';
import { Box, Grid, Typography, TextField, Divider } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { style } from '../../../Utils/themes/styles';
import { IMAGES } from '../../../asset/Dis';
import { useTranslation } from 'react-i18next';
import { baseTheme } from '../../../Utils/themes/base';

const Styles = {
    popupBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "stretch",
        border: `1px solid ${style.palette.black[50]}`,
        borderRadius: '8px',
        padding: '24px 32px',
        backgroundColor: "#E6E6E61A",
        width: '642px',
        [baseTheme.breakpoints.down('lg')]: {
            width: '475px',
        },
    },
    summaryBox: {
        padding: '24px 32px',
        borderRadius: '8px',
        border: `1px solid ${style.palette.black[50]}`,
        backgroundColor: '#fff',
        width: '700px',
        [baseTheme.breakpoints.down('lg')]: {
            width: '475px',
        },

    },
    title: {
        marginBottom: '20px',
        textAlign: 'left',
        color: style.palette.TitleColor,
    },
    section: {
        marginBottom: '20px',
        '& .css-q0igbi-MuiTypography-root': {
            textAlign: 'left'
        }
    },
    sectionHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
    },
    editButton: {
        fontSize: '14px',
        color: style.palette.green[500],
        cursor: 'pointer'
    },
    editIcon: {
        height: '14px',
        width: '14px',
        marginLeft: '5px',
    },
    detailsLoading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& .css-m7i2ec-MuiTypography-root': {
            color: style.palette.black[300]
        },
        '& .css-2bot5r-MuiInputBase-root-MuiOutlinedInput-root': {
            height: '90px',
            alignItems: 'flex-start',
        },
        '& .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input': {
            color: style.palette.black[100]
        },
    },
    comments: {
        '& .css-q0igbi-MuiTypography-root': {
            display: 'flex',
            justifyContent: 'space-between',
        },
        '& .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input': {
            color: style.palette.black[100]
        },
    },
    commentButton: {
        fontSize: '14px',
        color: style.palette.green[500],
        cursor: 'pointer'
    },
    loadingSlips: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    loadingPhotos: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    photoBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        padding: '10px',
        background: style.palette.BgColor,
        borderRadius: '4px',
        cursor: 'pointer',
        height: '50px'
    },
    sectionHead: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '20px'
    },
    pdfIconStyle: {
        height: '28px'
    },
    divider: {
        margin: '20px 0',
        backgroundColor: style.palette.GrayStroke,
    },
    loadingSlipBox: {
        height: '40px',
        padding: '5px 15px',
        background: style.palette.BgColor,
        fontSize: '14px',
        color: style.palette.black[400],
        textAlign: 'left',
        borderRadius: '4px',
    },
    detailsInput: {
        '& .css-2bot5r-MuiInputBase-root-MuiOutlinedInput-root': {
            height: '90px',
            display: 'flex',
            alignItems: 'flex-start',
            color: style.palette.black[100],
            fontSize: '12px'
        }
    },
    photoIconStyle: {
        height: '25px',

    },
    commentsInput: {
        '& .css-2bot5r-MuiInputBase-root-MuiOutlinedInput-root': {
            fontSize: '14px',
            color: style.palette.black[100],
        }
    }
};


const LoadingSummery: React.FC = () => {
    const { t } = useTranslation();
    const [isCommentsEditable, setIsCommentsEditable] = useState<boolean>(false);
    const handleAddCommentsClick = () => {
        setIsCommentsEditable(!isCommentsEditable);
    };

    return (
        <Box sx={Styles.summaryBox}>
            <Typography variant="subtitle2" sx={Styles.title}>{t("Summary")}</Typography>
            <Divider sx={Styles.divider} />

            <Formik
                initialValues={{
                    loadingDetails: "GRAPHIPAN CEE 103\n90 m2–25 PACKAGE",
                    comments: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable."
                }}
                onSubmit={(values) => {
                    console.log(values);
                }}
            >
                {({ values, handleChange, handleBlur }) => (
                    <Form>
                        <Grid sx={Styles.sectionHead}>
                            <Box sx={{ ...Styles.section, width: '50%' }}>
                                <Box sx={Styles.sectionHeader}>
                                    <Typography variant="h5">{t("LoadingDetails")}</Typography>
                                </Box>
                                <Field
                                    sx={Styles.detailsInput}
                                    as={TextField}
                                    name="loadingDetails"
                                    multiline
                                    variant="outlined"
                                    fullWidth
                                    value={values.loadingDetails}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{ readOnly: true }}
                                />
                            </Box>

                            <Box sx={{ ...Styles.section, width: '50%' }}>
                                <Box sx={Styles.sectionHeader}>
                                    <Typography variant="h5">{t("LoadingSlip")}</Typography>
                                </Box>
                                <Box sx={Styles.loadingSlips}>
                                    <Typography variant="subtitle1" sx={Styles.loadingSlipBox}>
                                        <img src={IMAGES.pdfIcon} style={Styles.pdfIconStyle} alt="pdf" /> Signed loading form
                                    </Typography>
                                    <Typography variant="subtitle1" sx={Styles.loadingSlipBox}>
                                        <img src={IMAGES.pdfIcon} style={Styles.pdfIconStyle} alt="pdf" />Signed loading form
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Box sx={Styles.section}>
                            <Box sx={Styles.sectionHeader}>
                                <Typography variant="h5">{t("LoadingPhotos")}</Typography>
                            </Box>
                            <Box sx={Styles.loadingPhotos}>
                                <Box sx={Styles.photoBox}>
                                    <img src={IMAGES.photoIcon} style={Styles.photoIconStyle} alt="Photo" />
                                    <Typography variant="body1">Photo</Typography>
                                </Box>
                                <Box sx={Styles.photoBox}>
                                    <img src={IMAGES.photoIcon} style={Styles.photoIconStyle} alt="Photo" />
                                    <Typography variant="body1">Photo</Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={Styles.section}>
                            <Box sx={Styles.sectionHeader}>
                                <Typography variant="h5">{t("Comments")}</Typography>
                                <Typography variant="h6" sx={Styles.commentButton} onClick={handleAddCommentsClick}>
                                    +{t("Add Comments")}
                                </Typography>
                            </Box>
                            <Field
                                sx={Styles.commentsInput}
                                as={TextField}
                                name="comments"
                                multiline
                                variant="outlined"
                                fullWidth
                                value={values.comments}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{ readOnly: !isCommentsEditable }}
                            />
                        </Box>

                        {/* <Box marginTop={2}>
                            <Button type="submit" variant="contained" color="primary">Submit</Button>
                        </Box> */}
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default LoadingSummery;
