import { Box, Grid, Modal } from '@mui/material'
import Heading from '../common/components/Heading'
import { useTranslation } from 'react-i18next';
import ListContainer from '../common/components/ListContainer';
import loadingData from './StaticData/allLoadingList.json';
import { useEffect, useState } from 'react';
import LoagisticsList from './common/components/LoagisticsList';
import LogisticsDetailSection from './common/components/LogisticsDetailSection';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import PopupModal from '../common/components/PopupModal';
import ModalView from '../common/components/ModalView';
import LoadingConfirmRejection from './common/components/LoadingConfirmRejection';

const Styles = {
    fullHeight: {
        height: "100%"
    },
    headerSpacing: {
        padding: "20px 20px 15px 20px"
    }
};

const Loading = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [isConfirmLoading, setIsConfirmLoading] = useState(false);
    const [loadingState, setLoadingState] = useState({
        isOpenCommentModal: false,
        isOpenConfirmValidationModal: false,
        pageHeading: '',
        listHeading: '',
        confirmLoading: false,
        fileUploadModal: false,
        isInvoiceQuotationModalOpen: false,
        confirmFinalValidation: 'Pending',
        isApprovedLoadingPhoto: 'Pending',
        viewLoadingPhotoModal: false,
        confirmFinalLoadingPhotos: false,
        confirmFinalLoadingPhotosReject: false,
        selectedAppointment: ''
    })
    const role = useSelector((state: RootState) => state.userReducer.user.role);
    useEffect(() => {
        setLoadingState((prev) => {
            return {
                ...prev,
                pageHeading: `Logistics${location.pathname}`,
                listHeading: location.pathname === '/unloading' ? 'AllUnloadings' : location.pathname === '/loading' ? 'AllLoadings' :
                    location.pathname === '/delivery' ? 'AllDeliveries' : 'AllReception',
                confirmLoading: role === 'DI-WH' ? false : true
            }
        })
    }, [location.pathname])

    const [loadingProcess, setLoadingProcess] = useState<boolean>(false)
    const [confirmLoadingStatus, setConfirmLoadingStatus] = useState<boolean>(false)
    const handleCancel = () => {
        console.log("handleCancel");

    }

    const handleSubmit = () => {
        console.log("handleSubmit");
        setLoadingProcess(true)
        setLoadingState((prev) => {
            return {
                ...prev,
                confirmLoading: true,
            }
        })
    }

    const handleAddCommentModal = () => {
        setLoadingState((prev) => {
            return {
                ...prev,
                isOpenCommentModal: true
            }
        })
    }

    const handleCloseCommentModal = () => {
        setLoadingState((prev) => {
            return {
                ...prev,
                isOpenCommentModal: false
            }
        })
    }

    const handleCloseConfirmModal = () => {
        setLoadingState((prev) => {
            return {
                ...prev,
                isOpenConfirmValidationModal: false,
            }
        })
    }

    const handleCloseFileUploadModal = () => {
        setLoadingState((prev) => {
            return {
                ...prev,
                fileUploadModal: false
            }
        })
    }

    const handleConfirmLoading = () => {
        setIsConfirmLoading(true)
    }

    const handleValidateComment = () => {
        setLoadingState((prev) => {
            return {
                ...prev,
                isOpenCommentModal: false,
                isOpenConfirmValidationModal: true
            }
        })
    }

    const handleViewInvoice = () => {
        setLoadingState((prev) => {
            return {
                ...prev,
                isInvoiceQuotationModalOpen: true
            }
        })
    }

    const handleUploadInvoice = () => {
        console.log("handleUploadInvoice");
        setLoadingState((prev) => {
            return {
                ...prev,
                fileUploadModal: true
            }
        })

    }

    const handleCloseQuotationModal = () => {
        setLoadingState((prev) => {
            return {
                ...prev,
                isInvoiceQuotationModalOpen: false
            }
        })
    }

    const handleConfirmFinalValidation = () => {
        console.log("handleConfirmFinalValidation");

        setLoadingState((prev) => {
            return {
                ...prev,
                confirmFinalValidation: 'Approved',
                isInvoiceQuotationModalOpen: false
            }
        })
    }

    const handleConfirmLoadingStatus = (value: boolean) => {

        setConfirmLoadingStatus(value)
    }

    const handleLoadingProcess = () => {
        setLoadingProcess(false)
    }

    const handleFinalValidationProcess = () => {
        console.log("handleFinalValidationProcess");
        setLoadingState((prev) => {
            return {
                ...prev,
                confirmFinalValidation: 'Approved',
            }
        })

    }

    const handleViewPhoto = () => {
        setLoadingState((prev) => {
            return {
                ...prev,
                viewLoadingPhotoModal: true
            }
        })
    }

    const handleValidateLoadingPhoto = (value: boolean) => {
        setLoadingState((prev) => {
            return {
                ...prev,
                isApprovedLoadingPhoto: value ? 'Approved' : 'Rejected',
                viewLoadingPhotoModal: false,
                confirmFinalLoadingPhotos: true,

            }
        })
        if (!value) {
            setLoadingState((prev) => {
                return {
                    ...prev,
                    isApprovedLoadingPhoto: 'Rejected',
                    confirmFinalLoadingPhotosReject: true

                }
            })
        }
    }

    const handleLoadingPhotoRejection = (value: boolean) => {
        setLoadingState((prev) => {
            return {
                ...prev,
                confirmFinalLoadingPhotosReject: false,
                viewLoadingPhotoModal: value ? false : true,
            }
        })
    }

    const handleIsConfirmLoading = () => {
        console.log("handleIsConfirmLoading");
    }

    const handleSelectAppointment = (value: string) => {
        if (value) {
            setLoadingState((prev) => {
                return {
                    ...prev,
                    selectedAppointment: value
                }
            })
        }
    }

    useEffect(() => {
        const submitTimeout = setTimeout(() => {
            if (loadingState.confirmFinalLoadingPhotos) {
                setLoadingState((prev) => {
                    return {
                        ...prev,
                        confirmFinalLoadingPhotos: false,
                    }
                })
            }
        }, 2000);
        return () => {
            clearTimeout(submitTimeout)
        }
    }, [loadingState.confirmFinalLoadingPhotos])

    useEffect(() => {
        handleSelectAppointment(loadingData[0].id)
    }, [])

    return (
        <Box sx={{ padding: '20px' }} >
            <Heading title={t(`${loadingState.pageHeading}`)} />
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} md={12} lg={3}>
                    <ListContainer
                        leftContainer={t(`${loadingState.listHeading}`)}
                        sx={Styles.fullHeight}
                        headerStyle={Styles.headerSpacing}
                        childContainerStyle={{ height: '92%' }}
                    >
                        <LoagisticsList
                            loadingData={loadingData}
                            handleSelectAppointment={handleSelectAppointment}
                            selectedAppointment={loadingState.selectedAppointment}
                        />
                    </ListContainer>
                </Grid>
                <Grid item xs={12} md={12} lg={9}>
                    <LogisticsDetailSection
                        selectedAppointment={loadingState.selectedAppointment}
                        loadingProcess={loadingProcess}
                        confirmLoading={loadingState.confirmLoading}
                        handleAddCommentModal={handleAddCommentModal}
                        handleCancel={handleCancel}
                        handleSubmit={handleSubmit}
                        setConfirmLoadingStatus={handleConfirmLoadingStatus}
                        setLoadingProcess={handleLoadingProcess}
                        isOpenCommentModal={loadingState.isOpenCommentModal}
                        handleCloseCommentModal={handleCloseCommentModal}
                        handleValidateComment={handleValidateComment}
                        isOpenConfirmValidationModal={loadingState.isOpenConfirmValidationModal}
                        handleCloseConfirmModal={handleCloseConfirmModal}
                        confirmLoadingStatus={confirmLoadingStatus}
                        handleConfirmLoading={handleConfirmLoading}
                        isConfirmLoading={isConfirmLoading}
                        setIsConfirmLoading={setIsConfirmLoading}
                        handleUploadInvoice={handleUploadInvoice}
                        isAccountFileUpload={loadingState.fileUploadModal}
                        handleCloseFileUploadModal={handleCloseFileUploadModal}
                        handleViewInvoice={handleViewInvoice}
                        isInvoiceQuotationModalOpen={loadingState.isInvoiceQuotationModalOpen}
                        handleCloseQuotationModal={handleCloseQuotationModal}
                        confirmFinalValidation={loadingState.confirmFinalValidation}
                        handleConfirmFinalValidation={handleConfirmFinalValidation}
                        handleIsConfirmLoading={handleIsConfirmLoading}
                        handleFinalValidationProcess={handleFinalValidationProcess}
                        handleUploadProforma={handleUploadInvoice}
                        handleViewPhoto={handleViewPhoto}
                        viewLoadingPhotoModal={loadingState.viewLoadingPhotoModal}
                        handleValidateLoadingPhoto={handleValidateLoadingPhoto}
                        isApprovedLoadingPhoto={loadingState.isApprovedLoadingPhoto}
                    />
                </Grid>
            </Grid>
            {loadingState.confirmFinalLoadingPhotos &&
                <ModalView
                    open={loadingState.confirmFinalLoadingPhotos}
                    handleClose={handleCloseCommentModal}
                >
                    <PopupModal showText={"“Photos” approved successfully !"} />
                </ModalView>
            }
            {loadingState.confirmFinalLoadingPhotosReject &&
                <Modal
                    open={loadingState.confirmFinalLoadingPhotosReject}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <LoadingConfirmRejection handleLoadingPhotoRejection={handleLoadingPhotoRejection} />
                </Modal>
            }
        </Box>
    )
}

export default Loading