import { Box, Grid, Paper, Typography } from '@mui/material';
import { style } from '../../../Utils/themes/styles';
import { IMAGES } from '../../../asset/Dis';

const Styles = {
    root: {
        borderRadius: '12px'
    },
    formSubHeading: {
        fontWeight: "500",
        color: style.palette.TitleColor,
        width: "400px",
        textAlign: "center"
    },
    subHeadParent: {
        display: "flex",
        justifyContent: "center",
        maxWidth:'100% !important'
    },
    popupBox: {
        width: "583px",
        height: "335px",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
    },
    popUpContainer: {
        height: '100%',
        borderRadius: "12px",
    },
    childComponent: {
        overflowY: "auto",
        padding: "0 20px",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        justifyContent: "center",
        gap: "20px"
    },
    bottomParent: {
        display: 'flex',
        justifyContent: 'center',
    },
    bottomHeading: {
        fontSize: "18px",
        color: style.palette.black[300],
        textAlign: 'center',
        width: '370px',
    }
};

interface IPopupProps {
    showText: string;
    bottomText?: string;
}

const PopupModal: React.FC<IPopupProps> = ({
    showText,
    bottomText
}) => {

    return (
        <Box>
            <Grid item xs={6} sx={Styles.popupBox}>
                <Paper sx={{ ...Styles.root, ...Styles.popUpContainer }}>
                    <Box sx={Styles.childComponent}>
                        <Box>
                            <img src={IMAGES.successIcon} alt='success' height='50px' />
                        </Box>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '100%' }, width: '100%', justifyContent: 'center', display: "flex", alignItems: 'center', flexDirection: 'column'
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Grid item xs={6} sx={Styles.subHeadParent}>
                                <Typography variant="h3" sx={Styles.formSubHeading}>{showText}</Typography>
                            </Grid>
                            <Grid item xs={6} sx={Styles.bottomParent}>
                                <Typography variant="h3" sx={Styles.bottomHeading}>{bottomText}</Typography>
                            </Grid>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        </Box>
    );
};

export default PopupModal;
