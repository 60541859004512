import { Box, Typography, Grid, List, ListItem } from '@mui/material'
import { stocksWarehouseTypes } from '../interfaces';

interface IStocksDetails {
    stockDetails?: stocksWarehouseTypes[];
}


const StockListDetails = ({
    stockDetails
}: IStocksDetails) => {
    // console.log(id)
    return (
        <Box>
            <Grid container spacing={""}>
                <Grid item xs={12} sm={6} md={6}>
                    <List>
                        {stockDetails?.slice(0, 5).map((item: stocksWarehouseTypes, index: number) => (
                            <ListItem key={index} sx={{ display: "flex", alignItems: "center" }}>
                                <Box px={1} sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <Typography color="gray" sx={{ textTransform: "capitalize" }}>
                                        {item.name}
                                    </Typography>
                                    <Typography>{item.value}</Typography>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <List>
                        {stockDetails?.slice(5, 10).map((item: stocksWarehouseTypes, index: number) => (
                            <ListItem key={index} sx={{ display: "flex", alignItems: "center" }}>
                                <Box px={1} sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <Typography color="gray" sx={{ textTransform: "capitalize" }}>
                                        {item.name}
                                    </Typography>
                                    <Typography >{item.value}</Typography>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </Box>


    )
}

export default StockListDetails;