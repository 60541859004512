
import Request from "../../../Axios/Instance";

export const SET_LOGIN_FLAG = "SET_LOGIN_FLAG";
export const SET_USER_AUTHTOKEN = "SET_USER_AUTHTOKEN";
export const FETCH_LOGIN = 'FETCH_LOGIN';
export const RECEIVE_FETCH_LOGIN = 'RECEIVE_FETCH_LOGIN';
export const RECEIVE_FETCH_LOGIN_ERROR = 'RECEIVE_FETCH_LOGIN_ERROR';


const fetchLogin = userData => ({
    type: FETCH_LOGIN,
    payload: userData,
});

const receiveLogin = (payload) => {
    return {
        type: RECEIVE_FETCH_LOGIN,
        payload,
    }

}

const setLoginFlag = (value) => {
    return {
      type: SET_LOGIN_FLAG,
      flag: value,
    };
  };

  const setAuthToken = (value) => {
    return {
      type: SET_USER_AUTHTOKEN,
      authToken: value,
    };
  };
  

const receiveLoginError = payload => ({
    type: RECEIVE_FETCH_LOGIN_ERROR,
    payload,
});


export const signIn = credentials => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        dispatch(fetchLogin(credentials));
        console.log("payload", credentials);
        const fetchPost = async () => {
            try {
                const response = await new Request.post(`/user/login`, credentials);
                console.log("response", response);
                dispatch(setLoginFlag(true));
                dispatch(receiveLogin(response?.data?.data));
                dispatch(setAuthToken(response?.data?.data?.auth_token));
                resolve(response);
            } catch (err) {
                dispatch(receiveLoginError(err));
                reject(err);
            }
        }
        fetchPost();
    });
