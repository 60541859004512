
import Request from "../../../Axios/Instance";
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../../store';
import { AxiosResponse } from "axios";
export const FETCH_WAREHOUSE = "FETCH_WAREHOUSE";
export const RECEIVE_WAREHOUSE_INVENTORY = "RECEIVE_WAREHOUSE_INVENTORY";
export const RECEIVE_WAREHOUSE_ERROR = "RECEIVE_WAREHOUSE_ERROR";
export const RECEIVE_WAREHOUSE_NO_DATA = "RECEIVE_WAREHOUSE_NO_DATA";

const fetchWarehouse = () => ({
    type: FETCH_WAREHOUSE
})

export const receiveWarehouseInventory = (data: any) => ({
    type: RECEIVE_WAREHOUSE_INVENTORY,
    payload: data
})

export const receiveWarehouseError = (err: unknown) => ({
    type: RECEIVE_WAREHOUSE_ERROR,
    payload: err
})

export const receiveWarehouseNoData = () => ({ // New action creator
    type: RECEIVE_WAREHOUSE_NO_DATA,
  });

export const getWarehousesInventory = (payload:any): ThunkAction<Promise<AxiosResponse<any>>, RootState, unknown, AnyAction> =>
    (dispatch, getState) =>
        new Promise((resolve, reject) => {
            dispatch(fetchWarehouse);
            const fetchPost = async () => {
                try {
                    const response = await Request.get(`/warehouse/inventory?warehouse_id=${payload}`);
                    dispatch(receiveWarehouseInventory(response.data.data));
                    resolve(response)
                } catch (err:any) {
                    console.log("err",err);
                    if(err.response.data.status === 404){
                        dispatch(receiveWarehouseNoData());
                    }
                    dispatch(receiveWarehouseError(err));
                }

            }
            fetchPost();
        })