import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { style } from '../../../Utils/themes/styles';
import Input from '../../../components/formComponents/input/Input';
import ComButtom from '../../../components/formComponents/button/ComButtom';
import { useTranslation } from 'react-i18next';

const Styles = {
    root:{
        padding:'16px',
        width:'583px'
    },
    textFieldInput: {
        width: '100%',
        '& .MuiInputBase-input': {
            backgroundColor: style.palette.Neutral,
            border: `1px solid ${style.palette.black[50]}`,
            borderRadius: '4px',
            '&:hover': {
                border: `1px solid ${style.palette.black[50]}`,
                borderRadius: '4px',
            }
        },
    },
    formFooter: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-end',
        borderTop: `1px solid ${style.palette.black[50]}`,
        padding: '16px',
        marginBottom: '0px',

    },
    confirmBtn: {
        height: '40px',
        width: '115px'
    },
    alignINput:{
        marginBottom:'12px',
        '& .MuiTypography-h4': {
            fontSize: '12px',
            fontWeight: 500,
            color: style.palette.black[300],
            marginBottom:'8px'
        },
    }
}

interface IQuotationDetailModal {
    handleConfirm?: () => void;
}
const QuotationDetailModal = ({ handleConfirm }: IQuotationDetailModal) => {
    const { t } = useTranslation();
    return (
        <Box sx={Styles.root}>
            <Formik
                initialValues={{
                    quotation_no: '',
                    amount_before_tax: '',
                    vat_amount: '',
                    amount_including_vat: ''
                }}
                onSubmit={(values) => {
                    console.log(values);
                }}
            >
                {({ values, handleChange, handleBlur }) => (
                    <Form>
                        <Grid container spacing={2} sx={Styles.alignINput}>
                            <Grid item xs={6} >
                                <Input
                                    label={t("QuotationNo")}
                                    // required
                                    // touched={touched.quotation_no}
                                    id="date"
                                    value={values.quotation_no}
                                    // type="date"
                                    width={'100%'}
                                    variant='outlined'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    // error={errors.quotation_no}
                                    sx={Styles.textFieldInput}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    label={t("AmountBeforeTax")}
                                    // required
                                    // touched={touched.quotation_no}
                                    id="date"
                                    value={values.quotation_no}
                                    // type="date"
                                    width={'100%'}
                                    variant='outlined'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    // error={errors.quotation_no}
                                    sx={Styles.textFieldInput}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={Styles.alignINput}>
                            <Grid item xs={6}>
                                <Input
                                    label={t("VATAmount")}
                                    // required
                                    // touched={touched.quotation_no}
                                    id="date"
                                    value={values.quotation_no}
                                    // type="date"
                                    width={'100%'}
                                    variant='outlined'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    // error={errors.quotation_no}
                                    sx={Styles.textFieldInput}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    label={t("AmountIncludingVAT")}
                                    // required
                                    // touched={touched.quotation_no}
                                    id="date"
                                    value={values.quotation_no}
                                    // type="date"
                                    width={'100%'}
                                    variant='outlined'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    // error={errors.quotation_no}
                                    sx={Styles.textFieldInput}
                                />
                            </Grid>
                        </Grid>
                        <Box sx={Styles.formFooter}>
                            <ComButtom
                                type='submit'
                                label={t("Confirm")}
                                variant="contained"
                                textVariant="subtitle1"
                                onClick={handleConfirm}
                                inputStyle={Styles.confirmBtn}
                            />
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    )
}

export default QuotationDetailModal