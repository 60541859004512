// LogisticsHistory.tsx
import React from 'react';
import { Box, Grid, Typography, Paper, Divider } from '@mui/material';
import { IMAGES } from '../../../asset/Dis';
import { style } from '../../../Utils/themes/styles';
import { logisticsData } from '../../Dis/common/constant';
// Example data

const deliveries = logisticsData.filter(item => item.type === 'Deliveries');
const loadings = logisticsData.filter(item => item.type === 'Loadings');
const unloadings = logisticsData.filter(item => item.type === 'Unloadings');

const Styles = {
    categoryHead: {
        textAlign: "left",
        color: style.palette.black[ 200 ],
        position: "relative"
    },
    cardHead1: {
        color: style.palette.Primary
    },
    boxHead: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    statusStylePending: {
        color: style.palette.Orange,
        padding: "6px",
        background: style.palette.UnloadingBgColor,
        borderRadius: "1px"
    },
    statusStyleValidated: {
        color: style.palette.Primary,
        padding: "6px",
        background: style.palette.DeliveryBgColor,
        borderRadius: "1px"
    },
    cards: {
        padding: '10px',
        marginBottom: '10px',
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${style.palette.GrayStroke}`,
        boxShadow: "0px 4px 4px 0px #00000005",
    },
    date: {
        alignSelf: "flex-end",
    },
    divider: {
        borderRadius: 0,
        backgroundColor: style.palette.GrayStroke,
        margin: "5px 0",
        width: "calc(100% + 40px)",
        position: "relative",
        left: "-20px",
    },
    miniDivider: {
        borderRadius: 0,
        backgroundColor: style.palette.GrayStroke,
        margin: "5px 0",
        width: "calc(100% + 20px)",
        position: "relative",
        left: "-10px",
    },
    verticalDivider: {
        height: "613px !important",
        width: "1px",
        position: "absolute",
        right: "-13px",
        top: "3px",
        borderRadius: 0,
        backgroundColor: style.palette.GrayStroke,
    },
    fontWeight: {
        fontWeight: "500",
        position: "relative",
        top: "-5px"
    },
    tinyText: {
        fontFamily: "Poppins",
        fontSize: "8px",
        fontWeight: 500,
        lineHeight: "6px",
        textAlign: "left",
    },
    marginTn: {
        marginTop: "10px",
    },
    imgWidth: {
        width: "20px",
    }
};

const LogisticsHistory = () => {
    const renderCards = (data) => (
        data.map((item) => (
            <Paper elevation={ 3 } sx={ Styles.cards } key={ item.id }>
                <Box sx={ Styles.boxHead }>
                    <Typography variant="body1" sx={ Styles.cardHead1 }>{ item.id }</Typography>
                    <Typography
                        sx={ {
                            ... (item.mainStatus === "pending" ? Styles.statusStylePending : Styles.statusStyleValidated),
                            ...Styles.tinyText
                        } }
                    >
                        { item.status }
                    </Typography>

                </Box>
                <Divider sx={ Styles.miniDivider } orientation="horizontal" flexItem />
                <Box sx={ Styles.boxHead }>
                    <Typography variant="body1" color="gray">{ item.distributor }</Typography>
                    <Typography variant="body1">{ item.destination }</Typography>
                </Box>
                <Box sx={ Styles.boxHead }>
                    <Typography variant="body1" color="gray">{ item.enrepot }</Typography>
                    <Typography variant="body1">{ item.enrepotDetails }</Typography>
                </Box>
                <Divider sx={ Styles.miniDivider } orientation="horizontal" flexItem />
                <Box sx={ { ...Styles.boxHead } }>
                    <Typography variant="body1">{ item.loadingStatus }</Typography>
                    <Typography variant="body1">
                        <a href={ item.loadingPdf } target="_blank" rel="noopener noreferrer">
                            <img src={ IMAGES.pdfIcon } alt="PDF" style={ Styles.imgWidth } />
                        </a>
                    </Typography>
                </Box>
                { item.validationStatus && (
                    <Box sx={ { ...Styles.boxHead, ...Styles.marginTn } }>
                        <Typography variant="body1">{ item.validationStatus }</Typography>
                        <Typography variant="body1">
                            <a href={ item.validationPdf } target="_blank" rel="noopener noreferrer">
                                <img src={ IMAGES.pdfIcon } alt="PDF" style={ Styles.imgWidth } />
                            </a>
                        </Typography>
                    </Box>
                ) }
                <Divider sx={ Styles.miniDivider } orientation="horizontal" flexItem />
                <Typography variant="body2" color="gray" sx={ Styles.date }>{ item.date }</Typography>
            </Paper>
        ))
    );

    return (
        <Box sx={ { padding: '20px' } }>
            <Grid container spacing={ 3 } >
                <Grid item xs={ 12 } md={ 12 } lg={ 4 } sx={ Styles.categoryHead } >
                    <Typography variant="h6" sx={ Styles.fontWeight } gutterBottom>Deliveries</Typography>
                    <Divider sx={ Styles.divider } orientation="horizontal" flexItem />
                    <Divider sx={ Styles.verticalDivider } orientation="vertical" flexItem />
                    { renderCards(deliveries) }
                </Grid>
                <Grid item xs={ 12 } md={ 12 } lg={ 4 } sx={ Styles.categoryHead }>
                    <Typography variant="h6" sx={ Styles.fontWeight } gutterBottom>Loadings</Typography>
                    <Divider sx={ Styles.divider } orientation="horizontal" flexItem />
                    <Divider sx={ Styles.verticalDivider } orientation="vertical" flexItem />
                    { renderCards(loadings) }
                </Grid>
                <Grid item xs={ 12 } md={ 12 } lg={ 4 } sx={ Styles.categoryHead }>
                    <Typography variant="h6" sx={ Styles.fontWeight } gutterBottom>Unloadings</Typography>
                    <Divider sx={ Styles.divider } orientation="horizontal" flexItem />
                    { renderCards(unloadings) }
                </Grid>
            </Grid>
        </Box>
    );
};

export default LogisticsHistory;
