import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { Box, Typography, Stack, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ActiveButton from './formComponents/button/ActiveButton';
import Input from './formComponents/input/Input';
import { style } from '../Utils/themes/styles';
import { baseTheme } from '../Utils/themes/base';
import { IMAGES } from '../asset/Dis';
import { COMMON_IMAGES } from '../asset';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { signIn } from '../redux/user/actions/login';
import { useNotification } from '../Utils/NotificationContext';
import { IToastTypes } from './toast/Toast';
import { emailRegEx } from '../constant/common';

const Styles = {
    loginForm: {
        '& .MuiTypography-h2': {
            color: style.palette.TitleColor,
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: '24px'
            },
        },
        '& .MuiTypography-caption': {
            color: style.palette.CellDataNotFoundColor,
            marginTop: '4px',
            fontSize: '10px'
        },

        '& .MuiTypography-h3': {
            color: style.palette.black[200],
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: '20px'
            },
        },
        '& .MuiTypography-subtitle2': {
            color: style.palette.black[500],
            wordWrap: "break-word",
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: '16px'
            },
        }

    },
    forgotPassDescription: {
        padding: '0px 5rem',
    },
    loginBtn: {
        marginTop: '8.688rem',
        [baseTheme.breakpoints.down('lg')]: {
            marginTop: '4.688rem !important',
        },
        [baseTheme.breakpoints.down('xl')]: {
            marginTop: '2.688rem !important',
        },
        [baseTheme.breakpoints.up('xl')]: {
            marginTop: '3rem !important',
        }
    },
    inputMargin: {
        marginTop: '2.5rem',
        [baseTheme.breakpoints.down('lg')]: {
            marginTop: '1rem !important',
        },
        [baseTheme.breakpoints.down('xl')]: {
            marginTop: '1rem !important',
        },
        [baseTheme.breakpoints.up('xl')]: {
            marginTop: '2rem !important',
        }
    },
    endAdornmentIcon: {
        'img': {
            height: '18px !important',
            marginBottom: '0px',
            width: 'auto',
            [baseTheme.breakpoints.up('xl')]: {
                height: '18px !important',
            }
        },
        minWidth:'15px !important'
    },
    errorMessage: {
        display: 'flex',
        alignItems: 'flex-start !important',
        '& .MuiTypography-caption': {
            color: style.palette.CellDataNotFoundColor,
            marginTop: '4px',
            fontSize: '10px'
        },

    }

}



const Login = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const { setNotify } = useNotification();
    const [LoginPageState, setLoginPageState] = useState({
        setForgotPassword: false,
        showPassword: false,
        loginError: ''
    })
    const BaseUrl = process.env.APP_URL;
    console.log("BaseUrl", BaseUrl);
    const handleBack = () => {
        setLoginPageState((prev) => {
            return {
                ...prev,
                setForgotPassword: false,
            }
        })
    }

    const handleLogin = async (values) => {
        // console.log("values", values);
        try {
            const data = await dispatch(signIn(values));
            console.log("data", data);
            if (data) {
                navigate("/home");
                setNotify({
                    open: true,
                    type: IToastTypes.Success,
                    message: data?.data?.msg,
                });
            }
        } catch (err) {
            console.log("error", err);
            setNotify({
                open: true,
                type: IToastTypes.Error,
                message: err?.response?.data?.msg,
            });
        }
    }
    const handleValidate = (values) => {
        const errors = {};

        if (!values.username) {
            errors.username = "Username shouldn't be empty";
        } else if (!emailRegEx.test(values.username)) {
            errors.username = "Please enter valid Username";
        }

        if (!values.password) {
            errors.password = " Password shouldn't be empty";
        }
        return errors;

    }

    const handleShowHidePassword = () => {
        setLoginPageState((prev) => {
            return {
                ...prev,
                showPassword: !LoginPageState.showPassword
            }
        })
    }

    const handleForgotPassword = () => {
        setLoginPageState((prev) => {
            return {
                ...prev,
                setForgotPassword: true,
            }
        })
    }

    const handleSubmitEmail = (values) => {
        console.log("values", values);
    }

    const handleValidateEmail = (values) => {
        console.log("values",values);
        const errors = {};

        if (!values.email) {
            errors.email = "Email should not be empty";
        } else if (!emailRegEx.test(values.email)) {
            errors.email = "Please enter correct Email";
        }

        console.log("errors",errors);
        return errors;
    }
    return (
        <div className='login-root'>
            <Grid container spacing={2}>
                <Grid md={7} sx={{ display: { xs: 'none', md: 'block' }, padding: '0px' }} className="login-sideImage">
                    <img src={COMMON_IMAGES?.LoginImage} alt='' height={"100vh"} width={'100%'} />
                </Grid>
                <Grid xs={12} md={5} className="login-form" sx={Styles.loginForm}>
                    <Box>
                        <img src={COMMON_IMAGES?.hiveLogo} alt='' />
                    </Box>
                    {!LoginPageState?.setForgotPassword &&
                        <div>
                            <Stack>
                                <Typography variant='h2'>{t("Login")}</Typography>
                            </Stack>
                            <Formik
                                initialValues={{
                                    username: '',
                                    password: '',
                                }}
                                onSubmit={handleLogin}
                                validate={handleValidate}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    setFieldValue,
                                }) => {
                                    return (
                                        <Form className='login-fields'>
                                            <Input
                                                label="Email"
                                                id="username"
                                                required
                                                value={values.username}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={errors.username}
                                                touched={touched.username}
                                                placeholder="samuelCooper123@gmail.com"
                                                width={'100%'}
                                                inputstyle={Styles.inputMargin}
                                            />

                                            <Input
                                                label="Password"
                                                id="password"
                                                required
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched.password}
                                                error={errors.password}
                                                placeholder={LoginPageState.showPassword ? "Password" : "*******"}
                                                type={LoginPageState.showPassword ? "text" : "password"}
                                                width={'100%'}
                                                inputstyle={Styles.inputMargin}
                                                InputProps={{
                                                    endAdornment: <Button sx={Styles.endAdornmentIcon} onClick={handleShowHidePassword}>
                                                        {LoginPageState.showPassword ?
                                                            <img src={IMAGES.showPasswordIcon} alt="hide" /> :
                                                            <img src={IMAGES.hidePasswordIcon} alt="hide" />
                                                        }
                                                    </Button>
                                                }}
                                            />
                                            <div>
                                                <label
                                                    className="text-end"
                                                    onClick={handleForgotPassword}
                                                >
                                                    <p
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#ForgotPassModal"
                                                    >
                                                        Forgot Password?
                                                    </p>
                                                </label>
                                            </div>
                                            {/* <Box sx={Styles.errorMessage}>
                                                <Typography variant='caption'>
                                                    {LoginPageState.loginError}
                                                </Typography>
                                            </Box> */}
                                            <ActiveButton label="Login" variant="contained" textVariant="button" boxStyle={Styles.loginBtn} />
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                    }
                    {LoginPageState.setForgotPassword &&
                        <div>
                            <Stack>
                                <Typography variant='h2'>{t("ForgotPasswordHeading")}</Typography>
                                <Typography variant='h3'>{t("ForgotPasswordSubHeading")}</Typography>
                            </Stack>
                            <Box sx={Styles.forgotPassDescription}>
                                <Typography inline variant='subtitle2' align='left'>{t("ForgotPasswordDescription")}</Typography>
                            </Box>
                            <Formik
                                initialValues={{
                                    email: '',
                                }}
                                onSubmit={handleSubmitEmail}
                                validate={handleValidateEmail}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    setFieldValue,
                                }) => {
                                    return (

                                        <Form className='login-fields'>
                                            <Input
                                                label={t("Email")}
                                                id="email"
                                                required
                                                value={values.email}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                placeholder="samuelCooper123@gmail.com"
                                                width={'100%'}
                                                inputstyle={{ marginTop: '2.5rem' }}
                                                touched={touched.email}
                                                error={errors.email}
                                            />
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} >
                                                    <ActiveButton label={"Back"} variant="outlined" textVariant="button" boxStyle={Styles.loginBtn} onClick={handleBack} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <ActiveButton label={t("Submit")} variant="contained" textVariant="button" boxStyle={Styles.loginBtn} />
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                    }
                </Grid>

            </Grid>
        </div>
    )
}

export default Login
