import Request from "../../../Axios/Instance";

export const SIGN_OUT = 'SIGN_OUT';

const signUserOut = () => ({
    type: SIGN_OUT,
});

export const signOut = () => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        console.log("fetchPost");
        const fetchPost = async () => {
            try {
                const response = await Request.get('/user/logout');
                console.log("response", response);
                if (response.status === 200) {
                    resolve(response);
                    dispatch(signUserOut());
                }
            } catch (err) {
                console.log('err', err);
                reject(err);
            }
        }
        fetchPost();
    });
