
import Request from "../../../Axios/Instance";
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../../store';
import { AxiosResponse } from "axios";
export const FETCH_WAREHOUSE = "FETCH_WAREHOUSE";
export const RECEIVE_WAREHOUSE_LOGISTICIAN = "RECEIVE_WAREHOUSE_LOGISTICIAN";
export const RECEIVE_WAREHOUSE_ERROR = "RECEIVE_WAREHOUSE_ERROR";
export const RECEIVE_WAREHOUSE_LOGISTICIAN_NO_DATA = "RECEIVE_WAREHOUSE_LOGISTICIAN_NO_DATA";

const fetchWarehouse = () => ({
    type: FETCH_WAREHOUSE
})

export const receiveWarehouseLogistician = (data: any) => ({
    type: RECEIVE_WAREHOUSE_LOGISTICIAN,
    payload: data
})

export const receiveWarehouseError = (err: unknown) => ({
    type: RECEIVE_WAREHOUSE_ERROR,
    payload: err
})

export const receiveWarehouseLogisticianNoData = () => {
    console.log("receiveWarehouseLogisticianNoData");
    return {
        type: RECEIVE_WAREHOUSE_LOGISTICIAN_NO_DATA,
    }};

    export const getWarehousesLogistician = (payload: any): ThunkAction<Promise<AxiosResponse<any>>, RootState, unknown, AnyAction> =>
        (dispatch, getState) =>
            new Promise((resolve, reject) => {
                dispatch(fetchWarehouse);
                const fetchPost = async () => {
                    try {
                        const response = await Request.get(`/logistician?warehouse_id=${payload}`);
                        dispatch(receiveWarehouseLogistician(response.data.data));
                        resolve(response)
                    } catch (err: any) {
                        console.log("err", err);
                        if (err.response.status === 404) {
                            dispatch(receiveWarehouseLogisticianNoData());
                        }else {
                            dispatch(receiveWarehouseError(err));

                        }
                    }

                }
                fetchPost();
            })