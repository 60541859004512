import { Box, SxProps, Typography } from '@mui/material'
import React from 'react'
import { style } from '../../../Utils/themes/styles';
import { baseTheme } from '../../../Utils/themes/base';

const Styles = {
    root: {
        padding: '11px 12px',
        backgroundColor: style.palette.BtnBgColor,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '8px'
    },
    infoBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: "center",
        '& .MuiTypography-body1': {
            fontSize: "13px",
            [baseTheme.breakpoints.down('lg')]: {
                fontSize: "11px"
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: "11px"
            }
        },
    },
    alignLeft: {
        width: "130px",
        textAlign: "left",
        [baseTheme.breakpoints.down('lg')]: {
            fontSize: "11px"
        },
        [baseTheme.breakpoints.up('lg')]: {
            fontSize: "11px"
        }
    },
    alignStart: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    infoText: {
        wordBreak: 'break-word'
    }
}

interface IDetailsView {
    id?: string;
    title?: string;
    image?: string;
    name?: string;
    assistantName?: string;
    company?: string;
    contact?: string;
    mail?: string;
    address?: string;
    gap?: string | number;
}

const DetailsView = ({
    id,
    name,
    assistantName,
    company, contact, mail, address, gap
}: IDetailsView) => {
    console.log(id)

    const ContainerStyle: SxProps = Styles.alignStart;
    const styledProps = {
        ...(ContainerStyle as SxProps),
        gap: gap,
    };

    return (
        <Box>
            <Box sx={styledProps}>
                {company && (
                    <Box sx={Styles.infoBox}>
                        <Typography color="gray" sx={Styles.alignLeft} variant="subtitle1">Company Name:</Typography>
                        <Typography variant="body1">{company}</Typography>
                    </Box>
                )}
                {name && (
                    <Box sx={Styles.infoBox}>
                        <Typography color="gray" sx={Styles.alignLeft} variant="subtitle1">Manager Name:</Typography>
                        <Typography variant="body1">{name}</Typography>
                    </Box>
                )}
                {assistantName && (
                    <Box sx={Styles.infoBox}>
                        <Typography color="gray" sx={Styles.alignLeft} variant="subtitle1">Assistant Name:</Typography>
                        <Typography variant="body1">{assistantName}</Typography>
                    </Box>
                )}
                {contact && (
                    <Box sx={Styles.infoBox}>
                        <Typography color="gray" sx={Styles.alignLeft} variant="subtitle1">Contact No:</Typography>
                        <Typography variant="body1" sx={Styles.infoText}>{contact}</Typography>
                    </Box>
                )}
                {mail && (
                    <Box sx={Styles.infoBox}>
                        <Typography color="gray" sx={Styles.alignLeft} variant="subtitle1">Mail:</Typography>
                        <Typography variant="body1" sx={Styles.infoText}>{mail}</Typography>
                    </Box>
                )}
                {address && (
                    <Box sx={Styles.infoBox}>
                        <Typography color="gray" sx={Styles.alignLeft} variant="subtitle1">Address:</Typography>
                        <Typography variant="body1">{address}</Typography>
                    </Box>
                )}
            </Box>
        </Box >


    )
}

export default DetailsView;