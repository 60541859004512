import { Box, Checkbox, Chip, Grid, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react'
import Input from '../../../../components/formComponents/input/Input';
import ComButtom from '../../../../components/formComponents/button/ComButtom';
import { style } from '../../../../Utils/themes/styles';
import SelectInput from '../../../../components/formComponents/input/SelectInput';
import { wearhouses } from '../constant';
import { useTranslation } from 'react-i18next';
import ModalView from '../../../common/components/ModalView';
import ConfirmationText from '../../../../components/ConfirmModals/ConfirmationText';

const Styles = {
    root: {
        width: '583px',
    },
    diveder: {
        borderTop: `1px solid ${style.palette.divederColor}`,
    },
    formFields: {
        marginTop: '0px',
        marginBottom: '12px',
        '& .MuiTypography-h4': {
            fontSize: '12px',
            fontWeight: 500,
            color: style.palette.black[300],
        },
        '& .css-1byot4x-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${style.palette.black[50]}`,
        },
        '& .css-1ugih9t-MuiFormControl-root-MuiTextField-root .MuiInputBase-input:hover': {
            border: `1px solid ${style.palette.black[50]}`,
        }
    },
    inputstyle: {
        marginTop: '12px'
    },
    textFieldInput: {
        width: '100%',
        '& .MuiInputBase-input': {
            ...style.typography.h3,
            fontSize: '12px',
            backgroundColor: style.palette.Neutral,
            border: `1px solid ${style.palette.black[50]}`,
            borderRadius: '4px',
            '&:hover': {
                border: `1px solid ${style.palette.black[50]}`,
                borderRadius: '4px',
            }
        }
    },
    heading: {
        marginTop: '12px',
        borderTop: `1px solid ${style.palette.divederColor}`,
        paddingTop: '12px',
        '& .MuiTypography-subTitle1': {
            fontSize: '12px',
            fontWeight: 400,
        },
        '& .MuiTypography-body1': {
            fontSize: '12px',
            fontWeight: 500,
            color: style.palette.green[500]
        }
    },
    selectInputAlign: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-body1': {
            fontSize: '12px',
            fontWeight: 500,
            color: style.palette.black[300]
        },
        '& .MuiCheckbox-root': {
            borderColor: style.palette.black[50]
        },
        '& .MuiTypography-body2': {
            fontSize: '12px',
            fontWeight: 400,
            color: style.palette.black[300]
        }
    },
    addMaterialSection: {
        backgroundColor: style.palette.formSectionBgColor,
        border: `1px solid ${style.palette.formSectionBgColor}`,
        padding: '12px',
        borderRadius: '4px',
        '& .MuiTypography-body2': {
            fontSize: '12px',
            fontWeight: 400,
            color: style.palette.black[300]
        },
    },
    packagingSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    unit: {
        display: 'flex',
        alignItems: 'center'
    },
    addMaterialBtn: {
        height: '28px',
        width: '89px'
    },
    addMaterialFooter: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        paddingTop: '16px',
    },
    cancelMaterial: {
        border: `1px solid ${style.palette.black[50]}`,
        marginRight: '4px',
        borderRadius: '4px',
        backgroundColor: style.palette.Neutral
    },
    loadingDetailSection: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        paddingTop: '12px',
        borderTop: `1px solid ${style.palette.black[50]}`,
        '& .MuiTypography-body1': {
            fontSize: '12px',
            fontWeight: 500,
            color: style.palette.black[300]
        },
        '& .MuiStack-root': {
            marginTop: '12px',
            display: 'flex',
            flexDirection: 'row'
        },
        '& .MuiChip-root': {
            borderRadius: '4px',
            border: 'none',
            backgroundColor: style.palette.BtnBgColor,
            marginLeft: '12px'
        },
        '& .MuiChip-deleteIcon': {
            color: style.palette.DarkRed,
            height: '16.7px'
        },
        '& .MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon': {
            color: style.palette.DarkRed,
            height: '16.7px'
        }
    },
    loadingAppointment: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& .MuiTypography-body1': {
            fontSize: '12px',
            fontWeight: 400,
            color: style.palette.black[200]
        },
        '& .MuiTypography-subTitle1': {
            fontSize: '14px',
            fontWeight: 500,
            color: style.palette.black[300],
            marginTop: '4px'
        },
    },
    confirmBtn: {
        height: '40px',
        width: '115px'
    },
    formFooter: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-end',
        borderTop: `1px solid ${style.palette.black[50]}`,
        padding: '16px',
        marginBottom: '0px',

    }
}

interface FormValues {
    date?: string,
    hours?: string,
    duration?: string,
    warehouse?: string,
    logistician?: string,
    select_Client?: string,
    select_truck?: string,
    truck?: string,
    Manufacturer?: string,
    Material?: string,
    Quantity?: string,
}

interface FormErrors {
    date?: string,
    hours?: string,
    duration?: string,
    warehouse?: string,
    logistician?: string,
    select_Client?: string,
    select_truck?: string,
    truck?: string,
    Manufacturer?: string,
    Material?: string,
    Quantity?: string,
}


interface IUnloadingAppointment {
    handleConfirm?: () => void;
    handleConfirmUnloadingAppointment: () => void;
    appointmentType?: string;
}

const UnloadingAppointment = ({ handleConfirm, handleConfirmUnloadingAppointment }: IUnloadingAppointment) => {
    const { t } = useTranslation();
    const [unloadingAppointmentState, setUnloadingAppointmentState] = useState({
        selectTruck: false,
        definedByClient: false,
        isAuthorizeToCustomerModal: false,
        isSuccessAuthorizeModal: false
    })
    const hadleSubmitUnloadingApponitment = () => {
        console.log("hadleSubmitUnloadingApponitment");
        handleConfirmUnloadingAppointment();
    }

    const handleValidation = (values: FormValues) => {
        const errors: FormErrors = {};

        console.log("values", values);

        if (!values.date) {
            errors.date = "Please fill in Date field";
        }

        if (!values.warehouse) {
            errors.warehouse = "Please fill in Warehouse field";
        }

        if (!values.logistician) {
            errors.logistician = "Please fill in Logistician field";
        }

        if (!values.select_Client) {
            errors.select_Client = "Please fill in Select Client field";
        }
        console.log("errors", errors);

        return errors;
    }

    const handleSelectTructChange = () => {
        if (!unloadingAppointmentState.selectTruck) {
            setUnloadingAppointmentState((prev) => {
                return {
                    ...prev,
                    isAuthorizeToCustomerModal: true
                }
            })
        } else {
            setUnloadingAppointmentState((prev) => {
                return {
                    ...prev,
                    selectTruck: !unloadingAppointmentState.selectTruck
                }
            })
        }

    }

    const handleSelectByClientChange = () => {
        setUnloadingAppointmentState((prev) => {
            return {
                ...prev,
                definedByClient: !unloadingAppointmentState.definedByClient
            }
        })
    }

    const handleSelectChange = (event: SelectChangeEvent<unknown>, setFieldValue: FormikHelpers<unknown>['setFieldValue'], fieldName: string) => {
        setFieldValue(fieldName, event.target.value)
    }

    const handleAddMaterial = () => {
        console.log("handleAddMaterial");

    }

    const handleDelete = () => {
        console.log("handleDelete");

    }

    const handleCloseModal = () => {
        setUnloadingAppointmentState((prev) => {
            return {
                ...prev,
                isAuthorizeToCustomerModal: false,
                isSuccessAuthorizeModal: false
            }
        })
    }

    const handleConfirmSelectTruck = () => {
        setUnloadingAppointmentState((prev) => {
            return {
                ...prev,
                isAuthorizeToCustomerModal: false,
                isSuccessAuthorizeModal: true,
                selectTruck: !unloadingAppointmentState.selectTruck
            }
        })
    }


    return (
        <Box sx={Styles.root}>
            <Formik
                initialValues={{
                    date: '',
                    hours: '',
                    duration: '',
                    warehouse: '',
                    logistician: '',
                    select_Client: '',
                    select_truck: '',
                    truck: '',
                    Manufacturer: '',
                    Material: '',
                    Quantity: '',
                    defined_by_client: '',
                }}
                onSubmit={hadleSubmitUnloadingApponitment}
                validate={handleValidation}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                }) => {
                    return (
                        <Form className='form-fields'>
                            <Box sx={{ ...Styles.heading, border: 'none', margin: '0px' }}>
                                <Typography variant='subtitle1'>{t("Planning")}</Typography>
                            </Box>
                            <Grid container spacing={2} sx={Styles.formFields}>
                                <Grid item xs={3}>
                                    <Stack sx={Styles.loadingAppointment}>
                                        <Typography variant='body1'>{t("LoadingNumber")}</Typography>
                                        <Typography variant='subtitle1'>CHT-568955</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={3}>
                                    <Stack sx={Styles.loadingAppointment}>
                                        <Typography variant='body1'>{t("Distributor")}</Typography>
                                        <Typography variant='subtitle1'>2L Distribution</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={3}>
                                    <Stack sx={Styles.loadingAppointment}>
                                        <Typography variant='body1'>{t("Client")}</Typography>
                                        <Typography variant='subtitle1'>-</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={3}>
                                    <Stack sx={Styles.loadingAppointment}>
                                        <Typography variant='body1'>{t("Truck")}</Typography>
                                        <Typography variant='subtitle1'>-</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} sx={Styles.formFields}>
                                <Grid item xs={6}>
                                    <Input
                                        label={t("Date")}
                                        required
                                        touched={touched.date}
                                        id="date"
                                        value={values.date}
                                        type="date"
                                        width={'100%'}
                                        variant='outlined'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={errors.date}
                                        sx={Styles.textFieldInput}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Input
                                        label={t("Hours")}
                                        id="hours"
                                        placeholder="*******"
                                        value={values.hours}
                                        type="time"
                                        width={'100%'}
                                        variant='outlined'
                                        onChange={handleChange}
                                        sx={Styles.textFieldInput}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={Styles.formFields}>
                                <Grid item xs={6}>
                                    <Input
                                        label={t("Duration")}
                                        id="duration"
                                        type="time"
                                        value={values.duration}
                                        width={'100%'}
                                        variant='outlined'
                                        onChange={handleChange}
                                        sx={Styles.textFieldInput}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Input
                                        label="time"
                                        id="duration"
                                        placeholder="*******"
                                        value={values.duration}
                                        type="time"
                                        width={'100%'}
                                        variant='outlined'
                                        onChange={handleChange}
                                        sx={Styles.textFieldInput}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={Styles.heading}>
                                <Typography variant='subtitle1'>Unloading Location</Typography>
                            </Box>
                            <Grid container spacing={2} sx={Styles.formFields}>
                                <Grid item xs={6}>
                                    <SelectInput
                                        label={t("Warehouse")}
                                        required
                                        value={values.warehouse}
                                        touched={touched.warehouse}
                                        id="warehouse"
                                        options={wearhouses}
                                        onBlur={handleBlur}
                                        error={errors.warehouse}
                                        onChange={(e) => handleSelectChange(e, setFieldValue, "warehouse")}
                                        sx={Styles.textFieldInput}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        label={t("Logistician")}
                                        required
                                        id="logistician"
                                        touched={touched.logistician}
                                        value={values.logistician}
                                        error={errors.logistician}
                                        options={wearhouses}
                                        onBlur={handleBlur}
                                        onChange={(e) => handleSelectChange(e, setFieldValue, "logistician")}
                                        sx={Styles.textFieldInput}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} sx={Styles.diveder}>
                                <Grid item xs={12}>
                                    <Box sx={Styles.selectInputAlign}>
                                        <Typography variant='body1'>{t("SelectTruck")}</Typography>
                                        <Checkbox
                                            id="select_truck"
                                            value={values.select_truck}
                                            checked={unloadingAppointmentState.selectTruck}
                                            onChange={handleSelectTructChange}
                                        />
                                        <Typography variant='body2'>{t("TobeDefinedbyCustomer")}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={Styles.formFields}>
                                <Grid item xs={12}>
                                    <SelectInput
                                        label={t("Truck")}
                                        id="truck"
                                        value={values.truck}
                                        options={wearhouses}
                                        onChange={(e) => handleSelectChange(e, setFieldValue, "truck")}
                                        sx={Styles.textFieldInput}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={Styles.diveder}>
                                <Grid item xs={12}>
                                    <Box sx={Styles.selectInputAlign}>
                                        {/* <Typography variant='body1'>{t("SelectClient")}</Typography> */}
                                        <Checkbox
                                            id="defined_by_client"
                                            value={values.defined_by_client}
                                            checked={unloadingAppointmentState.definedByClient}
                                            onChange={handleSelectByClientChange}
                                        />
                                        <Typography variant='body2'>{t("TobeDefinedbyClient")}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            {unloadingAppointmentState.definedByClient &&
                                <Grid container spacing={2} sx={{ ...Styles.formFields, ...Styles.diveder }}>
                                    <Grid item xs={12}>
                                        <SelectInput
                                            label={t("SelectClient")}
                                            required
                                            id="select_Client"
                                            touched={touched.select_Client}
                                            value={values.select_Client}
                                            error={errors.select_Client}
                                            options={wearhouses}
                                            onBlur={handleBlur}
                                            onChange={(e) => handleSelectChange(e, setFieldValue, "select_Client")}
                                            sx={Styles.textFieldInput}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            <Box sx={Styles.addMaterialSection}>
                                <Typography variant='body2'>{t("AddMaterial")}</Typography>
                                <Grid container spacing={2} sx={Styles.formFields}>
                                    <Grid item xs={6}>
                                        <SelectInput
                                            label={t("Manufacturer")}
                                            id="Manufacturer"
                                            value={values.Manufacturer}
                                            options={wearhouses}
                                            onChange={(e) => handleSelectChange(e, setFieldValue, "Manufacturer")}
                                            sx={Styles.textFieldInput}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SelectInput
                                            label={t("Material")}
                                            id="Material"
                                            value={values.Material}
                                            options={wearhouses}
                                            onChange={(e) => handleSelectChange(e, setFieldValue, "Material")}
                                            sx={Styles.textFieldInput}
                                        />
                                    </Grid>
                                </Grid>
                                <Stack sx={Styles.packagingSection}>
                                    <Typography variant='subtitle1'>{t("Packaging")} :</Typography>
                                    <Typography variant='subtitle1'>3,6m2 / {t("PackageUnit")}</Typography>
                                </Stack>
                                <Grid container spacing={2} sx={{ ...Styles.formFields, ...Styles.unit }}>
                                    <Grid item xs={11}>
                                        <SelectInput
                                            label={t("Quantity")}
                                            id="Quantity"
                                            value={values.Quantity}
                                            options={wearhouses}
                                            onChange={(e) => handleSelectChange(e, setFieldValue, "Quantity")}
                                            sx={Styles.textFieldInput}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography variant='body2'>{t("Unit")}</Typography>
                                    </Grid>

                                </Grid>
                                <Box sx={Styles.addMaterialFooter}>
                                    <ComButtom
                                        label={t("Cancel")}
                                        variant="outlined"
                                        textVariant="subtitle1"
                                        onClick={handleAddMaterial}
                                        inputStyle={{ ...Styles.addMaterialBtn, ...Styles.cancelMaterial }}
                                    />
                                    <ComButtom
                                        label={t("Add")}
                                        variant="contained"
                                        textVariant="subtitle1"
                                        onClick={handleAddMaterial}
                                        inputStyle={Styles.addMaterialBtn}
                                    />
                                </Box>
                            </Box>
                            <Box sx={Styles.heading}>
                                <Typography variant='body1'>{t("QuantitieswillbedeductedfromTruckStock")}</Typography>
                            </Box>
                            <Box sx={Styles.loadingDetailSection}>
                                <Typography variant='body1'>
                                    {t("LoadingDetailsMinimum1")}
                                </Typography>
                                <Stack>
                                    <Chip
                                        label={t("GraphipanUNIT")}
                                        variant="outlined"
                                        // onClick={handleClick}
                                        onDelete={handleDelete}
                                    />
                                    <Chip
                                        label="Airwell Mono 12kg 6 UNIT"
                                        variant="outlined"
                                        // onClick={handleClick}
                                        onDelete={handleDelete}
                                    />
                                </Stack>
                            </Box>
                            <Box sx={Styles.formFooter}>
                                <ComButtom
                                    type='submit'
                                    label={t("Confirm")}
                                    variant="contained"
                                    textVariant="subtitle1"
                                    onClick={handleConfirm}
                                    inputStyle={Styles.confirmBtn}
                                />
                            </Box>
                        </Form>
                    )
                }}
            </Formik>

            {unloadingAppointmentState.isAuthorizeToCustomerModal &&
                <ModalView
                    open={unloadingAppointmentState.isAuthorizeToCustomerModal}
                    handleClose={handleCloseModal}
                >
                    <ConfirmationText
                        confirmationMessage={"Your customer can assign one of his trucks to the Logistics appointment."}
                        infoText={`To authorize access to your Customer, click on "Continue"`}
                        handleCloseModal={handleCloseModal}
                        handleConfirm={handleConfirmSelectTruck}
                    />
                </ModalView>
            }
            {unloadingAppointmentState.isSuccessAuthorizeModal &&
                <ModalView
                    open={unloadingAppointmentState.isSuccessAuthorizeModal}
                    handleClose={handleCloseModal}
                >
                    <ConfirmationText
                        success
                        confirmationMessage={"Your customer can now assign a Truck to the Logistics . A notification has been sent."}
                        handleCloseModal={handleCloseModal}
                    />
                </ModalView>
            }
        </Box>
    )
}

export default UnloadingAppointment