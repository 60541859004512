import React from 'react'
import { style } from '../../../../Utils/themes/styles';
import { baseTheme } from '../../../../Utils/themes/base';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { IMAGES } from '../../../../asset/Dis';
import ComButtom from '../../../../components/formComponents/button/ComButtom';
import { useTranslation } from 'react-i18next';

const Styles = {
    root: {
        borderRadius: '12px',
        width: "583px",
        height: "335px",
    },
    header: {
        padding: '11px 0px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        'img': {
            marginRight: '12px',
        }
    },
    leftContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    children: {
        padding: '20px',
        height: '100%',
        overflowY: "auto"
    },
    formSubHeading: {
        fontWeight: "500",
        color: style.palette.TitleColor,
        width: "400px",
        textAlign: "center"
    },
    subHeadParent: {
        display: "flex",
        justifyContent: "center"
    },
    formHeading: {
        margin: "10px 0 0 0",
        textAlign: "left",
        fontSize: "14px",
        color: style.palette.black[300],
    },
    buttonBoxStyle: {
        position: "relative",
        top: "20px",
        marginRight: '4px'
    },
    buttonStyle: {
        height: "60px",
        width: '220px',
        '& .css-m7i2ec-MuiTypography-root': {
            fontSize: "20px",
            fontFamily: 'Poppins',
            fontWeight: "600"
        },
        border: `1px solid ${style.palette.black[300]}`,
        borderRadius: "8px",
        [baseTheme.breakpoints.down('lg')]: {
            width: '340px',
        },
    },
    divider: {
        borderRadius: 0,
        backgroundColor: style.palette.GrayStroke,
        marginTop: "15px",
        width: "570px",
        position: "relative",
        left: "-20px"
    },
    inputText: {
        '& .MuiInputBase-input': {
            padding: "8px 12px"
        },
        '& div': {
            height: "36px"
        }
    },
    subHeading2: {
        width: "auto !important",
        margin: "0",
        position: "relative",
        top: "42px",
        fontSize: "12px",
        color: style.palette.black[300]
    },
    editBtn: {
        display: "flex",
        justifyContent: "center",
        gap: "10px",
        marginTop: "40px"
    },
};

interface ILoadingConfirmRejection {
    handleLoadingPhotoRejection: (value: boolean) => void;
}

const LoadingConfirmRejection = ({ handleLoadingPhotoRejection }: ILoadingConfirmRejection) => {
    const { t } = useTranslation();
    return (
        <Paper sx={Styles.root}>

            <Box sx={Styles.children}>
                <Grid container sx={{ ...Styles.header }}>
                    <Grid sx={Styles.rightContainer}>
                        <img src={IMAGES.crossIcon} alt='search' height='36px' onClick={()=>handleLoadingPhotoRejection(false)} />
                    </Grid>
                </Grid>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '100%' }, display: "flex", justifyContent: 'center'
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Grid item xs={6} sx={Styles.subHeadParent}>
                        <Typography variant="h3" sx={Styles.formSubHeading}>{`Are you sure do you want to reject this “Photos” ?`}</Typography>
                    </Grid>
                </Box>
                <Grid sx={Styles.rightContainer}>
                    <Grid sx={Styles.editBtn}>
                        <ComButtom
                            label={"No"}
                            variant={"outlined"}
                            textVariant="subtitle1"
                            inputStyle={Styles.buttonStyle}
                            onClick={() => handleLoadingPhotoRejection(false)}
                        />
                        <ComButtom
                            label={"Yes"}
                            variant={"contained"}
                            textVariant="subtitle1"
                            inputStyle={Styles.buttonStyle}
                            onClick={() => handleLoadingPhotoRejection(true)}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )
}

export default LoadingConfirmRejection