import { ADD_WAREHOUSE_LOGISTICIAN } from "../actions/addLogistician";
import { RECEIVE_WAREHOUSE_INVENTORY, RECEIVE_WAREHOUSE_NO_DATA } from "../actions/getInventory";
import { RECEIVE_WAREHOUSE_LOGISTICIAN, RECEIVE_WAREHOUSE_LOGISTICIAN_NO_DATA } from "../actions/getLogistician";
import { FETCH_WAREHOUSE, RECEIVE_WAREHOUSE_LIST } from "../actions/getWarehouse"
import { RECEIVE_WAREHOUSE_UPDATE } from "../actions/updateWarehouse";


const INITIAL_STATE = {
    warehouses: [],
    warehouseInventory: {
        data: [],
        error: null,
        noData: false,
    },
    warehouseLogistician:{
        data: [],
        error: null,
        noData: false,
    }

}

const warehouseReducer = (state = INITIAL_STATE, action: any) => {
console.log("action",action);

    switch (action.type) {
        case FETCH_WAREHOUSE:
            return {
                ...state,
                warehouses: [
                    ...state.warehouses,
                ],
            }

        case RECEIVE_WAREHOUSE_LIST:
            return {
                ...state,
                warehouses: [
                    ...action.payload
                ],
            }

        case RECEIVE_WAREHOUSE_UPDATE:
            return {
                ...state,
                warehouses:[
                    ...action.payload
                ],
            }

        case RECEIVE_WAREHOUSE_INVENTORY:
            return {
                ...state,
                warehouseInventory: {
                    data: [
                        ...action.payload
                    ],
                    error: null,
                    noData: false,
                }

            }

        case RECEIVE_WAREHOUSE_NO_DATA: {
            return {
                ...state,
                warehouseInventory: {
                    data: [],
                    loading: false,
                    noData: true,
                }

            };
        }

        case RECEIVE_WAREHOUSE_LOGISTICIAN:{
            return {
                ...state,
                warehouseLogistician: {
                    data: [
                        ...action.payload
                    ],
                    loading: false,
                    noData: true,
                }

            };
        }

        case RECEIVE_WAREHOUSE_LOGISTICIAN_NO_DATA:{
            return {
                ...state,
                warehouseLogistician: {
                    data: [],
                    loading: false,
                    noData: true,
                }

            };
        }

        default:
            return state;
    }
};

export default warehouseReducer;