import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react'
import ListView from '../../../common/components/ListView';
import { useTranslation } from 'react-i18next';
import { style } from '../../../../Utils/themes/styles';
import { AllWarehouseDataTypes, appointmentType, logistiqueType } from '../../../common/interfaces';
import { baseTheme } from '../../../../Utils/themes/base';

const Styles = {
    accordian: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {
            background: style.palette.ActiveBgColor,
            '& .MuiTypography-root': {
                color: style.palette.green[500]
            },
            '& .MuiSvgIcon-root': {
                color: style.palette.green[500]
            }
        },
        '& .MuiAccordionSummary-content': {
            margin: '11px 0px'
        },
        '& .MuiAccordion-root': {
            marginBottom: '10px',
            background: style.palette.BgColor,
            border: 'none',
            boxShadow: "none",
            position: 'unset',
        },
        '& .MuiCollapse-wrapper': {
            background: style.palette.Neutral
        },
        '& .MuiAccordionDetails-root': {
            padding: '8px 0px 0px'
        }

    },
    AccordianHeading: {
        ...style.typography.subtitle1,
        fontSize: '14px',
        '& .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {
            color: style.palette.green[500]
        },
        [baseTheme.breakpoints.down('lg')]: {
            fontSize: '12px',
        },
        [baseTheme.breakpoints.up('lg')]: {
            fontSize: '12px',
        }
    },
}

interface IAllLogistique {
    AllWarehouseData: AllWarehouseDataTypes
}

const AllLogistique = ({ AllWarehouseData }: IAllLogistique) => {
    const { t } = useTranslation();
    return (
        <Box sx={Styles.accordian}>
            <Stack>
                {
                    AllWarehouseData[0]?.logistique.map((item: logistiqueType) => {
                        return (
                            <Accordion key={`${item.id}`}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id={`${item.id}`}
                                >
                                    <Typography sx={Styles.AccordianHeading}>{t(`${item.title}`)}</Typography>
                                </AccordionSummary>
                                {item?.appointments && item.appointments.map((apoint: appointmentType) => {
                                    return (
                                        <AccordionDetails key={`${apoint.id}`}>
                                            <ListView
                                                title={t(`${apoint?.title}`)}
                                                subTitle={apoint?.subTitle}
                                                wearhouse={t(`${apoint?.wearhouse}`)}
                                                image={apoint?.image}
                                            />
                                        </AccordionDetails>
                                    )
                                })}

                            </Accordion>
                        )
                    })
                }
            </Stack>

        </Box>
    )
}

export default AllLogistique