import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { style } from '../../../../Utils/themes/styles';
import { useTranslation } from 'react-i18next';
import ComButtom from '../../../../components/formComponents/button/ComButtom';
import { IMAGES } from '../../../../asset/Dis';
import PopupModal from '../../../common/components/PopupModal';
import { baseTheme } from '../../../../Utils/themes/base';

const Styles = {
    root: {
        borderRadius: '12px'
    },
    header: {
        padding: '28px 20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        'img': {
            marginRight: '12px',
        }
    },
    component: {},
    leftContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    rightContainer: {
        height: '24px',
        textAlign: "right"
    },
    children: {
        padding: '20px',
        height: '100%',
        overflowY: "auto"
    },
    formSubHeading: {
        fontWeight: "500",
        color: style.palette.TitleColor,
        width: "400px",
        textAlign: "center"
    },
    subHeadParent: {
        display: "flex",
        justifyContent: "center"
    },
    formHeading: {
        margin: "10px 0 0 0",
        textAlign: "left",
        fontSize: "14px",
        color: style.palette.black[300],
    },
    buttonBoxStyle: {
        position: "relative",
        top: "20px",
        marginRight: '4px'
    },
    buttonStyle: {
        height: "60px",
        width: '440px',
        '& .css-m7i2ec-MuiTypography-root': {
            fontSize: "24px",
            fontFamily: 'Poppins',
            fontWeight: "600"
        },
        border: `1px solid ${style.palette.black[300]}`,
        borderRadius: "8px",
        [baseTheme.breakpoints.down('lg')]: {
            width: '340px',
        },
    },
    divider: {
        borderRadius: 0,
        backgroundColor: style.palette.GrayStroke,
        marginTop: "15px",
        width: "570px",
        position: "relative",
        left: "-20px"
    },
    inputText: {
        '& .MuiInputBase-input': {
            padding: "8px 12px"
        },
        '& div': {
            height: "36px"
        }
    },
    subHeading2: {
        width: "auto !important",
        margin: "0",
        position: "relative",
        top: "42px",
        fontSize: "12px",
        color: style.palette.black[300]
    },
    editBtn: {
        display: "flex",
        justifyContent: "center",
        gap: "10px",
        marginTop: "40px"
    },
    popupBox: {
        width: "583px",
        height: "335px",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
    },
    popUpContainer: {
        height: '100%',
        borderRadius: "12px",
    },
    childComponent: {
        height: '81%',
        padding: "0 20px",
        overflowX: "hidden"
    }
};

interface IFormContainerProps {
    setConfirmLoadingStatus: (value: boolean) => void;
    setLoadingProcess?: (value: boolean) => void;
    handleConfirmLoading?: (value: boolean) => void;
    finalValidation?: boolean;
}

const LoadingConfirmationBox: React.FC<IFormContainerProps> = ({
    setConfirmLoadingStatus, setLoadingProcess, handleConfirmLoading, finalValidation
}) => {
    const [confirmationBoxState, setConfirmationBoxState] = useState({
        submit: false,
        confirmFinalLoading: false
    })
    const handleCancel = () => {
        console.log("cancel edit")
        setConfirmLoadingStatus(false);
    }
    const handleSubmit = () => {
        console.log("update edit")
        if (finalValidation) {
            setConfirmationBoxState((prev) => {
                return {
                    ...prev,
                    confirmFinalLoading: true,
                }
            })
        } else {
            setConfirmationBoxState((prev) => {
                return {
                    ...prev,
                    submit: true,
                }
            })
        }

    }
    useEffect(() => {
        const submitTimeout = setTimeout(() => {
            if (confirmationBoxState.submit || confirmationBoxState.confirmFinalLoading) {
                setConfirmationBoxState((prev) => {
                    return {
                        ...prev,
                        submit: false,
                    }
                })
                setConfirmLoadingStatus(false);
                setLoadingProcess && setLoadingProcess(false);
                handleConfirmLoading && handleConfirmLoading(false);
            }
        }, 2000);
        return () => {
            clearTimeout(submitTimeout)
        }
    }, [confirmationBoxState.submit, confirmationBoxState.confirmFinalLoading])
    const { t } = useTranslation();

    return (
        <>
            {!confirmationBoxState.submit && <Box>
                <Grid item xs={6} sx={Styles.popupBox}>
                    <Paper sx={{ ...Styles.root, ...Styles.popUpContainer }}>
                        <Grid container sx={{ ...Styles.header }}>
                            <Grid sx={Styles.rightContainer}>
                                <img src={IMAGES.crossIcon} alt='search' height='36px' onClick={handleCancel} />
                            </Grid>
                        </Grid>
                        <Box sx={{ ...Styles.children, ...Styles.childComponent }}>
                            <Box sx={Styles.component}>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { m: 1, width: '100%' }, display: "flex", justifyContent: 'center'
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Grid item xs={6} sx={Styles.subHeadParent}>
                                        <Typography variant="h3" sx={Styles.formSubHeading}>{t("ToConfirmTheLoadClickOnConfirm")}</Typography>
                                    </Grid>
                                </Box>
                            </Box>
                            <Grid sx={Styles.rightContainer}>
                                <Grid sx={Styles.editBtn}>
                                    <ComButtom
                                        label={t("Confirm")}
                                        variant={"contained"}
                                        textVariant="subtitle1"
                                        inputStyle={Styles.buttonStyle}
                                        onClick={handleSubmit}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            </Box>
            }
            {confirmationBoxState.submit && <PopupModal showText={t("LoadingInformationIsConfirmed")} bottomText={t("TheUploadWillBeClosedOnceItHasBeenValidatedByTheBackOffice")} />}
            {confirmationBoxState.confirmFinalLoading && <PopupModal showText={"Loading has been validated "} />}
        </>
    );
};

export default LoadingConfirmationBox;
