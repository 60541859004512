const IMAGES = {
    DisLogo: require('./images/DisRoleIcon.png'),
    HomeIcon: require('./images/Home.png'),
    AgendaIcon: require('./images/Agenda.png'),
    WarehouseIcon: require('./images/Warehouse.png'),
    LogisticsIcon: require('./images/Logistics.png',),
    SettingsIcon: require('./images/Settings.png'),
    BellIcon: require('./images/BellIcon.png'),
    HeaderBgImage: require('./images/header-bg-img.png'),
    Hamburger: require('./images/hamburger.png'),
    ENGLang: require('./images/eng-lang.png'),
    DistributerProfile: require('./images/distrubuter-profile.png'),
    ExpandLessIcon: require('./images/ExpandLess.png'),
    ExpandMoreIcon: require('./images/ExpandMore.png'),
    GlobalSearchIcon: require('./images/globalSearchIcon.png'),
    LineImage: require('./images/lineImg.png'),
    SearchFilesInProgressIcon: require('./images/searchFileInProgressIcon.png'),
    GraphQueIcon: require('./images/graphqueIcon.png'),
    CourseIcon: require('./images/courseIcon.png'),
    WearhouseQuickAccessIcon: require('./images/WearhouseQAIcon.png'),
    LogistitianQuickAccessIcon: require('./images/LogisticiansQAIcon.png'),
    PartnerQuickAccessIcon: require('./images/PartnerQAIcon.png'),
    AppointmentQuickAccessIcon: require('./images/AppointmentQAIcon.png'),
    ExportQuickAccessIcon: require('./images/ExportQAIcon.png'),
    LogQuickAccessIcon: require('./images/LogQAIcon.png'),
    MoreIconHorizontal: require('./images/moreIconHorizontal.png'),
    CalendarIcon: require('./images/calenderIcon.png'),
    ViewIcon: require('./images/ViewIcon.png'),
    EditIcon: require('./images/EditIcon.png'),
    TruckIcon: require('./images/TruckIcon.png'),
    dropdownArrow: require('./images/dropdownArrow.png'),
    downDropdownArrow: require('./images/downDropdownIcon.png'),
    eventTitleLineImg: require('./images/eventTitleLine.png'),
    watchIcon: require('./images/watchIcon.png'),
    DeleteIcon: require('./images/delete.png'),
    crossIcon: require('./images/crossIcon.png'),
    locationIcon: require('./images/locationIcon.png'),
    grayColoredCalenderIcon: require('./images/grayColoredCalenderIcon.png'),
    backIcon: require('./images/backIcon.png'),
    pdfIcon: require('./images/pdfIcon.png'),
    logisticianIcon: require('./images/logisticianIcon.png'),
    successIcon: require('./images/successIcon.png'),
    editIcon2: require('./images/editIcon2.png'),
    photoIcon: require('./images/photoIcon.png'),
    cancelfileIcon: require('./images/cancelfileIcon.png'),
    showPasswordIcon: require('./images/showPasswordIcon.png'),
    hidePasswordIcon: require('./images/hidePasswordIcon.png'),
    langDownArrow: require('./images/langDownArrow.png'),
    langUpArrow: require('./images/langUpArrow.png'),
    ApprovedIcon: require('./images/ApprovedIcon.png'),
    RejectIcon: require('./images/RejectIcon.png'),
    dividerIcon:require('./images/dividerIcon.png'),
    loadingPhoto:require('./images/loadingPhoto.png'),
    userProfileIcon:require('./images/userProfileIcon.png'),
    logoutIcon:require('./images/logoutIcon.png')

}

export { IMAGES }