import { Box, FilledInputProps, InputProps, OutlinedInputProps, SxProps, TextField, TextFieldVariants, Typography } from '@mui/material'
import React from 'react';
import { style } from '../../../Utils/themes/styles';
import { styled } from '@mui/system';
import { FormikTouched } from 'formik';
import { baseTheme } from '../../../Utils/themes/base';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: ' flex-start',
        width: '100%',
        '& .MuiTextFieldRoot': {
            width: '100%'
        },
        '& .MuiTypography-h4': {
            color: style.palette.black[300]
        }
    },
    input: (error?: string) => ({
        '& .MuiOutlinedInpuRoot': {
            display: 'flex',
            width: '100%',
            padding: '12px 22px !important',
            alignItems: 'center',

            borderRadius: '8px',
            border: `1px solid ${error ? '#D3393D' : style.palette.black[50]}`,
            background: ' var(--Neutral, #FFF)',
        },
        '& .MuiOutlinedInput-input':{
            padding: '12px 22px !important',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':{
            border:`1px solid ${error ? '#D3393D' : style.palette.black[50]} !important`,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${error ? '#D3393D' : style.palette.black[50]}`,
        },

        '& .MuiInputBase-input': {
            ...style.typography.h4,
            color: style.palette.black[300],

            [baseTheme.breakpoints.down('lg')]: {
                ...style.typography.h5,
                fontSize:'12px'
            },

            [baseTheme.breakpoints.down('xl')]: {
                ...style.typography.h5,
                fontSize:'12px'
            },

            [baseTheme.breakpoints.up('xl')]: {
                ...style.typography.h5,
                fontSize:'12px'
            }
        }


    }),
    errorMessage: {
        ...style.typography.body2,
        fontSize:'12px !important',
        color: `#D3393D !important`,
        marginTop: '4px',
    }
}

interface IInput {
    id?: string;
    type?: string;
    required?: boolean;
    label?: string;
    defaultValue?: string;
    InputProps?: Partial<OutlinedInputProps> | Partial<FilledInputProps> | Partial<InputProps> ;
    variant?: TextFieldVariants;
    autoComplete?: string;
    inputstyle?:SxProps | React.CSSProperties | undefined;
    placeholder?: string;
    sx?: SxProps;
    width?: string;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    error?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    value?: unknown;
    touched?:FormikTouched<unknown>;
}

const RequiredTypography = styled(Typography)({
    '&::after': {
        content: '" *"',
        color: 'red',
    },
});


const Input = ({
    id,
    type,
    required,
    label,
    defaultValue,
    InputProps,
    variant,
    autoComplete,
    inputstyle,
    placeholder,
    sx,
    onBlur,
    error,
    onChange,
    value,
    touched
}: IInput) => {
    const rootStyle: SxProps = styles.root;
    const styledProps = {
        ...(rootStyle as SxProps),
        ...inputstyle,
    };
    return (
        <Box
            sx={styledProps}
        >
            {label && required ? <RequiredTypography variant='h4'>{label}</RequiredTypography> : <Typography variant='h4'>{label}</Typography>}
            <TextField
                id={id}
                type={type}
                value={value}      //number,search,password
                // required={required}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                defaultValue={defaultValue}
                InputProps={InputProps}
                variant={variant}      // contained,standard,outlined
                autoComplete={autoComplete}
                sx={{ ...styles.input(error), ...sx }}
            />
            {error && touched ? (
                <Typography variant="caption" sx={styles.errorMessage}>
                    {error}
                </Typography>
            ) : null}
        </Box>
    )
}

export default Input