import { FormControl, MenuItem, Select, SxProps, Typography } from '@mui/material';
import React, { ReactNode, useEffect } from 'react';
import { style } from '../../../Utils/themes/styles';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

const Styles = {
    selectRoot: (sx: SxProps | undefined) => ({
        ...sx,
        backgroundColor: style.palette.BgColor,
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiOutlinedInput-root-MuiSelect-root': {
            backgroundColor: style.palette.BgColor
        },
        '& .MuiFormControl-root': {
            width: 'none'
        }
    })
}

type CategoryOptionType = {
    title: string;
    value: string;
}[];

type CalenderViewOption = {
    title: string;
    value: string;
}[];

type CalenderViewOptionItem = {
    title: string;
    value: string;
}

type CategoryOptionItem = {
    title: string;
    value: string;
}
type CalendarView = 'dayGridMonth' | 'timeGridWeek' | 'timeGridDay' | 'listWeek';

interface ISelectCategories {
    options?: CategoryOptionType | CalenderViewOption;
    category: string | undefined;
    handleChange?: (data: SelectChangeEvent) => void;
    placeholder?: string;
    sx?: SxProps;
}


const SelectCategories = ({
    options,
    category,
    handleChange,
    placeholder,
    sx
}: ISelectCategories) => {
    const { t } = useTranslation();
    const viewNames: Record<CalendarView, string> = {
        dayGridMonth: 'Month',
        timeGridWeek: 'Week',
        timeGridDay: 'Day',
        listWeek: 'List'
    };



    return (
        <FormControl>
            <Select
                labelId="select-label"
                id="select"
                value={category}
                onChange={handleChange}
                renderValue={(category: ReactNode) => {
                    if (!category) {
                        return <Typography variant='subtitle1'>{placeholder}</Typography>;
                    } else {
                        return <Typography variant='subtitle1'>{category}</Typography>;
                    }
                    return viewNames[t(`${category}`) as CalendarView]
                }}
                displayEmpty
                sx={Styles.selectRoot(sx)}
            >
                {options?.map((item: CategoryOptionItem | CalenderViewOptionItem) => (
                    <MenuItem
                        key={item.title}
                        value={t(`${item.value}`)}
                    >
                        <Typography variant='subtitle1'>{t(`${item.title}`)}</Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectCategories