import { Box } from '@mui/material';
import React from 'react'
import { useEffect } from 'react';

const AgendaMap = () => {
    // const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });
    const getCurrentLocation = () => {
        // if (navigator.geolocation) {
        //     navigator.geolocation.getCurrentPosition((position) => {
        //         setCurrentLocation({
        //             lat: position.coords.latitude,
        //             lng: position.coords.longitude
        //         });
        //     });
        // } else {
        //     alert('Geolocation is not supported by this browser.');
        // }
    };

    useEffect(() => {
        // Function to get current user location
        getCurrentLocation();
    }, []);

    // const MapComponent = withScriptjs(
    //     withGoogleMap((props:any) => (
    //         <GoogleMap
    //             defaultZoom={15}
    //             defaultCenter={{ lat: props.latitude, lng: props.longitude }}
    //         >
    //             <Marker position={{ lat: props.latitude, lng: props.longitude }} />
    //         </GoogleMap>
    //     ))
    // );
    return (
        <Box>
            {/* <MapComponent
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=d6d98f7a399143bd949c60f4f8b33af2&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                latitude={currentLocation.lat}
                longitude={currentLocation.lng}
            /> */}
        </Box>
    )
}

export default AgendaMap