import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { SyntheticEvent, useState } from 'react'
import ListView from '../../../common/components/ListView';
import { useTranslation } from 'react-i18next';
import { style } from '../../../../Utils/themes/styles';
import { AllAppointmentsEntry } from '../../../common/interfaces';
import { baseTheme } from '../../../../Utils/themes/base';

const Styles = {
    accordian: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {
            background: style.palette.ActiveBgColor,
            '& .MuiTypography-root': {
                color: style.palette.green[500]
            },
            '& .MuiSvgIcon-root': {
                color: style.palette.green[500],
                height:'20px',
            }
        },
        '& .MuiCollapse-root' :{
            maxHeight:'300px',
            overflow:'scroll'
        },
        '& .MuiAccordionSummary-root':{
            height:'44px',
        },
        '& .MuiAccordionSummary-content': {
            margin: '11px 0px'
        },
        '& .MuiAccordion-root': {
            marginBottom: '8px',
            background: style.palette.BgColor,
            border: 'none',
            boxShadow: "none",
            position: 'unset',
        },
        '& .MuiCollapse-wrapper': {
            background: style.palette.Neutral
        },
        '& .MuiAccordionDetails-root': {
            padding: '8px 0px 0px'
        }

    },
    AccordianHeading: {
        ...style.typography.subtitle1,
        fontSize: '14px',
        [baseTheme.breakpoints.down('lg')]: {
            fontSize: '12px',
        },
        [baseTheme.breakpoints.up('lg')]: {
            fontSize: '12px',
        },
        '& .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {
            color: style.palette.green[500]
        }
    },
    buttonStyle: {
    }
}

interface IAllAppointments {
    AllAppointmentsData:AllAppointmentsEntry[];
    handleCreateAppointment?:()=>void;
    
}

const AllAppointments = ({ AllAppointmentsData}:IAllAppointments) => {
    const { t } = useTranslation();
    const initialCount = 2;
    const [allAppointmentState,setAllAppointmentState] = useState({
        initialAppointments : 2,
        showAll:false,
    })

    const [expanded, setExpanded] = React.useState<number | null>(null);
   
    const handleViewAllClick = () =>{
        console.log("handleViewAllClick");
        setAllAppointmentState((prev)=>{
            return {
                ...prev,
                showAll:!allAppointmentState.showAll
            }
        })
    }

    const handleChange = (id: number) => (event:SyntheticEvent<Element, Event>, isExpanded: boolean) => {
        setExpanded(isExpanded ? id : null);
      };

   
    return (
        <Box sx={Styles.accordian}>
            <Stack>
            {
                AllAppointmentsData.map((item:AllAppointmentsEntry) => {
                    const recordsToShow = allAppointmentState.showAll ? item?.appointments : item?.appointments?.slice(0,initialCount);
                    return (
                        <Accordion
                        key={`${item.id}`}
                        onChange={handleChange(item.id)}
                        expanded={expanded === item.id}
                       
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id={`${item.id}`}
                            >
                                <Typography sx={Styles.AccordianHeading}>{t(`${item.title}`)}</Typography>
                            </AccordionSummary>
                            {recordsToShow.map((apoint,index) => {
                                return (
                                    <AccordionDetails className='customeScroll'>
                                        <ListView
                                            title={t(`${apoint?.title}`)}
                                            subTitle={apoint?.subTitle}
                                            wearhouse={t(`${apoint?.wearhouse}`)}
                                            image={apoint?.image} 
                                            label={undefined} 
                                            totalItems={item?.appointments?.length||0}
                                            index={index}
                                            handleViewAllClick={handleViewAllClick}
                                            showAll={allAppointmentState.showAll}
                                            />
                                       
                                    </AccordionDetails>
                                )
                            })}

                            </Accordion>
                        )
                    })
                }
            </Stack>
           
        </Box>
    )
}

export default AllAppointments