import { Box, Stack } from '@mui/material'
import ListView from '../../../common/components/ListView'
import ActiveButton from '../../../../components/formComponents/button/ActiveButton'
import { useTranslation } from 'react-i18next'
import { IMAGES } from '../../../../asset/Dis'
import { style } from '../../../../Utils/themes/styles'
import { Loading } from '../../../common/interfaces'

const Styles = {
    buttonStyle: {
        // width: "115px",
        height: "40px",
        border: `1px solid ${style.palette.black[50]}`,
        borderRadius: "4px",
    },
    accordian: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    }
}

interface ILoagisticsList {
    loadingData?: Loading[];
    handleSelectAppointment?:(value:string)=>void;
    selectedAppointment?:string;
}

const LoagisticsList = ({ loadingData,handleSelectAppointment,selectedAppointment }: ILoagisticsList) => {
    const { t } = useTranslation();
    return (
        <Box sx={Styles.accordian}>
            <Stack>
                {loadingData && loadingData.map((item) => {
                    return (
                        <ListView
                            key={item.id}
                            image={IMAGES.CourseIcon}
                            title={item.id}
                            subTitle={item.Client}
                            truck={item.Truck}
                            distributor={t(`${item.distributor}`)}
                            status={t(`${item.status}`)}
                            handleSelectAppointment={handleSelectAppointment}
                            selectedAppointment={selectedAppointment}
                        />
                    )
                })}
            </Stack>
            {/* <ActiveButton
                label={t(`${"CreateNewWarehouse"}`)}
                variant="contained"
                textVariant="subtitle1"
                boxStyle={Styles.buttonStyle}
                inputStyle={{ height: '40px' }}

            // onClick={() => setNewWarehouseStatus?.(true)}
            /> */}
        </Box>
    )
}

export default LoagisticsList