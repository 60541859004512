import { Box, Button, SxProps, Typography, TypographyProps } from '@mui/material'
import React, { CSSProperties, MouseEventHandler } from 'react'
import { baseTheme } from '../../../Utils/themes/base';

const Styles = {
    root: {
        '& .MuiTypography-subTitle1': {
            fontSize: "13px",
            [baseTheme.breakpoints.down('lg')]: {
                fontSize: "11px"
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: "11px"
            }
        },
    },
    button: (varinat: string) => ({
        borderRadius: '8px',
        border: varinat === 'contained' ? ' none' : '1px solid var(--Primary-Color, #007A6A);',
        background: varinat === 'contained' ? ' var(--Primary-Color, #007A6A)' : "transparent",
        height: '80px',
        width: '100%',
        color: varinat === 'contained' ? ' var(--Neutral, #FFF)' : 'var(--Primary-Color, #007A6A)',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        '&:hover': {
            background: varinat === 'contained' ? ' var(--Primary-Color, #007A6A)' : "transparent",
            border: varinat === 'contained' ? ' none' : '1px solid var(--Primary-Color, #007A6A);',

        },
        '& .MuiTypography-button': {
            color: varinat === 'contained' ? ' var(--Neutral, #FFF)' : 'var(--Primary-Color, #007A6A)'
        },
        '& .MuiTypography-h6': {
            fontSize: '14px',
            color: varinat === 'contained' ? ' var(--Neutral, #FFF)' : 'var(--Primary-Color, #007A6A)'
        },

        [baseTheme.breakpoints.down('lg')]: {
            height: '40px',
        },
        [baseTheme.breakpoints.down('xl')]: {
            height: '50px',
        },
        [baseTheme.breakpoints.up('xl')]: {
            height: '50px',
        }

    })
}

type ButtonVariant = 'text' | 'outlined' | 'contained';
type TypographyVariant = TypographyProps['variant'];
type Colors = "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";

interface IActiveButton {
    variant: ButtonVariant;
    disabled?: boolean;
    href?: string;
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
    color?: Colors;
    boxStyle?: CSSProperties;
    inputStyle?: SxProps;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    label?: string;
    textVariant: TypographyVariant;
}

const ActiveButton = ({
    variant,
    disabled,
    href,
    onClick,
    color,
    boxStyle,
    inputStyle,
    startIcon,
    endIcon,
    label,
    textVariant,
}: IActiveButton) => {
    const buttonStyle: SxProps = Styles.button(variant);
    const styledProps = {
        ...(buttonStyle as SxProps),
        ...inputStyle,
    };
    return (
        <Box sx={{ ...Styles.root, ...boxStyle }}>
            <Button
                type="submit"
                variant={variant}
                disabled={disabled}
                href={href}
                onClick={onClick}
                color={color}
                startIcon={startIcon}
                endIcon={endIcon}
                sx={styledProps}

            ><Typography variant={textVariant}>{label}</Typography></Button>
        </Box>
    )
}

export default ActiveButton