import { User } from '../../../types/User';
import {
    RECEIVE_FETCH_LOGIN,
    SET_LOGIN_FLAG,
    SET_USER_AUTHTOKEN,
} from '../actions/login';

type UserReducerState = {
    loginFlag: boolean;
    authToken: string | null,
    isFetchingUserProfile: boolean;
    fetchingUserProfileError: string | null;
    user: User;
};

export const INITIAL_USER_STATE: User = {
    id: 0,
    email: '',
    f_name: '',
    l_name: '',
    role: '',
    role_type: '',
    role_type_id: '',
    gender: '',
    dob: '',
    profile_img: '',
    created_at: 0,
    updated_at: 0,
}

const INITIAL_STATE: UserReducerState = {
    loginFlag: false,
    authToken: null,
    isFetchingUserProfile: false,
    fetchingUserProfileError: null,
    user: INITIAL_USER_STATE,
};

const userReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SET_LOGIN_FLAG:
            return { ...state, loading: false, loginFlag: action.flag };

        case SET_USER_AUTHTOKEN:
            return { ...state, loading: false, authToken: action.authToken };

        case RECEIVE_FETCH_LOGIN: {
            const { payload } = action;
            return {
                ...state,
                user: {
                    ...state.user,
                    ...payload,
                },
            };
        }
        default:
            return state;
    }
}

export default userReducer;
