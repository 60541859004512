import React, { useState } from 'react';
import sidebarLogo from "../asset/images/sidebar-logo.png";
import { NavLink } from 'react-router-dom';
import { getSidbarMenu } from '../Utils/AppConstants';
import { Box, Collapse, List, ListItem, Stack, Typography } from '@mui/material';
import { IMAGES } from '../asset/Dis';
import { useTranslation } from 'react-i18next';
import { style } from '../Utils/themes/styles';
import { baseTheme } from '../Utils/themes/base';

const Styles = {
    root: {
        navItem: {
            padding: '10px 12px'
        },
        '& .MuiTypography-subtitle1': {
            paddingLeft: '12px',
            [baseTheme.breakpoints.down('lg')]: {
                fontSize: "11px"
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: "11px"
            }
            // color:style.palette.black[100]
        },
    },
    subMenus: (open) => ({
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 12px',
        background: open ? style.palette.ActiveBgColor : style.palette.Neutral,
        '& .MuiTypography-subtitle1': {
            color: open ? style.palette.green[500] : style.palette.black[100],
        },
        'span img': {
            height: '20px',
            marginRight: '12px',
            [baseTheme.breakpoints.down('lg')]: {
                height: "18px"
            },
            [baseTheme.breakpoints.up('lg')]: {
                height: "18px"
            }
        }
    }),
    subMenuTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& .MuiTypography-subtitle1': {
            marginLeft: '12px'
        },
        'span img': {
            height: '20px',
            marginRight: '12px !important',
            [baseTheme.breakpoints.down('lg')]: {
                height: "18px"
            },
            [baseTheme.breakpoints.up('lg')]: {
                height: "18px"
            }
        }
    },
    listRoot: {
        paddingLeft: '30px'
    },
    sidebar: (isSidebarOpen) => ({
        width: isSidebarOpen ? '250px' : '60px', // Adjust width for expanded/collapsed state
        transition: 'width 0.3s ease'
    }),
    toggleButton: {
        position: 'absolute',
        top: '10px',
        left: '10px'
    }
};

const Sidebar = ({ userRole, isSidebarOpen }) => {
    const [open, setOpen] = useState(false);

    const { t } = useTranslation();

    const handleClick = () => {
        setOpen((prev) => !prev);
    };
    console.log(isSidebarOpen)
    return (
        <Box sx={Styles.sidebar(isSidebarOpen)}>
            <nav className='sidebar-panel' style={{ width: isSidebarOpen ? '220px' : '0px', transition: 'width 0.3s ease' }}>

                <div className="container-fluid">
                    <div className="logo-sidebar">
                        <div className="navbar-brand d-flex align-items-center justify-content-center">
                            <span className="bs-icon-sm bs-icon-rounded d-flex justify-content-center align-items-center me-2 bs-icon side-logo">
                                <img src={sidebarLogo} alt="img" />
                            </span>
                        </div>
                    </div>
                    <div className='align-list'>
                        <div className="side-align" id="navbarNav">
                            <ul className="navbar-nav">
                                {getSidbarMenu(userRole).map((menu, index) => (
                                    <React.Fragment key={index}>
                                        {menu?.routes ? (
                                            <>
                                                <li className="nav-item">
                                                    <ListItem button onClick={handleClick} sx={Styles.subMenus(open)}>
                                                        <Stack sx={Styles.subMenuTitle}>
                                                            <span><img src={menu.icon} alt='' /></span>
                                                            <Typography variant='subtitle1'>{t(menu.name)}</Typography>
                                                        </Stack>
                                                        <Stack>
                                                            {open ? <img src={IMAGES.ExpandMoreIcon} alt='expandMore' height={'6px'} /> : <img src={IMAGES.ExpandLessIcon} alt='expandLess' height={'6px'} />}
                                                        </Stack>
                                                    </ListItem>
                                                </li>
                                                <Collapse in={open} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding sx={Styles.listRoot}>
                                                        {menu?.routes.map((child, key) => (
                                                            <li className="nav-item" key={key}>
                                                                <NavLink to={child.path} exact activeClassName="active" key={key}>
                                                                    <Typography variant='subtitle1'>{t(`${child.name}`)}</Typography>
                                                                </NavLink>
                                                            </li>
                                                        ))}
                                                    </List>
                                                </Collapse>
                                            </>
                                        ) : (
                                            <li className="nav-item" key={menu?.id}>
                                                <NavLink to={menu.toPath} exact activeClassName="active">
                                                    <Stack sx={Styles.subMenuTitle}>
                                                        <span><img src={menu.icon} alt='' height='20px' /></span>
                                                        <Typography variant='subtitle1'>{t(`${menu.name}`)}</Typography>
                                                    </Stack>
                                                </NavLink>
                                            </li>
                                        )}
                                    </React.Fragment>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </Box>
    );
};

export default Sidebar;
