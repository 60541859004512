import { Box, Stack, TextareaAutosize } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import ComButtom from '../../../../components/formComponents/button/ComButtom'
import { style } from '../../../../Utils/themes/styles'

const Styles = {
    commentModalSection: {
        width:'581px'
    },
    textArea:{
        border:`1px solid ${style.palette.black[50]}`,
        borderRadius:'4px',
        padding:'11px 12px',
        width:'100%',
        marginBottom:'20px'

    },
    commentFormAlign:{
        padding:'20px',
        
    },
    validateBtn:{
        height:'40px',
        width:'122px'
    },
    footer:{
        display:'flex',
        alignItems:'flex-end',
        paddingTop:'16px',
        borderTop:`1px solid ${style.palette.black[50]}`
    }
}

interface ILoadingCommentForm {
    handleValidateComment?:()=>void;
}

const LoadingCommentForm = ({handleValidateComment}:ILoadingCommentForm) => {
    return (
        <Box sx={Styles.commentModalSection}>
            <Formik
                initialValues={{
                    comment: '',
                }}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    alert(JSON.stringify(values, null, 2));
                }}
            >
                <Form style={Styles.commentFormAlign}>
                    <TextareaAutosize
                        id="comment"
                        placeholder="Please add your comment here..."
                        minRows={6}
                        style={Styles.textArea}
                    />
                    <Stack sx={Styles.footer}>
                    <ComButtom label="Validate" variant="contained" textVariant="subtitle1"  onClick={handleValidateComment} inputStyle={Styles.validateBtn} />
                    </Stack>
                </Form>
            </Formik>
        </Box>
    )
}

export default LoadingCommentForm