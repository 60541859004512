
const COMMON_IMAGES ={
    hiveLogo:require('./images/hive-logo.png'),
    pdfIcon:require('./images/pdf-file.png'),
    checkImage:require('./images/hive-check.png'),
    PendingImage:require('./images/hive-pending.png'),
    NatureImage:require('./images/nature.png',),
    LoginImage:require('./images/login-img.png'),
    CloseIcon:require('./images/closeIcon.png'),
    SuccessIcon:require('./images/successIcon.png'),
    ErrorIcon:require('./images/errorIcon.png'),
    WarningIcon:require('./images/warningIcon.png'),
    DataNotFoundImage:require('./images/no-data-found.png'),
}

const STRING={
        abc:"nkjdvh ls",
        xyz:'njkdvsl k'
   
}
export {COMMON_IMAGES,STRING}
