import { Box, Stack } from '@mui/material';
import { style } from '../../../../Utils/themes/styles';
import DetailsView from '../../../common/components/DetailsView';
import { AllWarehouseDataTypes, ManagerDetail } from '../../../common/interfaces';
const Styles = {
    accordian: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {
            background: style.palette.ActiveBgColor,
            '& .MuiTypography-root': {
                color: style.palette.green[500]
            },
            '& .MuiSvgIcon-root': {
                color: style.palette.green[500]
            }
        },
        '& .MuiAccordionSummary-content': {
            margin: '11px 0px'
        },
        '& .MuiAccordion-root': {
            marginBottom: '10px',
            background: style.palette.BgColor,
            border: 'none',
            boxShadow: "none",
            position: 'unset',
        },
        '& .MuiCollapse-wrapper': {
            background: style.palette.Neutral
        },
        '& .MuiAccordionDetails-root': {
            padding: '8px 0px 0px'
        }

    },
}
interface IManagerDetails {
    ManagerData?: ManagerDetail;
}

const ManagerDetails = ({ ManagerData }: IManagerDetails) => {
    console.log("ManagerData",ManagerData)
    return (
        <Box sx={Styles.accordian}>
            <Stack>
                <DetailsView
                    name={ManagerData?.name}
                    contact={ManagerData?.contact_no}
                    mail={ManagerData?.mail}
                    gap="6px"

                />

            </Stack>
        </Box>
    )
}

export default ManagerDetails;