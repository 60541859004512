import { Box, Grid, Paper, Typography, Divider, SxProps } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { style } from '../../../Utils/themes/styles';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ComButtom from '../../../components/formComponents/button/ComButtom';
import PopupModal from './PopupModal';
import { useDispatch } from 'react-redux';
import { postWarehouse } from '../../../redux/warehouse/actions/addWarehouse';
import { AppDispatch } from '../../../redux/store';
import { useNotification } from '../../../Utils/NotificationContext';
import { AllWarehouseDataTypes } from '../interfaces';
import { updateWarehouses } from '../../../redux/warehouse/actions/updateWarehouse';

const Styles = {
    root: {
        borderRadius: '12px'
    },
    header: {
        padding: '28px 20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${style.palette.GrayStroke}`,
        '& .MuiTypography-h6': {
            color: style.palette.TitleColor,
        },
        'img': {
            marginRight: '12px',
        }
    },
    component: {
        // borderBottom: `1px solid ${style.palette.GrayStroke}`,
    },
    leftContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    rightContainer: {
        height: '24px',
        textAlign: "right"
    },
    children: {
        padding: '20px',
        height: '100%',
        overflowY: "auto"
    },
    formSubHeading: {
        margin: "7px 0",
        color: style.palette.black[300],
        fontSize: "12px",
        lineHeight: "18px",
    },
    formHeading: {
        margin: "10px 0 0 0",
        textAlign: "left",
        fontSize: "14px",
        color: style.palette.black[300],
    },
    buttonBoxStyle: {
        position: "relative",
        top: "20px",
        marginRight: '4px'
    },
    buttonStyle: {
        width: "122px",
        height: "40px",
        borderRadius: "4px",
        margin: "10px 0"
    },
    divider: {
        borderRadius: 0,
        backgroundColor: style.palette.GrayStroke,
        marginTop: "15px",
        width: "570px",
        position: "relative",
        left: "-20px"
    },
    inputText: {
        '& .MuiInputBase-input': {
            padding: "8px 12px"
        },
        '& div': {
            height: "36px"
        }
    }
};

interface IFormContainerProps {
    children?: React.ReactNode;
    leftContainer?: React.ReactNode;
    rightContainer?: React.ReactNode;
    sx?: SxProps;
    headerStyle?: SxProps;
    childContainerStyle?: SxProps;
    submitText: string;
    handleStatus: Dispatch<SetStateAction<boolean>> | undefined;
    WarehouseDetails?: AllWarehouseDataTypes
}

const FormContainer: React.FC<IFormContainerProps> = ({
    leftContainer,
    rightContainer,
    sx,
    headerStyle,
    childContainerStyle,
    submitText,
    handleStatus,
    WarehouseDetails
}) => {
    const [submit, setSubmit] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const handleEditSubmit = async (values: any) => {
        const warehouseData = {
            name: values.name,
            company_name: values.company_name,
            contact_no: values.contact_no,
            mail: values.email,
            address: values.address,
            manager_details: {
                name: values.managerName,
                contact_no: values.managerContactNo,
                mail: values.managerEmail,
            },
            assistant_details: {
                name: values.assistantName,
                contact_no: values.assistantContactNo,
                mail: values.assistantEmail,
            }
        }
        if (WarehouseDetails) {
            const payload = { ...warehouseData, warehouse_id: WarehouseDetails?.id }
            const data = await dispatch(updateWarehouses(payload))
            console.log("data",data);
            
            if(data.status === 200){
                setSubmit(true)
            }
        } else {
            await dispatch(postWarehouse(warehouseData))
        }
        setSubmit(true)
    };

    const validationSchema = Yup.object({
        name: Yup.string().required('Warehouse name is required'),
        company_name: Yup.string().required('Company name is required'),
        contact_no: Yup.string().required('Contact number is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        address: Yup.string().required('Address is required'),
        managerName: Yup.string().required('Manager name is required'),
        managerContactNo: Yup.string().required('Manager contact number is required'),
        managerEmail: Yup.string().email('Invalid email address').required('Manager email is required'),
        assistantName: Yup.string().required('Assistant name is required'),
        assistantContactNo: Yup.string().required('Asistant contact number is required'),
        assistantEmail: Yup.string().email('Invalid email address').required('Asistant email is required')
    });

    const formik = useFormik({
        initialValues: {
            name: WarehouseDetails ? WarehouseDetails?.name : '',
            company_name: WarehouseDetails ? WarehouseDetails.company_name : '',
            contact_no: WarehouseDetails ? WarehouseDetails.contact_no : '',
            email: WarehouseDetails ? WarehouseDetails?.mail : '',
            address: WarehouseDetails ? WarehouseDetails?.address : '',
            managerName: WarehouseDetails ? WarehouseDetails?.manager_details?.name : '',
            managerContactNo: WarehouseDetails ? WarehouseDetails?.manager_details?.contact_no : '',
            managerEmail: WarehouseDetails ? WarehouseDetails?.manager_details?.mail : '',
            assistantName: WarehouseDetails?.assistant_details ? WarehouseDetails?.assistant_details?.name : '',
            assistantContactNo: WarehouseDetails?.assistant_details ? WarehouseDetails?.assistant_details?.contact_no : '',
            assistantEmail: WarehouseDetails?.assistant_details ? WarehouseDetails?.assistant_details?.mail : '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log(values);
            handleEditSubmit(values);
        }
    });

    useEffect(() => {
        const submitTimeout = setTimeout(() => {
            if (submit) {
                setSubmit(false);
                if (handleStatus) handleStatus(false);
            }
        }, 1500);
        return () => {
            clearTimeout(submitTimeout)
        }
    }, [submit])
    const { t } = useTranslation();

    const ContainerStyle: SxProps = Styles.header;
    const ChildrenStyle: SxProps = Styles.children;
    const styledProps = {
        ...(ContainerStyle as SxProps),
        ...headerStyle,
    };

    const ChildrenStyleProps = {
        ...(ChildrenStyle as SxProps),
        ...childContainerStyle,
    };

    return (
        <>
            {!submit &&
                <Paper sx={{ ...Styles.root, ...sx }}>
                    <Grid container sx={styledProps}>
                        <Grid sx={Styles.leftContainer}>
                            <Typography variant='h6'> {leftContainer}</Typography>
                        </Grid>
                        <Grid sx={Styles.rightContainer}>
                            {rightContainer}
                        </Grid>
                    </Grid>
                    <Box sx={ChildrenStyleProps}>
                        <form onSubmit={formik.handleSubmit}>
                            <Box sx={{ ...Styles.component }}>
                                <Box
                                    sx={{
                                        '& > :not(style)': { m: 1, width: '50%' }, display: "flex",
                                    }}
                                >
                                    <Grid item xs={6}>
                                        <Typography variant="h3" sx={Styles.formSubHeading}>{t("WareHouseName")}</Typography>
                                        <TextField
                                            id="name"
                                            name="name"
                                            variant="outlined"
                                            sx={Styles.inputText}
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.name && Boolean(formik.errors.name)}
                                            helperText={formik.touched.name && formik.errors.name}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h3" sx={Styles.formSubHeading}>{t("CompanyName")}</Typography>
                                        <TextField
                                            id="company_name"
                                            name="company_name"
                                            variant="outlined"
                                            sx={Styles.inputText}
                                            value={formik.values.company_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                                            helperText={formik.touched.company_name && formik.errors.company_name}
                                        />
                                    </Grid>
                                </Box>
                                <Box
                                    sx={{
                                        '& > :not(style)': { m: 1, width: '50%' }, display: "flex"
                                    }}
                                >
                                    <Grid item xs={6}>
                                        <Typography variant="h3" sx={Styles.formSubHeading}>{t("ContactNo")}</Typography>
                                        <TextField
                                            id="contact_no"
                                            name="contact_no"
                                            variant="outlined"
                                            sx={Styles.inputText}
                                            value={formik.values.contact_no}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.contact_no && Boolean(formik.errors.contact_no)}
                                            helperText={formik.touched.contact_no && formik.errors.contact_no}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h3" sx={Styles.formSubHeading}>{t("Mail")}</Typography>
                                        <TextField
                                            id="email"
                                            name="email"
                                            variant="outlined"
                                            sx={Styles.inputText}
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                    </Grid>
                                </Box>
                                <Box
                                    sx={{
                                        '& > :not(style)': { m: 1 },
                                    }}
                                >
                                    <Grid item xs={12}>
                                        <Typography variant="h3" sx={Styles.formSubHeading}>{t("Address")}</Typography>
                                        <TextField
                                            id="address"
                                            name="address"
                                            variant="outlined"
                                            multiline
                                            minRows={5}
                                            fullWidth
                                            value={formik.values.address}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.address && Boolean(formik.errors.address)}
                                            helperText={formik.touched.address && formik.errors.address}
                                        />
                                    </Grid>
                                </Box>
                            </Box>
                            <Divider sx={Styles.divider} orientation="horizontal" flexItem />
                            <Box sx={{ ...Styles.component }}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" sx={Styles.formHeading}>{t("AddManagerDetails")}</Typography>
                                </Grid>
                                <Box
                                    sx={{
                                        '& > :not(style)': { m: 1, width: '50%' }, display: "flex"
                                    }}
                                >
                                    <Grid item xs={6}>
                                        <Typography variant="h3" sx={Styles.formSubHeading}>{t("ManagerName")}</Typography>
                                        <TextField
                                            id="managerName"
                                            name="managerName"
                                            variant="outlined"
                                            sx={Styles.inputText}
                                            value={formik.values.managerName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.managerName && Boolean(formik.errors.managerName)}
                                            helperText={formik.touched.managerName && formik.errors.managerName}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h3" sx={Styles.formSubHeading}>{t("ContactNo")}</Typography>
                                        <TextField
                                            id="managerContactNo"
                                            name="managerContactNo"
                                            variant="outlined"
                                            sx={Styles.inputText}
                                            value={formik.values.managerContactNo}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.managerContactNo && Boolean(formik.errors.managerContactNo)}
                                            helperText={formik.touched.managerContactNo && formik.errors.managerContactNo}
                                        />
                                    </Grid>
                                </Box>
                                <Box
                                    sx={{
                                        '& > :not(style)': { m: 1 },
                                    }}
                                >
                                    <Grid item xs={12}>
                                        <Typography variant="h3" sx={Styles.formSubHeading}>{t("Mail")}</Typography>
                                        <TextField
                                            id="managerEmail"
                                            name="managerEmail"
                                            variant="outlined"
                                            sx={Styles.inputText}
                                            value={formik.values.managerEmail}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.managerEmail && Boolean(formik.errors.managerEmail)}
                                            helperText={formik.touched.managerEmail && formik.errors.managerEmail}
                                        />
                                    </Grid>
                                </Box>
                            </Box>
                            <Divider sx={Styles.divider} orientation="horizontal" flexItem />
                            <Box sx={{ ...Styles.component }}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" sx={Styles.formHeading}>{t("AddAssistantDetails")}</Typography>
                                </Grid>
                                <Box
                                    sx={{
                                        '& > :not(style)': { m: 1, width: '50%' }, display: 'flex'
                                    }}
                                >
                                    <Grid item xs={6}>
                                        <Typography variant="h3" sx={Styles.formSubHeading}>{t("AssistantName")}</Typography>
                                        <TextField
                                            id="assistantName"
                                            name="assistantName"
                                            variant="outlined"
                                            sx={Styles.inputText}
                                            value={formik.values.assistantName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.assistantName && Boolean(formik.errors.assistantName)}
                                            helperText={formik.touched.assistantName && formik.errors.assistantName}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h3" sx={Styles.formSubHeading}>{t("ContactNo")}</Typography>
                                        <TextField
                                            id="assistantContactNo"
                                            name="assistantContactNo"
                                            variant="outlined"
                                            sx={Styles.inputText}
                                            value={formik.values.assistantContactNo}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.assistantContactNo && Boolean(formik.errors.assistantContactNo)}
                                            helperText={formik.touched.assistantContactNo && formik.errors.assistantContactNo}
                                        />
                                    </Grid>
                                </Box>
                                <Box
                                    sx={{
                                        '& > :not(style)': { m: 1 },
                                    }}
                                >
                                    <Grid item xs={12}>
                                        <Typography variant="h3" sx={Styles.formSubHeading}>{t("Mail")}</Typography>
                                        <TextField
                                            id="assistantEmail"
                                            name="assistantEmail"
                                            variant="outlined"
                                            sx={Styles.inputText}
                                            value={formik.values.assistantEmail}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.assistantEmail && Boolean(formik.errors.assistantEmail)}
                                            helperText={formik.touched.assistantEmail && formik.errors.assistantEmail}
                                        />
                                    </Grid>
                                </Box>
                            </Box>
                            <Divider sx={Styles.divider} orientation="horizontal" flexItem />
                            <Grid sx={Styles.rightContainer}>
                                <ComButtom
                                    label={submitText}
                                    variant="contained"
                                    textVariant="subtitle1"
                                    inputStyle={Styles.buttonStyle}
                                    type="submit"
                                />
                            </Grid>
                        </form>
                    </Box>
                </Paper>
            }
            {submit && <PopupModal showText={submitText === "Edit" ? t("Warehouse information has been updated successfully!!") : t("Warehouse#1 is created successfully!!")} bottomText='' />}
        </>
    );
};

export default FormContainer;
