import { Box, Divider, Grid, List, ListItem, ListItemText, Popover, PopoverPosition, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Heading from '../common/components/Heading'
import { useTranslation } from 'react-i18next';
import ListContainer from '../common/components/ListContainer';
import { IMAGES } from '../../asset/Dis';
import { AllAppointmentsData, Appointments, AppointmentsOptions, CalenderViewOption, CatgeoryOptions, ScheduleAppointmentsData, mapOptions } from './common/constant';
import AllAppointments from './common/components/AllAppointments';
import ListView from '../common/components/ListView';
import AgendaCalender from './common/components/AgendaCalender';
import ActiveButton from '../../components/formComponents/button/ActiveButton';
import QuickAccess from './common/components/QuickAccess';
import ComButtom from '../../components/formComponents/button/ComButtom';
import AgendaList from './common/components/AgendaList';
import AgendaMap from './common/components/AgendaMap';
import appointmentList from './StaticData/appointmentList.json';
import SelectCategories from '../../components/formComponents/select/SelectCategories';
import { style } from '../../Utils/themes/styles';
import { EventClickArg } from '@fullcalendar/core';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { rowsDataTypes } from '../common/interfaces';
import ModalView from '../common/components/ModalView';
import UnloadingAppointment from './common/components/UnloadingAppointment';
import ConfirmationText from '../../components/ConfirmModals/ConfirmationText';
import SelectAppointment from './common/components/SelectAppointment';
import { useNavigate } from 'react-router-dom';
import { baseTheme } from '../../Utils/themes/base';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { fontWeight } from '@mui/system';

const Styles = {
  agendaRoot: {
    padding: '20px',
    '& .MuiTypography-subtitle1': {
      [baseTheme.breakpoints.down('lg')]: {
        fontSize: '12px',
      },
      [baseTheme.breakpoints.up('lg')]: {
        fontSize: '12px',
      }

    }
  },
  AppointmentContainers: {
    marginTop: '10px',
    height: '783px'
  },
  scheduleAppointment: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  mapViewbtns: {
    display: 'flex',
    flexDirection: 'row'
  },
  CalendarSubHeader: {
    height: '832px',
    '& .MuiPaper-root': {
      height: '832px !important'
    },
  },
  AlignSubheader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 10px',
    '& .MuiTypography-subtitle2': {
      fontSize: '16px !important',
      fontWeight:600
    },
    '& .MuiSelect-outlined': {
      padding: '10px 14px !important'
  }
  },
  calenderTopRightView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: style.palette.BgColor,
    'img': {
      marginRight: '12px',
    },
    '& .MuiDivider-root': {
      height: '20px',
      alignSelf: 'center'
    }
  },
  alignListWithActiveBtn: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: "space-between"

  }
}

// type rowsDataTypes = [{ id: number; Appointment_Type: string; Truck_Name: string; File_Number: string; Warehouse: string; Date_Of_Work: string; }[]]
type CalendarView = 'Month' | 'Week' | 'Day' | 'listWeek';


interface MyState {
  calenderView: string;
  rowsData: rowsDataTypes;
  category?: string | undefined;
  calender?: CalendarView;
  isOpenCreateAppointmentModal?: boolean;
  appointmentType?: string;
  isUnloadingCreateModalOpen?: boolean;
  isOpenConfirmLoadingAppointmentModal?: boolean;
  unloadingCreateModalOpenTitle?: string;
}

const Agenda = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [agendaState, setAgendaState] = useState<MyState>({
    calenderView: 'Calendar',
    rowsData: [appointmentList],
    category: '',
    calender: 'Month',
    appointmentType: '',
    isOpenCreateAppointmentModal: false,
    isUnloadingCreateModalOpen: false,
    isOpenConfirmLoadingAppointmentModal: false,
    unloadingCreateModalOpenTitle: "",
  })
  const role = useSelector((state: RootState) => state.userReducer.user.role);
  const [anchorPositionModifyPopup, setAnchorPositionModifyPopup] = useState<PopoverPosition | undefined>(undefined);
  const openDayEditEvent = anchorPositionModifyPopup
  const open = Boolean(anchorPositionModifyPopup);
  const id = open ? 'simple-popover' : undefined;


  console.log("agendaState", agendaState.calender);

  const handleCalenderView = (viewName: string) => {
    setAgendaState((prev) => {
      return {
        ...prev,
        calenderView: viewName
      }
    })
  }

  const handleChange = (data: SelectChangeEvent) => {
    // document.body.classList.add('scroll-overflow');
    setAgendaState((prev) => {
      return {
        ...prev,
        category: data.target.value as string
      }
    })
  }

  const handleChangeCalender = (data: SelectChangeEvent) => {
    console.log("data===>", data);
    setAgendaState((prev) => {
      return {
        ...prev,
        calender: data.target.value as CalendarView
      }
    })
  }

  const handleEventData = (arg: EventClickArg) => {
    console.log("handle Event data", arg)
  }

  const handleCreateAppointment = () => {
    setAgendaState((prev) => {
      return {
        ...prev,
        isOpenCreateAppointmentModal: true
      }
    })
  }

  const handleCloseCreateAppointmentModal = () => {
    setAgendaState((prev) => {
      return {
        ...prev,
        isOpenCreateAppointmentModal: false,
        isUnloadingCreateModalOpen: false,
      }
    })
  }

  const handleCloseConfirmLoadingAppointmentModal = () => {
    setAgendaState((prev) => {
      return {
        ...prev,
        isOpenCreateAppointmentModal: false,
        isOpenConfirmLoadingAppointmentModal: false
      }
    })
    if (agendaState.appointmentType === Appointments.Loading) {
      navigate("/loading");
    }
    if (agendaState.appointmentType === Appointments.Unloading) {
      navigate("/unloading");
    }
    if (agendaState.appointmentType === Appointments.Receipt) {
      navigate("/receipt");
    }
    if (agendaState.appointmentType === Appointments.Delivery) {
      navigate("/delivery");
    }


  }

  const handleChangeAppointmentType = (data: SelectChangeEvent) => {
    console.log("data", data.target.value, agendaState.appointmentType);
    setAgendaState((prev) => {
      return {
        ...prev,
        appointmentType: data.target.value

      }
    })
  }

  const handleClose = () => {
    setAnchorPositionModifyPopup(undefined);
  };

  const handleUnloadingCreateModal = () => {
    setAgendaState((prev) => {
      return {
        ...prev,
        isOpenCreateAppointmentModal: false,
        isUnloadingCreateModalOpen: true,
        unloadingCreateModalOpenTitle: agendaState.appointmentType === Appointments.Loading ? "Create a new loading appointment" :
          agendaState.appointmentType === Appointments.Unloading ? "Create a new Unloading appointment" :
            agendaState.appointmentType === Appointments.Receipt ? "Create a new Reception appointment" : "Create a new delivery appointment"
      }
    })
  }

  const handleModifyAppointModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    console.log("event", event);

    setAnchorPositionModifyPopup({
      top: event.clientY,
      left: event.clientX,
    })
  }

  const handleConfirmUnloadingAppointment = () => {
    console.log("handleConfirmUnloadingAppointment");

    setAgendaState((prev) => {
      return {
        ...prev,
        isUnloadingCreateModalOpen: false,
        isOpenCreateAppointmentModal: false,
        isOpenConfirmLoadingAppointmentModal: true
      }
    })
  }

  const handleModifyAppointment = () => {
    setAgendaState((prev) => {
      return {
        ...prev,
        isUnloadingCreateModalOpen: true,

      }
    })
  }


  useEffect(() => {
    setAgendaState((prev) => {
      return {
        ...prev,
        rowsData: appointmentList as unknown as rowsDataTypes,
      }
    })
  }, [])
  return (
    <Box sx={Styles.agendaRoot}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={6}>
          <Heading title={t("Agenda")} />
          <ListContainer
            leftContainer={t("Calendar")}
            rightContainer={
              <Box sx={Styles.mapViewbtns}>
                {mapOptions.map((btn: { id: number, name: string }) => {
                  return (
                    <ComButtom
                      label={t(`${btn.name}`)}
                      variant={btn.name === agendaState.calenderView ? "contained" : "outlined"}
                      textVariant="subtitle1"
                      inputStyle={{ height: '40px' }}
                      boxStyle={{ marginRight: '8px' }}
                      onClick={() => handleCalenderView(btn.name)}
                    />
                  )
                })}

              </Box>
            }
          >
            <Box sx={Styles.CalendarSubHeader}>
              {agendaState.calenderView === "List" &&
                <Box sx={Styles.AlignSubheader}>
                  <Typography variant='subtitle2'>15 December 2023</Typography>
                  <Stack sx={Styles.calenderTopRightView}>
                    <SelectCategories
                      options={CatgeoryOptions}
                      category={agendaState.category}
                      handleChange={handleChange}
                      placeholder={t("AppointmentCategories")}
                      sx={{ width: '200px' }}
                    />
                    <img src={IMAGES.CalendarIcon} alt='calenadar' height='20px' />
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <SelectCategories
                      options={CalenderViewOption}
                      category={agendaState.calender}
                      handleChange={handleChangeCalender}
                      placeholder={t("Month")}
                      sx={{ width: '100px' }}
                    />
                  </Stack>
                </Box>
              }
              {(agendaState.calenderView === "Calendar" &&
                <AgendaCalender
                  handleEventData={handleEventData}
                  CatgeoryOptions={AppointmentsOptions}
                  appointmentType={agendaState.appointmentType}
                  handleChangeAppointmentType={handleChangeAppointmentType}
                  handleUnloadingCreateModal={handleUnloadingCreateModal}

                />) ||
                (agendaState.calenderView === "List" &&
                  <AgendaList
                    rowsData={agendaState?.rowsData}
                  // setRows={(appointmentList: rowsDataTypes) => {
                  //   setAgendaState((prev) => {
                  //     return {
                  //       ...prev,
                  //       rowsData: appointmentList
                  //     }
                  //   })
                  // }}
                  />) ||
                (agendaState.calenderView === "Map" && <AgendaMap />)}
            </Box>
          </ListContainer>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <ListContainer
            leftContainer={t("QuickAccess")}
          >
            <QuickAccess />
          </ListContainer>
          <Grid container spacing={2} sx={Styles.AppointmentContainers}>
            <Grid item xs={6}>
              <ListContainer
                leftContainer={t("AllAppointments")}
                rightContainer={
                  <img src={IMAGES.SearchFilesInProgressIcon} alt='search' height='24px' />
                }
                sx={{ height: '100%' }}
                // headerStyle={{ height: '12%' }}
                childContainerStyle={{ height: '92%' }}
              >
                <Box sx={Styles.alignListWithActiveBtn}>
                  <AllAppointments AllAppointmentsData={AllAppointmentsData} handleCreateAppointment={handleCreateAppointment} />
                  {role === "DI-BO" &&
                    <ActiveButton
                      label={t(`${"CreateNewAppointment"}`)}
                      variant="contained"
                      textVariant="subtitle1"
                      inputStyle={{ height: '40px' }}
                      onClick={handleCreateAppointment}

                    />
                  }
                </Box>
              </ListContainer>
            </Grid>

            <Grid item xs={6}>
              <ListContainer
                leftContainer={t("ScheduleAppointments")}
                rightContainer={
                  <img src={IMAGES.SearchFilesInProgressIcon} alt='search' height='24px' />
                }
                sx={{ height: '100%' }}
                // headerStyle={{ height: "12%" }}
                childContainerStyle={{ height: '92%' }}
              >
                <Box sx={Styles.scheduleAppointment}>
                  <Stack>
                    {ScheduleAppointmentsData.map((item) => {
                      return (
                        <ListView
                          title={(item.title)}
                          subTitle={item.subTitle}
                          label={t(`${item.label}`)}
                          image={item.image}
                          handleModifyAppointModal={handleModifyAppointModal}
                        />
                      )
                    })}
                  </Stack>
                  <ActiveButton
                    label={t("ViewAll")}
                    variant="outlined"
                    textVariant="subtitle2"
                    inputStyle={{ height: '40px' }}

                  />
                </Box>
              </ListContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {agendaState.isOpenCreateAppointmentModal &&
        <SelectAppointment
          open={agendaState.isOpenCreateAppointmentModal}
          handleClose={handleCloseCreateAppointmentModal}
          category={agendaState.appointmentType}
          options={AppointmentsOptions}
          handleChangeAppointmentType={handleChangeAppointmentType}
          handleAddEvent={handleUnloadingCreateModal}
        />
      }
      {anchorPositionModifyPopup &&
        <Popover
          id={id}
          open={anchorPositionModifyPopup ? true : false}
          // anchorEl={anchorPositionModifyPopup}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={openDayEditEvent}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <List aria-label="mailbox folders">
            <ListItem>
              <Box onClick={handleModifyAppointment}>
                <ListItemText primary="Modify an appointment" />
              </Box>
            </ListItem>
            <Divider component="li" />
            <ListItem>
              <Box onClick={handleModifyAppointment}>
                <ListItemText primary="Assign a team" />
              </Box>
            </ListItem>
          </List>
        </Popover>
      }

      {agendaState.isUnloadingCreateModalOpen &&
        <ModalView
          open={agendaState.isUnloadingCreateModalOpen}
          handleClose={handleCloseCreateAppointmentModal}
          title={`${agendaState.unloadingCreateModalOpenTitle}`}
          subTitle={"Logistique"}
        >
          <UnloadingAppointment
            handleConfirmUnloadingAppointment={handleConfirmUnloadingAppointment}
            appointmentType={agendaState.appointmentType}
          />
        </ModalView>
      }
      {
        agendaState.isOpenConfirmLoadingAppointmentModal &&
        <ModalView
          open={agendaState.isOpenConfirmLoadingAppointmentModal}
          handleClose={handleCloseConfirmLoadingAppointmentModal}
        >
          <ConfirmationText success confirmationMessage={"The Loading appointment has been created."} handleCloseModal={handleCloseConfirmLoadingAppointmentModal} />
        </ModalView>
      }
    </Box>
  )
}

export default Agenda