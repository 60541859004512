import { Box, Stack, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import { IMAGES } from '../../../asset/Dis';
import { style } from '../../../Utils/themes/styles';
import { baseTheme } from '../../../Utils/themes/base';

const Styles = {
    root: {
        padding: '11px 12px',
        backgroundColor: style.palette.BtnBgColor,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '8px'
    },
    listTitle: {
        ...style.typography.subtitle1,
        fontSize: '14px',
        marginLeft: '10px',
        color: style.palette.black[300],
        [baseTheme.breakpoints.down('lg')]: {
            fontSize: '11px',
        },
        [baseTheme.breakpoints.up('lg')]: {
            fontSize: '11px',
        }
    },
    leftConatiner: {
        display: 'flex',
        flexDirection: 'row',

        'img': {
            marginRight: '12px',
            height: '32px',
            [baseTheme.breakpoints.down('lg')]: {
                height: '32px',
            },
            [baseTheme.breakpoints.up('lg')]: {
                height: '32px',
            }
        },
        '& .MuiTypography-subtitle1': {
            color: style.palette.black[200]
        }
    },
    rightConatiner: {
        '& .MuiTypography-body2': {
            color: style.palette.green[500]
        }
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
        'img': {
            marginRight: '12px',
            height: '32px',
            [baseTheme.breakpoints.down('lg')]: {
                height: '32px',
            },
            [baseTheme.breakpoints.up('lg')]: {
                height: '32px',
            }
        },
    },
    marginLeft: {
        marginLeft: '10px'
    }
}

interface IListViewLogistician {
    id?:string;
    name?: string;
    image?: string;
    image2?: string;
    image3?: string;
    handleEditLogistician?: (value:boolean,id?:string) => void;
    setSelection: Dispatch<SetStateAction<string>> | undefined;
    handleDeleteLogistician?: (value:boolean,id?:string) => void;
}

const ListViewLogistician = ({
    id,
    name,
    image,
    image2,
    image3,
    handleEditLogistician,
    setSelection,
    handleDeleteLogistician,
}: IListViewLogistician) => {
    const handleEdit = (id?:string) => {
        handleEditLogistician && handleEditLogistician(true,id);
        if (setSelection) {
            setSelection("logistician")
        }

    }
    const handleDelete = (id?:string) => {

        if (handleDeleteLogistician) {
            handleDeleteLogistician(true,id)
        }

    }
    return (
        <Box sx={Styles.root} >
            <Box sx={Styles.alignCenter}>
                {image && image?.length > 1000 ? <img src={image} alt='logisticianIcon'/> :
                    <img src={IMAGES.logisticianIcon} alt='logisticianIcon'/>}
                <Stack>
                    <Typography sx={Styles.listTitle}>{name}</Typography>

                </Stack>

            </Box>
            <Box sx={Styles.rightConatiner}>
                {image2 && image2?.length > 1000 ? <img src={image3} alt='EditIcon' height='16px' onClick={()=>handleEdit(id)} /> :
                    <img src={IMAGES.EditIcon} alt='EditIcon' height='16px' onClick={()=>handleEdit(id)} />}

                {image2 && image2?.length > 1000 ? <img style={Styles.marginLeft} src={image2} alt='deleteicon' height='16px' onClick={()=>handleDelete(id)} /> :
                    <img style={Styles.marginLeft} src={IMAGES.DeleteIcon} alt='deleteicon' height='16px' onClick={()=>handleDelete(id)} />}
            </Box>
        </Box>
    )
}

export default ListViewLogistician