import React, { useState } from 'react';
import { style } from '../Utils/themes/styles';
import { Box, FormControl, List, ListItem, ListItemText, MenuItem, Popover, Select, Stack, Typography } from '@mui/material';
import { IMAGES } from '../asset/Dis';
import { useTranslation } from 'react-i18next';
import { baseTheme } from '../Utils/themes/base';
import { signOut } from '../redux/user/actions/logout';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Styles = {
    root: {
        'header': {
            background: style.palette.green[500],
            alignItems: 'center',
            '&.MuiSelect-root': {
                '&:hover': {
                    borderColor: style.palette.green[200], // Hide border on hover
                },
            },

            'div': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',

                '& .MuiStack-root': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    paddingLeft: '8px',

                    '& .MuiTypography-subtitle1': {
                        color: style.palette.Neutral,
                        wordWrap: 'no-wrap',

                        [baseTheme.breakpoints.down('lg')]: {
                            fontSize: '10px'
                        },
                        [baseTheme.breakpoints.up('lg')]: {
                            fontSize: '10px'
                        }
                    },

                    '& .MuiTypography-body1': {
                        color: style.palette.green[200],
                        [baseTheme.breakpoints.down('lg')]: {
                            fontSize: '8px'
                        },
                        [baseTheme.breakpoints.up('lg')]: {
                            fontSize: '8px'
                        }
                    },
                },
                '& .MuiSelect-outlined': {
                    '& .MuiTypography-subtitle1': {
                        color: style.palette.Neutral,
                        wordWrap: 'no-wrap'
                    },
                },
                '& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {
                    color: style.palette.Neutral,
                },
                '& .css-bpeome-MuiSvgIcon-root-MuiSelect-icon': {
                    color: style.palette.Neutral,
                },
                '& .css-1dlx53a-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    '&:hover': {
                        borderColor: style.palette.green[200],
                    }
                }
            },
            '& .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
                border: 'none',
                '&:hover': {
                    border: 'none',
                }
            },
            '& .css-1dlx53a-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
                '&:hover': {
                    borderColor: style.palette.green[200],
                }
            },
            '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input ': {
                paddingRight: '8px !important',
                [baseTheme.breakpoints.down('lg')]: {
                    padding: "9.5px 14px"
                },
                [baseTheme.breakpoints.up('lg')]: {
                    padding: "9.5px 14px"
                }
            },

            '& .MuiPopover-paper': {
                top: '64px !important '
            }
        },


        '& .MuiFormControl-root': {
            border: 'none !important',
            borderRadius: '4px',
            marginRight: '28px',
            width: '133px',
            'img': {
                marginRight: '10px'
            },
            '&:hover': {
                border: 'none',
            }
        },
        '& .MuiInputBase-root': {
            background: style.palette.green[400],
            // padding: '0px 12px',
            border: 'none !important'
        },

    },
    headerRight: {
        // padding: '11px 20px 12px',
        'img': {
            marginRight: '28px'
        }
    },
    select: {
        border: 'none !important',
        '&:hover': {
            borderColor: 'transparent',
        },
        '&.MuiSelect-root': {
            '&:hover': {
                borderColor: 'transparent', // Hide border on hover
            },
        },
    },
    selectLang: {
        'img': {
            marginRight: '20px'
        },
    },
    roleProfile: {
        background: style.palette.green[400],
        marginLeft: '16px',
        padding: '5px 33px 5px 12px',
        borderRadius: '4px',

        'img': {
            height: '32px',
            [baseTheme.breakpoints.down('lg')]: {
                height: '30px',
            },
            [baseTheme.breakpoints.up('lg')]: {
                height: '26px',
            },
        }
    },
    distributerProfile: {
        padding: '5px 33px 5px 12px',
        borderRadius: '4px',
        'img': {
            marginRight: '8px'
        },
    },
    hamburgerBtn: {
        cursor: 'pointer'
    },
    settingListStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        'i': {
            marginRight: '8px',
            height: '14px',
            color: style.palette.black[200],

        }
    },
    listStyle: {
        borderRadius: '12px',
        '& .MuiListItem-root': {
            padding: '4px 16px 6px 12px',
            width: '144px !important',
            '&:hover': {
                backgroundColor: style.palette.ActiveBgColor,
                '& .MuiTypography-root': {
                    color: style.palette.Primary
                },
                'i': {
                    color: style.palette.Primary
                }
            }
        },
        '& .MuiList-root': {
            padding: '0px',
            width: '144px !important'
        }
    }
}

const Header = ({ toggleSidebar }) => {
    const [lang, setLang] = useState('en');
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const  navigate = useNavigate();
    const { t } = useTranslation();
    const [headerState, setHeaderState] = useState({
        open: false,
        isOpenProfilePopup: undefined
    })
    const [anchorPositionModifyPopup, setAnchorPositionModifyPopup] = useState(undefined);
    const open = Boolean(anchorPositionModifyPopup);
    const id = open ? 'simple-popover' : undefined;
    const handleChange = (e) => {
        i18n.changeLanguage(e.target.value);
        setLang(e.target.value)
    }

    const customeIcon = () => {
        return (<>{
            headerState.open ? <img src={IMAGES.langUpArrow} alt='down' height={'8px'} /> :
                <img src={IMAGES.langDownArrow} alt='down' height={'8px'} />
        }
        </>)
    }



    const handleLanguageClick = () => {
        setHeaderState((prev) => {
            return {
                ...prev,
                open: !headerState.open
            }
        })
    }

    const handleLogout = async() => {
        console.log("handleLogout");
        const data = await dispatch(signOut());
        if(data){
            navigate("/");
        }
    }

    const handleClose = () => {
        console.log("handleClose");
        setAnchorPositionModifyPopup(undefined);
    }

    const handleProfileClick = (event) => {
        console.log("event", event);

        setAnchorPositionModifyPopup({
            top: event.clientY + 30,
            left: event.clientX,
        })
    }

    return (
        <Stack sx={Styles.root}>
            <header>
                <div className="header-left ps-4">
                    <img src={IMAGES.Hamburger} alt='Hamburger' height='14px' style={Styles.hamburgerBtn} onClick={toggleSidebar} />

                    <Box sx={Styles.roleProfile}>
                        <img src={IMAGES.DisLogo} alt='' />
                        <Stack>
                            <Typography variant='subtitle1'>
                                Caleb Baker
                            </Typography>
                            <Typography variant='body1'>
                                {t("DIS")}
                            </Typography>
                        </Stack>
                    </Box>
                </div>

                <Box sx={Styles.headerRight}>
                    <FormControl fullWidth>
                        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            open={headerState.open}
                            value={lang}
                            IconComponent={customeIcon}
                            // label="Age"
                            onChange={handleChange}
                            onClick={handleLanguageClick}
                            sx={Styles.select}
                        >
                            <MenuItem value={'en'} sx={Styles.selectLang}>
                                <img
                                    src={IMAGES.ENGLang}
                                    alt="img"
                                    height="20px"
                                /><Typography variant='subtitle1' >English</Typography>
                            </MenuItem>
                            <MenuItem value={'fe'} sx={Styles.selectLang}> <img
                                src={IMAGES.ENGLang}
                                alt="img"
                                height="20px"
                            /><Typography variant='subtitle1'>French</Typography></MenuItem>
                            <MenuItem value={'sp'} sx={Styles.selectLang}> <img
                                src={IMAGES.ENGLang}
                                alt="img"
                                height="20px"
                            /><Typography variant='subtitle1'>Spanish</Typography></MenuItem>
                        </Select>
                    </FormControl>

                    {/* <img src={IMAGES.GlobalSearchIcon} alt='search' height={'28px'} /> */}

                    <img src={IMAGES.BellIcon} alt='notification' height={'28px'} />

                    <Box sx={Styles.distributerProfile} onClick={handleProfileClick}>
                        <img src={IMAGES.DistributerProfile} alt='' height='32px' />
                        <Stack>
                            <Typography variant='subtitle1'>
                                Samuel Cooper
                            </Typography>
                            <Typography variant='body1'>
                                {t("Distributor")}
                            </Typography>
                        </Stack>
                    </Box>
                </Box>


            </header>
            {anchorPositionModifyPopup &&
                <Popover
                    id={id}
                    open={anchorPositionModifyPopup ? true : false}
                    // anchorEl={anchorPositionModifyPopup}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={{
                        top: 64,
                        left: 1468,
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                >
                    <ul className="navbar-nav">
                        <List aria-label="mailbox folders" sx={Styles.listStyle}>
                            <li className="nav-item">
                                <ListItem button>
                                    <Box sx={Styles.settingListStyle}>
                                        <i className='bx bxs-user'></i>
                                        <ListItemText primary="Profile" />
                                    </Box>
                                </ListItem>
                            </li>
                            <li className="nav-item">
                                <ListItem button onClick={handleLogout}>
                                    <Box sx={Styles.settingListStyle} >
                                        <i className='bx bxs-log-out-circle'></i>
                                        <ListItemText primary="Logout" />
                                    </Box>
                                </ListItem>
                            </li>
                        </List>
                    </ul>
                </Popover>
            }

        </Stack>
    )
}

export default Header
