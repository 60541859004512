import { Box, Stack } from '@mui/material';
import React from 'react'
import { IMAGES } from '../../../../asset/Dis';
import ComButtom from '../../../../components/formComponents/button/ComButtom';
import { useTranslation } from 'react-i18next';
import { style } from '../../../../Utils/themes/styles';

const Styles = {
    root: {
        padding: '20px 20px 0px 20px'
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: '20px',
        gap: '12px',
        padding: "4px 0px 16px",
    },
    buttonStyle: {
        width: "182px",
        height: "40px",
        color: style.palette.Neutral,
        // border: `1px solid ${style.palette.black[50]}`,
        borderRadius: "4px",
        '&:hover': {
            color: style.palette.Neutral,
        }
    },
    approveBtn: {
        backgroundColor: style.palette.Red,
        '&:hover': {
            backgroundColor: style.palette.Red,
        }
    },
    rejectBtn: {
        backgroundColor: style.palette.DarkBlue,
        '&:hover': {
            backgroundColor: style.palette.DarkBlue,
        }
    }
}

interface IValidateLoadingPhoto {
    handleValidateLoadingPhoto: (value: boolean) => void;
}

const ValidateLoadingPhoto = ({ handleValidateLoadingPhoto }: IValidateLoadingPhoto) => {
    const { t } = useTranslation();
    return (
        <Box sx={Styles.root}>
            <img src={IMAGES.loadingPhoto} alt="photo" height={'400px'} />
            <Stack sx={Styles.footer}>
                <ComButtom
                    label={"Reject"}
                    variant={"outlined"}
                    textVariant="subtitle1"
                    inputStyle={{ ...Styles.buttonStyle, ...Styles.rejectBtn }}
                    onClick={() => handleValidateLoadingPhoto(false)}
                />
                <ComButtom
                    label={"Approve"}
                    variant={"outlined"}
                    textVariant="subtitle1"
                    inputStyle={{ ...Styles.buttonStyle, ...Styles.approveBtn }}
                    onClick={() => handleValidateLoadingPhoto(true)}
                />
            </Stack>
        </Box>
    )
}

export default ValidateLoadingPhoto