import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { chartData } from '../../../common/interfaces';
import CanvasJSReact from '@canvasjs/react-charts';
import { style } from '../../../../Utils/themes/styles';
import { Box } from '@mui/material';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Styles = {
  root: {
    '& .canvasjs-chart-credit': {
      display: 'none !important'
    },
  }
}
interface IDoughnutChart {
  data: chartData;
  subTitle?: string;
  colors?: string[];
}

const DoughnutChart = ({ data, subTitle, colors }: IDoughnutChart) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart<'doughnut'> | null>(null);
  const Options = {
    animationEnabled: true,
    colorSet: "customColorSet",
    title: {
      text: ""
    },
    subtitles: [{
      text: subTitle,
      verticalAlign: "center",
      fontSize: 20,
      color: style.palette.black[200],
      dockInsidePlotArea: true
    }],

    data: [{
      type: "doughnut",
      showInLegend: false,
      indexLabel: "",
      yValueFormatString: "#,###'%'",
      dataPoints: [
        { name: "Loading", y: 50 },
        { name: "Unloading", y: 20 },
        { name: "Reciept", y: 3 },
        { name: "Delivery", y: 15 },
      ],

    }]
  }


  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstance.current) {
        chartInstance?.current?.destroy(); // Destroy existing instance before rendering a new one
      }
      CanvasJSReact.CanvasJS.addColorSet('customColorSet', colors || [`${style.palette.PurpleStroke}`, `${style.palette.Orange}`, `${style.palette.Primary}`, `${style.palette.Yellow}`]);
      const ctx = chartRef.current.getContext('2d') as CanvasRenderingContext2D
   
      chartInstance.current = new Chart(ctx, {
        type: 'doughnut',
        data: {
          datasets: [{
            data: data.values,
            borderWidth: 1,
            backgroundColor: data.colors
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
            },
          }
        }
      });
    }


    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy(); // Destroy chart instance on unmount
      }
    };
  }, [data]);

  return (
    <Box sx={Styles.root}>
      <CanvasJSChart options={Options} />
    </Box>)
};

export default DoughnutChart;
