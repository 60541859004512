import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/reducers/userReducer';
import { loadState, saveState } from './localstorage';
import { SIGN_OUT } from './user/actions/logout';
import warehouseReducer from './warehouse/reducers/warehouseReducer';

const mainReducer = combineReducers({
    userReducer,
    warehouseReducer
})

export type RootState = ReturnType<typeof mainReducer>;

const rootReducer = (state:RootState | undefined, action:any) => {
    if (action.type === SIGN_OUT) {
      return mainReducer(undefined, action);
    }
  
    return mainReducer(state, action);
  };
  const persistedState = loadState();
  
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: persistedState,
  });

  store.subscribe(() => {
    saveState(store.getState());
  });
  
  export type AppDispatch = typeof store.dispatch;
  
  store.dispatch({ type: 'Redux Init' });
  
  export default store;