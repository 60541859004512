import { createTheme } from "@mui/material/styles";

const baseTheme = createTheme({
    typography: {
        fontFamily: [ 'Poppins' ].join(',')
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 769,
            lg: 1025,
            xl: 1366,
            xxl: 1440,
            xxxl: 1920,
        },

    },
})

export { baseTheme };