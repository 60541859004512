import React from 'react'
import ComButtom from '../../../../components/formComponents/button/ComButtom'
import { useTranslation } from 'react-i18next';

const Styles = {
    buttonStyle: {
        // width: "115px",
        height: "40px",
        // border: `1px solid ${style.palette.black[50]}`,
        borderRadius: "4px",
    },
}

interface ILogisticsDetailDIFooter {
    handleConfirmLoading?: () => void;
    confirmLoading?: boolean;
    confirmFinalValidation?: string
}

const LogisticsDetailDIFooter = ({ handleConfirmLoading, confirmLoading, confirmFinalValidation }: ILogisticsDetailDIFooter) => {
    const { t } = useTranslation();
    console.log("confirmLoading", confirmLoading);

    return (
        <>
            {confirmLoading &&
                <ComButtom
                    label={t("ConfirmLoading")}
                    variant={"contained"}
                    textVariant="subtitle1"
                    inputStyle={Styles.buttonStyle}
                    onClick={handleConfirmLoading}
                />
            }
            {confirmFinalValidation === 'Approved' &&
                <ComButtom
                    label={"Appointment Closed"}
                    variant={"contained"}
                    textVariant="subtitle1"
                    inputStyle={Styles.buttonStyle}
                    // onClick={handleConfirmLoading}
                />
            }
        </>
    )
}

export default LogisticsDetailDIFooter