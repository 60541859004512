import { createTheme } from "@mui/material/styles";
import { baseTheme } from "./base";
import { ITheme } from "./styleInterface";

const theme = createTheme(baseTheme, {
  palette: {
    green: {
      50: "#E6F2F0",
      100: "#B0D6D1",
      200: "#8AC2BA",
      300: "#54A69B",
      400: "#339588",
      500: "#007A6A",
      600: "#006F60",
      700: "#00574B",
      800: "#00433A",
      900: "#00332D",
    },
    black: {
      50: "#E6E6E6",
      100: "#B0B0B0",
      200: "#8A8A8A",
      300: "#545454",
      400: "#333333",
      500: "#000000",
      600: "#000000",
      700: "#000000",
      800: "#000000",
      900: "#000000",
    },

    Neutral: "#FFFFFF",
    Primary: "#007A6A",
    TitleColor: "#36404D",
    BtnBgColor: "#F9F9F9",
    HeaderBgColor: "#339588",
    ContentBgColor: "#F2F2F2",
    GrayStroke: "#E1E1E1",
    ActiveBgColor: "#F6FAF9",
    PurpleStroke: "#531BB5",
    BgColor: "#F9F9F9",
    AppointmentBgColor: "#F9F8FC",
    CellDataNotFoundColor: "#FF0000",
    divederColor: "#E6E6E642",
    formSectionBgColor: "#E6E6E620",

    Red: "#FF0909",
    Green: "#78D600",
    DarkRed: "#B71C1C",
    LightGreen: "Green",
    Orange: "#F49600",
    Yellow: "#FECF16",
    Blue: "#2C88D9",
    DarkBlue: "#153351",
    Purple: "#531BB5",

    BlueBgColor: "#2C88D9",
    GreenBgColor: "#007A6A",
    Purple50Stroke: "#531BB58",
    DeliveryBgColor: "#007A6A26",
    LoadingBgColor: "#2C88D926",
    UnloadingBgColor: "#F4960026",
    ReceiptBgColor: "#531BB526",
    Purple50BgColor: "#531BB526",
    ViewIconBGColor: "#2C88D915",
    EditIconBGColor: "#007A6A15",
    eventBgColor: "#531BB503",
    unloadingEventBgColor: "#F4960015",
    RecieptEventBgColor: "#531BB515",
    InProgressBgColor: "#F9F5E3",
    InProgressTextColor: "#D1AF29",
    waitingTextBgColor: "#D1AF2913",

    gradient: {
      GreenLInearStroke:
        "linear-gradient(90deg, #ADFDF2 -4.99%,#007A6A 107.44%)",
      PurpleLinearColor:
        "linear-gradient(180deg, #531BB5 -15.4%, rgba(255, 255, 255, 0.00) 107.87%)",
      OrangeLinearColor:
        "linear-gradient(180deg, #F6A417 -15.4%, rgba(255, 255, 255, 0.00) 107.87%)",
      GreenLinearColor:
        "linear-gradient(180deg, #007A6A -15.4%, rgba(255, 255, 255, 0.00) 107.87%)",
      YellowLinearColor:
        "linear-gradient(180deg, #FECF16 -15.4%, rgba(255, 255, 255, 0.00) 107.87%)",
    },
  },
  typography: {
    h1: {},
    h2: {
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: 600,
    },
    h3: {
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    h4: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
    h5: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
    h6: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
    },
    subtitle1: {
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
    subtitle2: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
    body1: {
      fontFamily: "Poppins",
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "normal",
      textAlign: "left",
    },
    body2: {
      fontFamily: "Poppins",
      fontSize: "10px",
      fontWeight: 500,
      lineHeight: "normal",
    },
    button: {
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      textTransform: "capitalize",
    },
    caption: {
      fontFamily: "Poppins",
      fontSize: "8px",
      fontWeight: 500,
      lineHeight: "normal",
    },
    overline: {},
    subHeading2: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      textAlign: "left",
    },
  },
}) as unknown as ITheme;

export { theme, theme as style };
