import { Stack, Typography } from '@mui/material'
import React from 'react'
import { style } from '../../../Utils/themes/styles'
import { baseTheme } from '../../../Utils/themes/base'

const Styles = {
    root: {
        marginBottom:'20px',
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-start',
        '& .MuiTypography-button': {
            color: style.palette.green[500],
            [baseTheme.breakpoints.down('lg')]: {
                fontSize:"18px"
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize:"18px"
            }
        }
    }
}

const Heading = ({ title }) => {
    return (
        <Stack sx={Styles.root}>
            <Typography variant='button'>{title}</Typography>
        </Stack>
    )
}

export default Heading