import Request from "../../../Axios/Instance";
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../../store';
import { AxiosResponse } from "axios";
import { getWarehouses } from "./getWarehouse";

export const ADD_INVENTORY = "ADD_INVENTORY";
export const ADD_INVENTORY_ERROR = "RECEIVE_INVENTORY_ERROR";

export const addInventory = (data: any) => ({
    type: ADD_INVENTORY,
    payload: data
})

export const addInventoryError = (err: unknown) => ({
    type: ADD_INVENTORY_ERROR,
    payload: err
})

export const postWarehouseInventory = (payload: any): ThunkAction<Promise<AxiosResponse<any>>, RootState, unknown, AnyAction> =>
    (dispatch, getState) =>
        new Promise((resolve, reject) => {
            console.log("payload", payload);
            const fetchPost = async () => {
                try {
                    const response = await Request.post(`/warehouse/inventory`, payload);
                    // await dispatch(getWarehouses());
                    dispatch(addInventory(response?.data?.data));
                    resolve(response.data);
                } catch (err) {
                    dispatch(addInventoryError(err));
                    reject(err);
                }
            }
            fetchPost();
        });

