import { Box, Grid } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next';
import { IMAGES } from '../../../../asset/Dis';
import MiniFormContainer from '../../../common/components/MiniFormContainer';
import { baseTheme } from '../../../../Utils/themes/base';

const Styles = {
    popupBox: {
        width: "583px",
        height: "360px",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        [baseTheme.breakpoints.down('sm')]: {
            width: "520px"
        },
    },
    popUpContainer: {
        height: '100%',
        borderRadius: "12px",
    },
    childComponent: {
        height: '81%',
        padding: "0 20px",
        overflowX: "hidden"
    }
}

interface IAddLogistician {
    setNewLogisticianStatus: Dispatch<SetStateAction<boolean>>;
    selection?: string;
    selectedWarehouse?:string;
}

const AddLogistician = ({ setNewLogisticianStatus, selection ,selectedWarehouse }: IAddLogistician) => {

    const handleClose = () => {
        setNewLogisticianStatus(false);
    }
    const { t } = useTranslation();
    return (
        <Box>
            <Grid item xs={6} sx={Styles.popupBox}>
                <MiniFormContainer
                    leftContainer={t("AddNewLogistician")}
                    rightContainer={
                        <img src={IMAGES.crossIcon} alt='search' height='36px' onClick={handleClose} />
                    }
                    sx={Styles.popUpContainer}
                    childContainerStyle={Styles.childComponent}
                    submitText={t("Submit")}
                    selection={selection}
                    handleStatus={setNewLogisticianStatus}
                    selectedWarehouse={selectedWarehouse}
                />
            </Grid>
        </Box>
    )
}
export default AddLogistician;