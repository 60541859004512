import { IMAGES } from "../asset/Dis"

export const SIDEBAR_MENU_CODE = {
    HOME:'Home',
    DASHBOARD: 'Dashboard',
    AGENDA: 'Agenda',
    CLIENT: 'Client',
    PARTNERS: 'Partners',
    NOTIFICATION: 'Notification',
    ASSISTANCE: 'Assistance',
    LOG: 'Log',
    OFFICES: 'Offices',
    TECHNIQUE: 'Technique',
    STOCKS: 'Stocks',
    EXPORTS: 'Exports',
    WAREHOUSE: 'Warehouse',
    LOGISTICS: 'Logistics',
    SETTINGS: 'Settings',

}

export const DEFAULT_SIDEBAR = [
    {
        id: 0,
        code: SIDEBAR_MENU_CODE.DASHBOARD,
        name: "Dashboard",
        toPath: '/dashboard',
        icon: <svg
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M4 11h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1Zm10 0h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1ZM4 21h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1Zm13 0c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4Z" />
        </svg>
    },
]

export const PSC_SIDEBAR = [
    {
        id: 0,
        code: SIDEBAR_MENU_CODE.DASHBOARD,
        name: "Dashboard",
        toPath: '/dashboard',
        icon: <svg
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M4 11h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1Zm10 0h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1ZM4 21h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1Zm13 0c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4Z" />
        </svg>
    },
    {
        id: 1,
        code: SIDEBAR_MENU_CODE.AGENDA,
        name: "Agenda",
        toPath: '/agenda',
        icon: <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2Zm11-6H8v-2h8v2ZM5 7h14v2H5V7Z"></path>
        </svg>
    },
    {
        id: 2,
        code: SIDEBAR_MENU_CODE.PARTNERS,
        name: "Partners",
        toPath: '/partners',
        icon: <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm0 8a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1h2Z"></path>
        </svg>
    },
    {
        id: 3,
        code: SIDEBAR_MENU_CODE.CLIENT,
        name: "Clients",
        toPath: '/clients',
        icon: <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2a4.505 4.505 0 0 0-4.5 4.5ZM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17Z"></path>
        </svg>
    },
    {
        id: 4,
        code: SIDEBAR_MENU_CODE.NOTIFICATION,
        name: "Notification",
        toPath: '/notification',
        icon: <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="m5.705 3.71-1.41-1.42C1 5.563 1 7.935 1 11h1l1-.063C3 8.009 3 6.396 5.705 3.71Zm13.999-1.42-1.408 1.42C21 6.396 21 8.009 21 11l2-.063c0-3.002 0-5.374-3.296-8.647ZM12 22a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22Zm7-7.414V10c0-3.217-2.185-5.927-5.145-6.742A1.99 1.99 0 0 0 12 2a1.99 1.99 0 0 0-1.855 1.258C7.184 4.073 5 6.783 5 10v4.586l-1.707 1.707A.996.996 0 0 0 3 17v1a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-1a.996.996 0 0 0-.293-.707L19 14.586Z"></path>
        </svg>
    },
    {
        id: 5,
        code: SIDEBAR_MENU_CODE.ASSISTANCE,
        name: "Assistance",
        toPath: '/assistance',
        icon: <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 12v-1.708c0-4.442-3.479-8.16-7.755-8.29-2.204-.05-4.251.736-5.816 2.256A7.933 7.933 0 0 0 4 10v2c-1.103 0-2 .897-2 2v4c0 1.103.897 2 2 2h2V10a5.95 5.95 0 0 1 1.821-4.306 5.977 5.977 0 0 1 4.363-1.69C15.392 4.097 18 6.92 18 10.292v9.706h2c1.103 0 2-.897 2-2v-4c0-1.103-.897-2-2-2Z"></path>
            <path d="M7 12h2v8H7v-8Zm8 0h2v8h-2v-8Z"></path>
        </svg>
    },
    {
        id: 7,
        code: SIDEBAR_MENU_CODE.LOG,
        name: "Log",
        toPath: '/log',
        icon: <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6h2v2H4V6Zm0 5h2v2H4v-2Zm0 5h2v2H4v-2Zm16-8V6H8.023v2H20ZM8 11h12v2H8v-2Zm0 5h12v2H8v-2Z"></path>
        </svg>
    },

]

export const CEO_SIDEBAR = [
    {
        id: 0,
        code: SIDEBAR_MENU_CODE.DASHBOARD,
        name: "Dashboard",
        toPath: '/dashboard',
        icon: <svg
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M4 11h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1Zm10 0h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1ZM4 21h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1Zm13 0c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4Z" />
        </svg>
    },
    {
        id: 1,
        code: SIDEBAR_MENU_CODE.AGENDA,
        name: "Agenda",
        toPath: '/agenda',
        icon: <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2Zm11-6H8v-2h8v2ZM5 7h14v2H5V7Z"></path>
        </svg>
    },
    {
        id: 2,
        code: SIDEBAR_MENU_CODE.OFFICES,
        name: "Offices",
        toPath: '/offices',
        icon: <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 7h-6a1 1 0 0 0-1 1v3h-2V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1ZM8 6h2v2H8V6ZM6 16H4v-2h2v2Zm0-4H4v-2h2v2Zm0-4H4V6h2v2Zm4 8H8v-2h2v2Zm0-4H8v-2h2v2Zm9 4h-2v-2h2v2Zm0-4h-2v-2h2v2Z"></path>
        </svg>
    },
    {
        id: 3,
        code: SIDEBAR_MENU_CODE.CLIENT,
        name: "Client",
        toPath: '/clients',
        icon: <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2a4.505 4.505 0 0 0-4.5 4.5ZM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17Z"></path>
        </svg>
    },
    {
        id: 4,
        code: SIDEBAR_MENU_CODE.TECHNIQUE,
        name: "Technique",
        toPath: '/technique',
        icon: <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="m5.705 3.71-1.41-1.42C1 5.563 1 7.935 1 11h1l1-.063C3 8.009 3 6.396 5.705 3.71Zm13.999-1.42-1.408 1.42C21 6.396 21 8.009 21 11l2-.063c0-3.002 0-5.374-3.296-8.647ZM12 22a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22Zm7-7.414V10c0-3.217-2.185-5.927-5.145-6.742A1.99 1.99 0 0 0 12 2a1.99 1.99 0 0 0-1.855 1.258C7.184 4.073 5 6.783 5 10v4.586l-1.707 1.707A.996.996 0 0 0 3 17v1a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-1a.996.996 0 0 0-.293-.707L19 14.586Z"></path>
        </svg>
    },
    {
        id: 5,
        code: SIDEBAR_MENU_CODE.PARTNERS,
        name: "Partners",
        toPath: '/partners',
        icon: <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 12c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm1.5 1H8c-3.309 0-6 2.691-6 6v1h15v-1c0-3.309-2.691-6-6-6z"></path><path d="M16.604 11.048a5.67 5.67 0 0 0 .751-3.44c-.179-1.784-1.175-3.361-2.803-4.44l-1.105 1.666c1.119.742 1.8 1.799 1.918 2.974a3.693 3.693 0 0 1-1.072 2.986l-1.192 1.192 1.618.475C18.951 13.701 19 17.957 19 18h2c0-1.789-.956-5.285-4.396-6.952z"></path>
        </svg>
    },
    {
        id: 6,
        code: SIDEBAR_MENU_CODE.STOCKS,
        name: "Stocks",
        toPath: '/stocks',
        icon: <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="m3.553 18.896 4 2a1 1 0 0 0 .894 0L12 19.119l3.553 1.776a.99.99 0 0 0 .894 0l4-2a1 1 0 0 0 .553-.894v-5a1 1 0 0 0-.553-.895L17 10.383V6a1 1 0 0 0-.553-.895l-4-2a1 1 0 0 0-.895 0l-4 2A1.004 1.004 0 0 0 7 6v4.382l-3.447 1.724A.998.998 0 0 0 3 13v5c0 .379.214.725.553.895ZM8 12.119l2.264 1.132-2.913 1.457-2.264-1.132L8 12.119Zm4-2.5 3-1.5v2.264l-3 1.5V9.619Zm6.264 3.632-2.882 1.44-2.264-1.131L16 12.119l2.264 1.132ZM8 18.883l-.062-.031V16.65L11 15.119v2.264l-3 1.5Zm8 0v-2.264l3-1.5v2.264l-3 1.5ZM12 5.119l2.264 1.132-2.882 1.44L9.118 6.56 12 5.119Z"></path>
        </svg>
    },
    {
        id: 7,
        code: SIDEBAR_MENU_CODE.EXPORTS,
        name: "Exports",
        toPath: '/exports',
        icon: <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 22a2 2 0 0 0 2-2v-5l-5 4v-3H8v-2h7v-3l5 4V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12ZM13 4l5 5h-5V4Z"></path>
        </svg>
    },
    {
        id: 8,
        code: SIDEBAR_MENU_CODE.NOTIFICATION,
        name: "Notification",
        toPath: '/notification',
        icon: <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="m5.705 3.71-1.41-1.42C1 5.563 1 7.935 1 11h1l1-.063C3 8.009 3 6.396 5.705 3.71Zm13.999-1.42-1.408 1.42C21 6.396 21 8.009 21 11l2-.063c0-3.002 0-5.374-3.296-8.647ZM12 22a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22Zm7-7.414V10c0-3.217-2.185-5.927-5.145-6.742A1.99 1.99 0 0 0 12 2a1.99 1.99 0 0 0-1.855 1.258C7.184 4.073 5 6.783 5 10v4.586l-1.707 1.707A.996.996 0 0 0 3 17v1a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-1a.996.996 0 0 0-.293-.707L19 14.586Z"></path>
        </svg>
    },
    {
        id: 9,
        code: SIDEBAR_MENU_CODE.ASSISTANCE,
        name: "Assistance",
        toPath: '/assistance',
        icon: <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 12v-1.708c0-4.442-3.479-8.16-7.755-8.29-2.204-.05-4.251.736-5.816 2.256A7.933 7.933 0 0 0 4 10v2c-1.103 0-2 .897-2 2v4c0 1.103.897 2 2 2h2V10a5.95 5.95 0 0 1 1.821-4.306 5.977 5.977 0 0 1 4.363-1.69C15.392 4.097 18 6.92 18 10.292v9.706h2c1.103 0 2-.897 2-2v-4c0-1.103-.897-2-2-2Z"></path>
            <path d="M7 12h2v8H7v-8Zm8 0h2v8h-2v-8Z"></path>
        </svg>
    },
    {
        id: 10,
        code: SIDEBAR_MENU_CODE.LOG,
        name: "Log",
        toPath: '/log',
        icon: <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6h2v2H4V6Zm0 5h2v2H4v-2Zm0 5h2v2H4v-2Zm16-8V6H8.023v2H20ZM8 11h12v2H8v-2Zm0 5h12v2H8v-2Z"></path>
        </svg>
    }
]

export const DIS_SIDEBAR = [
    {
        id: 0,
        code: SIDEBAR_MENU_CODE.HOME,
        name: "Home",
        toPath: '/home',
        icon: IMAGES.HomeIcon
    },
    {
        id: 1,
        code: SIDEBAR_MENU_CODE.AGENDA,
        name: "Agenda",
        toPath: '/agenda',
        icon: IMAGES.AgendaIcon
    },
    {
        id: 2,
        code: SIDEBAR_MENU_CODE.WAREHOUSE,
        name: "Warehouse",
        toPath: '/warehouse',
        icon: IMAGES.WarehouseIcon
    },
    {
        id: 3,
        code: SIDEBAR_MENU_CODE.LOGISTICS,
        name: "Logistics",
        // toPath: '/logistics',
        icon: IMAGES.LogisticsIcon,
        routes: [
            {
              path: "/loading",
              name: 'Loading'
            },
            {
              path: "/unloading",
              name: "Unloading",
            },
            {
              path: "/delivery",
              name: "Delivery",
            },
            {
              path: "/receipt",
              name: "Receipt"
            }
          ],
    },
    {
        id: 4,
        code: SIDEBAR_MENU_CODE.SETTINGS,
        name: "Settings",
        toPath: '/Settings',
        icon: IMAGES.SettingsIcon
    },
   
]



export const getSidbarMenu = (userRole) => {
    switch (userRole) {
        case 'PSC':
            return PSC_SIDEBAR;

        case 'CEO':
            return CEO_SIDEBAR;

        case 'DIS':
            return DIS_SIDEBAR;

        default:
            return DEFAULT_SIDEBAR;
    }
}